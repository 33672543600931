import { useAPI } from 'common-components';

export const GET_FAQ_RECORDS = '/api/faq/';

interface FAQRecord {
  id: number;
  question: string;
  answer: string;
  order: number;
}

export const useFAQAPI = () => {
  return useAPI<Array<FAQRecord>>(GET_FAQ_RECORDS);
};
