import { Stack, Typography } from '@mui/material';
import { AlignTable, FontAwesomeIcon, HeadCell, NoDataAvailable, Table, Tooltip, checkNoData } from 'common-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { parseVisitFilterData } from './constants';
import { Category, GroupField } from './enums';
import { FilterForm } from './filterform';
import { useAudienceFieldsAPI } from './selectors';
import { FormFieldConfig, TableProps } from './types';

interface VisitFiltersTableProps extends TableProps {
  category: Category;
}

export const VisitFiltersTable: React.FC<VisitFiltersTableProps> = ({ category, values, registerField }) => {
  const { t } = useTranslation('audienceexport');
  const [audienceFields] = useAudienceFieldsAPI();

  const dataSources = useMemo(() => {
    const allVisitFilters = parseVisitFilterData(audienceFields.data);
    return allVisitFilters.visitsFilter.reduce<FormFieldConfig[]>((acc, curr, idx) => {
      if (curr.category.includes(category)) {
        acc.push({ ...curr, formIndex: idx });
      }
      return acc;
    }, []);
  }, [audienceFields.data, category]);

  const noVisitsData = useMemo(() => checkNoData(dataSources), [dataSources]);

  const columns = useMemo<Array<HeadCell<FormFieldConfig>>>(
    () => [
      {
        title: t('visitFilter.optionColumn'),
        dataIndex: 'fieldName',
        width: '30%',
        render: (type, row: FormFieldConfig) => {
          if (!row.description) return row.fieldName;
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body2">{row.fieldName}</Typography>
              <Tooltip title={row.description}>
                <Typography>
                  <FontAwesomeIcon size="lg" icon={['fal', 'circle-info']} />
                </Typography>
              </Tooltip>
            </Stack>
          );
        },
      },
      {
        title: t('visitFilter.filterColumn'),
        dataIndex: 'type',
        width: '70%',
        align: 'left' as AlignTable,
        render: (type, row: FormFieldConfig) => (
          <FilterForm
            key={`visitFields_${row.formIndex}`}
            operator={audienceFields.data?.operators}
            groupName={GroupField.VisitFields}
            index={row.formIndex}
            rowValue={values.visitFields[row.formIndex]}
            rowConfig={row}
            registerField={registerField}
          />
        ),
      },
    ],
    [registerField, t, values.visitFields, audienceFields.data?.operators]
  );

  if (noVisitsData) return <NoDataAvailable />;
  return (
    <Table
      columns={columns}
      dataSources={dataSources}
      tableContainerProps={{ sx: { flex: 1, flexBasis: 0, overflowX: 'unset' } }}
      tableProps={{ stickyHeader: true, sx: { tableLayout: 'fixed' } }}
    />
  );
};
