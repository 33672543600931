export enum ContentType {
  B2B = 'B2B',
  B2C = 'B2C',
}

export enum AnalyzerType {
  Age = 'age',
  Income = 'income',
  Gender = 'gender',
  Married = 'married',
  Children = 'children',
  Homeowner = 'homeowner',
  NetWorth = 'net_worth',
  SeniorityLevel = 'seniority_level',
  Department = 'department',
  Geography = 'geography',
  CompanyGeography = 'companyGeography',
  ContactGeography = 'contactGeography',
  CompanyRevenue = 'company_revenue',
  CompanyEmployeeCount = 'company_employee_count',
}

export enum UnitType {
  Revenue = 'revenue',
  Employees = 'employees',
}

export enum GeographyViewMode {
  Map = 'map',
  Regions = 'regions',
}
export interface TrafficInsightsDataAvailability {
  [key: string]: number;
}
export interface Frequency {
  dataField: AnalyzerType;
  value: string;
  sequence: number;
  percent: number;
  count: number;
}
export type TrafficInsightsDataFrequency = Frequency[];
export type TrafficInsightsGeoMappings = { latitude: string; longitude: string; count: number }[];
export type TrafficInsightsGeoMappingsByUSGroupings = { value: string; count: number }[];
export interface ChartData {
  label: string[];
  values: number[];
}
