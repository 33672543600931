import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Site, SiteContextValue } from '../context';
import { useUserSessionAPI } from '../selectors';
import { useAPI } from '../state';
var USERS_API = '/api/user';
var ORGANIZATION_USERS_API = '/api/user/all-org-users/{orgId}';
var UPDATE_ORGANIZATION_API = '/api/organization';
var GET_ORGANIZATION_DETAIL_API = '/api/organization/{orgId}';
var PLAN_DETAIL_BY_ORG_ID_API = '/api/plan/detail?orgId={orgId}';
var PLAN_DETAIL_API = '/api/plan';
export var useUsersAPI = function () {
    var _a = useParams().orgId, orgId = _a === void 0 ? '' : _a;
    var _b = useContext(SiteContextValue).site, site = _b === void 0 ? Site.Portal : _b;
    return useAPI(site === Site.Admin ? ORGANIZATION_USERS_API : USERS_API, { orgId: orgId });
};
export var useUpdateOrganizationAPI = function () {
    var _a = useUserSessionAPI(), fetchUserSessionData = _a[1];
    var _b = useAPI(UPDATE_ORGANIZATION_API, undefined, 'PUT'), data = _b[0], fetch = _b[1], clear = _b[2];
    var _c = useContext(SiteContextValue).site, site = _c === void 0 ? Site.Portal : _c;
    useEffect(function () {
        if (data.data && site === Site.Portal) {
            fetchUserSessionData();
        }
    }, [data.data, fetchUserSessionData, site]);
    return [data, fetch, clear];
};
export var usePlanDetail = function () {
    var _a = useParams().orgId, orgId = _a === void 0 ? '' : _a;
    var _b = useContext(SiteContextValue).site, site = _b === void 0 ? Site.Portal : _b;
    return useAPI(site === Site.Admin ? PLAN_DETAIL_BY_ORG_ID_API : PLAN_DETAIL_API, { orgId: orgId });
};
export var useOrganizationDetailAPI = function () {
    var _a;
    var _b = useParams().orgId, orgId = _b === void 0 ? '' : _b;
    var userSessionData = useUserSessionAPI()[0];
    return useAPI(GET_ORGANIZATION_DETAIL_API, { orgId: orgId || ((_a = userSessionData.data) === null || _a === void 0 ? void 0 : _a.orgId) });
};
export var useAccountManagementExcel = function () {
    var t = useTranslation('accountmanagement').t;
    var users = useUsersAPI()[0];
    var excelData = useMemo(function () { return ({
        name: t('users'),
        sheets: [
            {
                name: t('users'),
                header: {
                    lastName: t('fieldName.lastName'),
                    firstName: t('fieldName.firstName'),
                    title: t('fieldName.title'),
                    mobilePhone: t('fieldName.mobilePhone'),
                    email: t('fieldName.email'),
                    'role.name': t('fieldName.role'),
                    lastLogin: t('fieldName.lastLogin'),
                },
                data: users.data || [],
                transform: {
                    lastLogin: function (value) {
                        return value
                            ? t('dateTimeFormat.longDateTime', { val: moment(value).toDate(), ns: 'translation' })
                            : t('text.never', { ns: 'translation' });
                    },
                },
            },
        ],
    }); }, [users.data, t]);
    return [excelData];
};
