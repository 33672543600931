import { Box, Grid, Stack, ThemeProvider } from '@mui/material';
import {
  AUDIT_EVENTS,
  FallbackComponent,
  FullscreenContainer,
  GA_EVENTS,
  PortalPageName,
  themeLevel3,
  useAudit,
  useGA4,
  useLastRefreshedAPI,
} from 'common-components';
import _ from 'lodash';
import { useEffect } from 'react';
import { withSuspense } from 'react-suspenser';
import { GroupAnalysis } from './groupanalysis';
import { Header } from './header';
import { IdealLocationDetail } from './ideallocationdetail';
import { Insights } from './insights';
import { ReferralChannel } from './referralchannel';
import { ResponseProfileExplorer } from './responseprofileexplorer';
import { useLookupCampaignsAPI, useMetricCountAPI } from './selectors';
import { StatSummary } from './statsummary';

export const AnalysisComponents = () => {
  const [sendAuditEvent] = useAudit();
  useEffect(() => sendAuditEvent({ action: AUDIT_EVENTS.campaign360.view }), [sendAuditEvent]);

  return (
    <Box flex={1} display={'flex'}>
      <Grid flex={1} container spacing={5.5}>
        <Grid container item xs={8}>
          <Grid flex={1} container spacing={5.5} direction="column" wrap="nowrap" minHeight="39rem">
            <Grid container item xs={12} height="50%" spacing={5.5}>
              <Grid container item xs={6} maxHeight="100%">
                <StatSummary />
              </Grid>
              <Grid container item xs={6} maxHeight="100%">
                <ResponseProfileExplorer />
              </Grid>
            </Grid>
            <Grid container item xs={12} height="50%" spacing={5.5}>
              <Grid container item xs={6} maxHeight="100%">
                <GroupAnalysis />
              </Grid>
              <Grid container item xs={6} maxHeight="100%">
                <IdealLocationDetail />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={4}>
          <Grid flex={1} container spacing={5.5} direction="row" minHeight="39rem">
            <Grid container item xs={12} height="50%">
              <Insights />
            </Grid>
            <Grid container item xs={12} height="50%">
              <ReferralChannel />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const P360Analysis = withSuspense()(() => {
  const [, , putEvent] = useGA4();
  const [campaignsData, fetchCampaignsData, clearCampaignsData] = useLookupCampaignsAPI();
  const [metricCountData, fetchMetricCountData, clearMetricCountData] = useMetricCountAPI();
  const [lastRefreshedData] = useLastRefreshedAPI(PortalPageName.campaign360);

  useEffect(
    () => putEvent({ category: GA_EVENTS._360InsightsDashboard.__name, action: GA_EVENTS._360InsightsDashboard.view }),
    [putEvent]
  );

  useEffect(() => {
    if (!lastRefreshedData.requesting && lastRefreshedData.data) fetchCampaignsData(undefined, true);
    return clearCampaignsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCampaignsData, fetchCampaignsData, lastRefreshedData.requesting]);

  useEffect(() => {
    if (!_.isEmpty(campaignsData.data)) {
      fetchMetricCountData(undefined, true);
    }
    return clearMetricCountData;
  }, [clearMetricCountData, fetchMetricCountData, campaignsData.data]);

  return (
    <ThemeProvider theme={themeLevel3}>
      <Stack flex={1} spacing={5.5}>
        <Header
          campaignsData={campaignsData.data ?? []}
          requestingCampaign={campaignsData.requesting}
          metricCountData={metricCountData.data ?? {}}
        />
        <FallbackComponent requesting={(campaignsData?.requesting || lastRefreshedData?.requesting) ?? false}>
          <FullscreenContainer>
            <AnalysisComponents />
          </FullscreenContainer>
        </FallbackComponent>
      </Stack>
    </ThemeProvider>
  );
});
