import { Card, CardContent, CardHeader, checkNoData } from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AudienceExportType, FilterFieldId, Operators } from '../../audienceexport/enums';
import { FilterItem } from '../../audienceexport/types';
import { SiteEngagementCategoryLabel } from '../constants';
import { useEngagementAction, useSiteEngagementAPI } from '../selectors';
import { EngagementPieChart } from './piechart';

const LabelFilterMap: Record<SiteEngagementCategoryLabel, FilterItem[]> = {
  [SiteEngagementCategoryLabel.singleVisitSinglePage]: [
    {
      fieldId: FilterFieldId.UniquePagesVisited,
      operator: Operators.Equal,
      values: [1],
    },
    {
      fieldId: FilterFieldId.Visits,
      operator: Operators.Equal,
      values: [1],
    },
  ],
  [SiteEngagementCategoryLabel.singleVisitMultiPage]: [
    {
      fieldId: FilterFieldId.UniquePagesVisited,
      operator: Operators.MoreThan,
      values: [1],
    },
    {
      fieldId: FilterFieldId.Visits,
      operator: Operators.Equal,
      values: [1],
    },
  ],
  [SiteEngagementCategoryLabel.multiVisitSinglePage]: [
    {
      fieldId: FilterFieldId.UniquePagesVisited,
      operator: Operators.Equal,
      values: [1],
    },
    {
      fieldId: FilterFieldId.Visits,
      operator: Operators.MoreThan,
      values: [1],
    },
  ],
  [SiteEngagementCategoryLabel.multiVisitMultiPage]: [
    {
      fieldId: FilterFieldId.UniquePagesVisited,
      operator: Operators.MoreThan,
      values: [1],
    },
    {
      fieldId: FilterFieldId.Visits,
      operator: Operators.MoreThan,
      values: [1],
    },
  ],
};

export const SiteEngagement = () => {
  const { t } = useTranslation(['webresolution', 'translation']);
  const [siteData, fetchChartData, clearChartData] = useSiteEngagementAPI();

  const chartData = useMemo(
    () =>
      siteData.data
        ?.filter((item) => item.value)
        ?.map((item: { label: SiteEngagementCategoryLabel; value: number }) => ({
          ...item,
          label: t(`siteEngagement.${item.label}`) ?? '',
          filters: LabelFilterMap[item.label],
        })) ?? [],
    [siteData.data, t]
  );

  const isNoData = useMemo(() => checkNoData(chartData), [chartData]);
  const action = useEngagementAction(AudienceExportType.SiteEngagement, isNoData);

  useEffect(() => {
    fetchChartData(undefined, true);
    return clearChartData;
  }, [clearChartData, fetchChartData]);

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader title={t('siteEngagement.title')} titleTruncate action={action} />
      <CardContent>
        <EngagementPieChart
          chartData={chartData}
          isNoData={isNoData}
          requesting={!!siteData.requesting}
          exportType={AudienceExportType.SiteEngagement}
        />
      </CardContent>
    </Card>
  );
};
