var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDateRangeFields } from './basicdaterangefields';
import { DateTimeInput } from './commonfields';
import { DateRangePickerForm } from './daterangepickerform';
export var DateRangeSelector = function (props) {
    var _a, _b;
    var t = useTranslation().t;
    var _c = useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    var handleOpen = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleDateRangeChange = function (_a) {
        var _b;
        var startDate = _a[0], endDate = _a[1];
        (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, [startDate, endDate]);
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ "data-testid": "btn-date-range-picker", onClick: handleOpen, variant: "outlined", color: "inherit", size: "large", disabled: props.readonly }, { children: "".concat((_a = props.fromDate) === null || _a === void 0 ? void 0 : _a.format(t('dateTimeFormat.longDate') + ''), " to ").concat((_b = props.toDate) === null || _b === void 0 ? void 0 : _b.format(t('dateTimeFormat.longDate') + '')) })), _jsx(DateRangePickerForm, { fromDate: props.fromDate, toDate: props.toDate, maxDate: props.maxDate, minDate: props.minDate, minStartDate: props.minStartDate, onSubmit: handleDateRangeChange, onCancel: handleClose, popoverProps: { open: !!anchorEl, anchorEl: anchorEl } })] }));
};
export * from './constants';
export { DateTimeInput, BasicDateRangeFields, DateRangePickerForm };
