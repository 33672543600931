import { Box, useTheme } from '@mui/material';
import { FeatureList, MouseEventType, PlotComponent, useAuthorize } from 'common-components';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { AudienceExportType } from '../../audienceexport/enums';
import { FilterItem } from '../../audienceexport/types';
import { ChartTooltipContainer, ChartTooltipHeader } from '../charttooltip';

interface ChartTooltipProps {
  label?: string;
  value?: { value: number; filters: FilterItem[] };
  exportType: AudienceExportType;
}

export const ChartTooltip: React.FC<ChartTooltipProps> = (props) => {
  const hasAudienceExportPermission = useAuthorize({ featureAccess: [FeatureList.AudienceExport] });
  return (
    <ChartTooltipContainer width={hasAudienceExportPermission ? '15rem' : undefined}>
      <ChartTooltipHeader
        exportType={props.exportType}
        label={props.label}
        value={props.value?.value}
        exportFilters={props.value?.filters}
      />
    </ChartTooltipContainer>
  );
};

export interface EngagementPieChartProps {
  chartData: {
    label: string;
    value: number;
    filters: FilterItem[];
  }[];
  requesting: boolean;
  isNoData: boolean;
  exportType: AudienceExportType;
}

export const EngagementPieChart: React.FC<EngagementPieChartProps> = ({
  chartData,
  requesting,
  isNoData,
  exportType,
}) => {
  const theme = useTheme();

  const customData = useMemo(
    () =>
      chartData.map((data) => ({
        label: data.label,
        value: data,
      })),
    [chartData]
  );

  const TooltipComponent = useCallback(
    (tooltipProps: Omit<ChartTooltipProps, 'exportType'>) => <ChartTooltip {...tooltipProps} exportType={exportType} />,
    [exportType]
  );

  return (
    <Box flex={1} paddingTop="0.5rem" data-testid="piechart-id">
      <PlotComponent
        data={[
          {
            type: 'pie',
            labels: _.map(chartData, 'label'),
            values: _.map(chartData, 'value'),
            marker: {
              colors: [
                theme.palette.primary.main,
                theme.palette.primary.light,
                theme.palette.secondary.main,
                theme.palette.text.primary,
              ],
            },
            customdata: customData as any,
            hoverinfo: 'none',
            textinfo: 'none',
            automargin: true,
            direction: 'clockwise',
          },
        ]}
        layout={{
          legend: {
            y: 0.5,
            xanchor: 'left',
            font: {
              size: 11,
              family: theme.typography.fontFamily,
            },
          },
          autosize: true,
        }}
        requesting={requesting}
        isNoData={isNoData}
        TooltipRender={TooltipComponent}
        showTooltipEvent={MouseEventType.Click}
        allowTooltipEvents
      />
    </Box>
  );
};
