import { Typography, styled, useTheme } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
  FullscreenHandler,
  PlotComponent,
  PlotProps,
  SessionActive,
  generateCountPercentAxisTicks,
  useActiveFullscreenSession,
} from 'common-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { AnalyzerSessionName, AnalyzerUnitType } from './constants';
import { useTrafficFrequencyAPI, useTrafficFrequencyChartData } from './selectors';
import { AnalyzerType, ContentType } from './types';

const StyledPlot = styled(PlotComponent)({
  '.overplot': {
    display: 'none',
  },
});

interface DemographicProps {
  analyzerType: AnalyzerType;
  contentType: ContentType;
}

export const Demographic = withSuspense()(({ analyzerType, contentType }: DemographicProps) => {
  const [chartWidth, setChartWidth] = useState(0);
  const [demographicData, fetchDemographicData, clearDemographicData] = useTrafficFrequencyAPI(contentType);
  const [[chartData, maxValue], chartMargin] = useTrafficFrequencyChartData(contentType, analyzerType);
  const [activeFullscreenSession] = useActiveFullscreenSession();
  const { t } = useTranslation(['trafficinsightdialog', 'webresolution', 'translation']);
  const theme = useTheme();
  const isFullscreen =
    !!activeFullscreenSession &&
    ![SessionActive.EnrichedB2B, SessionActive.EnrichedB2C].includes(activeFullscreenSession as SessionActive);

  useEffect(() => {
    fetchDemographicData(undefined, isFullscreen);
    return clearDemographicData;
  }, [fetchDemographicData, clearDemographicData, isFullscreen]);

  const onResize = useCallback<Required<PlotProps>['onResize']>((clientRect) => {
    setChartWidth(clientRect?.width ?? 0);
  }, []);

  const [percentXAxis, countXAxis] = useMemo(() => {
    const { l = 0, r = 0 } = chartMargin;
    return generateCountPercentAxisTicks(maxValue?.percent, maxValue?.count, {
      availableSpace: chartWidth - l - r,
      includeEndValue: false,
      maxStepSpace: !isFullscreen ? 60 : 100,
    });
  }, [isFullscreen, chartMargin, chartWidth, maxValue?.count, maxValue?.percent]);

  const unitDisplay = t(`unit.${AnalyzerUnitType[analyzerType]}`);

  return (
    <Card sx={{ flex: 1 }} disabledShadow={!isFullscreen}>
      <CardHeader
        title={
          <Typography minHeight="2rem" variant="h6">
            {t(`demographic.${analyzerType}`)}
            {AnalyzerUnitType[analyzerType] && (
              <Typography variant="body4" component="span">
                {` (${unitDisplay})`}
              </Typography>
            )}
          </Typography>
        }
        action={<FullscreenHandler sessionName={AnalyzerSessionName[analyzerType]} />}
        sx={{
          ...(!isFullscreen && { paddingTop: 0, paddingBottom: 3 }),
        }}
      />
      <CardContent
        sx={{
          ...(!isFullscreen && { padding: '0!important' }),
        }}
      >
        <StyledPlot
          onResize={onResize}
          fullscreenName={AnalyzerSessionName[analyzerType]}
          data={[
            {
              y: chartData.y,
              x: [maxValue?.percent ?? 0],
              type: 'bar',
              textposition: 'none',
              showlegend: false,
              hoverinfo: 'none',
              xaxis: 'x2',
              orientation: 'h',
            },
            {
              ...chartData,
              type: 'bar',
              textposition: 'none',
              showlegend: false,
              hoverinfo: 'none',
              xaxis: 'x',
              marker: {
                color: theme.palette.primary.main,
              },
              orientation: 'h',
            },
          ]}
          layout={{
            xaxis: {
              showgrid: true,
              ...countXAxis,
            },
            xaxis2: {
              showgrid: false,
              tickcolor: 'transparent',
              side: 'top',
              position: 1,
              overlaying: 'x',
              ticks: 'outside',
              ...percentXAxis,
            },
            margin: chartMargin,
            dragmode: false,
          }}
          config={{ showAxisDragHandles: false }}
          templatetooltip={(label, value) => t('template.chartTooltip', { ns: 'translation', label, value })}
          requesting={demographicData.requesting}
          isNoData={!(chartData.x?.length && chartData.y?.length)}
        />
      </CardContent>
    </Card>
  );
});
