import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDateRangeState } from '../../../selectors';
import { AudienceScheduleForm, DateRangeFilter } from '../types';
import { DATE_FILTER_FORMAT } from './constants';
import { useAudience } from './context';
import { NoScheduleOption } from './datestab/constants';
import { OneTimeScopeEnum } from './scheduledetails/enums';

export const useDefaultDateRangeFilter = () => {
  const [appDateRange] = useDateRangeState();
  const defaultDateRangeFilter = useRef<DateRangeFilter>({
    scope: OneTimeScopeEnum.CustomRange,
    fromDate: appDateRange[0].format(DATE_FILTER_FORMAT),
    toDate: appDateRange[1].format(DATE_FILTER_FORMAT),
  });
  return defaultDateRangeFilter;
};

export const NON_SCHEDULE_ID = NoScheduleOption.id;

export const useSelectSchedule = (
  schedules: AudienceScheduleForm[],
  noScheduleFromDate?: string | null,
  noScheduleToDate?: string | null
): [AudienceScheduleForm | null, (id: string) => void] => {
  const [selectedScheduleId, setSelectedScheduleId] = useState<string>(NON_SCHEDULE_ID);
  const audienceContext = useAudience();
  const hasChanged = useRef(false);

  const handleSelectScheduleId = useCallback((id: string) => {
    setSelectedScheduleId(id);
    hasChanged.current = true;
  }, []);

  const selectedSchedule = useMemo(
    () => schedules.find((schedule) => schedule.__internalId === selectedScheduleId) || null,
    [schedules, selectedScheduleId]
  );

  useEffect(() => {
    if (selectedSchedule) {
      audienceContext.setDateRange({
        scope: selectedSchedule?.scope,
        fromDate: selectedSchedule?.fromDate,
        toDate: selectedSchedule?.toDate,
      } as DateRangeFilter);
    } else if (selectedScheduleId === NON_SCHEDULE_ID) {
      audienceContext.setDateRange({
        scope: OneTimeScopeEnum.CustomRange,
        fromDate: noScheduleFromDate,
        toDate: noScheduleToDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchedule, selectedScheduleId, noScheduleFromDate, noScheduleToDate]);

  useEffect(() => {
    const isPristine = !hasChanged.current && !_.isEmpty(schedules) && selectedScheduleId === NON_SCHEDULE_ID;
    const isNotExist =
      hasChanged.current &&
      !_.isEmpty(schedules) &&
      selectedScheduleId !== NON_SCHEDULE_ID &&
      !schedules.some((sche) => sche.__internalId === selectedScheduleId);
    const autoSelectFirst = isPristine || isNotExist;
    if (autoSelectFirst) {
      handleSelectScheduleId(schedules[0].__internalId);
    } else if (hasChanged.current && _.isEmpty(schedules)) {
      handleSelectScheduleId(NON_SCHEDULE_ID);
    }
  }, [schedules, selectedScheduleId, handleSelectScheduleId]);

  return [selectedSchedule, handleSelectScheduleId];
};
