var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { cleanFormattingNumber } from '../utils';
var StyledTextField = styled(TextField)(function () { return ({
    '& .MuiInputBase-input': { textOverflow: 'ellipsis' },
}); });
var numberToString = function (value) { var _a; return (_a = value === null || value === void 0 ? void 0 : value.toString()) !== null && _a !== void 0 ? _a : ''; };
var stringToNumber = function (value) { return (value ? Number(value) : null); };
export var NumberInput = function (_a) {
    var decimalPlaces = _a.decimalPlaces, onChange = _a.onChange, onBlur = _a.onBlur, props = __rest(_a, ["decimalPlaces", "onChange", "onBlur"]);
    var _b = useState(''), value = _b[0], setValue = _b[1];
    useEffect(function () {
        setValue(function (curr) { return (stringToNumber(curr) !== props.value ? numberToString(props.value) : curr); });
    }, [props.value]);
    var handleChange = function (event) {
        var _a = event.target, newValue = _a.value, name = _a.name;
        var cleanedValue = cleanFormattingNumber(newValue, decimalPlaces);
        if (props.value !== cleanedValue) {
            setValue(cleanedValue);
            onChange === null || onChange === void 0 ? void 0 : onChange({
                target: {
                    name: name,
                    value: stringToNumber(cleanedValue),
                },
            });
        }
    };
    var handleBlur = function (event) {
        var inputValue = event.target.value;
        setValue(inputValue ? Number(inputValue).toString() : '');
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
    };
    return _jsx(StyledTextField, __assign({}, props, { value: value, onChange: handleChange, onBlur: handleBlur }));
};
