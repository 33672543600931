import { ChartMenu, FS_SESSION_ACTIVE_KEY, FeatureList, UseAPI, checkNoData, useAuthorize } from 'common-components';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DateSelectorDependency, useDateSelectorBindingAPI } from '../../selectors';
import { useDateRangeState } from '../../state/app';
import { AudienceExportType } from '../audienceexport/enums';
import { AudienceLocationState } from '../audienceexport/types';
import { PageURL } from '../navbar/constants';
import { SiteEngagementCategoryLabel, VisitorBreakdownEnum, generateData } from './constants';

export type VisitorEmailDetail = { email: string; date: string }[];
export type VisitorAvailabilityDetail = Record<string, number>;
export type VisitorDetailType = VisitorEmailDetail | VisitorAvailabilityDetail;
export type CountByDate<T extends VisitorDetailType = VisitorDetailType> = { date: number; count: number; detail: T };
export interface VisitorClassification<T extends VisitorDetailType = VisitorDetailType> {
  totalCount: number;
  countsByDate: CountByDate<T>[];
}
export interface ClassificationChartData {
  dateRange: [string, string];
  label: string;
  count: number;
  detail?: VisitorDetailType;
}
export interface ResolutionSummaryRes extends Record<VisitorBreakdownEnum, VisitorClassification> {
  totalCount: number;
}
export interface ResolutionData {
  targetColumns: string[];
  data: Record<string, any>[];
}

export const useVisitorAPI = (): UseAPI<ResolutionSummaryRes> => {
  return useDateSelectorBindingAPI<ResolutionSummaryRes>({ depend: DateSelectorDependency.WebResolution });
};

export const useClassificationChartData = (
  visitorType: VisitorBreakdownEnum
): [number, Partial<Plotly.PlotData>, boolean] => {
  const [visitorData] = useVisitorAPI();
  const [[fromDate, toDate] = []] = useDateRangeState();

  const data = useMemo(() => visitorData.data?.[visitorType], [visitorData.data, visitorType]);

  const chartData = useMemo<Partial<Plotly.PlotData>>(() => {
    const generatedDate = generateData(fromDate, toDate, visitorData.data?.[visitorType]);
    const x = generatedDate.map(({ label }) => label);
    const y = generatedDate.map(({ count }) => count);
    const details = generatedDate.map(({ dateRange, detail, count }) => ({ dateRange, total: count, detail }));
    return {
      x,
      y,
      customdata: [x, details as any],
    };
  }, [fromDate, toDate, visitorData.data, visitorType]);

  return [data?.totalCount ?? 0, chartData, checkNoData(data?.countsByDate)];
};

export const useHandleExport = (exportType: AudienceExportType, exportState: AudienceLocationState = {}) => {
  const navigate = useNavigate();

  return useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has(FS_SESSION_ACTIVE_KEY)) {
      searchParams.delete(FS_SESSION_ACTIVE_KEY);
    }
    const state: AudienceLocationState = {
      exportType,
      ...exportState,
    };
    navigate(`${PageURL._AudienceExport}?${searchParams.toString()}`, {
      state,
    });
  }, [exportState, exportType, navigate]);
};

export const useEngagementAction = (exportType: AudienceExportType, isNoData: boolean) => {
  const hasAudiencePermission = useAuthorize({ featureAccess: [FeatureList.AudienceExport] });
  const handleExport = useHandleExport(exportType);
  const { t } = useTranslation('webresolution');

  const action = useMemo(
    () =>
      hasAudiencePermission ? (
        <Suspense>
          <ChartMenu exportExcelItemName={t('export')} onExcelExport={handleExport} isNoData={isNoData} />
        </Suspense>
      ) : undefined,
    [handleExport, hasAudiencePermission, isNoData, t]
  );

  return action;
};

export const useVisitorEngagementAPI = () => {
  return useDateSelectorBindingAPI<{ label: string; value: number }[]>({
    depend: DateSelectorDependency.VisitorEngagement,
  });
};

export const useSiteEngagementAPI = () => {
  return useDateSelectorBindingAPI<{ label: SiteEngagementCategoryLabel; value: number }[]>({
    depend: DateSelectorDependency.SiteEngagement,
  });
};
