export var AUDIT_EVENTS = {
    welcome: {
        view: 'Welcome-View',
    },
    visitor360: {
        view: 'Prospect 360-View',
    },
    campaign360: {
        view: 'Campaign 360-View',
        statSummary: {
            fullScreen: 'Campaign 360-Stat Summary-Fullscreen',
        },
        demographicSummary: {
            fullScreen: 'Campaign 360-Demographic Summary-Fullscreen',
        },
        locationDetail: {
            fullScreen: 'Campaign 360-Location Detail-Fullscreen',
        },
        responseProfileExplorer: {
            fullScreen: 'Campaign 360-Response Profile Explorer-Fullscreen',
            export: 'Campaign 360-Response Profile Explorer-Export',
        },
        insights: {
            fullScreen: 'Campaign 360-Insights-Fullscreen',
            export: 'Campaign 360-Insights-Export',
        },
    },
    accountManagement: {
        users: {
            view: 'Account Management-Users-View',
            export: 'Accoutn Management-Users-Export to Excel',
        },
        connections: {
            view: 'Account Management-Connection-View',
        },
        plan: {
            manage: 'Account Management-Plan-Manage Plan',
        },
        organization: {
            view: 'Account Management-Organization-View',
            manage: 'Account Management-Organization-Manage',
        },
    },
    audienceExport: {
        view: 'Audience Export-View',
    },
    audience: {
        view: 'Audience-View',
    },
};
