var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DialogActions, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '../common/dialog';
import { TagBox } from './tag';
import { ConnectionBusinessTypeInfo } from './connectionBusinessTypeInfo';
export var TagDialogInfo = function (_a) {
    var open = _a.open, connectionData = _a.connectionData, connectionBusinessType = _a.connectionBusinessType, props = __rest(_a, ["open", "connectionData", "connectionBusinessType"]);
    var t = useTranslation(['accountmanagement', 'translation']).t;
    return (_jsxs(Dialog, __assign({ open: open, maxWidth: "sm", onClose: props.onCancel }, { children: [_jsxs(DialogTitle, { children: [_jsx(Typography, __assign({ variant: "h6", marginRight: "auto" }, { children: t('tagDialog.title') })), _jsx(IconButton, __assign({ onClick: props.onCancel }, { children: _jsx(FontAwesomeIcon, { fontSize: "1.5rem", icon: ['fal', 'xmark-large'] }) }))] }), _jsx(DialogContent, __assign({ sx: { padding: '0 2.25rem 1.5rem 2.25rem' } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 9 }, { children: [_jsx(Typography, __assign({ sx: { fontSize: '1rem', lineHeight: '1.75rem' } }, { children: t('tagDialog.provider') })), _jsx(Typography, __assign({ sx: { fontSize: '1rem', lineHeight: '1.75rem', paddingLeft: '0.75rem' } }, { children: connectionData === null || connectionData === void 0 ? void 0 : connectionData.displayName }))] })), _jsxs(Grid, __assign({ item: true, xs: 3 }, { children: [_jsx(Typography, { children: "\u00A0" }), _jsx(ConnectionBusinessTypeInfo, { connectionBusinessType: connectionBusinessType })] })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TagBox, { connection: connectionData === null || connectionData === void 0 ? void 0 : connectionData.connections }) }))] })) })), _jsx(DialogActions, __assign({ sx: { gap: 3.5, padding: '0 2.25rem 2.25rem 2.25rem' }, disableSpacing: true }, { children: _jsx(Button, __assign({ variant: "contained", onClick: props.onCancel, disabled: false }, { children: t('button.close', { ns: 'translation' }) })) }))] })));
};
