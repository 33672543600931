import { useTheme } from '@mui/material';
import { GA_EVENTS, UseAPI, checkEveryFalse, useStateParam } from 'common-components';
import _ from 'lodash';
import { Data } from 'plotly.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelectorDependency, useDateSelectorBindingAPI, useDateSelectorBindingExcelAPI } from '../../../selectors';
import { DateRangeStateEnum } from '../../../state/app/appSlice';
import { getMetricsParam } from '../constants';
import { useCampaignState, useLookupCampaignsAPI, useMetricTypeState, useMetricsAPI } from '../selectors';
import { LocationDetailMode } from '../types';

export interface GeoLocationCount {
  latitude: number;
  longitude: number;
  count: number;
}
export interface RegionLocationCount {
  region: string;
  count: number;
}

export const useLocationDetailModeState = () => {
  return useStateParam<LocationDetailMode>(
    useMemo(
      () => ({
        initialState: LocationDetailMode.Geography,
        paramName: 'lMode',
        validator: (state) => Object.values(LocationDetailMode).includes(state),
      }),
      []
    )
  );
};

export const useGeoLocationDetailAPI = (): UseAPI<GeoLocationCount[]> => {
  const [campaignIds] = useCampaignState();
  const [metricType] = useMetricTypeState();
  const [campaigns] = useLookupCampaignsAPI();

  return useDateSelectorBindingAPI<GeoLocationCount[]>({
    depend: DateSelectorDependency.P360GeoLocationDetail,
    params: {
      campaign: !_.isEmpty(campaigns.data) ? campaignIds?.join(',') : '',
      metrics: getMetricsParam(metricType),
      minCount: 0,
    },
    dateRangeState: DateRangeStateEnum.Campaign360,
  });
};

export const useRegionsLocationDetailAPI = () => {
  const [campaignIds] = useCampaignState();
  const [metricType] = useMetricTypeState();

  return useDateSelectorBindingAPI<RegionLocationCount[]>({
    depend: DateSelectorDependency.P360RegionsLocationDetail,
    params: {
      campaign: campaignIds?.join(','),
      metrics: getMetricsParam(metricType),
    },
    dateRangeState: DateRangeStateEnum.Campaign360,
  });
};

export const useGeoLocationChartData = (): [Data[], boolean] => {
  const theme = useTheme();
  const [locationDetailData] = useGeoLocationDetailAPI();
  const [metricData] = useMetricsAPI();
  const size = useMemo(() => locationDetailData.data?.map((e) => e.count) || [], [locationDetailData.data]);

  const data = useMemo<Data[]>(
    () =>
      locationDetailData.data?.length
        ? [
            {
              type: 'scattermapbox',
              lat: locationDetailData.data.map((e) => e.latitude),
              lon: locationDetailData.data.map((e) => e.longitude),
              customdata: [[], locationDetailData.data?.map((e) => e.count) || []],
              hoverinfo: 'none',
              marker: {
                size,
                color: theme.palette.primary.main,
                opacity: 0.5,
              },
            },
          ]
        : [],
    [locationDetailData.data, size, theme.palette.primary.main]
  );
  const pending = useMemo(
    () =>
      !checkEveryFalse([
        !_.isEmpty(metricData.data) && (locationDetailData.requesting ?? false),
        metricData.requesting,
      ]),
    [locationDetailData.requesting, metricData.data, metricData.requesting]
  );

  return [data, pending];
};

export const useRegionsLocationChartData = (): [Data[], boolean | undefined] => {
  const theme = useTheme();
  const [regionsData] = useRegionsLocationDetailAPI();
  const [metricData] = useMetricsAPI();

  const sortedData = useMemo(() => regionsData.data?.sort((a, b) => a.count - b.count), [regionsData.data]);
  const x = useMemo(() => sortedData?.map(({ count }) => count) || [], [sortedData]);
  const y = useMemo(() => sortedData?.map(({ region: value }) => value) || [], [sortedData]);
  const data = useMemo<Data[]>(
    () => [
      {
        x,
        y,
        type: 'bar',
        marker: {
          color: theme.palette.primary.main,
        },
        orientation: 'h',
        hoverinfo: 'none',
        customdata: [y, x],
      },
    ],
    [theme.palette.primary.main, x, y]
  );
  const pending = useMemo(
    () => !checkEveryFalse([!_.isEmpty(metricData.data) && regionsData.requesting, metricData.requesting]),
    [regionsData.requesting, metricData.data, metricData.requesting]
  );

  return [data, pending];
};

export const useLocationDetailExcelData = () => {
  const { t } = useTranslation('360analysis');
  const fileName = t('locationDetail.title');
  const [campaignIds] = useCampaignState();
  const [metricType] = useMetricTypeState();

  const gaEvents = {
    category: GA_EVENTS._360InsightsDashboard.__name,
    action: GA_EVENTS._360InsightsDashboard.exportLocationDetailToExcel,
  };
  return useDateSelectorBindingExcelAPI(
    DateSelectorDependency.P360GeoLocationDetailExportExcel,
    {
      campaign: campaignIds?.join(','),
      metrics: getMetricsParam(metricType),
      fileName,
    },
    gaEvents,
    fileName,
    DateRangeStateEnum.Campaign360
  );
};
