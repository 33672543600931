var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Link, Stack, Typography, styled } from '@mui/material';
import { replace } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { Card, CardActions, CardContent, CardHeader, FallbackComponent, HelpDialog, TabComponent, TabPanel, } from '../common';
import { OverlayButton } from '../common/overlaybutton';
import { themeLevel3 } from '../config/theme';
import { customerSpecificCodes, customerStandardCodes } from '../constants';
import { useUserSessionAPI } from '../selectors';
import { TAG_TAB_INDEX } from './constants';
import { useTrovoConnectionAPI } from './selectors';
var StyledCardContent = styled(CardContent)(function (_a) {
    var theme = _a.theme;
    return ({
        minHeight: theme.typography.pxToRem(100),
        overflowY: 'auto',
        flex: 1,
        flexBasis: 0,
        display: 'flex',
        flexDirection: 'column',
    });
});
var StyledStandardBox = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        border: "1px solid ".concat(theme.palette.text.primary),
        borderRadius: "".concat(theme.shape.borderRadius / 2, "px"),
        padding: '1rem',
    });
});
var StyledCustomBox = styled(StyledStandardBox)(function () { return ({
    height: '20rem',
    overflowY: 'scroll',
}); });
var customTabStyles = {
    '&.Mui-selected': {
        border: "1px solid ".concat(themeLevel3.palette.text.primary),
        backgroundColor: themeLevel3.palette.background.default,
        color: themeLevel3.palette.text.primary,
    },
    marginRight: '0.5rem',
    minHeight: themeLevel3.typography.pxToRem(38),
    height: themeLevel3.typography.pxToRem(38),
};
var handleCopy = function (data, activeTab) {
    var customerCode = activeTab === TAG_TAB_INDEX.CUSTOM ? customerSpecificCodes : customerStandardCodes;
    var text = data === null || data === void 0 ? void 0 : data.map(function (_a) {
        var details = _a.details;
        return replace(customerCode, '{cleanName}', details === null || details === void 0 ? void 0 : details.cleanName);
    }).join('\n');
    navigator.clipboard.writeText(text !== null && text !== void 0 ? text : '');
};
var renderIframeLink = function (trovoCon, index, customMode) {
    var _a;
    return (_jsxs(Typography, __assign({ variant: "body2", sx: {
            wordBreak: 'break-word',
            color: '#e4e4e4',
            fontStyle: 'regular',
            fontFamily: 'courier',
            fontSize: '1rem',
        } }, { children: ['<script async src="https://tag.krateo.ai/ldc.js?pid=', _jsx(Box, __assign({ component: "span", sx: {
                    color: 'success.main',
                } }, { children: (_a = trovoCon.details) === null || _a === void 0 ? void 0 : _a.cleanName })), '&aid=081b0267"></script>', customMode && (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { height: '1em' } }), _jsx(Box, __assign({ component: "pre", sx: {
                            fontStyle: 'italic',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            fontFamily: 'inherit',
                            margin: 0,
                        } }, { children: "<!-- The following script can be used to inject custom pass-through data, uncomment and populate the `pageInfo` variable to send customized data\n<script>\n  try {\n      /*\n      * The following are the pageInfo attributes and their usage:\n      *  - productId - the id representation of the product the customer is viewing on the page\n      *  - pageTitle - the title of the page which the customer is viewing\n      *  - campaignId - the id of the campaign which this customer came from\n      * Remove unused attributes or pass `undefined` values if not available\n      */\n      const pageInfo = {\n          productId: \"sku12345\",\n          pageTitle: \"Product ABC\",\n          campaignId: \"0a12cf56\"\n      };\n      const pageInfoString = JSON.stringify(pageInfo);\n      if (pageInfoString !== \"{}\"){\n          window.ldcDataLayer = window.ldcDataLayer || [];\n          function ldcConfig(){ldcDataLayer.push(arguments);}\n          ldcConfig('externalId', pageInfoString);\n      }\n  } catch (exception) { }\n</script>\n-->" }))] }))] }), index));
};
export var TagBox = function (props) {
    var _a, _b;
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _c = useState(TAG_TAB_INDEX.STANDARD), activeTab = _c[0], setActiveTab = _c[1];
    return (_jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Typography, __assign({ sx: { fontSize: '1rem' } }, { children: t('tag') })) })), _jsx(Grid, __assign({ item: true, xs: 6, sx: { textAlign: 'right' } }, { children: _jsx(OverlayButton, __assign({ onClick: function () { return handleCopy(props.connection, activeTab); }, variant: "contained", startIcon: _jsx(FontAwesomeIcon, { icon: ['fal', 'clipboard'] }), overlayProps: {
                        startIcon: _jsx(FontAwesomeIcon, { icon: ['fal', 'clipboard-check'] }),
                        children: t('text.copied', { ns: 'translation' }),
                    }, sx: { height: '2rem' } }, { children: t('button.copy') })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(TabComponent, __assign({ defaultTab: activeTab, tabs: [
                        {
                            label: 'Standard',
                            value: TAG_TAB_INDEX.STANDARD,
                        },
                        {
                            label: 'Custom',
                            value: TAG_TAB_INDEX.CUSTOM,
                        },
                    ], onChange: function (selectedTab) { return setActiveTab(selectedTab); }, customTabStyles: customTabStyles }, { children: [_jsx(TabPanel, __assign({ value: TAG_TAB_INDEX.STANDARD, sx: { marginTop: '1rem' } }, { children: _jsx(StyledStandardBox, { children: (_a = props.connection) === null || _a === void 0 ? void 0 : _a.map(function (con, index) { return renderIframeLink(con, index, false); }) }) })), _jsx(TabPanel, __assign({ value: TAG_TAB_INDEX.CUSTOM, sx: { marginTop: '1rem' } }, { children: _jsx(StyledCustomBox, { children: (_b = props.connection) === null || _b === void 0 ? void 0 : _b.map(function (con, index) { return renderIframeLink(con, index, true); }) }) }))] })) }))] })));
};
export var TagCard = withSuspense()(function (_a) {
    var _b, _c;
    var _d = _a.showDescriptionText, showDescriptionText = _d === void 0 ? false : _d;
    var _e = useState(false), showHelpDialog = _e[0], setShowHelpDialog = _e[1];
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _f = useTrovoConnectionAPI(), connections = _f[0], fetchConnections = _f[1], clearConnections = _f[2];
    var userSessionData = useUserSessionAPI()[0];
    var _g = useState(TAG_TAB_INDEX.STANDARD), activeTab = _g[0], setActiveTab = _g[1];
    useEffect(function () {
        fetchConnections();
        return clearConnections;
    }, [fetchConnections, clearConnections, userSessionData.data]);
    return (_jsxs(_Fragment, { children: [_jsxs(Card, __assign({ sx: { flex: 1 } }, { children: [_jsx(CardHeader, { title: t('tag'), action: _jsx(OverlayButton, __assign({ onClick: function () { return handleCopy(connections.data, activeTab); }, variant: "contained", startIcon: _jsx(FontAwesomeIcon, { icon: ['fal', 'clipboard'] }), overlayProps: {
                                startIcon: _jsx(FontAwesomeIcon, { icon: ['fal', 'clipboard-check'] }),
                                children: t('text.copied', { ns: 'translation' }),
                            } }, { children: t('button.copy') })) }), _jsx(StyledCardContent, { children: _jsx(FallbackComponent, __assign({ requesting: connections.requesting }, { children: _jsxs(Stack, __assign({ spacing: 4, sx: { overflow: 'auto' } }, { children: [showDescriptionText && (_jsx(Typography, __assign({ variant: "body2", sx: {
                                            color: '#e4e4e4',
                                            fontStyle: 'regular',
                                            fontFamily: 'courier',
                                            fontSize: 16,
                                        } }, { children: "Copy and embed this code on your website." }))), _jsxs(TabComponent, __assign({ defaultTab: activeTab, tabs: [
                                            {
                                                label: 'Standard',
                                                value: TAG_TAB_INDEX.STANDARD,
                                            },
                                            {
                                                label: 'Custom',
                                                value: TAG_TAB_INDEX.CUSTOM,
                                            },
                                        ], onChange: function (selectedTab) { return setActiveTab(selectedTab); }, customTabStyles: customTabStyles }, { children: [_jsx(TabPanel, __assign({ value: TAG_TAB_INDEX.STANDARD }, { children: (_b = connections.data) === null || _b === void 0 ? void 0 : _b.map(function (con, index) { return renderIframeLink(con, index, false); }) })), _jsx(TabPanel, __assign({ value: TAG_TAB_INDEX.CUSTOM }, { children: (_c = connections.data) === null || _c === void 0 ? void 0 : _c.map(function (con, index) { return renderIframeLink(con, index, true); }) }))] }))] })) })) }), _jsxs(CardActions, { children: [_jsx(Link, __assign({ color: "success.main", fontSize: "inherit", marginRight: "auto", underline: "none", target: "_blank", component: "button", onClick: function () { return setShowHelpDialog(true); }, "data-testid": "btn-need-help" }, { children: t('button.needHelp') })), _jsx(Button, __assign({ disabled: true, sx: { color: 'white', borderColor: 'white', display: 'none' }, variant: "outlined", startIcon: _jsx(FontAwesomeIcon, { icon: ['fal', 'power-off'] }) }, { children: t('button.disable') }))] })] })), _jsx(HelpDialog, { title: t('title.tagHelp'), open: showHelpDialog, onCancel: function () { return setShowHelpDialog(false); }, messages: [
                    t('fieldName.tagHelpContent.firstParagraph'),
                    t('fieldName.tagHelpContent.secondParagraph'),
                    t('fieldName.tagHelpContent.thirdParagraph'),
                    t('fieldName.tagHelpContent.lastParagraph'),
                ] })] }));
});
