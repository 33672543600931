import _ from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export var useActivePageItem = function (pageItems) {
    var location = useLocation();
    return useMemo(function () { return _.findLast(pageItems, function (_a) {
        var url = _a.url;
        return new RegExp("^".concat(url, "(/.*)?$")).test(location.pathname);
    }); }, [location.pathname, pageItems]);
};
