import { createSelector } from '@reduxjs/toolkit';
import { useAPI } from '../state/api';
var GET_USER_SESSION_API = '/api/auth/get-session';
var UPDATE_USER_INFORMATION_API = '/api/user/information';
var ADMIN_RESET_USER_PASSWORD_API = '/api/user/admin-reset-user-password';
var GET_USER_DETAIL_API = '/api/user/detail/{id}';
var DELETE_USER_API = '/api/user/{id}';
var UPSERT_USER_API = '/api/user';
export var ExportSetting;
(function (ExportSetting) {
    ExportSetting[ExportSetting["All"] = 0] = "All";
    ExportSetting[ExportSetting["Restricted"] = 1] = "Restricted";
})(ExportSetting || (ExportSetting = {}));
var selectFirstName = function (user) { var _a; return (_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : ''; };
var selectLastName = function (user) { var _a; return (_a = user === null || user === void 0 ? void 0 : user.lastName) !== null && _a !== void 0 ? _a : ''; };
var selectUserInitials = createSelector(selectFirstName, selectLastName, function (firstName, lastName) { return "".concat(firstName.trim()[0].toUpperCase()).concat(lastName.trim()[0].toUpperCase()); });
export var useUserSessionAPI = function () {
    return useAPI(GET_USER_SESSION_API);
};
export var useUserInitials = function () {
    var userData = useUserSessionAPI()[0];
    return selectUserInitials(userData.data);
};
export var useUpdateUserInformationAPI = function () {
    return useAPI(UPDATE_USER_INFORMATION_API, undefined, 'PUT');
};
export var useResetUserPasswordAPI = function () {
    return useAPI(ADMIN_RESET_USER_PASSWORD_API, undefined, 'POST');
};
export var useUserDetailAPI = function (id) {
    return useAPI(GET_USER_DETAIL_API, { id: id });
};
export var useUpsertUserAPI = function () {
    return useAPI(UPSERT_USER_API, undefined, 'POST');
};
export var useDeleteUserAPI = function (id) {
    return useAPI(DELETE_USER_API, { id: id }, 'DELETE');
};
