import { Map } from 'immutable';
export var FETCH_URL_START = 'FETCH_URL_START';
export var FETCH_URL_SUCCESS = 'FETCH_URL_SUCCESS';
export var FETCH_URL_FAILED = 'FETCH_URL_FAILED';
export var FETCH_URL_RESET = 'FETCH_URL_RESET';
var initialState = Map();
var actionHandlers = {};
actionHandlers[FETCH_URL_START] = function (s, a) {
    return s
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'requesting'], true)
        .removeIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'error'])
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'timeout'], a.payload.timeout)
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'abortController'], a.payload.abortController);
};
actionHandlers[FETCH_URL_SUCCESS] = function (s, a) {
    return s
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'requesting'], false)
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'data'], a.payload.data)
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'statusCode'], a.payload.statusCode)
        .removeIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'abortController']);
};
actionHandlers[FETCH_URL_FAILED] = function (s, a) {
    return s
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'requesting'], false)
        .removeIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'data'])
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'error'], a.payload.error)
        .setIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'statusCode'], a.payload.statusCode)
        .removeIn(["".concat(a.payload.key, ":").concat(a.payload.method), 'abortController']);
};
actionHandlers[FETCH_URL_RESET] = function (s, a) { return s.remove("".concat(a.payload.key, ":").concat(a.payload.method)); };
export var api = function (state, action) {
    if (state === void 0) { state = initialState; }
    var fn = actionHandlers[action.type];
    return fn ? fn(state, action) : state;
};
