import { Box, Typography, styled } from '@mui/material';
import { Trans } from 'react-i18next';

interface FAQItemProps {
  title: string;
  content: string;
}

const FAQItemContainer = styled(Box)(({ theme }) => ({
  borderLeft: `solid ${theme.typography.pxToRem(10)} ${theme.palette.primary.main}`,
  paddingLeft: theme.spacing(3.5),
  borderRadius: `${theme.typography.pxToRem(theme.shape.borderRadius)} 0 0 ${theme.typography.pxToRem(
    theme.shape.borderRadius
  )}`,
}));

export const FAQItem = ({ title, content }: FAQItemProps) => {
  return (
    <FAQItemContainer>
      <Typography fontWeight="bold">{title}</Typography>
      <Typography whiteSpace="pre-line" sx={{ img: { maxWidth: '100%' } }}>
        <Trans defaults={content} components={{ ul: <ul />, li: <li />, img: <img alt="" /> }} />
      </Typography>
    </FAQItemContainer>
  );
};
