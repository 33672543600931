import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Paper, Stack, Typography, styled } from '@mui/material';
import {
  DateRangeSelector,
  Dialog,
  DialogContent,
  DialogTitle,
  FallbackComponent,
  GA_EVENTS,
  ParagraphTruncateTooltip,
  ProgressBar,
  themeLevel3,
  useGA4,
  usePrintAPI,
} from 'common-components';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { useDateRangeState } from '../../state/app';
import { PageTitleSelect } from '../pagetitleselect';
import { Demographic } from './demographic';
import { Geography } from './geography';
import { useTrafficAvailabilityAPI, useTrafficFrequencyAPI } from './selectors';
import { AnalyzerType, ContentType } from './types';

const ChartContainer = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  minHeight: theme.typography.pxToRem(625),
  padding: theme.spacing(4),
}));
const StyledDataAvailability = styled(Paper)(({ theme }) => ({
  flexShrink: 0,
  minHeight: theme.typography.pxToRem(78),
  justifyContent: 'center',
  padding: theme.spacing(3, 8),
}));

interface IconButtonTrafficDialogProps {
  contentType: ContentType;
  isPrintPDF?: boolean;
  onHide: () => void;
}
export interface TrafficInsightDialogProps extends IconButtonTrafficDialogProps {
  show: boolean;
}
interface TrafficInsightProps {
  id?: string;
}
interface DataAvailabilityProps {
  contentType: ContentType;
}

interface AnalyzerParam {
  analyzerType: AnalyzerType;
  Component?: React.FC<any>;
}

interface InsightsChartsProps {
  contentType: ContentType;
  analyzersParam: AnalyzerParam[];
}

const StyledProgressBar = styled(ProgressBar)(({ theme }) => ({
  height: theme.typography.pxToRem(30),
  '&::after': {
    fontSize: theme.typography.body3.fontSize,
  },
}));

const DataAvailability: React.FC<DataAvailabilityProps> = withSuspense()((props) => {
  const [trafficAvailabilityData, fetchTrafficAvailabilityData, clearTrafficAvailabilityData] =
    useTrafficAvailabilityAPI(props.contentType);
  const { t } = useTranslation('trafficinsightdialog');
  const girdColumns = 12;
  const xsProgressBar = girdColumns / _.size(trafficAvailabilityData.data);

  useEffect(() => {
    fetchTrafficAvailabilityData();
    return clearTrafficAvailabilityData;
  }, [clearTrafficAvailabilityData, fetchTrafficAvailabilityData]);

  return (
    <StyledDataAvailability>
      <FallbackComponent requesting={trafficAvailabilityData.requesting}>
        <Grid container>
          <Grid item xs={1.2} display="flex" alignItems="flex-end">
            <Typography variant="body1">{t('dataAvailability.title')}</Typography>
          </Grid>
          <Grid item xs={10.8}>
            <Grid container columnSpacing={2.5}>
              {_.map(trafficAvailabilityData.data, (value, key) => (
                <Grid key={key} item xs={xsProgressBar}>
                  <ParagraphTruncateTooltip
                    maxLine={1}
                    variant="body4"
                    content={t(`dataAvailability.${key}`)}
                    tooltipProps={{ placement: 'top' }}
                  />
                  <StyledProgressBar value={value} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FallbackComponent>
    </StyledDataAvailability>
  );
});

const InsightsCharts: React.FC<InsightsChartsProps> = (props) => {
  const [, fetchTrafficFrequencyData, clearTrafficFrequencyData] = useTrafficFrequencyAPI(props.contentType);
  const rowItem = props.analyzersParam.length / 2;

  useEffect(() => {
    fetchTrafficFrequencyData();
    return clearTrafficFrequencyData;
  }, [clearTrafficFrequencyData, fetchTrafficFrequencyData]);

  const renderRow = (analyzersParam: AnalyzerParam[]) => (
    <Stack flex={1} direction="row">
      {analyzersParam.map(({ analyzerType, Component = Demographic }) => (
        <Component key={analyzerType} contentType={props.contentType} analyzerType={analyzerType} />
      ))}
    </Stack>
  );

  return (
    <Stack spacing={5} minHeight="100%">
      <ChartContainer data-testid="insight-chart-content">
        <Stack flex={1} spacing={10}>
          {renderRow(props.analyzersParam.slice(0, rowItem))}
          {renderRow(props.analyzersParam.slice(rowItem, props.analyzersParam.length))}
        </Stack>
      </ChartContainer>
      <DataAvailability contentType={props.contentType} />
    </Stack>
  );
};

const TrafficInsightB2B: React.FC<TrafficInsightProps> = () => {
  return (
    <InsightsCharts
      contentType={ContentType.B2B}
      analyzersParam={[
        { analyzerType: AnalyzerType.SeniorityLevel },
        { analyzerType: AnalyzerType.Department },
        { analyzerType: AnalyzerType.CompanyRevenue },
        { analyzerType: AnalyzerType.CompanyEmployeeCount },
        { analyzerType: AnalyzerType.CompanyGeography, Component: Geography },
        { analyzerType: AnalyzerType.ContactGeography, Component: Geography },
      ]}
    />
  );
};

const TrafficInsightB2C: React.FC<TrafficInsightProps> = () => {
  return (
    <InsightsCharts
      contentType={ContentType.B2C}
      analyzersParam={[
        { analyzerType: AnalyzerType.Age },
        { analyzerType: AnalyzerType.Income },
        { analyzerType: AnalyzerType.Gender },
        { analyzerType: AnalyzerType.Married },
        { analyzerType: AnalyzerType.Children },
        { analyzerType: AnalyzerType.Homeowner },
        { analyzerType: AnalyzerType.NetWorth },
        { analyzerType: AnalyzerType.Geography, Component: Geography },
      ]}
    />
  );
};

const IconButtonsHeaderTrafficDialog = ({ contentType, isPrintPDF, onHide }: IconButtonTrafficDialogProps) => {
  const [printPDF, fetchPrintPDF] = usePrintAPI(contentType as string);
  if (isPrintPDF) return null;
  if (printPDF.requesting) return <FallbackComponent overlay />;
  return (
    <>
      <IconButton data-testid="print-pdf-button" onClick={() => fetchPrintPDF()}>
        <FontAwesomeIcon fontSize="1.5rem" icon={['fal', 'file-export']} />
      </IconButton>
      <IconButton data-testid="close-button" hidden onClick={onHide}>
        <FontAwesomeIcon fontSize="1.5rem" icon={['fal', 'xmark-large']} />
      </IconButton>
    </>
  );
};

interface GATrafficTracking {
  element: React.FC<TrafficInsightProps>;
  category: string;
  action: string;
}

export const TrafficInsightByContentType: Record<ContentType, GATrafficTracking> = {
  [ContentType.B2B]: {
    element: TrafficInsightB2B,
    category: GA_EVENTS.websiteResolutionDashboard.__name,
    action: GA_EVENTS.websiteResolutionDashboard.viewEnrichedBusinessContactB2bData,
  },
  [ContentType.B2C]: {
    element: TrafficInsightB2C,
    category: GA_EVENTS.websiteResolutionDashboard.__name,
    action: GA_EVENTS.websiteResolutionDashboard.viewEnrichedConsumerDataB2cTraffic,
  },
};

export const TrafficInsightDialog: React.FC<TrafficInsightDialogProps> = withSuspense()((props) => {
  const { t } = useTranslation('trafficinsightdialog');
  const TrafficInsightComponent = TrafficInsightByContentType[props.contentType].element;
  const [dateRange] = useDateRangeState();
  const [fromDate, toDate] = dateRange || [];
  const [, , putEvent] = useGA4();

  useEffect(() => {
    if (props.show) {
      putEvent({
        category: TrafficInsightByContentType[props.contentType].category,
        action: TrafficInsightByContentType[props.contentType].action,
      });
    }
  }, [props.contentType, props.show, putEvent]);
  return (
    <Dialog
      open={props.show}
      onClose={props.onHide}
      fullScreen={props.isPrintPDF}
      fullScreenBreakpoint="md"
      maxWidth="lg"
      baseTheme={themeLevel3}
      scroll="paper"
    >
      <DialogTitle>
        <Typography variant="h2" color="success.main" marginRight="auto">
          {t(props.contentType)}
        </Typography>
        <PageTitleSelect direction="row" disabled />
        <DateRangeSelector readonly fromDate={fromDate} toDate={toDate} />
        <IconButtonsHeaderTrafficDialog
          onHide={props.onHide}
          isPrintPDF={props.isPrintPDF}
          contentType={props.contentType}
        />
      </DialogTitle>
      <DialogContent>
        <TrafficInsightComponent />
      </DialogContent>
    </Dialog>
  );
});
