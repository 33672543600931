import { BasicDateRangeFields, PortalPageName, useLastRefreshedAPI } from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { FilterFormValues } from '../../types';
import { DatesTabProps } from './index';
import { useScheduleInfo } from './selectors';

export type CalendarSelectorProps = Omit<DatesTabProps, 'audience'>;
export const CalendarSelector: React.FC<CalendarSelectorProps> = ({
  values,
  registerField,
  setFieldValue,
  handleReset,
  selectedSchedule,
  errors,
}) => {
  const cleanupPropsRef = useRef(errors);
  const [lastRefreshedData] = useLastRefreshedAPI(PortalPageName.audiencesPage);
  const { currentSchedule, scheduleIndex } = useScheduleInfo(selectedSchedule, values.schedules);

  const sanitizeDateRange = () => {
    const currentError = cleanupPropsRef.current;
    // noschedule
    const hasError = _.some(currentError, (_value, key) => key === 'fromDate' || key === 'toDate');
    if (hasError) {
      handleReset?.('fromDate');
      handleReset?.('toDate');
    }
    _.map(currentError.schedules, (error: any, index: any) => {
      const toDateError = _.get(error, `toDate`);
      const fromDateError = _.get(error, `fromDate`);
      if (toDateError || fromDateError) {
        const isNew = !values.schedules[index]?.id;
        if (isNew) {
          setFieldValue(moment(), `schedules.${index}.toDate`);
          setFieldValue(moment().subtract(7, 'days'), `schedules.${index}.fromDate`);
        } else {
          handleReset?.(`schedules[${index}].fromDate` as keyof FilterFormValues);
          handleReset?.(`schedules[${index}].toDate` as keyof FilterFormValues);
        }
      }
    });
  };

  useEffect(() => {
    return () => {
      sanitizeDateRange();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sanitizeDateRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchedule]);

  useEffect(() => {
    cleanupPropsRef.current = errors;
  }, [errors]);

  if (currentSchedule) {
    return (
      <BasicDateRangeFields
        fromDate={currentSchedule?.fromDate ? moment(currentSchedule.fromDate) : null}
        toDate={currentSchedule?.toDate ? moment(currentSchedule.toDate) : null}
        maxDate={lastRefreshedData.data ? moment(lastRefreshedData.data.maxDate) : null}
        minDate={lastRefreshedData.data ? moment(lastRefreshedData.data.minDate) : null}
        setFieldValue={setFieldValue}
        registerField={registerField}
        fromDateFieldName={`schedules.${scheduleIndex}.fromDate`}
        toDateFieldName={`schedules.${scheduleIndex}.toDate`}
      />
    );
  }
  return (
    <BasicDateRangeFields
      fromDate={values.fromDate ? moment(values.fromDate) : null}
      toDate={values.toDate ? moment(values.toDate) : null}
      maxDate={lastRefreshedData.data ? moment(lastRefreshedData.data.maxDate) : null}
      minDate={lastRefreshedData.data ? moment(lastRefreshedData.data.minDate) : null}
      setFieldValue={setFieldValue}
      registerField={registerField}
      fromDateFieldName={'fromDate'}
      toDateFieldName={'toDate'}
    />
  );
};
