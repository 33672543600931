import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Box, styled } from '@mui/material';
import { themeLevel2 } from 'common-components';

const DOT_ICON_SIZE = 20;
const MIN_CONNECTOR_HEIGHT = 60;
export const ACTION_ICON_SIZE = 34;
export const DATE_ICON_SIZE = 50;
export const StyledTimeline = styled(Timeline)(({ theme }) => ({
  marginBottom: theme.spacing(-5),
  paddingLeft: theme.spacing(1.125),
  [`& .${timelineItemClasses.root}:before`]: {
    flex: 0,
    padding: 0,
  },
  '.MuiTimelineDot-root': {
    margin: 0,
  },
}));
export const StyledTimelineDot = styled((props) => (
  <TimelineDot {...props}>
    <Box
      sx={({ typography }) => ({
        width: typography.pxToRem(6),
        height: typography.pxToRem(6),
        backgroundColor: themeLevel2.palette.background.default,
        borderRadius: '50%',
      })}
    />
  </TimelineDot>
))(({ theme: { palette, typography } }) => ({
  backgroundColor: palette.text.primary,
  width: typography.pxToRem(DOT_ICON_SIZE),
  height: typography.pxToRem(DOT_ICON_SIZE),
  justifyContent: 'center',
  alignItems: 'center',
}));
export const StyledTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  border: `1px dashed #FFFFFFAB`,
  width: 0,
  backgroundColor: 'transparent',
  minHeight: theme.typography.pxToRem(DOT_ICON_SIZE) + theme.typography.pxToRem(MIN_CONNECTOR_HEIGHT),
}));
export const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  padding: 0,
  lineHeight: 1,
  marginTop: theme.typography.pxToRem(-17),
  marginBottom: theme.typography.pxToRem(17),
  marginLeft: theme.typography.pxToRem(15),
}));
