import {
  Card,
  CardContent,
  CardHeader,
  ChartMenu,
  FeatureList,
  useAuthorize,
  useFeaturePermissionAPI,
} from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { AudienceExportType } from '../audienceexport/enums';
import { VisitorBreakdownEnum, getMatchTypeFilter, getRestrictedFeatures } from './constants';
import { useClassificationChartData, useHandleExport, useVisitorAPI } from './selectors';
import { Classification } from './visitorclassification/classification';

export const EmailClassification = withSuspense()(() => {
  const { t } = useTranslation(['webresolution', 'translation']);
  const [featurePermissionData] = useFeaturePermissionAPI();
  const hasAudienceExportPermission = useAuthorize({ featureAccess: [FeatureList.AudienceExport] });
  const [, , isNoEmailData] = useClassificationChartData(VisitorBreakdownEnum.EmailOnly);
  const [, fetchData, clearData] = useVisitorAPI();
  const handleExport = useHandleExport(AudienceExportType.EmailsProspects, {
    filters: [getMatchTypeFilter(VisitorBreakdownEnum.EmailOnly)],
  });

  const restrictedEmail = useMemo(
    () => !!getRestrictedFeatures(VisitorBreakdownEnum.EmailOnly, featurePermissionData.data).length,
    [featurePermissionData.data]
  );

  useEffect(() => {
    fetchData(undefined, true);
    return clearData;
  }, [fetchData, clearData]);

  return (
    <Card>
      <CardHeader
        title={t('emailOnly')}
        titleTruncate
        action={
          !restrictedEmail && (
            <ChartMenu
              fsSession={VisitorBreakdownEnum.EmailOnly}
              onExcelExport={handleExport}
              exportExcelItemName={t('exportEmailsProspects')}
              isNoData={isNoEmailData && !restrictedEmail}
              showExport={hasAudienceExportPermission}
            />
          )
        }
      />
      <CardContent>
        <Classification type={VisitorBreakdownEnum.EmailOnly} />
      </CardContent>
    </Card>
  );
});
