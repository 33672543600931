var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import _ from 'lodash';
var niceFractionDigits = function (value, minDigits) {
    if (_.isNil(minDigits))
        return;
    if (value && Math.abs(value) < Math.pow(10, minDigits)) {
        return Math.abs(Math.floor(Math.log10(value) - minDigits));
    }
    return 0;
};
export var niceNum = function (range, round) {
    var exponent = Math.floor(Math.log10(range)); /** exponent of range */
    var fraction = range / Math.pow(10, exponent); /** fractional part of range */
    var getNiceFraction = function () {
        if (round) {
            if (fraction < 1.5)
                return 1;
            if (fraction < 3)
                return 2;
            if (fraction < 7)
                return 5;
            return 10;
        }
        else {
            if (fraction <= 1)
                return 1;
            if (fraction <= 2)
                return 2;
            if (fraction <= 5)
                return 5;
            return 10;
        }
    };
    return getNiceFraction() * Math.pow(10, exponent);
};
var numberFormatter = function (value, _a) {
    var _b;
    var ignoreNotation = _a.ignoreNotation, _c = _a.notation, notation = _c === void 0 ? 'compact' : _c, autoRound = _a.autoRound, options = __rest(_a, ["ignoreNotation", "notation", "autoRound"]);
    var noNotation = _.isNumber(ignoreNotation) ? value < Math.pow(10, ignoreNotation) : ignoreNotation;
    var formatter = Intl.NumberFormat('en', __assign(__assign({}, options), { notation: noNotation ? undefined : notation, maximumFractionDigits: (_b = options.maximumFractionDigits) !== null && _b !== void 0 ? _b : niceFractionDigits(value, autoRound) }));
    return formatter.format(value);
};
export var formatValue = function (value, _a) {
    if (_a === void 0) { _a = {}; }
    var unitLabel = _a.unitLabel, options = __rest(_a, ["unitLabel"]);
    if (_.isNumber(value)) {
        var formattedValue = numberFormatter(value, options);
        return unitLabel ? "".concat(formattedValue, " ").concat(unitLabel) : formattedValue;
    }
    return 'N/A';
};
export var conditionalNumberFormatter = function (value, conditions, defaultFormat) {
    var _a;
    var conditionalFormat = !_.isNil(value)
        ? (_a = conditions === null || conditions === void 0 ? void 0 : conditions.find(function (c) { var _a, _b; return _.inRange(value, (_a = c.from) !== null && _a !== void 0 ? _a : -Infinity, (_b = c.to) !== null && _b !== void 0 ? _b : Infinity); })) === null || _a === void 0 ? void 0 : _a.format
        : undefined;
    return formatValue(value, __assign(__assign({}, conditionalFormat), defaultFormat));
};
