import {
  Authenticated,
  ChartMenu as ChartMenuComponent,
  ChartUtilProps,
  ChoiceItem,
  FeatureList,
} from 'common-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrafficInsightDialog } from '../trafficinsightdialog';
import { ContentType } from '../trafficinsightdialog/types';

interface ChartMenuProps<D extends Record<string, any>> extends ChartUtilProps<D> {
  trafficType?: ContentType;
}

export const ChartMenu = <D extends Record<string, any>>({ trafficType, ...props }: ChartMenuProps<D>) => {
  const [trafficInsightShow, setTrafficInsightShow] = useState(false);
  const { t } = useTranslation('chartmenu');

  const handleShowTraffic = () => {
    setTrafficInsightShow(true);
  };

  const trafficItem: ChoiceItem | undefined = trafficType
    ? { name: t('showTraffic'), icon: ['fal', 'route-interstate'], action: handleShowTraffic }
    : undefined;

  return (
    <>
      <ChartMenuComponent trafficItem={trafficItem} {...props} />
      {trafficType && (
        <Authenticated featureAccess={[FeatureList.TrafficInsight]} hasPermission={!!trafficType}>
          <TrafficInsightDialog
            contentType={trafficType}
            show={trafficInsightShow}
            onHide={() => setTrafficInsightShow(false)}
          />
        </Authenticated>
      )}
    </>
  );
};
