var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, styled } from '@mui/material';
import { NoDataAvailable } from './nodataavailable';
var FallbackOverlay = styled(Box)(function () { return ({
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
}); });
export var FallbackComponent = function (props) {
    var fallback = props.requesting !== false ? (_jsx(Box, __assign({ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }, { children: _jsx(CircularProgress, { color: "success" }) }))) : null;
    if (fallback && !props.overlay)
        return (_jsx(Box, __assign({ display: 'flex', height: '100%', width: '100%' }, { children: fallback })));
    return (_jsxs(_Fragment, { children: [props.isNoData ? _jsx(NoDataAvailable, __assign({}, props.noDataProps)) : props.children, fallback && props.overlay && _jsx(FallbackOverlay, { children: fallback })] }));
};
