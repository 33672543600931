import { useCallback, useContext } from 'react';
import { Site, SiteContextValue } from '../context';
import { useAxios } from '../state/api/axios';
var LOG_AUDIT_EVENT = '/api/audit/log';
export var useAudit = function () {
    var axiosInstance = useAxios();
    var site = useContext(SiteContextValue).site;
    var send = useCallback(function (data) {
        var _a;
        if (site === Site.Portal) {
            (_a = axiosInstance.current) === null || _a === void 0 ? void 0 : _a.post(LOG_AUDIT_EVENT, data);
        }
    }, [axiosInstance, site]);
    return [send];
};
