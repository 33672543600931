import { ConnectionSource, GA_EVENTS, Order, UseAPI, useAPI, useExportExcelAPI } from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { AudienceListItem } from './types';

export const GET_AUDIENCES_API = '/api/audience/audience';
export const LIST_BY_CONNECTION_ID_API = '/api/audience/lists?connectionId={connectionId}';
export const GET_WRITE_ACCESS_CONNECTIONS = '/api/connection/write-access-connections';
export const DELETE_AUDIENCE_API = '/api/audience/audience/{audienceId}';
export const PROSPECT_EXPORT_API = '/api/audience/audience/download/{audienceId}/{scheduleId}';

export const DATE_TIME_FORMAT = 'YYYYMMDD HHmmss';
export const GET_ALL_LIST_BY_CONNECTION_ID_API = '/api/audience/lists/all?connectionIds={connectionIds}';

export const useAudiencesAPI = () => {
  return useAPI<AudienceListItem[]>(GET_AUDIENCES_API);
};

export type WriteAccessConnection = { id: string; connectionName: string; connectionSource: ConnectionSource };
export const useGetWriteAccessConnections = () => {
  return useAPI<WriteAccessConnection[]>(GET_WRITE_ACCESS_CONNECTIONS);
};

export type ListByConnection = { id: string; name: string };
export const useGetListByConnectionId = (
  connectionId: string | null = null,
  sort?: { orderField: keyof ListByConnection; order: Order }
) => {
  let url = LIST_BY_CONNECTION_ID_API;
  if (sort) {
    url = `${LIST_BY_CONNECTION_ID_API}&orderField=${sort.orderField}&order=${sort.order}`;
  }
  return useAPI<ListByConnection[]>(url, { connectionId });
};

export type CreateListOutput = { id: string; name: string; created: Date };
export const useCreateListByConnectionId = (connectionId?: string, onCreated?: (newList: CreateListOutput) => void) => {
  const apiStates = useAPI<{ id: string; name: string; created: Date }>(
    LIST_BY_CONNECTION_ID_API,
    { connectionId },
    'POST'
  );
  const [{ data }, , clearData] = apiStates;
  useEffect(() => {
    if (data) {
      onCreated?.(data);
      return () => clearData();
    }
  }, [data, onCreated, clearData]);
  return apiStates;
};

export const useAudienceExportAPI = (audienceId: number, scheduleId?: number, fileName = 'Export'): UseAPI => {
  const gaEvents = {
    category: GA_EVENTS.audience.__name,
    action: GA_EVENTS.audience.downloadAudience,
  };
  const now = moment().format(DATE_TIME_FORMAT);
  return useExportExcelAPI(PROSPECT_EXPORT_API, { audienceId, scheduleId }, `${fileName} ${now}.csv`, gaEvents);
};

export const useDeleteAudienceAPI = (audienceId?: number) => {
  return useAPI(DELETE_AUDIENCE_API, { audienceId }, 'DELETE');
};

export type AllListByConnection = { connectionId: string; lists: ListByConnection[] };
export const useGetAllList = () => {
  const [{ data: connections = [] }] = useGetWriteAccessConnections();
  const connectionIds = _.map(connections, 'id') || '';
  return useAPI<AllListByConnection[]>(GET_ALL_LIST_BY_CONNECTION_ID_API, {
    connectionIds: connectionIds.join(','),
  });
};
