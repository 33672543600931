import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography, styled } from '@mui/material';
import { ParagraphTruncateTooltip, important } from 'common-components';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileType } from '../types';
import { MetricIcons, getMetricNames } from './constant';
import { RPETileProps } from './rpetile';

const StyledStack = styled(Stack)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const StyledMetrics = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'stretch',
  width: '100%',
}));

const StyledMetricBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  padding: theme.spacing(2),
  background: '#424242',
  boxShadow: '0px 0px 2px rgba(20, 24, 47, 0.1), 0px 2px 4px rgba(13, 16, 33, 0.08)',
  borderRadius: theme.typography.pxToRem(10),
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export const MetricsContent: React.FC<RPETileProps> = ({ data: { profile }, activeFullScreen }) => {
  const { t } = useTranslation('360analysis');
  const isNoData = useMemo(() => _.every(_.values(profile), _.isNil), [profile]);

  return (
    <StyledStack>
      {isNoData ? (
        <Box textAlign={'center'} alignSelf="center">
          N/A
        </Box>
      ) : (
        <StyledMetrics sx={{ gap: activeFullScreen && 3 }}>
          {_.map(profile, (value, key) => {
            const icon = value !== null && MetricIcons[key as keyof ProfileType];
            const metricText = getMetricNames(value, key, t);
            return (
              icon && (
                <StyledMetricBox data-testid={`metric-box-${key}`} key={key} sx={{ padding: activeFullScreen && 3 }}>
                  <Box display={'flex'} flex={1} alignItems="center" justifyContent="center">
                    {!activeFullScreen ? (
                      <ParagraphTruncateTooltip
                        maxLine={4}
                        textAlign="center"
                        content={metricText}
                        tooltipProps={{ disableInteractive: true }}
                        lineHeight={important(1.5)}
                        variant="caption"
                        typography={{ xl: 'body3', lg: 'body4' }}
                      />
                    ) : (
                      <Typography variant="body3" textAlign="center">
                        {metricText}
                      </Typography>
                    )}
                  </Box>
                  <StyledIcon fontSize="1.5rem" icon={icon} />
                </StyledMetricBox>
              )
            );
          })}
        </StyledMetrics>
      )}
    </StyledStack>
  );
};
