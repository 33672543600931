import { useCachedData } from '../state/api';
var GET_USER_PERMISSION_API = '/api/auth/get-permission';
export var FeatureList;
(function (FeatureList) {
    FeatureList["WebsiteResolution"] = "WEBSITE_RESOLUTION";
    FeatureList["TrafficInsight"] = "TRAFFIC_INSIGHT";
    FeatureList["_360Analysis"] = "360_ANALYSIS";
    FeatureList["AccountManagement"] = "ACCOUNT_MANAGEMENT";
    FeatureList["Faq"] = "FAQ";
    FeatureList["AudienceExport"] = "AUDIENCE_EXPORT";
    FeatureList["AudiencesPage"] = "AUDIENCES";
})(FeatureList || (FeatureList = {}));
export var useFeaturePermissionAPI = function () {
    return useCachedData(GET_USER_PERMISSION_API);
};
