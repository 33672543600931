import { useParams } from 'react-router-dom';
import { FeatureFlagStatus } from '../enum';
import { useAPI, useCachedData } from '../state/api';
import { useUserSessionAPI } from './user';
var GET_FEATURE_FLAG_API = '/api/feature-flag/all';
var GET_ORGANIZATION_FEATURE_FLAG_PERMISSION_API = '/api/feature-flag/organization/access?orgId={orgId}&featureFlagIds={featureFlagIds}';
var GET_LIMITED_FEATURE_FLAG_API = '/api/feature-flag/limited';
export var useFeatureFlagAPI = function () {
    return useCachedData(GET_FEATURE_FLAG_API);
};
export var useLimitedFeatureFlagAPI = function () {
    return useAPI(GET_LIMITED_FEATURE_FLAG_API);
};
export var useOrgFeatureFlagPermissionAPI = function () {
    var _a, _b;
    var _c = useParams().orgId, orgId = _c === void 0 ? '' : _c;
    var userSessionData = useUserSessionAPI()[0];
    var featureFlags = useFeatureFlagAPI()[0];
    var limitedIds = ((_a = featureFlags.data) === null || _a === void 0 ? void 0 : _a.filter(function (feature) { return feature.status === FeatureFlagStatus.Limited; }).map(function (item) { return item.id; })) || [];
    return useAPI(GET_ORGANIZATION_FEATURE_FLAG_PERMISSION_API, {
        orgId: orgId || ((_b = userSessionData.data) === null || _b === void 0 ? void 0 : _b.orgId),
        featureFlagIds: limitedIds.join(', '),
    });
};
