import { UseAPI, useStateParam } from 'common-components';
import _ from 'lodash';
import { Margin, PlotData } from 'plotly.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataFieldType,
  DateSelectorDependency,
  TrafficInsightPayload,
  useDateSelectorBindingAPI,
} from '../../selectors';
import { DemographyMaxDisplay, calculateChartMargin } from './constants';
import {
  AnalyzerType,
  ContentType,
  Frequency,
  GeographyViewMode,
  TrafficInsightsDataAvailability,
  TrafficInsightsDataFrequency,
  TrafficInsightsGeoMappings,
  TrafficInsightsGeoMappingsByUSGroupings,
} from './types';

const DataFieldByAnalyzerByMap: Record<string, DataFieldType> = {
  [AnalyzerType.CompanyGeography]: DataFieldType.CompanyZip,
  [AnalyzerType.ContactGeography]: DataFieldType.ContactZip,
};

const DataFieldByAnalyzerByRegion: Record<string, DataFieldType> = {
  [AnalyzerType.CompanyGeography]: DataFieldType.CompanyState,
  [AnalyzerType.ContactGeography]: DataFieldType.ContactState,
};

export const TrafficAvailabilityAPIProps: Record<ContentType, DateSelectorDependency> = {
  [ContentType.B2B]: DateSelectorDependency.TrafficInsightsDataAvailabilityB2B,
  [ContentType.B2C]: DateSelectorDependency.TrafficInsightsDataAvailabilityB2C,
};

const TrafficInsightsGeoMappingsProps: Record<
  ContentType,
  (analyzerType?: AnalyzerType) => { depend: DateSelectorDependency; params?: TrafficInsightPayload }
> = {
  [ContentType.B2B]: (analyzerType) => ({
    depend: DateSelectorDependency.TrafficInsightsGeoMappingsB2B,
    params: {
      dataField: analyzerType && DataFieldByAnalyzerByMap[analyzerType],
    },
  }),
  [ContentType.B2C]: () => ({ depend: DateSelectorDependency.TrafficInsightsGeoMappingsB2C }),
};

const TrafficInsightsGeoMappingsByUSGroupingsProps: Record<
  ContentType,
  (analyzerType?: AnalyzerType) => { depend: DateSelectorDependency; params?: TrafficInsightPayload }
> = {
  [ContentType.B2B]: (analyzerType) => ({
    depend: DateSelectorDependency.TrafficInsightsGeoMappingsByUSGroupingsB2B,
    params: {
      dataField: analyzerType && DataFieldByAnalyzerByRegion[analyzerType],
    },
  }),
  [ContentType.B2C]: () => ({ depend: DateSelectorDependency.TrafficInsightsGeoMappingsByUSGroupingsB2C }),
};

const TrafficFrequencyDepends: Record<ContentType, DateSelectorDependency> = {
  [ContentType.B2B]: DateSelectorDependency.TrafficInsightsDataFrequencyB2B,
  [ContentType.B2C]: DateSelectorDependency.TrafficInsightsDataFrequencyB2C,
};

export const useGeoViewModeState = (paramName: string) => {
  return useStateParam<GeographyViewMode>(
    useMemo(
      () => ({
        initialState: GeographyViewMode.Map,
        paramName,
        validator: (s) => Object.values(GeographyViewMode).includes(s as GeographyViewMode),
      }),
      [paramName]
    )
  );
};

export const useTrafficAvailabilityAPI = (contentType: ContentType) => {
  return useDateSelectorBindingAPI<TrafficInsightsDataAvailability>({
    depend: TrafficAvailabilityAPIProps[contentType],
  });
};

export const useTrafficFrequencyAPI = (contentType: ContentType): UseAPI<Record<string, Array<Frequency>>> => {
  return useDateSelectorBindingAPI<TrafficInsightsDataFrequency, Record<string, Array<Frequency>>>({
    depend: TrafficFrequencyDepends[contentType],
  });
};

export const useTrafficInsightsGeoMappingsAPI = (contentType: ContentType, analyzerType?: AnalyzerType) => {
  const APIProps = TrafficInsightsGeoMappingsProps[contentType](analyzerType);
  return useDateSelectorBindingAPI<TrafficInsightsGeoMappings>({ depend: APIProps.depend, params: APIProps.params });
};

export const useTrafficInsightsGeoMappingsByUSGroupingsAPI = (
  contentType: ContentType,
  analyzerType?: AnalyzerType
) => {
  const APIProps = TrafficInsightsGeoMappingsByUSGroupingsProps[contentType](analyzerType);
  return useDateSelectorBindingAPI<TrafficInsightsGeoMappingsByUSGroupings>({
    depend: APIProps.depend,
    params: APIProps.params,
  });
};

export const useTrafficFrequencyChartData = (
  contentType: ContentType,
  analyzerType: AnalyzerType
): [[Partial<PlotData>, Frequency | undefined], Partial<Margin>] => {
  const { t } = useTranslation(['trafficinsightdialog']);
  const [demographicData] = useTrafficFrequencyAPI(contentType);
  const demographicChartData = useMemo(
    () => demographicData.data?.[analyzerType] || [],
    [analyzerType, demographicData.data]
  );

  return useMemo<[[Partial<PlotData>, Frequency | undefined], Partial<Margin>]>(() => {
    const maxDisplay = DemographyMaxDisplay[contentType];
    let data = [...demographicChartData];
    if (data.length > maxDisplay) {
      data = data.slice(1 - maxDisplay);

      data.unshift(
        demographicChartData.slice(0, 1 - maxDisplay).reduce(
          (prev, current) => ({
            ...prev,
            value: t('allOther'),
            count: prev.count + current.count,
            percent: prev.percent + current.percent,
          }),
          {} as Frequency
        )
      );
    }
    const maxValue = _.maxBy(data, 'count');
    const y = data.map(({ value }) => value);
    const x = data.map(({ count }) => count);

    return [[{ y, x, customdata: [y, x] }, maxValue], calculateChartMargin(y)];
  }, [contentType, demographicChartData, t]);
};
