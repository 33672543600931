var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { visuallyHidden } from '@mui/utils';
export var EnhancedTableHead = function (_a) {
    var columns = _a.columns, props = __rest(_a, ["columns"]);
    var order = props.order, orderBy = props.orderBy, onRequestSort = props.onRequestSort;
    var createSortHandler = function (property) { return function (event) {
        onRequestSort === null || onRequestSort === void 0 ? void 0 : onRequestSort(event, property);
    }; };
    return (_jsx(TableHead, { children: _jsx(TableRow, { children: columns.map(function (headCell) {
                return (_jsx(TableCell, __assign({ align: headCell.align ? 'right' : 'left' }, headCell.cellProps, { sortDirection: orderBy === headCell.sortKey ? order : false }, { children: _jsxs(TableSortLabel, __assign({ active: orderBy === headCell.sortKey, direction: orderBy === headCell.sortKey ? order : 'asc', onClick: createSortHandler(headCell.sortKey), hideSortIcon: headCell === null || headCell === void 0 ? void 0 : headCell.hideSortIcon }, { children: [headCell.title, orderBy === headCell.sortKey ? (_jsx(Box, __assign({ component: "span", sx: visuallyHidden }, { children: order === 'desc' ? 'sorted descending' : 'sorted ascending' }))) : null] })) }), headCell.sortKey));
            }) }) }));
};
