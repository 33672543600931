import { jsx as _jsx } from "react/jsx-runtime";
import { withSuspense } from 'react-suspenser';
import { CommonDateRangeFields } from './commonfields';
export var BasicDateRangeFields = withSuspense()(function (_a) {
    var fromDate = _a.fromDate, toDate = _a.toDate, maxDate = _a.maxDate, minDate = _a.minDate, setFieldValue = _a.setFieldValue, registerField = _a.registerField, fromDateFieldName = _a.fromDateFieldName, toDateFieldName = _a.toDateFieldName;
    var fromDateInputProps = registerField(fromDateFieldName);
    var toDateInputProps = registerField(toDateFieldName);
    var handleOnQuickSelect = function (_a) {
        var start = _a[0], end = _a[1];
        setFieldValue(start, fromDateFieldName);
        setFieldValue(end, toDateFieldName);
    };
    var handleBlur = function (inputName) {
        var event = { target: { name: inputName } };
        inputName === fromDateInputProps.name ? fromDateInputProps.onBlur(event) : toDateInputProps.onBlur(event);
    };
    return (_jsx(CommonDateRangeFields, { fromDate: fromDate, toDate: toDate, fromDateName: fromDateFieldName, toDateName: toDateFieldName, maxDate: maxDate, minDate: minDate, setFieldValue: setFieldValue, handleOnQuickSelect: handleOnQuickSelect, helperTextFromDate: fromDateInputProps.helperText, helperTextToDate: toDateInputProps.helperText, handleBlur: handleBlur }));
});
