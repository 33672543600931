import { useAPI } from '../state/api';
var GET_STATES_API = '/api/lookup/state-code';
export var OrganizationStatus;
(function (OrganizationStatus) {
    OrganizationStatus[OrganizationStatus["Onboarding"] = 0] = "Onboarding";
    OrganizationStatus[OrganizationStatus["Active"] = 1] = "Active";
    OrganizationStatus[OrganizationStatus["Inactive"] = 2] = "Inactive";
    OrganizationStatus[OrganizationStatus["Archived"] = 3] = "Archived";
})(OrganizationStatus || (OrganizationStatus = {}));
export var useStateCodes = function () {
    return useAPI(GET_STATES_API);
};
