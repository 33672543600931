import { ORG_OBJECT_SHAPE, PHONE_FORMAT_REGEX, UserFieldLength } from 'common-components';
import { TFunction, TOptionsBase } from 'i18next';
import * as yup from 'yup';

export const validationSchema = (t: TFunction, options: TOptionsBase = {}) =>
  yup.object().shape({
    ...ORG_OBJECT_SHAPE(t, options),
    firstName: yup
      .string()
      .trim()
      .nullable()
      .required('error.required')
      .max(UserFieldLength.name, `${t('error.maxLength', { ...options, length: UserFieldLength.name })}`),
    lastName: yup
      .string()
      .trim()
      .nullable()
      .required('error.required')
      .max(UserFieldLength.name, `${t('error.maxLength', { ...options, length: UserFieldLength.name })}`),
    title: yup
      .string()
      .trim()
      .nullable()
      .required('error.required')
      .max(UserFieldLength.name, `${t('error.maxLength', { ...options, length: UserFieldLength.name })}`),
    mobilePhone: yup
      .string()
      .trim()
      .nullable()
      .required('error.required')
      .matches(PHONE_FORMAT_REGEX, 'error.mobilePhoneFormatError')
      .max(UserFieldLength.phone, `${t('error.maxLength', { ...options, length: UserFieldLength.phone })}`)
      .label('Mobile phone'),
  });
