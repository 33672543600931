import { Box, Stack, ThemeProvider, Typography, styled } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
  FallbackComponent,
  HeadCell,
  ParagraphTruncateTooltip,
  ProgressBar,
  Table,
  checkNoData,
  themeLevel3,
} from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { LearningInProgress } from '../learninginprogress';
import { ReferralChannelType, useReferralChannelAPI } from './selectors';

const StyledCard = styled(Card)({
  flex: 1,
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
  flexBasis: 0,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 0,
  marginTop: theme.spacing(4),
  overflowY: 'auto',
}));

const StyledProgressBar = styled(ProgressBar)(({ theme }) => ({
  height: theme.typography.pxToRem(20),
  color: theme.palette.primary.main,
}));

export const getTooltipTitle = (row: ReferralChannelType) => {
  return (
    <Box>
      <Typography variant="body2" fontWeight="bold">
        {row.channel}
      </Typography>
      <Typography variant="body2" fontWeight="light">
        {row.visitors} Visitors
      </Typography>
    </Box>
  );
};

export const ReferralTable: React.FC = () => {
  const [data] = useReferralChannelAPI();
  const { t } = useTranslation(['360analysis', 'translation']);

  const columns = useMemo<Array<HeadCell<ReferralChannelType>>>(
    () => [
      {
        title: t('referralchannel.channel'),
        dataIndex: 'channel',
        width: '50%',
        maxWidth: '50%',
        render: (channel) => (
          <Box>
            <ParagraphTruncateTooltip
              maxLine={1}
              variant="body2"
              content={channel}
              tooltipProps={{ placement: 'top' }}
            />
          </Box>
        ),
      },
      {
        title: t('referralchannel.visitors'),
        dataIndex: 'percentage',
        width: '50%',
        maxWidth: '50%',
        render: (percentage, row) => (
          <StyledProgressBar value={percentage} withToolTip={true} tooltipTitle={getTooltipTitle(row)} />
        ),
      },
    ],
    [t]
  );

  return (
    <Table
      columns={columns}
      dataSources={data.data}
      getRowId={(row) => row.channel}
      tableContainerProps={{ sx: { flex: 1, flexBasis: 0, color: 'inherit' } }}
      tableProps={{ stickyHeader: true }}
    />
  );
};

export const ReferralChannel: React.FC = withSuspense()(() => {
  const { t } = useTranslation('360analysis');
  const [data, fetchData, clearData] = useReferralChannelAPI();
  const isNoData = useMemo(() => checkNoData(data.data), [data]);

  useEffect(() => {
    fetchData(undefined, true);
    return clearData;
  }, [clearData, fetchData]);

  return (
    <StyledCard>
      <CardHeader title={t('referralchannel.title')} />
      <StyledCardContent>
        <ThemeProvider theme={themeLevel3}>
          <FallbackComponent requesting={!!data.requesting}>
            {isNoData ? (
              <LearningInProgress />
            ) : (
              <Stack height="100%" flexShrink={0} spacing={2.5}>
                <ReferralTable />
              </Stack>
            )}
          </FallbackComponent>
        </ThemeProvider>
      </StyledCardContent>
    </StyledCard>
  );
});
