import { useCallback, useEffect, useRef, useState } from 'react';
export var useTimeOut = function () {
    var refCount = useRef(false);
    var refCallback = useRef(undefined);
    var refTick = useRef(1000);
    var _a = useState(0), timeRemaining = _a[0], setTimeRemaining = _a[1];
    var handleTimeOut = useCallback(function (callback, duration, tick) {
        if (tick === void 0) { tick = 1000; }
        refTick.current = duration > tick ? tick : duration;
        refCallback.current = callback;
        setTimeRemaining(duration);
    }, []);
    var resetTimeOut = useCallback(function () {
        refCallback.current = undefined;
        setTimeRemaining(0);
    }, []);
    useEffect(function () {
        var _a;
        if (timeRemaining > 0) {
            if (!refCount.current) {
                refCount.current = true;
                var handler_1 = setTimeout(function () {
                    refCount.current = false;
                    setTimeRemaining(function (prev) { return (prev - refTick.current < 0 ? 0 : prev - refTick.current); });
                }, refTick.current);
                return function () {
                    clearTimeout(handler_1);
                };
            }
        }
        else {
            (_a = refCallback.current) === null || _a === void 0 ? void 0 : _a.call(refCallback);
            refCount.current = false;
        }
    }, [timeRemaining]);
    return [timeRemaining, handleTimeOut, resetTimeOut];
};
