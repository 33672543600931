import { useMemo } from 'react';
var DEFAULT_FONT = '10px';
var DEFAULT_GAP = 10;
export var useTick = function (labels, maxWidth, font, gap) {
    if (font === void 0) { font = DEFAULT_FONT; }
    if (gap === void 0) { gap = DEFAULT_GAP; }
    var canvasContext = useMemo(function () { return document.createElement('canvas').getContext('2d'); }, []);
    var tick = useMemo(function () {
        if (canvasContext && (labels === null || labels === void 0 ? void 0 : labels.length) && maxWidth) {
            var _tick0 = 0;
            canvasContext.font = font;
            var labelWidths = labels.map(function (label) { return canvasContext.measureText("".concat(label)).width; });
            var maxLabelWidth = Math.max.apply(Math, labelWidths) + gap;
            var labelNum = Math.max(Math.floor(maxWidth / maxLabelWidth), 1);
            var _dTick = Math.floor((labelWidths.length - 1) / (labelNum - 1));
            if (Math.floor((labelWidths.length - 1) / _dTick) > labelNum - 1) {
                _dTick++;
            }
            if (labelNum === 1) {
                _tick0 = Math.floor((labelWidths.length - 1) / 2);
            }
            return { dTick: Math.min(_dTick, labelWidths.length), tick0: _tick0 };
        }
    }, [canvasContext, font, gap, labels, maxWidth]);
    return [tick === null || tick === void 0 ? void 0 : tick.dTick, tick === null || tick === void 0 ? void 0 : tick.tick0];
};
