import _ from 'lodash';
export function cleanFormattingNumber(value, decimalPlaces) {
    if (decimalPlaces === void 0) { decimalPlaces = 0; }
    if (decimalPlaces <= 0)
        return value.replace(/\D/g, '');
    var matchResult = value.match(new RegExp("^\\d+\\.?\\d{0,".concat(decimalPlaces, "}")));
    return matchResult ? matchResult[0] : '';
}
export function formatPhoneNumber(phoneNumberString) {
    if (!phoneNumberString) {
        return phoneNumberString;
    }
    var cleaned = cleanFormattingNumber(phoneNumberString);
    var match = cleaned.match(/^(\d{1,3})(\d{1,3})?(\d+)?$/);
    var arr = [];
    if (match) {
        var separation = '';
        for (var i = 1; i < match.length; i += 1) {
            if (match[i]) {
                arr.push(separation);
                arr.push(match[i]);
                separation = '-';
            }
        }
    }
    return arr.join('');
}
export function formatZipCode(zipCodeString) {
    if (!zipCodeString) {
        return zipCodeString;
    }
    var cleaned = cleanFormattingNumber(zipCodeString);
    var match = cleaned.match(/^(\d{1,5})(\d+)?$/);
    var arr = [];
    if (match) {
        var separation = '';
        for (var i = 1; i < match.length; i += 1) {
            if (match[i]) {
                arr.push(separation);
                arr.push(match[i]);
                separation = '-';
            }
        }
    }
    return arr.join('');
}
export var checkEmptyString = function (text) {
    return _.isUndefined(text) || _.isNull(text) || typeof text === 'string' ? !text : false;
};
