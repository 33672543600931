var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { useEffect, useRef } from 'react';
export var StringInput = function (_a) {
    var value = _a.value, format = _a.format, clean = _a.clean, onChange = _a.onChange, props = __rest(_a, ["value", "format", "clean", "onChange"]);
    var ref = useRef(null);
    useEffect(function () {
        var formattedValue = format(value);
        if (ref.current && formattedValue !== ref.current.value)
            ref.current.value = formattedValue;
    }, [format, value]);
    var handleChange = function (event) {
        var _a = event.target, selectionEnd = _a.selectionEnd, newValue = _a.value;
        var formattedValue = format(newValue);
        var cleanedValue = clean(newValue);
        if (ref.current) {
            ref.current.value = formattedValue;
        }
        if (value !== cleanedValue) {
            event.target.value = cleanedValue;
            onChange === null || onChange === void 0 ? void 0 : onChange(event);
            var newSelection_1 = Math.max(formattedValue.length - newValue.length, 0) + (selectionEnd !== null && selectionEnd !== void 0 ? selectionEnd : 0);
            setTimeout(function () { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setSelectionRange(newSelection_1, newSelection_1); });
        }
    };
    var handleKeyUp = function (event) {
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!/^\d*$/.test(key)) {
            event.preventDefault();
            return false;
        }
        return true;
    };
    return _jsx(TextField, __assign({ inputRef: ref, onChange: handleChange, onKeyUp: handleKeyUp }, props));
};
