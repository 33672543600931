import { Grid, ThemeProvider } from '@mui/material';
import {
  AUDIT_EVENTS,
  FallbackComponent,
  FullscreenContainer,
  GA_EVENTS,
  themeLevel3,
  useAudit,
  useGA4,
} from 'common-components';
import { useEffect } from 'react';
import { usePageTitleAPI } from '../pagetitleselect/selectors';
import { EmailClassification } from './emailclassification';
import { SiteEngagement, VisitorEngagement } from './engagementchart';
import { EnrichedClassifications } from './enrichedclassifications';
import { ProspectCount } from './prospectcount';

export const WebResolutionContainer = () => {
  return (
    <Grid container columnSpacing="4" flex={1}>
      <Grid item xs={8} display="flex" flexDirection="column" gap={4}>
        <EmailClassification />
        <EnrichedClassifications />
      </Grid>
      <Grid item xs={4} display="flex" flexDirection="column" gap={4}>
        <ProspectCount />
        <VisitorEngagement />
        <SiteEngagement />
      </Grid>
    </Grid>
  );
};

export const WebResolution = () => {
  const [, , putEvent] = useGA4();
  const [sendAuditEvent] = useAudit();
  const [pageTitleData, fetchPageTitleData] = usePageTitleAPI();

  useEffect(() => {
    fetchPageTitleData();
  }, [fetchPageTitleData]);

  useEffect(() => {
    putEvent({
      category: GA_EVENTS.websiteResolutionDashboard.__name,
      action: GA_EVENTS.websiteResolutionDashboard.view,
    });
  }, [putEvent]);

  useEffect(() => {
    sendAuditEvent({ action: AUDIT_EVENTS.visitor360.view });
  }, [sendAuditEvent]);

  return (
    <ThemeProvider theme={themeLevel3}>
      <FallbackComponent requesting={pageTitleData.requesting !== false}>
        <FullscreenContainer>
          <WebResolutionContainer />
        </FullscreenContainer>
      </FallbackComponent>
    </ThemeProvider>
  );
};
