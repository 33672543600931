import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAPI } from '../state/api';
var KEY_PARAM_PRINT = 'printPage';
var PRINT_DOWNLOAD_API = '/api/pdf/download?printPage={printPage}';
var PrintPage;
(function (PrintPage) {
    PrintPage["WebResolution"] = "WebResolution";
    PrintPage["Campaign360"] = "Campaign360";
    PrintPage["TrafficB2C"] = "TrafficB2C";
    PrintPage["TrafficB2B"] = "TrafficB2B";
})(PrintPage || (PrintPage = {}));
var getPDFInformation = {
    websiteResolution: function (t) { return ({ printPage: PrintPage.WebResolution, filename: t('pageName.visitor360') }); },
    campaign360: function (t) { return ({ printPage: PrintPage.Campaign360, filename: t('pageName.campaign360') }); },
    B2C: function (t) { return ({ printPage: PrintPage.TrafficB2C, filename: t('pageName.trafficInsightB2CDialog') }); },
    B2B: function (t) { return ({ printPage: PrintPage.TrafficB2B, filename: t('pageName.trafficInsightB2BDialog') }); },
};
export var usePrintParam = function () {
    var searchParams = useSearchParams()[0];
    return [searchParams.get(KEY_PARAM_PRINT)];
};
export var usePrintAPI = function (screen) {
    var _a, _b;
    var t = useTranslation(['header']).t;
    var pathname = window.location.pathname;
    var pageName = pathname.split(/^.*[\\/]/)[1];
    var search = window.location.search;
    var _c = (_b = (_a = getPDFInformation[screen === '' ? pageName : screen !== null && screen !== void 0 ? screen : pageName]) === null || _a === void 0 ? void 0 : _a.call(getPDFInformation, t)) !== null && _b !== void 0 ? _b : {}, printPage = _c.printPage, filename = _c.filename;
    var _d = useAPI(PRINT_DOWNLOAD_API, { printPage: printPage }, 'POST'), printData = _d[0], fetchPrintData = _d[1], clearPrintData = _d[2];
    useEffect(function () {
        if (printData.data) {
            var blob = new Blob([printData.data], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename !== null && filename !== void 0 ? filename : '';
            link.click();
        }
        return clearPrintData;
    }, [clearPrintData, filename, printData.data]);
    var doFetchData = useCallback(function () {
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        fetchPrintData({ pathname: pathname, search: search, currentSession: sessionStorage, currentTz: timeZone }, undefined, undefined, 'blob');
    }, [fetchPrintData, pathname, search]);
    return [printData, doFetchData, clearPrintData];
};
