import { Box, CardMedia, Grid, Paper, Stack, ThemeProvider, Typography, styled } from '@mui/material';
import {
  AUDIT_EVENTS,
  Card,
  CardContent,
  CardHeader,
  GA_EVENTS,
  Role,
  TagCard,
  themeLevel3,
  useAudit,
  useGA4,
  useUserSessionAPI,
} from 'common-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { withSuspense } from 'react-suspenser';
import { PageURL } from '../navbar/constants';

const WelcomeContent = styled(Paper)(({ theme }) => ({
  flex: 2,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(7),
  overflow: 'auto',
}));

const Shortcut = styled(RouterLink)(({ theme }) => ({
  display: 'block',
  color: theme.palette.success.main,
  textDecoration: 'none',
  paddingLeft: theme.spacing(5.5),
}));

export const Welcome = withSuspense()(() => {
  const { t } = useTranslation(['welcome']);
  const [{ data: user }] = useUserSessionAPI();
  const [, , putEvent] = useGA4();
  const [sendAuditEvent] = useAudit();

  useEffect(() => {
    putEvent({ category: GA_EVENTS.welcomePage.__name, action: GA_EVENTS.welcomePage.view });
    sendAuditEvent({ action: AUDIT_EVENTS.welcome.view });
  }, [putEvent, sendAuditEvent]);

  return (
    <ThemeProvider theme={themeLevel3}>
      <Grid flex={1} container spacing={5.5}>
        <Grid container item xs={8}>
          <WelcomeContent>
            <Typography>{t('paragraph1')}</Typography>
            <Typography>{t('paragraph2')}</Typography>
            <Typography dangerouslySetInnerHTML={{ __html: t('paragraph3') }} />
            <Typography>{t('paragraph4')}</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="1.5rem">
              <Card sx={{ maxWidth: 513 }}>
                <CardMedia role="video" component="video" src="/assets/welcome-video-720.mp4" controls />
              </Card>
            </Box>
          </WelcomeContent>
        </Grid>

        <Grid container item xs={4}>
          <Stack spacing={4} flex={1}>
            <Card style={{ overflow: 'unset' }}>
              <CardHeader title="Links" />
              <CardContent>
                <Stack spacing={4}>
                  <Shortcut to={PageURL.Faq}>Frequently Asked Questions</Shortcut>
                  {user?.role.name !== Role.Viewer && <Shortcut to={PageURL.AccountManagement}>Add Users</Shortcut>}
                </Stack>
              </CardContent>
            </Card>
            <TagCard showDescriptionText />
          </Stack>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
});
