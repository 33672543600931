import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ReadyState } from 'react-use-websocket';
import { useChat } from './selectors';
import { ChatMessage } from './types';

interface ChatbotState {
  enabled: boolean;
  readyState: ReadyState;
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  messages: ChatMessage[];
  isWaitingResponse: boolean;
  onFeedback: (messageId: string) => void;
  onSendMessage: (value: string) => void;
  onOpen: (el: HTMLButtonElement | null) => void;
  onClose: VoidFunction;
}

const ChatbotContext = createContext<ChatbotState>({} as ChatbotState);

export const useChatbot = () => useContext(ChatbotContext);

export const ChatbotProvider = ({ enabled = false, children }: React.PropsWithChildren<{ enabled?: boolean }>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { readyState, isWaitingResponse, messages, onSendMessage, onFeedback } = useChat();

  const onOpen = useCallback((el: HTMLButtonElement | null) => {
    setAnchorEl(el);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const value: ChatbotState = useMemo(
    () => ({
      readyState,
      isWaitingResponse,
      messages,
      enabled,
      anchorEl,
      open: Boolean(anchorEl),
      onOpen,
      onClose,
      onSendMessage,
      onFeedback,
    }),
    [anchorEl, enabled, isWaitingResponse, messages, onClose, onFeedback, onOpen, onSendMessage, readyState]
  );

  return <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>;
};
