import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, ThemeProvider, Typography, styled } from '@mui/material';
import {
  AUDIT_EVENTS,
  Card,
  CardContent,
  CardHeader,
  ChartMenu,
  ExcelData,
  FallbackComponent,
  checkNoData,
  themeLevel4,
  useAudit,
} from 'common-components';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { useDateRangeState } from '../../../state/app';
import { DATE_FORMAT_EXCEL } from '../constants';
import { LearningInProgress } from '../learninginprogress';
import { EXCEL_COLUMN_INSIGHTS } from '../types';
import { DemographicIcon } from './constants';
import { useInsightAPI } from './selectors';

const StyledCard = styled(Card)({
  flex: 1,
});

const StyledInsightsCardContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
  flexBasis: 0,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 0,
  marginTop: theme.spacing(4),
  overflowY: 'auto',
}));

const StyledInsightItemCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  padding: theme.spacing(4),
}));

const StyledInsightItemIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.typography.pxToRem(24),
  height: 'auto',
  color: theme.palette.success.main,
}));

export const Insights: React.FC = withSuspense()(() => {
  const { t } = useTranslation('360analysis');
  const [sendAuditEvent] = useAudit();
  const [insightsData, fetchInsightsData, clearInsightsData] = useInsightAPI();
  const isNoData = useMemo(() => checkNoData(insightsData.data?.results), [insightsData.data?.results]);
  const [dateRange] = useDateRangeState();
  const [fromDate, toDate] = dateRange || [];

  const excelData = useMemo<ExcelData>(() => {
    const sheetData = insightsData.data?.results.map((ins) => ({
      [EXCEL_COLUMN_INSIGHTS.StartDate]: fromDate?.startOf('day').format(DATE_FORMAT_EXCEL).toString(),
      [EXCEL_COLUMN_INSIGHTS.EndDate]: toDate?.endOf('day').format(DATE_FORMAT_EXCEL).toString(),
      [EXCEL_COLUMN_INSIGHTS.Campaign]: insightsData.data?.campaigns.toString(),
      [EXCEL_COLUMN_INSIGHTS.ResponseType]: insightsData.data?.events.toString(),
      [EXCEL_COLUMN_INSIGHTS.Demographic]: ins.demographicType,
      [EXCEL_COLUMN_INSIGHTS.Insight]: ins.insightText,
    }));
    return {
      name: t('insights'),
      sheets: [
        {
          name: t('insights'),
          header: sheetData ? _.keys(sheetData[0]) : [''],
          data: sheetData || [],
        },
      ],
    };
  }, [insightsData.data, fromDate, toDate, t]);

  useEffect(() => {
    fetchInsightsData();
    return clearInsightsData;
  }, [clearInsightsData, fetchInsightsData]);

  return (
    <StyledCard>
      <CardHeader
        title={t('insights')}
        action={
          <ChartMenu
            excelData={excelData}
            isNoData={isNoData}
            onExcelExported={() => {
              sendAuditEvent({ action: AUDIT_EVENTS.campaign360.insights.export });
            }}
          />
        }
      />
      <StyledInsightsCardContent>
        <FallbackComponent requesting={insightsData.requesting ?? false}>
          {isNoData ? (
            <LearningInProgress />
          ) : (
            <ThemeProvider theme={themeLevel4}>
              <Stack flexShrink={0} spacing={2.5}>
                {insightsData.data?.results.map(({ demographicType, insightText }) => (
                  <Card key={`${demographicType}-${insightText}`}>
                    <StyledInsightItemCardContent>
                      <Box>
                        <StyledInsightItemIcon icon={DemographicIcon[demographicType]} />
                      </Box>
                      <Typography sx={{ fontSize: themeLevel4.typography.body3 }}>{insightText}</Typography>
                    </StyledInsightItemCardContent>
                  </Card>
                ))}
              </Stack>
            </ThemeProvider>
          )}
        </FallbackComponent>
      </StyledInsightsCardContent>
    </StyledCard>
  );
});
