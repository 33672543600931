import { usePrintParam } from 'common-components';
import { PropsWithChildren } from 'react';
import { P360Analysis } from '../campaign360';
import { TrafficInsightDialog } from '../trafficinsightdialog';
import { ContentType } from '../trafficinsightdialog/types';
import { WebResolution } from '../webresolution';

export const PRINT_COMPONENTS: Record<string, React.FC> = {
  WebResolution: WebResolution,
  '360Analysis': P360Analysis,
  TrafficB2C: () => <TrafficInsightDialog contentType={ContentType.B2C} show isPrintPDF onHide={() => {}} />,
  TrafficB2B: () => <TrafficInsightDialog contentType={ContentType.B2B} show isPrintPDF onHide={() => {}} />,
};

export const PrintPDFProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [printPDF] = usePrintParam();
  const PrintPDFSessionComponent = PRINT_COMPONENTS[printPDF ?? ''];

  return <>{PrintPDFSessionComponent ? <PrintPDFSessionComponent /> : children}</>;
};
