import { Box, Stack, TextField, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, ConfirmDialog, useForm } from 'common-components';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { AudienceScheduleForm, FilterFormValues } from '../types';
import { ScheduleCard } from './scheduledetails';

interface SettingsProps {
  values: FilterFormValues;
  registerField: ReturnType<typeof useForm<FilterFormValues>>['registerField'];
  setFieldValue: ReturnType<typeof useForm<FilterFormValues>>['setFieldValue'];
  setSelectedScheduleId: (id: string) => void;
}

export const Settings: React.FC<SettingsProps> = withSuspense()(
  ({ values, registerField, setFieldValue, setSelectedScheduleId }) => {
    const { t: tAudience } = useTranslation('audience', { keyPrefix: 'detail' });
    const { t: tTranslation } = useTranslation('translation');
    const [removingSchedule, setRemovingSchedule] = useState<AudienceScheduleForm | null>(null);

    const handleSaveSchedule = useCallback(
      (schedule: AudienceScheduleForm) => {
        const newSchedules = _.cloneDeep(values.schedules);
        const idx = newSchedules.findIndex((currSchedule) => currSchedule.__internalId === schedule.__internalId);
        if (idx === -1) {
          newSchedules.unshift(schedule);
        } else {
          newSchedules[idx] = { ...newSchedules[idx], ...schedule };
        }
        setFieldValue(newSchedules, 'schedules');
        setSelectedScheduleId(schedule.__internalId);
      },
      [setFieldValue, values.schedules, setSelectedScheduleId]
    );

    const showConfirmDeleteSchedule = useCallback((schedule: AudienceScheduleForm) => {
      setRemovingSchedule(schedule);
    }, []);

    const deleteSchedule = (schedule: AudienceScheduleForm) => {
      if (schedule.id) {
        setFieldValue(_.uniq([...values.removedScheduleIds, schedule.id]), 'removedScheduleIds');
      }
      const newSchedules = values.schedules.filter((curr) => curr.__internalId !== schedule.__internalId);
      setFieldValue(newSchedules, 'schedules');
      setRemovingSchedule(null);
    };

    return (
      <>
        <Card sx={{ flex: 1 }}>
          <CardHeader title={tAudience('settings')} titleTruncate action={undefined} />
          <CardContent>
            <Stack direction={{ lg: 'column' }} gap={4} flex={1}>
              <Box>
                <Typography variant="subtitle2" marginBottom={1.5}>
                  {tAudience('name')}
                </Typography>
                <TextField
                  InputProps={{ name: 'name' }}
                  placeholder={`${tAudience('newExport')}`}
                  {...registerField('name')}
                />
              </Box>

              <ScheduleCard
                schedules={values.schedules}
                onSave={handleSaveSchedule}
                onDelete={showConfirmDeleteSchedule}
              />
            </Stack>
          </CardContent>
        </Card>
        <ConfirmDialog
          title={tAudience('deleteConfirmation.title')}
          message={tAudience('deleteConfirmation.message')}
          confirmButtonText={tTranslation('button.remove')}
          abortButtonText={tTranslation('button.keep')}
          open={!!removingSchedule}
          onConfirm={() => removingSchedule && deleteSchedule(removingSchedule)}
          onAbort={() => setRemovingSchedule(null)}
        />
      </>
    );
  }
);
