import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  ThemeProvider,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  AUDIT_EVENTS,
  Card,
  CardContent,
  CardHeader,
  ErrorMessageQueueBox,
  FallbackComponent,
  FeatureList,
  FontAwesomeIcon,
  FullscreenContainer,
  GA_EVENTS,
  Select,
  TabComponent,
  TabPanel,
  Tooltip,
  baseTheme,
  themeLevel2,
  themeLevel3,
  useAudit,
  useAuthorize,
  useForm,
  useGA4,
} from 'common-components';
import _ from 'lodash';
import { CSSProperties, Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { usePageTitleState } from '../../state/app';
import { usePageTitleAPI } from '../pagetitleselect/selectors';
import {
  NEW_EXPORT_DEFINITION,
  getDefinitionPayload,
  getGroupFieldsMap,
  getUniqueQuickSelectGroups,
  parseFieldsFilterData,
  parseVisitFilterData,
  populateFormData,
  sortFieldIdsByOriginalOrder,
  updateDateRange,
  validationSchema,
} from './constants';
import { AudienceExportProvider, useAudienceExport } from './context';
import { DefinitionDialog } from './definitiondialog';
import { Category, GroupField, QuickSelectGroup } from './enums';
import { FieldsTable } from './fieldstable';
import { ProspectCount } from './prospectcount';
import {
  useAudienceExportAPI,
  useAudienceFieldsAPI,
  useAutoEnableExportFilterValues,
  useCreateDefinitionAPI,
  useDeleteDefinitionAPI,
  useExportFields,
  useGetAllDefinitionAPI,
  useProspectCountAPI,
  useUpdateDefinitionAPI,
} from './selectors';
import { Settings } from './settings';
import { Definition, FilterFormValues, TableProps } from './types';
import { VisitFiltersTable } from './visittable';

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: themeLevel2.palette.background.paper,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

interface FieldsFiltersCardProps extends TableProps {
  selectedRow: string[];
  setSelectedRow: Dispatch<SetStateAction<string[]>>;
  selectedDefId: number;
}
interface VisitTableProps extends TableProps {
  handleReset: (key?: keyof FilterFormValues) => void;
  setFieldValue: (value: any, field: keyof FilterFormValues) => void;
  definition?: Definition;
}

export const FieldsFiltersCard = ({ ...props }: FieldsFiltersCardProps) => {
  const { selectedRow, setSelectedRow } = props;
  const { t } = useTranslation('audienceexport');
  const theme = useTheme();
  const [audienceFields] = useAudienceFieldsAPI();
  const { fieldsFilter } = useMemo(() => parseFieldsFilterData(audienceFields.data), [audienceFields.data]);
  const hasPermission = useAuthorize({ featureAccess: [FeatureList.TrafficInsight] });

  const quickSelectGroup = useMemo(() => {
    return [
      QuickSelectGroup.Custom,
      QuickSelectGroup.AllFields,
      ...getUniqueQuickSelectGroups(audienceFields.data?.fields || []),
    ];
  }, [audienceFields.data]);

  const groupFieldsMap = useMemo(() => getGroupFieldsMap(fieldsFilter), [fieldsFilter]);

  const quickSelect = useMemo(() => {
    if (fieldsFilter.length === selectedRow.length) return QuickSelectGroup.AllFields;
    const groupFieldsEntry = Array.from(groupFieldsMap.entries()).find((item) =>
      _.isEqual(_.keyBy(item[1]), _.keyBy(selectedRow))
    );
    return groupFieldsEntry ? groupFieldsEntry[0] : QuickSelectGroup.Custom;
  }, [fieldsFilter, groupFieldsMap, selectedRow]);

  const handleChangeQuickSelect = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    if (value === QuickSelectGroup.Custom) return;
    if (value === QuickSelectGroup.AllFields) return setSelectedRow(fieldsFilter.map((item) => item.id));
    setSelectedRow(_.filter(fieldsFilter, (item) => _.includes(item.quickSelectGroup, value)).map((item) => item.id));
  };

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader
        title={t('fieldsFilter.title')}
        action={
          <Box display="flex" flexWrap={'nowrap'} alignItems={'center'} minWidth={'18rem'}>
            <Typography marginRight={theme.spacing(2)} sx={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}>
              {t('fieldsFilter.quickSelect')}
            </Typography>
            <Select fullWidth value={quickSelect} onChange={handleChangeQuickSelect}>
              {_.map(quickSelectGroup, (item: QuickSelectGroup, index: number) => {
                const disabled = Object.values(QuickSelectGroup).includes(item) && !hasPermission;
                const menuItem = (
                  <MenuItem
                    value={item}
                    key={index}
                    sx={{
                      fontSize: theme.typography.body3.fontSize,
                      display: item === QuickSelectGroup.Custom ? 'none' : 'flex',
                    }}
                    disabled={disabled}
                  >
                    {item}
                  </MenuItem>
                );
                return disabled ? (
                  <Tooltip
                    title={t('tooltipDisableQickSelect')}
                    placement="top"
                    disableInteractive
                    componentsProps={{ tooltip: { sx: { fontWeight: 'bold', maxWidth: 'unset' } } }}
                  >
                    <span>{menuItem}</span>
                  </Tooltip>
                ) : (
                  menuItem
                );
              })}
            </Select>
          </Box>
        }
      />
      <CardContent sx={{ overflow: 'auto' }}>
        <FieldsTable {...props} />
      </CardContent>
    </Card>
  );
};

export const VisitFiltersCard = (props: VisitTableProps) => {
  const { t } = useTranslation('audienceexport');
  const { handleReset, setFieldValue, values, definition } = props;

  const { onReset: resetExportFilterValues } = useAutoEnableExportFilterValues(
    values.visitFields,
    handleReset,
    setFieldValue,
    definition
  );

  const onHandleReset = () => {
    handleReset(GroupField.VisitFields);
    resetExportFilterValues();
  };

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
            <Typography variant="h6">{t('visitFilter.title')}</Typography>
            <Button
              sx={(theme) => ({
                color: 'white',
                border: 'none !important',
                fontSize: theme.typography.body3.fontSize,
              })}
              variant="text"
              startIcon={<FontAwesomeIcon icon={['fal', 'arrow-rotate-left']} size="xs" />}
              onClick={onHandleReset}
            >
              <Typography component="span" lineHeight={1} variant="body3">
                {t('visitFilter.resetBtn')}
              </Typography>
            </Button>
          </Stack>
        }
      />
      <CardContent sx={{ overflow: 'auto' }}>
        <Stack>
          <TabComponent
            activeTab={props.values.visitFilterCategory}
            onChange={(newValue) => setFieldValue(newValue, 'visitFilterCategory')}
            headerAction={
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="exportFilterValues"
                    checked={values.exportFilterValues}
                    onChange={(__, checked) => setFieldValue(checked, 'exportFilterValues')}
                  />
                }
                label={
                  <Typography component="span" lineHeight={1}>
                    {t('visitFilter.exportFilterValues')}
                  </Typography>
                }
              />
            }
            tabs={[
              {
                label: 'Score',
                value: Category.VisitScore,
              },
              {
                label: 'Details',
                value: Category.VisitDetails,
              },
            ]}
            customTabStyles={
              {
                '&.Mui-selected': {
                  backgroundColor: '#424242',
                  color: baseTheme.palette.text.primary,
                },
                '&:not(.Mui-selected)': {
                  backgroundColor: themeLevel3.palette.background.default,
                  color: '#adadad',
                },
              } as CSSProperties
            }
          >
            <TabPanel value={Category.VisitScore} sx={{ overflow: 'auto' }}>
              <VisitFiltersTable {...props} category={Category.VisitScore} />
            </TabPanel>

            <TabPanel value={Category.VisitDetails} sx={{ overflow: 'auto' }}>
              <VisitFiltersTable {...props} category={Category.VisitDetails} />
            </TabPanel>
          </TabComponent>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const AudienceExportComponents = withSuspense()(() => {
  const theme = useTheme();
  const { t } = useTranslation('audienceexport');
  const {
    exportType,
    defaultVisitFilterCategory: defaultVisitCategory,
    defaultFilters,
    dateRange,
    setDateRange,
    refreshProspectCount,
    resetDefaultFilters,
  } = useAudienceExport();
  const [audienceFields] = useAudienceFieldsAPI();
  const [pageTitleData] = usePageTitleAPI();
  const [pageTitle] = usePageTitleState();
  // state
  const [showSaveDefDialog, setShowSaveDefDialog] = useState(false);
  const [selectedDefId, setSelectedDefId] = useState(NEW_EXPORT_DEFINITION.value);
  const [deleteDefId, setDeleteDefId] = useState<number>();

  const [definitions, fetchDefinitions] = useGetAllDefinitionAPI();
  const currentDefinition = _.find(definitions.data, ['id', selectedDefId]);

  //api
  const [exportData, doFetchExport] = useAudienceExportAPI(currentDefinition?.title);
  const [createDefData, , clearCreateDef] = useCreateDefinitionAPI();
  const [deleteDefData, fetchDeleteDef, clearDeleteDef] = useDeleteDefinitionAPI(deleteDefId);
  const [updateDefData, , clearUpdateDef] = useUpdateDefinitionAPI(selectedDefId);
  const [prospectCount] = useProspectCountAPI();

  const { visitsFilter } = useMemo(() => parseVisitFilterData(audienceFields.data), [audienceFields.data]);
  const { fieldsFilter } = useMemo(() => parseFieldsFilterData(audienceFields.data), [audienceFields.data]);
  const { exportFields, setExportFields, resetForNewExport } = useExportFields(fieldsFilter, exportType);

  const { values, hasError, handleSubmit, registerField, setFieldValue, castedValues, handleReset } = useForm<
    FilterFormValues,
    FilterFormValues
  >(
    useMemo(
      () => ({
        initialValues: {
          defId: currentDefinition?.id ?? NEW_EXPORT_DEFINITION.value,
          version: audienceFields.data?.version ?? '',
          visitFilterCategory: currentDefinition?.definition?.visitFilterCategory ?? defaultVisitCategory,
          visitFields: populateFormData(visitsFilter, currentDefinition, defaultFilters),
          commonFields: populateFormData(fieldsFilter, currentDefinition, defaultFilters),
          bounceRateReduction: currentDefinition?.definition.bounceRateReduction ?? !exportType,
          exportFilterValues: !!currentDefinition?.definition.exportFilterValues || !!defaultFilters?.length,
          pageTitle: _.includes(pageTitleData.data, currentDefinition?.definition.pageTitle)
            ? currentDefinition?.definition.pageTitle
            : pageTitle,
        },
        validationSchema: validationSchema(t, { ns: 'translation' }),
      }),
      [
        currentDefinition,
        audienceFields.data?.version,
        defaultVisitCategory,
        visitsFilter,
        defaultFilters,
        fieldsFilter,
        exportType,
        pageTitleData.data,
        pageTitle,
        t,
      ]
    )
  );

  const formRef = useRef({ castedValues, hasError });
  formRef.current = { castedValues, hasError };

  useEffect(() => {
    if (castedValues?.defId === selectedDefId) {
      refreshProspectCount(formRef.current.castedValues, formRef.current.hasError);
    }
  }, [castedValues?.defId, selectedDefId, dateRange, refreshProspectCount]);

  useEffect(() => {
    if (createDefData.data || updateDefData.data || deleteDefData.data) {
      setShowSaveDefDialog(false);
      setDeleteDefId(undefined);
      fetchDefinitions();
      clearUpdateDef();
      clearDeleteDef();
      clearCreateDef();
    }
    if (createDefData.data?.id) {
      setSelectedDefId(createDefData.data.id);
    }
    return () => {
      clearUpdateDef();
      clearDeleteDef();
      clearCreateDef();
    };
  }, [
    createDefData.data,
    updateDefData.data,
    deleteDefData.data,
    clearUpdateDef,
    fetchDefinitions,
    clearDeleteDef,
    clearCreateDef,
  ]);

  useEffect(() => {
    if (currentDefinition) {
      setExportFields(currentDefinition.definition.exportFields);
      setDateRange(updateDateRange(currentDefinition));
    }
  }, [selectedDefId, definitions.data, currentDefinition, setDateRange, setExportFields]);

  const handleDeleteDefinition = () => {
    fetchDeleteDef();
  };

  const handleChangeDefinition = (event: SelectChangeEvent<any>) => {
    resetDefaultFilters();
    setSelectedDefId(event.target.value);
    if (event.target.value === NEW_EXPORT_DEFINITION.value) {
      resetForNewExport();
    }
  };

  const handleShowSaveDialog = handleSubmit(() => {
    if (!hasError) {
      setShowSaveDefDialog(true);
    }
  });

  const onSubmit = handleSubmit((formValues) => {
    doFetchExport(getDefinitionPayload(formValues, dateRange, sortFieldIdsByOriginalOrder(fieldsFilter, exportFields)));
  });

  return (
    <>
      <Box component="form" onSubmit={onSubmit} height={'100%'}>
        <FallbackComponent
          overlay
          requesting={!!exportData.requesting || !!deleteDefData.requesting || !!definitions.requesting}
        >
          <ErrorMessageQueueBox errors={[exportData.error, prospectCount.error, deleteDefData.error]} />
          <Grid flex={1} container spacing={5.5} alignContent={'flex-start'} height={'100%'}>
            <Grid item xs={12} minHeight={'12.5rem'}>
              <Settings
                values={castedValues}
                isSaveDisabled={!!createDefData.requesting || !exportFields.length}
                selectedDef={selectedDefId}
                setDeleteDefId={setDeleteDefId}
                setFieldValue={setFieldValue}
                registerField={registerField}
                onChangeDefinition={handleChangeDefinition}
                onDeleteDefinition={handleDeleteDefinition}
                handleShowSaveDialog={handleShowSaveDialog}
              />
            </Grid>
            <Grid item xs={12} height={'calc(100% - 12.625rem)'} minHeight={'30rem'}>
              <Grid container spacing={7.5} height={'100%'}>
                <Grid item xs={7} sx={{ display: 'flex' }} height={'100%'}>
                  <FieldsFiltersCard
                    values={values}
                    selectedRow={exportFields}
                    setSelectedRow={setExportFields}
                    registerField={registerField}
                    selectedDefId={selectedDefId}
                  />
                </Grid>
                <Grid item xs={5} sx={{ display: 'flex' }} height={'100%'}>
                  <StyledPaper elevation={0}>
                    <VisitFiltersCard
                      values={values}
                      definition={currentDefinition}
                      registerField={registerField}
                      setFieldValue={setFieldValue}
                      handleReset={handleReset}
                    />
                  </StyledPaper>
                </Grid>
              </Grid>
              <Grid container spacing={7.5} minHeight="7rem" alignItems="center">
                <Grid item xs={7}>
                  <ProspectCount hasError={hasError} values={castedValues} />
                </Grid>
                <Grid item xs={5}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={exportData.requesting || exportFields.length === 0}
                  >
                    <Typography variant="button" color={theme.palette.common.black}>
                      {t('visitFilter.exportBtn')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FallbackComponent>
      </Box>
      <DefinitionDialog
        open={showSaveDefDialog}
        onClose={() => setShowSaveDefDialog(false)}
        definition={currentDefinition}
        filters={castedValues}
        exportFields={exportFields}
      />
    </>
  );
});

export const AudienceExport = withSuspense()(() => {
  const [, , putEvent] = useGA4();
  const [sendAuditEvent] = useAudit();
  const [audienceFields, fetchAudienceFields, clearAudienceFields] = useAudienceFieldsAPI();
  const [, fetchDefinitions, clearDefinitions] = useGetAllDefinitionAPI();
  const [pageTitleData, fetchPageTitleData] = usePageTitleAPI();

  useEffect(
    () => putEvent({ category: GA_EVENTS.audienceExport.__name, action: GA_EVENTS.audienceExport.view }),
    [putEvent]
  );
  useEffect(() => sendAuditEvent({ action: AUDIT_EVENTS.audienceExport.view }), [sendAuditEvent]);

  useEffect(() => {
    fetchAudienceFields();
    fetchDefinitions();
    fetchPageTitleData();
    return () => {
      clearAudienceFields();
      clearDefinitions();
    };
  }, [fetchAudienceFields, fetchDefinitions, clearAudienceFields, clearDefinitions, fetchPageTitleData]);

  return (
    <ThemeProvider theme={themeLevel3}>
      <FullscreenContainer>
        <AudienceExportProvider requesting={!!audienceFields.requesting || !!pageTitleData.requesting}>
          <AudienceExportComponents />
        </AudienceExportProvider>
      </FullscreenContainer>
    </ThemeProvider>
  );
});
