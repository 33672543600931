import _ from 'lodash';
import moment from 'moment';
import { ScheduleFrequency } from '../../types';
import { DailyScopeEnum, MonthlyScopeEnum, Weekday, WeeklyScopeEnum } from './enums';

export type Option = { id: any; name: any };
export const NEW_LIST_VALUE = 'new_list';
type GenerateOptionsType = {
  format?: (opt: Option) => Option;
  frequency?: ScheduleFrequency;
};
export const generateOptions = (
  type: 'frequency' | 'daysOfMonth' | 'daysOfWeek' | 'days' | 'times' | 'scope',
  options?: GenerateOptionsType
): Option[] => {
  const generators = {
    frequency: () => Object.values(ScheduleFrequency),
    daysOfMonth: () => _.range(1, 32, 1),
    daysOfWeek: () => {
      const DAY_OF_WEEK = {
        1: 'sun',
        2: 'mon',
        3: 'tue',
        4: 'wed',
        5: 'thu',
        6: 'fri',
        7: 'sat',
      };
      return Object.keys(DAY_OF_WEEK).map((key) => ({
        id: Number(key),
        name: DAY_OF_WEEK[key as unknown as keyof typeof DAY_OF_WEEK],
      }));
    },
    days: () => {
      const WeekDayDisplay = {
        [Weekday.Weekdays]: 'weekdays',
        [Weekday.Workdays]: 'workdays',
        [Weekday.Weekends]: 'weekends',
      };
      return Object.values(Weekday)
        .filter((val) => !isNaN(Number(val)))
        .map((val) => ({
          id: val,
          name: WeekDayDisplay[val as keyof typeof WeekDayDisplay],
        }));
    },
    times: () => {
      const start = moment().startOf('day');

      const times = 24 * 2; // 24 hours * 30 mins in an hour
      const result = [];
      for (let i = 0; i < times; i++) {
        const time = moment(start).add(30 * i, 'minutes');
        result.push({ id: time.format('HH:mm'), name: time.format('hh:mm A') });
      }
      return result;
    },
    scope: (opts?: GenerateOptionsType) => {
      const enumVal = (() => {
        switch (opts?.frequency) {
          case ScheduleFrequency.Monthly:
            return MonthlyScopeEnum;
          case ScheduleFrequency.Weekly:
            return WeeklyScopeEnum;
          case ScheduleFrequency.Daily:
            return DailyScopeEnum;
          default:
            return {};
        }
      })();
      return Object.values(enumVal);
    },
  };

  return (
    generators[type](options)?.map((val) => {
      const opt = (typeof val === 'object' ? val : { id: val, name: val }) as Option;
      return options?.format ? options?.format(opt) : opt;
    }) || []
  );
};
