import { useCallback, useContext, useMemo, useState } from 'react';
import GA4 from 'react-ga4';
import { Site, SiteContextValue } from '../context';
import { useSystemParamAPI, useUserSessionAPI } from '../selectors';
export var useGA4 = function () {
    var systemParam = useSystemParamAPI()[0];
    var userSessionData = useUserSessionAPI()[0];
    var _a = useState(false), isInitialized = _a[0], setIsInitialized = _a[1];
    var site = useContext(SiteContextValue).site;
    var isSuperUser = useMemo(function () { var _a; return (_a = userSessionData.data) === null || _a === void 0 ? void 0 : _a.superUser; }, [userSessionData.data]);
    var setInitializeGA4 = useCallback(function (_a) {
        var _b, _c;
        var hasUserId = _a.hasUserId;
        var trackingId = (_b = systemParam.data) === null || _b === void 0 ? void 0 : _b.gaTrackingId;
        var gaOptions = hasUserId
            ? {
                userId: (_c = userSessionData.data) === null || _c === void 0 ? void 0 : _c.id,
            }
            : undefined;
        if (trackingId && ((gaOptions === null || gaOptions === void 0 ? void 0 : gaOptions.userId) || !hasUserId)) {
            GA4.initialize([
                {
                    trackingId: trackingId,
                    gaOptions: gaOptions,
                },
            ]);
            setIsInitialized(true);
        }
    }, [systemParam.data, userSessionData.data]);
    var putEvent = useCallback(function (_a) {
        var category = _a.category, action = _a.action;
        if (site === Site.Admin || isSuperUser)
            return;
        GA4.event({ category: category, action: action });
    }, [site, isSuperUser]);
    return [isInitialized, setInitializeGA4, putEvent];
};
