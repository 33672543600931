import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import {
  AlignTable,
  HeadCell,
  NoDataAvailable,
  TabComponent,
  TabPanel,
  Table,
  baseTheme,
  checkNoData,
  themeLevel3,
  themeLevel5,
} from 'common-components';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterForm } from '../../audienceexport/filterform';
import { PageTitleSelect } from '../../pagetitleselect';
import { AudienceExport, VisitCategory } from '../enums';
import { FormFieldConfig } from '../types';
import { FieldWithHelperText, ResetButton } from './component';
import { ExportConfigProps } from './exportconfigtabs';
import { useAutoEnableExportVisitMetrics } from './selectors';
import { AudienceTabLayout } from './tablayout';

type FieldsTableProps = Readonly<Pick<ExportConfigProps, 'values' | 'setFieldValue' | 'registerField' | 'fields'>>;
type VisitFiltersTableProps = { category: VisitCategory } & Omit<FieldsTableProps, 'setFieldValue'>;
export const VisitFiltersTable = ({ category, values, registerField, fields }: Readonly<VisitFiltersTableProps>) => {
  const { t } = useTranslation('audience');
  const dataSources = useMemo(() => {
    const data =
      fields?.fields.visit.reduce<FormFieldConfig[]>((acc, curr, idx) => {
        if (curr.group?.includes(category)) {
          acc.push({ ...curr, formIndex: idx });
        }
        return acc;
      }, []) || [];

    // Add an empty config as a placeholder for page title filter (index=0)
    return [{} as FormFieldConfig, ...data];
  }, [fields, category]);

  const noVisitsData = useMemo(() => checkNoData(dataSources), [dataSources]);

  const columns = useMemo<Array<HeadCell<FormFieldConfig>>>(
    () => [
      {
        title: t('detail.option'),
        dataIndex: 'type',
        width: '45%',
        render: (type, row: FormFieldConfig, index: number) => {
          if (index === 0) return t('detail.pageTitle');
          if (!row.description) return row.fieldName;
          return <FieldWithHelperText label={row.fieldName} helperText={row.description} />;
        },
      },
      {
        title: t('detail.filter'),
        dataIndex: 'type',
        width: '55%',
        align: 'left' as AlignTable,
        render: (type, row: FormFieldConfig, index: number) =>
          index === 0 ? (
            <PageTitleSelect
              showTitle={false}
              enableAutoHide={false}
              sx={{ width: '9.5rem' }}
              {...registerField('pageTitle')}
            />
          ) : (
            <FilterForm
              key={`${AudienceExport.Visit}_${row.formIndex}`}
              operator={fields?.operators}
              groupName={AudienceExport.Visit}
              index={row.formIndex}
              rowValue={values[AudienceExport.Visit][row.formIndex]}
              rowConfig={row}
              registerField={registerField}
            />
          ),
      },
    ],
    [t, fields?.operators, values, registerField]
  );

  if (noVisitsData) return <NoDataAvailable />;

  return (
    <Table
      columns={columns}
      dataSources={dataSources}
      tableContainerProps={{
        sx: {
          flex: 1,
          flexBasis: 0,
          overflowX: 'unset',
        },
      }}
      tableProps={{ stickyHeader: true, sx: { tableLayout: 'fixed' } }}
    />
  );
};

function FieldsTable({ values, setFieldValue, registerField, fields }: FieldsTableProps) {
  const { t } = useTranslation('audience');
  return (
    <Stack>
      <TabComponent
        activeTab={values.visitCategory}
        onChange={(newValue) => setFieldValue(newValue, 'visitCategory')}
        headerAction={
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="exportVisitMetrics"
                checked={values.exportVisitMetrics}
                onChange={(__, checked) => setFieldValue(checked, 'exportVisitMetrics')}
              />
            }
            label={
              <Typography component="span" variant="body3">
                {t('visitFilters.exportVisitMetrics')}
              </Typography>
            }
          />
        }
        tabs={[
          {
            label: t('visitFilters.score'),
            value: VisitCategory.Score,
          },
          {
            label: t('visitFilters.details'),
            value: VisitCategory.Detail,
          },
        ]}
        customTabStyles={
          {
            width: baseTheme.typography.pxToRem(156),
            maxHeight: baseTheme.typography.pxToRem(36),
            minHeight: baseTheme.typography.pxToRem(36),
            fontSize: baseTheme.typography.body3.fontSize,
            '&.Mui-selected': {
              backgroundColor: themeLevel5.palette.background.default,
              color: baseTheme.palette.text.primary,
            },
            '&:not(.Mui-selected)': {
              backgroundColor: themeLevel3.palette.background.default,
              color: '#adadad',
            },
          } as CSSProperties
        }
      >
        <TabPanel value={VisitCategory.Score} sx={{ overflow: 'auto' }}>
          <VisitFiltersTable
            values={values}
            category={VisitCategory.Score}
            registerField={registerField}
            fields={fields}
          />
        </TabPanel>

        <TabPanel value={VisitCategory.Detail} sx={{ overflow: 'auto' }}>
          <VisitFiltersTable
            values={values}
            category={VisitCategory.Detail}
            registerField={registerField}
            fields={fields}
          />
        </TabPanel>
      </TabComponent>
    </Stack>
  );
}
export function VisitFiltersTab({ handleReset, ...props }: Readonly<ExportConfigProps>) {
  const { t } = useTranslation(['audience', 'translation']);
  const { onReset: resetExportFilterValues } = useAutoEnableExportVisitMetrics(
    props.values[AudienceExport.Visit],
    handleReset,
    props.setFieldValue,
    props.audience
  );

  const onHandleReset = () => {
    handleReset('pageTitle');
    handleReset(AudienceExport.Visit);
    resetExportFilterValues();
  };

  return (
    <AudienceTabLayout
      headerProps={{
        title: (
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
            <Typography variant="h6">{t('visitFilters.title')}</Typography>
            <ResetButton onClick={onHandleReset} />
          </Stack>
        ),
      }}
      contentProps={{
        children: <FieldsTable {...props} />,
      }}
    />
  );
}
