import { ConnectionSource, useForm } from 'common-components';
import { DailyScopeEnum, MonthlyScopeEnum, OneTimeScopeEnum, WeeklyScopeEnum } from './detail/scheduledetails/enums';
import { Category, FieldType, QuickSelectGroup, VisitCategory } from './enums';

export type DateScopeType = MonthlyScopeEnum | WeeklyScopeEnum | DailyScopeEnum | OneTimeScopeEnum;

export enum ScheduleFrequency {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
  OneTime = 'onetime',
}

export interface AudienceSchedule {
  id?: number;
  audienceId?: number;
  frequency: ScheduleFrequency;
  day?: number | null;
  time: string;
  nextFireTime?: Date;
  connectionName: string;
  connectionId: string;
  connectionSource: ConnectionSource;
  scope: DateScopeType;
  list?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  lastRunTime?: Date | null;
}

export interface AudienceScheduleForm extends AudienceSchedule {
  __internalId: string;
}

export interface AudienceListItem {
  id: number;
  name: string;
  schedules: AudienceSchedule[];
  lastRun?: {
    eventDate: Date;
  };
}

export interface FilterItem {
  fieldId: string;
  operator: string;
  values: (string | number)[];
}

export interface Audience {
  id: number;
  name: string;
  pageTitle?: string;
  orgId: string;
  visitCategory?: VisitCategory;
  bounceRateReduction: boolean;
  exportVisitMetrics: boolean;
  exportFields?: string[] | null;
  prospectFilters?: FilterItem[];
  visitFilters?: FilterItem[];
  schedules: AudienceSchedule[];
  fromDate?: string | null;
  toDate?: string | null;
}

export interface FieldConfig {
  id: string;
  type: FieldType;
  fieldName: string;
  category: Category;
  group?: VisitCategory[];
  quickSelectGroup?: QuickSelectGroup[];
  decimalPlaces?: number;
  description?: string;
  options?: string[];
  connections?: ConnectionSource[];
}

export interface FormFieldConfig extends FieldConfig {
  formIndex: number;
}

export interface AudienceFieldsResponse {
  version: string;
  fields: Record<Category, FieldConfig[]>;
  operators: Record<FieldType, string[]>;
}

export interface CellFormData {
  fieldId: string;
  type: FieldType;
  operator: string;
  value0: string | null | number;
  value1: string | null | number;
  enumValues: string[];
  decimalPlaces?: number;
  category: Category;
  group?: VisitCategory[];
}
export interface ExportField {
  id: string;
  checked: boolean;
  fieldName: string;
  connections?: ConnectionSource[];
}
export interface FilterFormValues {
  version: string;
  id?: number;
  name: string;
  pageTitle?: string;
  bounceRateReduction: boolean;
  exportFields: ExportField[];
  prospectFilters: CellFormData[];
  exportVisitMetrics: boolean;
  visitCategory: VisitCategory;
  visitFilters: CellFormData[];
  schedules: AudienceScheduleForm[];
  removedScheduleIds: number[];
  fromDate?: string | null;
  toDate?: string | null;
}

export interface TableProps {
  values: FilterFormValues;
  registerField: ReturnType<typeof useForm>['registerField'];
}

export interface DateRangeFilter {
  fromDate?: string | null;
  toDate?: string | null;
  scope: DateScopeType;
}
export interface ProspectCountPayload {
  pageTitle: string;
  bounceRateReduction: boolean;
  prospectFilters: FilterItem[];
  visitFilters: FilterItem[];
  dateRangeFilter: DateRangeFilter;
}
