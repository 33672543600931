import _ from 'lodash';
import { GA_EVENTS, LOGOUT_URL } from '../constants';
export var checkNoData = function (data) {
    if (data && typeof data === 'object') {
        return !_.reduce(Object.values(data), function (result, value) { return result || value; });
    }
    return !data || !_.isEmpty(data);
};
export var checkEveryFalse = function (items) {
    return items.every(function (i) { return i === false; });
};
export var getSessionData = function () {
    var searchParams = new URLSearchParams(window.location.search);
    var sessionData = searchParams.get('sessionData');
    if (sessionData) {
        return JSON.parse(window.atob(sessionData));
    }
    return null;
};
export var logoutSession = function (putEvent) {
    window.isLogout = true;
    window.open(LOGOUT_URL, '_self');
    putEvent === null || putEvent === void 0 ? void 0 : putEvent({ category: GA_EVENTS.auth.__name, action: GA_EVENTS.auth.logout });
};
export * from './chart';
export * from './excel';
export * from './number';
export * from './string';
export * from './styled';
export * from './testUtils';
