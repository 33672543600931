import { Box, Paper, ThemeProvider, Typography, styled } from '@mui/material';
import {
  Authenticated,
  FallbackComponent,
  FullscreenProvider,
  NoAccess,
  OrganizationStatus,
  PrivateRoute,
  Role,
  RoleId,
  SessionExpiredDialog,
  Site,
  SiteContextValue,
  getSessionData,
  themeLevel2,
  useActiveFullscreenSession,
  useFeaturePermissionAPI,
  useGA4,
  usePaymentConfigAPI,
  usePrintParam,
  useSystemParamAPI,
  useUserSessionAPI,
} from 'common-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useOutletContext, useSearchParams } from 'react-router-dom';
import { Chatbot } from './components/chatbot';
import { ChatbotProvider } from './components/chatbot/context';
import { FULL_SCREEN_COMPONENTS } from './components/fullscreen';
import { Header } from './components/header';
import { Navbar } from './components/navbar';
import { APP_ROUTES } from './components/navbar/constants';
import { PrintPDFProvider } from './components/printPDF';
import { UserInformationDialog } from './components/userinformationdialog';
import { useDefaultPageUrl } from './selectors/page';
import { DateRangeStateEnum } from './state/app/appSlice';

const StyledApp = styled(Box)({
  height: '100vh',
  display: 'flex',
  '@media print': {
    height: 'auto',
    minHeight: '100vh',
  },
});
const AppContainer = styled(Paper)(({ theme }) => {
  return {
    borderRadius: `${theme.typography.pxToRem(theme.shape.borderRadius * 2)} 0 0 ${theme.typography.pxToRem(
      theme.shape.borderRadius * 2
    )}`,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    '@media print': {
      borderRadius: 0,
    },
  };
});
const AppRoute = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  paddingInline: theme.spacing(7.75),
  paddingBottom: theme.spacing(8.25),
  flexDirection: 'column',
  overflow: 'auto',
}));

export interface AppOutletContext {
  title: string;
  noHeaderAction: boolean;
  dateRangeState?: string;
  showMenu?: boolean;
  showDateRange?: boolean;
  enableChatbot?: boolean;
  setTitle: (title: string) => void;
  setNoHeaderAction: (noHeaderAction: boolean) => void;
  setDateRangeState: (value?: string) => void;
  setShowMenu: (value?: boolean) => void;
  setShowDateRange: (value?: boolean) => void;
  setEnableChatbot: (value?: boolean) => void;
}

interface AppRouteElementProps {
  title: string;
  noHeaderAction: boolean;
  dateRangeState?: string;
  showMenu?: boolean;
  showDateRange?: boolean;
  enableChatbot?: boolean;
}

const PageLayout = () => {
  const [title, setTitle] = useState('');
  const [noHeaderAction, setNoHeaderAction] = useState(false);
  const [dateRangeState, setDateRangeState] = useState<DateRangeStateEnum>();
  const [showMenu, setShowMenu] = useState();
  const [showDateRange, setShowDateRange] = useState();
  const [enableChatbot, setEnableChatbot] = useState();
  const [userSessionData] = useUserSessionAPI();
  const [printActive] = usePrintParam();
  const { t } = useTranslation();
  const [activeSession] = useActiveFullscreenSession();

  const pageContent = (
    <Box flex={1} display="flex" flexDirection="column" overflow="auto">
      {userSessionData.data?.organization && !printActive && (
        <Typography component="div" variant="body2" textAlign="right" paddingTop={3} paddingBottom={2} paddingRight={4}>
          {userSessionData.data?.organization?.name ?? t('text.notProvided')}
        </Typography>
      )}
      <AppContainer elevation={3}>
        <Header
          dateRangeState={dateRangeState}
          title={title}
          noAction={noHeaderAction}
          showMenu={showMenu}
          showDateRange={showDateRange}
        />
        <AppRoute>
          <Outlet
            context={{
              dateRangeState,
              showMenu,
              showDateRange,
              enableChatbot,
              title,
              noHeaderAction,
              setDateRangeState,
              setShowMenu,
              setShowDateRange,
              setEnableChatbot,
              setTitle,
              setNoHeaderAction,
            }}
          />
        </AppRoute>
      </AppContainer>
    </Box>
  );

  if (printActive || activeSession) return pageContent;
  return (
    <ChatbotProvider enabled={enableChatbot}>
      {pageContent}
      <Chatbot />
    </ChatbotProvider>
  );
};

const AppRouteWrapper = (props: React.PropsWithChildren<AppRouteElementProps>) => {
  const { setTitle, setNoHeaderAction, setDateRangeState, setShowMenu, setShowDateRange, setEnableChatbot } =
    useOutletContext<AppOutletContext>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (window.orgId && !searchParams.has('sessionData')) {
      searchParams.append('sessionData', window.btoa(JSON.stringify({ orgId: window.orgId })));
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    setTitle(props.title);
    setDateRangeState(props.dateRangeState);
    setShowMenu(props.showMenu);
    setShowDateRange(props.showDateRange ?? true);
    setEnableChatbot(props.enableChatbot);
    setNoHeaderAction(props.noHeaderAction);
  }, [
    props.dateRangeState,
    props.noHeaderAction,
    props.showMenu,
    props.enableChatbot,
    props.title,
    setDateRangeState,
    setNoHeaderAction,
    setShowMenu,
    setShowDateRange,
    setEnableChatbot,
    setTitle,
    props.showDateRange,
  ]);

  return <PrintPDFProvider>{props.children}</PrintPDFProvider>;
};

export const App = () => {
  const defaultPageUrl = useDefaultPageUrl();
  const [featurePermissionData, fetchFeaturePermissionData] = useFeaturePermissionAPI();
  const [userSessionData, fetchUserSessionData] = useUserSessionAPI();
  const [, fetchSystemParam] = useSystemParamAPI();
  const [, fetchPaymentConfig] = usePaymentConfigAPI();
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [isInitialized, setInitializeGA4] = useGA4();
  const wrongSite = userSessionData.data?.roleId === RoleId.Administrator;

  useEffect(() => {
    const sessionData = getSessionData();
    if (sessionData?.orgId) window.orgId = sessionData.orgId;
    fetchFeaturePermissionData();
    fetchUserSessionData();
    fetchSystemParam();
    fetchPaymentConfig();
  }, [fetchUserSessionData, fetchFeaturePermissionData, fetchSystemParam, fetchPaymentConfig]);

  useEffect(() => {
    setInitializeGA4({ hasUserId: true });
  }, [setInitializeGA4]);

  useEffect(() => {
    if (wrongSite) {
      window.location.href = `${process.env.REACT_APP_ADMIN_URL ?? ''}/admin`;
    }
  }, [wrongSite]);

  useEffect(() => {
    setOpenInfoDialog(
      !!userSessionData.data &&
        userSessionData.data.role.name === Role.Manager &&
        !userSessionData.data.superUser &&
        !userSessionData.data.organization?.initialSetup
    );
  }, [userSessionData.data]);

  const handleHide = useCallback(() => {
    setOpenInfoDialog(false);
    fetchUserSessionData();
  }, [fetchUserSessionData]);

  const ctxValue = useMemo(() => ({ site: Site.Portal }), []);

  if (!userSessionData.data || featurePermissionData.requesting || !isInitialized) return <FallbackComponent />;
  if (wrongSite) return null;
  if (userSessionData.data.organization?.status === OrganizationStatus.Inactive)
    return <NoAccess message="inactiveAccount" disableReLogin={userSessionData.data.superUser} />;
  if (userSessionData.data.organization?.status === OrganizationStatus.Archived)
    return <NoAccess message="archivedOrg" disableReLogin={userSessionData.data.superUser} />;
  return (
    <SiteContextValue.Provider value={ctxValue}>
      <FullscreenProvider components={FULL_SCREEN_COMPONENTS}>
        <BrowserRouter>
          <StyledApp>
            <SessionExpiredDialog />
            <UserInformationDialog open={openInfoDialog} onHide={handleHide} />
            <Navbar />
            <ThemeProvider theme={themeLevel2}>
              {APP_ROUTES.map((i) => (
                <Authenticated
                  key={i.id}
                  hasPermission={i.hasPermission(
                    userSessionData.data?.organization?.status ?? OrganizationStatus.Onboarding
                  )}
                >
                  <Routes>
                    <Route path="" element={<PageLayout />}>
                      {i.pages.map((p) => (
                        <Route
                          key={p.url}
                          path={p.url}
                          element={
                            <PrivateRoute featureAccess={p.accessFeature}>
                              <AppRouteWrapper
                                title={p.name}
                                noHeaderAction={!p.icon}
                                dateRangeState={p.dateRangeState}
                                showMenu={p.showMenu}
                                enableChatbot={p.enableChatbot}
                                showDateRange={p.showDateRange}
                              >
                                <p.element />
                              </AppRouteWrapper>
                            </PrivateRoute>
                          }
                        />
                      ))}
                      <Route path="/" element={<Navigate to={defaultPageUrl} replace />} />
                      <Route path="*" element={<Navigate to={defaultPageUrl} replace />} />
                    </Route>
                  </Routes>
                </Authenticated>
              ))}
            </ThemeProvider>
          </StyledApp>
        </BrowserRouter>
      </FullscreenProvider>
    </SiteContextValue.Provider>
  );
};
