import { Box, Checkbox, FormControlLabel, MenuItem, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { AlignTable, FeatureList, HeadCell, Select, Table, Tooltip, useAuthorize } from 'common-components';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuickSelectGroup } from '../enums';
import { ExportField } from '../types';
import { getFieldsIDByQuickSelect, getGroupFieldsMap, getUniqueQuickSelectGroups } from './constants';
import { ExportConfigProps } from './exportconfigtabs';
import { useAudienceAPI, useFieldAPI } from './selectors';
import { AudienceTabLayout } from './tablayout';

export const FieldsTab: React.FC<ExportConfigProps> = ({ values, registerField, setFieldValue }) => {
  const { t } = useTranslation('audience', { keyPrefix: 'detail' });
  const theme = useTheme();
  const [fieldData] = useFieldAPI();
  const [audienceData] = useAudienceAPI();
  const hasPermission = useAuthorize({ featureAccess: [FeatureList.TrafficInsight] });

  const hasScheduled = useMemo(() => {
    return _.size(audienceData.data?.schedules) > 0;
  }, [audienceData.data]);

  const quickSelectGroup = useMemo(() => {
    return [
      QuickSelectGroup.Custom,
      QuickSelectGroup.AllFields,
      ...getUniqueQuickSelectGroups(fieldData.data?.fields.prospect || []),
    ];
  }, [fieldData.data]);

  const selectedRow = useMemo(() => {
    return _.filter(values.exportFields, { checked: true });
  }, [values.exportFields]);
  const groupFieldsMap = useMemo(() => getGroupFieldsMap(fieldData.data?.fields.prospect || []), [fieldData.data]);

  const quickSelect = useMemo(() => {
    const selectedIds = selectedRow.map((item) => item.id);

    if (_.size(fieldData.data?.fields.prospect) === _.size(selectedIds)) {
      return QuickSelectGroup.AllFields;
    }

    const groupFieldsEntry = Array.from(groupFieldsMap.entries()).find((item) =>
      _.isEqual(_.keyBy(item[1]), _.keyBy(selectedIds))
    );
    return groupFieldsEntry ? groupFieldsEntry[0] : QuickSelectGroup.Custom;
  }, [fieldData.data, groupFieldsMap, selectedRow]);

  const handleSelectAllRow = (checked: boolean) => {
    _.map(values.exportFields, (field, index) => {
      setFieldValue(checked, `exportFields.${index}.checked`);
    });
  };

  const handleChangeQuickSelect = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    if (value === QuickSelectGroup.Custom) return;
    if (value === QuickSelectGroup.AllFields) return handleSelectAllRow(true);
    const fieldsID = getFieldsIDByQuickSelect(value, fieldData.data?.fields.prospect || []);
    _.map(values.exportFields, (field, index) => {
      setFieldValue(_.includes(fieldsID, field.id), `exportFields.${index}.checked`);
    });
  };

  const columns = useMemo<Array<HeadCell<ExportField>>>(
    () => [
      {
        title: t('fields.selectAll'),
        dataIndex: 'id',
        align: 'left' as AlignTable,
        render: (id, row: ExportField, index: number) => (
          <FormControlLabel
            sx={{ mx: 0 }}
            control={
              <Checkbox
                key={id}
                color="primary"
                inputProps={{
                  'aria-labelledby': 'labelId',
                }}
                {...registerField(`exportFields.${index}.checked`, 'checked')}
              />
            }
            label={
              <Typography variant="subtitle2" component="span" lineHeight={1}>
                {row.fieldName}
              </Typography>
            }
          />
        ),
        isCheckBoxAll: true,
        cellProps: { padding: 'checkbox' },
      },
    ],
    [registerField, t]
  );

  return (
    <AudienceTabLayout
      backdrop
      cardProps={{
        sx: {
          borderTopLeftRadius: 0,
        },
      }}
      backdropProps={{
        open: !!hasScheduled,
        description: t('restrictText'),
      }}
      headerProps={{
        title: t('fieldsToExport'),
        titleTruncate: true,
        action: (
          <Box display="flex" flexWrap={'nowrap'} alignItems={'center'} minWidth={'18rem'}>
            <Typography marginRight={theme.spacing(2)} sx={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}>
              {t('fields.quickSelect')}
            </Typography>
            <Select fullWidth value={quickSelect} onChange={handleChangeQuickSelect}>
              {_.map(quickSelectGroup, (item: QuickSelectGroup, index: number) => {
                const disabled = Object.values(QuickSelectGroup).includes(item) && !hasPermission;
                const menuItem = (
                  <MenuItem
                    value={item}
                    key={index}
                    sx={{
                      fontSize: theme.typography.body3.fontSize,
                      display: item === QuickSelectGroup.Custom ? 'none' : 'flex',
                    }}
                    disabled={disabled}
                  >
                    {item}
                  </MenuItem>
                );
                return disabled ? (
                  <Tooltip
                    title={t('tooltipDisableQickSelect')}
                    placement="top"
                    disableInteractive
                    componentsProps={{ tooltip: { sx: { fontWeight: 'bold', maxWidth: 'unset' } } }}
                  >
                    <span>{menuItem}</span>
                  </Tooltip>
                ) : (
                  menuItem
                );
              })}
            </Select>
          </Box>
        ),
      }}
      contentProps={{
        children: (
          <Table
            columns={columns}
            dataSources={values.exportFields}
            tableContainerProps={{ sx: { flex: 1, flexBasis: 0 } }}
            tableProps={{ stickyHeader: true, sx: { tableLayout: 'fixed' } }}
            onSelectAllClick={(event: React.ChangeEvent<HTMLInputElement>) => handleSelectAllRow(event.target.checked)}
            numSelected={_.size(selectedRow)}
            rowCount={_.size(values.exportFields)}
          />
        ),
      }}
    />
  );
};
