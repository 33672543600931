var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider, Tooltip, Typography, createTheme, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { themeLevel4 } from '../config/theme';
var StyledParagraphTruncate = styled(Typography)({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    height: 'fit-content',
});
var themeTooltip = createTheme(themeLevel4, {
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: themeLevel4.typography.body2.fontSize,
                    background: themeLevel4.palette.background.default,
                    color: themeLevel4.palette.text.primary,
                    padding: themeLevel4.spacing(1.25),
                    boxShadow: themeLevel4.shadows[1],
                },
            },
        },
    },
});
export var ParagraphTruncateTooltip = function (_a) {
    var content = _a.content, _b = _a.maxLine, maxLine = _b === void 0 ? 1 : _b, onMouseOver = _a.onMouseOver, tooltipProps = _a.tooltipProps, props = __rest(_a, ["content", "maxLine", "onMouseOver", "tooltipProps"]);
    var targetRef = useRef(null);
    var _c = useState(false), isTruncated = _c[0], setIsTruncated = _c[1];
    var handleMouseOver = function (e) {
        onMouseOver === null || onMouseOver === void 0 ? void 0 : onMouseOver(e);
        if (targetRef.current &&
            (targetRef.current.scrollWidth > targetRef.current.offsetWidth ||
                targetRef.current.scrollHeight > targetRef.current.offsetHeight)) {
            setIsTruncated(true);
        }
        else
            setIsTruncated(false);
    };
    return (_jsx(ThemeProvider, __assign({ theme: themeTooltip }, { children: _jsx(Tooltip, __assign({ children: _jsx(StyledParagraphTruncate, __assign({}, props, { ref: targetRef, dangerouslySetInnerHTML: {
                    __html: "".concat(content),
                }, style: { WebkitLineClamp: maxLine }, onMouseOver: handleMouseOver })), title: isTruncated && (_jsx("span", { dangerouslySetInnerHTML: {
                    __html: "".concat(content),
                } })), componentsProps: { popper: { onClick: function (e) { return e.stopPropagation(); } } } }, tooltipProps)) })));
};
