import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import { useUnMount } from '../hooks';
export var excelExport = function (_a) {
    var excelName = _a.name, sheets = _a.sheets;
    var wb = utils.book_new();
    sheets.forEach(function (_a) {
        var sheetName = _a.name, header = _a.header, data = _a.data, transform = _a.transform;
        var ws;
        if (header instanceof Array) {
            ws = utils.json_to_sheet(data, { header: header });
        }
        else {
            var invertedHeader_1 = _.invert(header);
            ws = utils.json_to_sheet(data.map(function (item) {
                return _.mapValues(invertedHeader_1, function (key) {
                    var _a;
                    var value = _.get(item, key);
                    return (transform === null || transform === void 0 ? void 0 : transform[key]) ? (_a = transform === null || transform === void 0 ? void 0 : transform[key]) === null || _a === void 0 ? void 0 : _a.call(transform, value, item) : value;
                });
            }), { header: Object.keys(invertedHeader_1) });
        }
        utils.book_append_sheet(wb, ws, sheetName);
    });
    var fileName = excelName.endsWith('.xlsx') ? excelName : "".concat(excelName, ".xlsx");
    writeFileXLSX(wb, "[MS] ".concat(fileName));
};
export var useExcelExport = function (fileName, useExcelExportAPI) {
    var _a = useState(false), exporting = _a[0], setExporting = _a[1];
    var onExcelExported = useRef();
    var _b = useExcelExportAPI(), excelData = _b[0], fetchData = _b[1], clearData = _b[2];
    var handleExport = function (callback) {
        if (!excelData.requesting)
            fetchData();
        onExcelExported.current = callback;
        setExporting(true);
    };
    useEffect(function () {
        var _a;
        if (excelData.data && exporting) {
            excelExport({
                name: fileName,
                sheets: [
                    {
                        name: _.truncate(fileName, {
                            length: 31,
                            omission: '...',
                        }),
                        header: excelData.data.targetColumns,
                        data: excelData.data.data,
                    },
                ],
            });
            clearData();
            (_a = onExcelExported.current) === null || _a === void 0 ? void 0 : _a.call(onExcelExported);
            setExporting(false);
            onExcelExported.current = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearData, excelData.data]);
    useEffect(function () {
        if (excelData.error) {
            setExporting(false);
            onExcelExported.current = undefined;
        }
    }, [excelData.error]);
    useUnMount(function () {
        clearData();
    });
    return [exporting, handleExport];
};
