import { Stack, useTheme } from '@mui/material';
import { TabComponent, TabPanel, useForm, useStateWithCallbackLazy } from 'common-components';
import React, { CSSProperties, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { AudienceExport } from '../enums';
import { Audience, AudienceFieldsResponse, FilterFormValues, TableProps } from '../types';
import { DatesTab } from './datestab';
import { FieldsTab } from './fieldstab';
import { ProspectFiltersTab } from './prospectfilters';
import { VisitFiltersTab } from './visitfilters';

export interface ExportConfigProps extends TableProps {
  setFieldValue: ReturnType<typeof useForm>['setFieldValue'];
  handleReset: (key?: keyof FilterFormValues) => void;
  fields?: AudienceFieldsResponse;
  audience?: Audience;
  selectedSchedule: string;
  setSelectedSchedule: (value: string) => void;
  errors: ReturnType<typeof useForm>['errors'];
}

export type ExportConfigTabsApi = { showError: (errorTabs: AudienceExport[], cb: () => void) => void };
export const ExportConfigTabs = forwardRef((props: ExportConfigProps, ref: React.ForwardedRef<ExportConfigTabsApi>) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useStateWithCallbackLazy(AudienceExport.Fields);
  const { t } = useTranslation('audience', { keyPrefix: 'detail' });

  useImperativeHandle(
    ref,
    () => ({
      showError: (errorTabs: AudienceExport[], cb: () => void) => {
        if (errorTabs.includes(activeTab)) {
          cb();
        } else {
          setActiveTab(errorTabs[0], cb);
        }
      },
    }),
    [setActiveTab, activeTab]
  );

  const handleChangeTab = (newValue: string) => {
    setActiveTab(newValue as AudienceExport);
  };

  return (
    <Stack flex={1}>
      <TabComponent
        activeTab={activeTab}
        onChange={handleChangeTab}
        orientation="vertical"
        variant="scrollable"
        tabs={[
          {
            label: t('tabs.fields'),
            value: AudienceExport.Fields,
            icon: ['fal', 'columns-3'],
          },
          {
            label: t('tabs.prospect'),
            value: AudienceExport.Prospect,
            icon: ['fal', 'id-card'],
          },
          {
            label: t('tabs.visit'),
            value: AudienceExport.Visit,
            icon: ['fal', 'browser'],
          },
          {
            label: t('tabs.dates'),
            value: AudienceExport.Dates,
            icon: ['fal', 'calendar-days'],
          },
        ]}
        customTabStyles={
          {
            minWidth: '6rem',
            '&.Mui-selected': {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.success.main,
              borderTopRightRadius: 'unset',
              borderBottomRightRadius: 'unset',
            },
            '&:not(.Mui-selected)': {
              backgroundColor: 'transparent',
              color: theme.palette.text.primary,
            },
          } as CSSProperties
        }
      >
        <TabPanel value={AudienceExport.Fields} sx={{ overflow: 'auto' }}>
          <FieldsTab {...props} />
        </TabPanel>

        <TabPanel value={AudienceExport.Prospect} sx={{ overflow: 'auto' }}>
          <ProspectFiltersTab {...props} />
        </TabPanel>
        <TabPanel value={AudienceExport.Visit} sx={{ overflow: 'auto' }}>
          <VisitFiltersTab {...props} />
        </TabPanel>
        <TabPanel value={AudienceExport.Dates} sx={{ overflow: 'auto' }}>
          <DatesTab {...props} />
        </TabPanel>
      </TabComponent>
    </Stack>
  );
});
