import { TFunction, TOptionsBase } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import {
  AudienceExportType,
  Category,
  FieldType,
  Operators,
  QuickSelectGroup,
  VisitCategories,
  VisitCategory,
} from './enums';
import {
  AudienceFieldsResponse,
  CellFormData,
  Definition,
  DefinitionPayload,
  FieldConfig,
  FilterFormValues,
  FilterItem,
  ProspectCountPayload,
} from './types';

export const DEFAULT_VISIT_FILTER_CATEGORY = Category.VisitScore;

export const NEW_EXPORT_DEFINITION = {
  value: 0,
  label: 'New Export',
};

export const ExportTypeQuickSelect: Record<AudienceExportType, QuickSelectGroup> = {
  [AudienceExportType.EmailsProspects]: QuickSelectGroup.Emails,
  [AudienceExportType.EmailsPlusB2CProspects]: QuickSelectGroup.EnrichedB2C,
  [AudienceExportType.EmailsPlusB2BProspects]: QuickSelectGroup.EnrichedB2B,
  [AudienceExportType.VisitorEngagement]: QuickSelectGroup.AllFields,
  [AudienceExportType.SiteEngagement]: QuickSelectGroup.AllFields,
};

export const getUpdatedSelectedRows = (prevSelect: string[], id: string): string[] => {
  const selectedIndex = prevSelect.indexOf(id);
  let newSelected: string[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(prevSelect, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(prevSelect.slice(1));
  } else if (selectedIndex === prevSelect.length - 1) {
    newSelected = newSelected.concat(prevSelect.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(prevSelect.slice(0, selectedIndex), prevSelect.slice(selectedIndex + 1));
  }
  return newSelected;
};

export const sortFieldIdsByOriginalOrder = (fieldsFilter: FieldConfig[], fieldIds: string[]) => {
  const fieldOrderingMap = fieldsFilter.reduce((acc, current, i) => acc.set(current.id, i), new Map<string, number>());
  return _.sortBy(fieldIds, (field) => fieldOrderingMap.get(field));
};

export const parseFieldsFilterData = (data: AudienceFieldsResponse | undefined) => {
  const fieldsFilter: FieldConfig[] = _.filter(data?.fields, (field) =>
    field.category.some((c) => [Category.Demographic].includes(c))
  );

  return {
    fieldsFilter,
    operators: data?.operators,
  };
};

const isVisitCategory = (category: Category[]) => category.some((c) => VisitCategories.includes(c as VisitCategory));
export const parseVisitFilterData = (data: AudienceFieldsResponse | undefined) => {
  const visitsFilter: FieldConfig[] = _.filter(data?.fields, (field) => isVisitCategory(field.category));

  return {
    visitsFilter,
    operators: data?.operators,
  };
};

export const getUniqueQuickSelectGroups = (data: FieldConfig[]): string[] =>
  _.uniq(_.flatMap(data, (i) => i.quickSelectGroup ?? []));

export const getGroupFieldsMap = (data: FieldConfig[]) =>
  data.reduce((map, field) => {
    field.quickSelectGroup?.forEach((group) => {
      if (!map.get(group)) map.set(group, []);
      map.get(group)?.push(field.id);
    });
    return map;
  }, new Map<QuickSelectGroup, string[]>());

const fieldSchema = (t: TFunction, options: TOptionsBase = {}, min = 0): any =>
  yup.lazy(({ decimalPlaces = 0, operator, type, category }, data: any) => {
    const currVisitFilterCategory = _.get(data.from?.[0].value, 'visitFilterCategory' as keyof FilterFormValues);
    if (isVisitCategory(category) && !category.includes(currVisitFilterCategory)) {
      return yup.object();
    }

    if (operator === Operators.DoNotFilter) {
      return yup.object();
    }
    let shape: any = {
      value0: yup
        .string()
        .nullable()
        .trim()
        .required(`${t('error.required', options)}`),
    };
    switch (type) {
      case FieldType.Enum: {
        shape = {
          enumValues: yup.array().min(1, `${t('error.required', options)}`),
        };
        break;
      }
      case FieldType.Number:
      case FieldType.NumberMetric:
      case FieldType.NumberSet: {
        const stepSize = 1 / Math.pow(10, decimalPlaces);
        shape = {
          value0: yup
            .number()
            .nullable()
            .required(`${t('error.required', options)}`)
            .when('operator', {
              is: Operators.LessThan,
              then: (schema) => schema.min(min + stepSize, `${t('error.invalid', options)}`),
              otherwise: (schema) => schema.min(min, `${t('error.invalid', options)}`),
            })
            .when('operator', {
              is: Operators.TopPercent,
              then: (schema) =>
                schema.min(1, `${t('error.invalid', options)}`).max(99, `${t('error.invalid', options)}`),
            }),
          value1: yup
            .number()
            .nullable()
            .when('operator', {
              is: Operators.Between,
              then: (schema) =>
                schema.required(`${t('error.required', options)}`).test({
                  message: `${t('error.invalid', options)}`,
                  test: (value, ctx) => (value ?? min + stepSize) > (ctx.parent.value0 ?? min),
                }),
            }),
        };
      }
    }
    return yup.object().shape(shape);
  });

export const validationSchema = (t: TFunction, options: TOptionsBase = {}) =>
  yup.object().shape({
    visitFields: yup.array().of(fieldSchema(t, options, 1)),
    commonFields: yup.array().of(fieldSchema(t, options)),
  });

type DateRange = [moment.Moment, moment.Moment];
export function getDefinitionPayload(
  formValues: FilterFormValues,
  dateRange: DateRange,
  exportFields?: undefined,
  exportFilters?: FilterItem[]
): ProspectCountPayload;
export function getDefinitionPayload(
  formValues: FilterFormValues,
  dateRange: DateRange,
  exportFields: string[]
): DefinitionPayload;

export function getDefinitionPayload(
  formValues: FilterFormValues,
  dateRange: DateRange,
  exportFields?: string[],
  exportFilters?: FilterItem[]
): unknown {
  const [fromDate, toDate] = dateRange || [];
  const extractValues = (field: CellFormData): (string | number)[] => {
    const values = _.compact([...field.enumValues, field.value0]);
    if (field.operator === Operators.Between) {
      return _.compact([...values, field.value1]);
    }
    return values;
  };
  const visitCats = _.keyBy(VisitCategories);
  const filters: FilterItem[] =
    exportFilters ??
    [...(formValues?.commonFields ?? []), ...(formValues?.visitFields ?? [])]
      .filter(
        ({ category, operator }) =>
          operator !== Operators.DoNotFilter &&
          (!visitCats[category[0]] || category.includes(formValues?.visitFilterCategory))
      )
      .map((field) => ({
        fieldId: field.fieldId,
        operator: field.operator,
        values: extractValues(field),
      }));

  return {
    pageTitle: formValues?.pageTitle ?? '',
    visitFilterCategory: exportFields ? formValues?.visitFilterCategory : undefined,
    version: exportFields ? formValues?.version : undefined,
    bounceRateReduction: formValues?.bounceRateReduction,
    exportFilterValues: exportFields ? formValues?.exportFilterValues : undefined,
    dateRange: { fromDate: fromDate?.format('YYYY-MM-DD') || '', toDate: toDate?.format('YYYY-MM-DD') || '' },
    exportFields,
    filters,
  };
}

export const populateFormData = (
  fields: FieldConfig[],
  currentDefinition?: Definition,
  defaultFilters?: FilterItem[]
): CellFormData[] => {
  return _.map(fields, (field) => {
    const defaultFilterValue = _.find(defaultFilters, ['fieldId', field.id]);
    const filterValue = _.find(currentDefinition?.definition.filters, ['fieldId', field.id]) ?? defaultFilterValue;
    const getDefaultValue = [FieldType.Number, FieldType.NumberSet, FieldType.NumberMetric].includes(field.type)
      ? null
      : '';
    const getEnumValues = field.type === FieldType.Enum ? filterValue?.values || [] : [];
    return {
      fieldId: field.id,
      type: field.type,
      operator: filterValue ? filterValue.operator : Operators.DoNotFilter,
      value0: field.type !== FieldType.Enum && _.get(filterValue, 'values[0]', getDefaultValue),
      value1: field.type !== FieldType.Enum && _.get(filterValue, 'values[1]', getDefaultValue),
      enumValues: getEnumValues,
      decimalPlaces: field.decimalPlaces,
      category: field.category,
    } as CellFormData;
  });
};

export const updateDateRange = (currentDefinition: Definition): DateRange => {
  const startDate = moment(currentDefinition.definition.dateRange.fromDate);
  const endDate = moment(currentDefinition.definition.dateRange.toDate);
  return [startDate, endDate];
};
