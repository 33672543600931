import { LoadingButton } from '@mui/lab';
import { Box, Button, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, ErrorMessageQueueBox, useForm } from 'common-components';
import { TFunction } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { getDefinitionPayload } from './constants';
import { useAudienceExport } from './context';
import { useCreateDefinitionAPI, useUpdateDefinitionAPI } from './selectors';
import { Definition, FilterFormValues } from './types';

interface DefinitionDialogProps {
  open: boolean;
  onClose: () => void;
  definition: Definition | undefined;
  filters: FilterFormValues;
  exportFields: string[];
}

interface FormValues {
  title: string;
}

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    title: yup
      .string()
      .trim()
      .required('error.required')
      .max(100, `${t('error.maxLength', { ns: 'translation', length: 100 })}`)
      .matches(/^[\w\s.-]+$/, 'error.specialCharacters'),
  });

export const DefinitionDialog: React.FC<DefinitionDialogProps> = ({
  open,
  onClose,
  definition,
  filters,
  exportFields,
}) => {
  const { t } = useTranslation('audienceexport');
  const theme = useTheme();
  const [createDefData, fetchCreateDef] = useCreateDefinitionAPI();
  const [updateDefData, fetchUpdateDef] = useUpdateDefinitionAPI(definition?.id);
  const { dateRange } = useAudienceExport();

  const { registerField, handleReset, handleSubmit } = useForm<FormValues>(
    useMemo(
      () => ({
        initialValues: {
          title: definition?.title ?? '',
        },
        validationSchema: validationSchema(t),
      }),
      [definition?.title, t]
    )
  );

  useEffect(() => {
    if (open) {
      handleReset();
    }
  }, [handleReset, open]);

  const onSubmit = handleSubmit((formValues) => {
    const payload: Omit<Definition, 'id'> = {
      ...formValues,
      definition: getDefinitionPayload(filters, dateRange, exportFields),
    };
    if (definition) {
      fetchUpdateDef(payload);
    } else {
      fetchCreateDef(payload);
    }
  });

  return (
    <Dialog open={open} fullScreenBreakpoint="xs" maxWidth="sm" onClose={onClose}>
      <Box component="form" onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h6">{t('settings.saveExportDefinition')}</Typography>
        </DialogTitle>
        <DialogContent>
          <ErrorMessageQueueBox errors={[createDefData.error, updateDefData.error]} />
          <InputLabel>{t('name')}</InputLabel>
          <TextField {...registerField('title')} inputProps={{ maxLength: 100 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="inherit" sx={{ minWidth: theme.typography.pxToRem(120) }}>
            {t('button.cancel', { ns: 'translation' })}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={!!createDefData.requesting || !!updateDefData.requesting}
            sx={{ minWidth: theme.typography.pxToRem(120) }}
          >
            <Typography variant="button" color={theme.palette.common.black}>
              {t('button.save', { ns: 'translation' })}
            </Typography>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
