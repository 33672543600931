import { Grid } from '@mui/material';
import { Card, CardContent, CardHeader, FallbackComponent, useFeaturePermissionAPI } from 'common-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { EngagementNestedMenu } from '../nestedengagementmenu';
import { VisitorBreakdownEnum, getRestrictedFeatures } from './constants';
import { useClassificationChartData } from './selectors';
import { Classification } from './visitorclassification/classification';
import { RequiredUpgradeInsightBackdrop } from './visitorclassification/upgraderequiredbackdrop';

export const EnrichedClassifications = withSuspense()(() => {
  const { t } = useTranslation(['webresolution', 'translation']);
  const [featurePermissionData] = useFeaturePermissionAPI();
  const [, , isNoB2BData] = useClassificationChartData(VisitorBreakdownEnum.EnrichedB2B);
  const [, , isNoB2CData] = useClassificationChartData(VisitorBreakdownEnum.EnrichedB2C);

  const restrictedB2C = useMemo(
    () => !!getRestrictedFeatures(VisitorBreakdownEnum.EnrichedB2C, featurePermissionData.data).length,
    [featurePermissionData.data]
  );
  const restrictedB2B = useMemo(
    () => !!getRestrictedFeatures(VisitorBreakdownEnum.EnrichedB2B, featurePermissionData.data).length,
    [featurePermissionData.data]
  );
  const restrictedEngagement = restrictedB2C && restrictedB2B;

  return (
    <Card sx={{ flex: 7, position: 'relative', minHeight: '25rem' }}>
      <FallbackComponent requesting={featurePermissionData.requesting}>
        <RequiredUpgradeInsightBackdrop show={!!restrictedEngagement}>
          <CardHeader
            title={t('enrichedOutcomes')}
            action={
              !restrictedEngagement && (
                <EngagementNestedMenu
                  isNoB2CData={isNoB2CData && !restrictedB2C}
                  isNoB2BData={isNoB2BData && !restrictedB2B}
                />
              )
            }
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item container key={VisitorBreakdownEnum.EnrichedB2C}>
                <Classification type={VisitorBreakdownEnum.EnrichedB2C} />
              </Grid>
              <Grid item container key={VisitorBreakdownEnum.EnrichedB2B}>
                <Classification type={VisitorBreakdownEnum.EnrichedB2B} />
              </Grid>
            </Grid>
          </CardContent>
        </RequiredUpgradeInsightBackdrop>
      </FallbackComponent>
    </Card>
  );
});
