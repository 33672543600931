import { Box, Paper, ThemeProvider, styled } from '@mui/material';
import { FallbackComponent, GA_EVENTS, themeLevel3, useGA4 } from 'common-components';
import { useEffect } from 'react';
import { FAQItem } from './faqitem';
import { useFAQAPI } from './selectors';

const FQAContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  flexBasis: 0,
  display: 'flex',
  padding: `${theme.spacing(7)} ${theme.spacing(5.5)}`,
  rowGap: theme.spacing(8),
  flexDirection: 'column',
  overflow: 'auto',
}));

export const FAQ = () => {
  const [faqData, fetchFAQ, clearFAQ] = useFAQAPI();
  const [, , putEvent] = useGA4();

  useEffect(() => putEvent({ category: GA_EVENTS.faq.__name, action: GA_EVENTS.faq.view }), [putEvent]);
  useEffect(() => {
    fetchFAQ();
    return () => {
      clearFAQ();
    };
  }, [fetchFAQ, clearFAQ]);

  return (
    <ThemeProvider theme={themeLevel3}>
      <Paper sx={{ overflow: 'hidden', display: 'flex', flex: 1 }}>
        <FallbackComponent requesting={faqData.requesting}>
          <FQAContainer>
            {faqData.data?.map((faq) => (
              <FAQItem key={faq.id} title={faq.question} content={faq.answer} />
            ))}
          </FQAContainer>
        </FallbackComponent>
      </Paper>
    </ThemeProvider>
  );
};
