import moment, { Moment } from 'moment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppState,
  DateRangeStateEnum,
  selectAppState,
  set360FilterCampaignId,
  set360FilterIgnoreLowVolume,
  set360FilterMetrics,
  setDateRange,
  setIsCampaignDateRangeSet,
  setPageTitle,
} from './appSlice';

export const usePageTitleState = (): [string | undefined, (value?: string) => void] => {
  const dispatch = useDispatch();
  const doSetState = useCallback(
    (value?: string) => {
      dispatch(setPageTitle({ value }));
    },
    [dispatch]
  );
  const data = useSelector(selectAppState) as AppState;
  return [data.pageTitle, doSetState];
};

export const useDateRangeState = (
  key: DateRangeStateEnum = DateRangeStateEnum.Global
): [[Moment, Moment], (v: [Moment, Moment]) => void] => {
  const dispatch = useDispatch();
  const doSetState = useCallback(
    ([fromDate, toDate]: [Moment, Moment]) => {
      if (
        fromDate.isValid() &&
        toDate.isValid() &&
        fromDate.isSameOrBefore(toDate, 'date') &&
        fromDate.isSameOrBefore(moment().subtract(1, 'day'), 'date') &&
        toDate.isSameOrBefore(moment(), 'date')
      ) {
        dispatch(setDateRange({ key, value: [fromDate, toDate] }));
      }
    },
    [dispatch, key]
  );
  const data = useSelector(selectAppState) as AppState;
  return [data.dateRange[key], doSetState];
};

export const use360FilterCampaignIdState = (): [string[], (v: string[]) => void] => {
  const dispatch = useDispatch();
  const doSetState = (value: string[]) => dispatch(set360FilterCampaignId({ value }));

  const data = useSelector(selectAppState) as AppState;
  return [data?.page360Filter.campaignId ?? [], doSetState];
};

export const use360FilterResponseMetricState = (): [string | undefined, (v: string) => void] => {
  const dispatch = useDispatch();
  const doSetState = (value: string) => dispatch(set360FilterMetrics({ value }));

  const data = useSelector(selectAppState) as AppState;
  return [data?.page360Filter.responseMetric, doSetState];
};

export const useIsCampaignDateRangeSetState = (): [boolean | undefined, (value: boolean) => void] => {
  const dispatch = useDispatch();
  const doSetState = useCallback(
    (value: boolean) => {
      dispatch(setIsCampaignDateRangeSet({ value }));
    },
    [dispatch]
  );
  const data = useSelector(selectAppState) as AppState;
  return [data.isCampaignDateRangeSet, doSetState];
};

export const use360FilterIgnoreLowVolumeState = (): [boolean, (v: boolean) => void] => {
  const dispatch = useDispatch();
  const doSetState = (value: boolean) => dispatch(set360FilterIgnoreLowVolume({ value }));

  const data = useSelector(selectAppState) as AppState;
  return [data?.page360Filter.ignoreLowVolume ?? [], doSetState];
};
