import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { styled } from '@mui/material';
import {
  CardContent,
  CardHeader,
  ChartMenu,
  FallbackComponent,
  GA_EVENTS,
  PlotComponent,
  SessionActive,
  checkNoData,
  useGA4,
} from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { useMetricTypeState, useMetricsAPI } from '../selectors';
import { StyledItemCard } from '../styled';
import { LocationDetailMode } from '../types';
import {
  useGeoLocationChartData,
  useGeoLocationDetailAPI,
  useLocationDetailExcelData,
  useLocationDetailModeState,
  useRegionsLocationChartData,
  useRegionsLocationDetailAPI,
} from './selectors';

const StyledMapboxPlotly = styled(PlotComponent)({
  '.mapboxgl-control-container': {
    display: 'none',
  },
});

interface IdealLocationDetailProps {
  zoom?: number;
}

const GeographyIdealLocationDetail: React.FC<IdealLocationDetailProps> = withSuspense()(({ zoom }) => {
  const { t } = useTranslation(['360analysis', 'translation']);
  const [metricType] = useMetricTypeState();
  const [metricData] = useMetricsAPI();
  const [, fetchGeoData, clearGeoData] = useGeoLocationDetailAPI();
  const [data, isPending] = useGeoLocationChartData();
  const isNoData = useMemo(() => checkNoData(data), [data]);

  useEffect(() => {
    if (metricData.data) {
      fetchGeoData(undefined, true);
      return clearGeoData;
    }
  }, [clearGeoData, fetchGeoData, metricData.data]);

  const layout = useMemo(
    () => ({
      margin: { l: 0, r: 0, b: 0, t: 0, pad: 0 },
      mapbox: {
        style: 'dark',
        accesstoken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        center: {
          lon: -98,
          lat: 38.5,
        },
        zoom,
      },
    }),
    [zoom]
  );

  return (
    <StyledMapboxPlotly
      data={data}
      layout={layout}
      templatetooltip={(__, value: number) =>
        t('template.chartTooltipNoLabel', {
          value,
          unitLabel: { name: `unitLabel.${metricType}`, ns: '360analysis' },
          ns: 'translation',
        })
      }
      requesting={isPending ?? false}
      isNoData={isNoData}
    />
  );
});

const RegionsIdealLocationDetail: React.FC<IdealLocationDetailProps> = withSuspense()(() => {
  const { t } = useTranslation(['360analysis', 'translation']);
  const [metricType] = useMetricTypeState();
  const [metricData] = useMetricsAPI();
  const [regionsData, fetchRegionsData, clearRegionsData] = useRegionsLocationDetailAPI();
  const [data, isPending] = useRegionsLocationChartData();
  const isNoData = useMemo(() => checkNoData(regionsData.data), [regionsData.data]);

  useEffect(() => {
    if (metricData.data) {
      fetchRegionsData(undefined, true);
      return clearRegionsData;
    }
  }, [clearRegionsData, fetchRegionsData, metricData.data]);

  return (
    <PlotComponent
      data={data}
      layout={{ yaxis: { showgrid: true } }}
      templatetooltip={(label, value) =>
        t('template.chartTooltip', {
          label,
          value,
          unitLabel: { name: `unitLabel.${metricType}`, ns: '360analysis' },
          ns: 'translation',
        })
      }
      requesting={isPending}
      isNoData={isNoData}
    />
  );
});

const LocationDetailComponents: Record<LocationDetailMode, React.FC<IdealLocationDetailProps>> = {
  [LocationDetailMode.Geography]: GeographyIdealLocationDetail,
  [LocationDetailMode.Regions]: RegionsIdealLocationDetail,
};

export const IdealLocationDetail = withSuspense()(({ zoom = 2 }: IdealLocationDetailProps) => {
  const { t } = useTranslation('360analysis');
  const [locationDetailMode, setLocationDetailMode] = useLocationDetailModeState();
  const [exporting, handleExcelExport] = useLocationDetailExcelData();
  const [, , putEvent] = useGA4();

  const [regionsData] = useRegionsLocationDetailAPI();
  const [geoData] = useGeoLocationChartData();

  const isNoData = useMemo(
    () => (locationDetailMode === LocationDetailMode.Regions ? checkNoData(regionsData.data) : checkNoData(geoData)),
    [regionsData.data, geoData, locationDetailMode]
  );

  const prefixItems = useMemo(() => {
    return [
      {
        name: t(`locationDetail.${LocationDetailMode.Regions}`),
        hidden: locationDetailMode === LocationDetailMode.Regions,
        icon: ['fal', 'bar-chart'] as IconProp,
        action: () => {
          setLocationDetailMode(LocationDetailMode.Regions);
          putEvent({
            category: GA_EVENTS._360InsightsDashboard.__name,
            action: GA_EVENTS._360InsightsDashboard.changeLocationDetailMetric,
          });
        },
      },
      {
        name: t(`locationDetail.${LocationDetailMode.Geography}`),
        hidden: locationDetailMode === LocationDetailMode.Geography,
        icon: ['fal', 'map'] as IconProp,
        action: () => {
          setLocationDetailMode(LocationDetailMode.Geography);
          putEvent({
            category: GA_EVENTS._360InsightsDashboard.__name,
            action: GA_EVENTS._360InsightsDashboard.changeLocationDetailMetric,
          });
        },
      },
    ];
  }, [t, locationDetailMode, setLocationDetailMode, putEvent]);

  const Component = useMemo(
    () => locationDetailMode && LocationDetailComponents[locationDetailMode],
    [locationDetailMode]
  );

  return (
    <FallbackComponent overlay requesting={exporting.requesting === true}>
      <StyledItemCard data-testid="locationdetail-item-card">
        <CardHeader
          title={t('locationDetail.title')}
          titleTruncate
          action={
            <ChartMenu
              fsSession={SessionActive.LocationDetail}
              onExcelExport={handleExcelExport}
              prefixItems={prefixItems}
              isNoData={isNoData}
            />
          }
        />
        <CardContent>{Component && <Component zoom={zoom} />}</CardContent>
      </StyledItemCard>
    </FallbackComponent>
  );
});
