var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete as AutocompleteMUI, Chip, TextField, ThemeProvider, createTheme, styled, useTheme, } from '@mui/material';
import { useMemo, useRef } from 'react';
import { themeLevel4 } from '../config/theme';
var StyledAutocomplete = styled(AutocompleteMUI, {
    shouldForwardProp: function (prop) { return prop !== 'transformPopupIcon'; },
})(function (_a) {
    var limitTags = _a.limitTags, value = _a.value, forcePopupIcon = _a.forcePopupIcon, transformPopupIcon = _a.transformPopupIcon;
    // 90px is min-width of +tag, input, icon search, padding
    var tagMinWidth = forcePopupIcon !== false ? 90 : 44;
    var numberOfTags = value ? Math.min(value.length, limitTags) : limitTags;
    var tagMaxWidth = "calc((100% - ".concat(tagMinWidth, "px) / ").concat(numberOfTags, ")");
    return {
        '.MuiAutocomplete-endAdornment > button': __assign(__assign({}, (!transformPopupIcon ? { transform: 'none' } : {})), { borderRadius: '50%' }),
        '.MuiAutocomplete-inputRoot': {
            height: 'inherit',
            maxHeight: 'auto',
            'z-index': 1,
        },
        '.MuiAutocomplete-tag': {
            fontSize: themeLevel4.typography.body3.fontSize,
            lineHeight: themeLevel4.typography.body3.fontSize,
        },
        'div:not(.Mui-focused).MuiOutlinedInput-root': {
            '.MuiAutocomplete-tag:nth-of-type(1), .MuiAutocomplete-tag:nth-of-type(2)': {
                maxWidth: tagMaxWidth,
                textOverflow: 'ellipsis',
            },
        },
        '.MuiAutocomplete-input': {
            minWidth: '0.5rem !important',
        },
    };
});
var ChipIcon = styled(FontAwesomeIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        '&.MuiChip-deleteIcon': {
            color: "".concat(theme.palette.text.primary, " !important"),
            fontSize: theme.typography.pxToRem(12),
        },
    });
});
var themeAutocomplete = createTheme(themeLevel4, {
    components: {
        MuiAutocomplete: {
            defaultProps: {
                size: 'small',
                fullWidth: true,
            },
            styleOverrides: {
                select: {
                    lineHeight: themeLevel4.typography.pxToRem(20),
                },
                icon: {
                    fontSize: themeLevel4.typography.body3.fontSize,
                },
            },
        },
    },
});
export var Autocomplete = function (_a) {
    var _b = _a.getOptionValue, getOptionValue = _b === void 0 ? function (o) { var _a; return (_a = o === null || o === void 0 ? void 0 : o.id) !== null && _a !== void 0 ? _a : o; } : _b, _c = _a.getOptionLabel, getOptionLabel = _c === void 0 ? function (o) { var _a; return (_a = o === null || o === void 0 ? void 0 : o.name) !== null && _a !== void 0 ? _a : o; } : _c, allOption = _a.allOption, disabled = _a.disabled, _d = _a.options, options = _d === void 0 ? [] : _d, _e = _a.multiple, multiple = _e === void 0 ? true : _e, _f = _a.value, value = _f === void 0 ? (multiple ? [] : null) : _f, onChange = _a.onChange, name = _a.name, helperText = _a.helperText, error = _a.error, inputRef = _a.inputRef, onBlur = _a.onBlur, _g = _a.requesting, requesting = _g === void 0 ? false : _g, _h = _a.transformPopupIcon, transformPopupIcon = _h === void 0 ? true : _h, placeholder = _a.placeholder, _j = _a.disableIfOptionEmpty, disableIfOptionEmpty = _j === void 0 ? true : _j, props = __rest(_a, ["getOptionValue", "getOptionLabel", "allOption", "disabled", "options", "multiple", "value", "onChange", "name", "helperText", "error", "inputRef", "onBlur", "requesting", "transformPopupIcon", "placeholder", "disableIfOptionEmpty"]);
    var theme = useTheme();
    var getOptionValueRef = useRef(getOptionValue);
    var noOption = !options.length;
    var optionList = useMemo(function () {
        return allOption && value.includes(getOptionValueRef.current(allOption))
            ? __spreadArray([allOption], options, true) : options;
    }, [allOption, options, value]);
    var selectValue = useMemo(function () {
        var getSelectOption = function (v) { var _a; return (_a = optionList.find(function (o) { return getOptionValueRef.current(o) === v; })) !== null && _a !== void 0 ? _a : null; };
        return multiple ? value.map(getSelectOption) : getSelectOption(value);
    }, [multiple, optionList, value]);
    var handleChange = function (event, newValue) {
        newValue =
            newValue && multiple
                ? newValue.map(getOptionValue).filter(function (v) { return !allOption || v !== getOptionValue(allOption); })
                : getOptionValue(newValue);
        var newEventData = {
            target: {
                name: name,
                value: multiple && allOption && !newValue.length ? [getOptionValue(allOption)] : newValue,
            },
        };
        onChange === null || onChange === void 0 ? void 0 : onChange(newEventData);
    };
    return (_jsx(ThemeProvider, __assign({ theme: themeAutocomplete }, { children: _jsx(StyledAutocomplete, __assign({ "data-testid": "multiple-autocomplete", id: "multiple-autocomplete", multiple: multiple, disableClearable: true, options: optionList, value: selectValue, disabled: disabled || (noOption && !!disableIfOptionEmpty), loading: requesting, ListboxProps: {
                sx: {
                    '&.MuiAutocomplete-listbox': {
                        fontSize: themeLevel4.typography.body3.fontSize,
                    },
                },
            }, renderInput: function (params) {
                return (_jsx(TextField, __assign({}, params, { helperText: helperText, error: error, inputRef: inputRef, onBlur: onBlur, name: name, placeholder: placeholder })));
            }, renderTags: function (v, getTagProps) {
                if (requesting)
                    return;
                return v.map(function (option, index) { return (_jsx(Chip, __assign({}, getTagProps({ index: index }), { label: getOptionLabel(option), size: "small", sx: {
                        color: 'text.primary',
                        backgroundColor: 'transparent',
                        marginRight: '0.25rem',
                        borderColor: 'text.primary',
                        border: '1px solid',
                        fontSize: themeLevel4.typography.body4.fontSize,
                        height: theme.typography.pxToRem(20),
                    }, deleteIcon: !allOption || getOptionValue(option) !== getOptionValue(allOption) ? (_jsx(ChipIcon, { icon: ['fal', 'xmark'], style: { marginRight: '1rem' } })) : (_jsx(_Fragment, {})) }))); });
            } }, props, { onChange: handleChange, getOptionLabel: getOptionLabel, transformPopupIcon: transformPopupIcon })) })));
};
