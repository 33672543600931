import { useTheme } from '@mui/material';
import { GA_EVENTS, checkEveryFalse, useStateParam } from 'common-components';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelectorDependency, useDateSelectorBindingAPI, useDateSelectorBindingExcelAPI } from '../../../selectors';
import { DateRangeStateEnum } from '../../../state/app/appSlice';
import { getMetricsParam } from '../constants';
import { useCampaignState, useLookupCampaignsAPI, useMetricTypeState } from '../selectors';
import { GroupBy } from '../types';
import { BuildDataFunctionType, buildGroupAnalysisData, getGroupAnalysisName } from './constants';

export interface GroupAnalysisChartData {
  title?: string;
  labels: string[];
  values: number[];
}

export interface GroupAnalysis {
  response: string;
  value1: string;
  value2: string;
  count: number;
  percent: number;
}

export const useGroupByState = () => {
  return useStateParam<GroupBy>(
    useMemo(
      () => ({
        initialState: GroupBy.GenderAge,
        paramName: 'groupSetting',
        validator: (groupBy) => Object.values(GroupBy).includes(groupBy as GroupBy),
      }),
      []
    )
  );
};

export const useGroupAnalysisAPI = () => {
  const [campaignIds] = useCampaignState();
  const [groupShow] = useMetricTypeState();
  const [groupBy] = useGroupByState();
  const [campaigns] = useLookupCampaignsAPI();

  return useDateSelectorBindingAPI<GroupAnalysis[]>({
    depend: DateSelectorDependency.P360GroupAnalysis,
    params: {
      campaign: !_.isEmpty(campaigns.data) ? campaignIds?.join(',') : '',
      metrics: getMetricsParam(groupShow),
      field1: groupBy === GroupBy.GenderAge ? GroupBy.Age : groupBy,
      field2: GroupBy.Gender,
    },
    dateRangeState: DateRangeStateEnum.Campaign360,
  });
};

export const useGroupAnalysisData = (): [ReturnType<BuildDataFunctionType>, boolean] => {
  const { t } = useTranslation('360analysis');
  const [campaignIds] = useCampaignState();
  const [groupShow] = useMetricTypeState();
  const [groupBy] = useGroupByState();
  const [groupAnalysisData] = useGroupAnalysisAPI();
  const theme = useTheme();

  const data = useMemo(
    () => buildGroupAnalysisData[groupBy ?? GroupBy.GenderAge](t, groupShow, groupBy, groupAnalysisData.data, theme),
    [groupAnalysisData.data, groupBy, groupShow, t, theme]
  );
  const isPending = useMemo(
    () => !checkEveryFalse([groupAnalysisData.requesting ?? false, !campaignIds, !groupShow, !groupBy]),
    [campaignIds, groupAnalysisData.requesting, groupBy, groupShow]
  );

  return [data, isPending];
};

export const useGroupAnalysisExcelData = () => {
  const { t } = useTranslation('360analysis');
  const [campaignIds] = useCampaignState();
  const [groupShow] = useMetricTypeState();
  const [groupBy] = useGroupByState();
  const fileName = getGroupAnalysisName(t, groupShow, groupBy);

  const gaEvents = {
    category: GA_EVENTS._360InsightsDashboard.__name,
    action: GA_EVENTS._360InsightsDashboard.exportDemographicsToExcel,
  };

  return useDateSelectorBindingExcelAPI(
    DateSelectorDependency.P360GroupAnalysisExportExcel,
    {
      campaign: campaignIds?.join(','),
      metrics: getMetricsParam(groupShow),
      field1: groupBy === GroupBy.GenderAge ? GroupBy.Age : groupBy,
      field2: GroupBy.Gender,
      fileName,
    },
    gaEvents,
    fileName,
    DateRangeStateEnum.Campaign360
  );
};
