var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider, Tooltip as TooltipMUI, createTheme } from '@mui/material';
import { themeLevel4 } from '../config/theme';
var themeTooltip = createTheme(themeLevel4, {
    components: {
        MuiTooltip: {
            defaultProps: {
                enterDelay: 500,
                enterNextDelay: 500,
            },
            styleOverrides: {
                tooltip: {
                    fontSize: themeLevel4.typography.body2.fontSize,
                    background: themeLevel4.palette.background.default,
                    color: themeLevel4.palette.text.primary,
                    borderRadius: themeLevel4.typography.pxToRem(themeLevel4.shape.borderRadius / 2),
                    padding: themeLevel4.spacing(3, 5),
                    boxShadow: themeLevel4.shadows[8],
                },
            },
        },
    },
});
export var Tooltip = function (props) {
    return (_jsx(ThemeProvider, __assign({ theme: themeTooltip }, { children: _jsx(TooltipMUI, __assign({}, props)) })));
};
