import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { Autocomplete, useForm } from 'common-components';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllList } from '../../selectors';
import { Audience, FilterFormValues, ScheduleFrequency, TableProps } from '../../types';
import { ResetButton } from '../component';
import { AudienceTabLayout } from '../tablayout';
import { CalendarSelector } from './calendarselector';
import { NoScheduleOption, ScheduleType, getOptions } from './constants';
import { RecurringScheduleScope } from './recurringschedulescope';
import { useScheduleInfo } from './selectors';

export interface DatesTabProps extends TableProps {
  handleReset?: (key?: keyof FilterFormValues) => void;
  setFieldValue: ReturnType<typeof useForm>['setFieldValue'];
  audience?: Audience;
  selectedSchedule: string;
  setSelectedSchedule: (value: string) => void;
  errors: ReturnType<typeof useForm>['errors'];
}

export const DatesContentComponent: React.FC<DatesTabProps> = (props) => {
  const theme = useTheme();
  const { selectedSchedule, values } = props;
  const { t } = useTranslation('audience', { keyPrefix: 'datesTab' });

  const [allListResponse] = useGetAllList();
  const { currentType } = useScheduleInfo(selectedSchedule, values.schedules);

  const options = useMemo(() => {
    const scheduleOptions = getOptions(values.schedules, allListResponse.data);
    return [NoScheduleOption, ...scheduleOptions];
  }, [values.schedules, allListResponse.data]);

  const handleChangeSchedule = (e: SelectChangeEvent<string>) => {
    props.setSelectedSchedule(e.target.value);
  };

  return (
    <Stack flex={1}>
      <Stack
        flex={1}
        direction={'row'}
        alignItems={'center'}
        gap={4}
        height="100%"
        width={(themes) => themes.typography.pxToRem(450)}
      >
        <Typography
          color={(themes) => themes.palette.text.primary}
          whiteSpace="nowrap"
          sx={{ fontSize: theme.typography.subtitle2.fontSize }}
        >
          {t('selectSchedule')}
        </Typography>
        <Box flex={1}>
          <Autocomplete
            requesting={!!allListResponse.requesting}
            fullWidth
            multiple={false}
            options={options}
            onChange={handleChangeSchedule}
            value={props.selectedSchedule}
            popupIcon={<FontAwesomeIcon fontSize="1.125rem" icon={['fal', 'chevron-down']} />}
            {...props}
          />
        </Box>
      </Stack>
      {currentType === ScheduleType.Recurring ? <RecurringScheduleScope {...props} /> : <CalendarSelector {...props} />}
    </Stack>
  );
};

export const DatesTab: React.FC<DatesTabProps> = (props) => {
  const { t } = useTranslation('audience', { keyPrefix: 'datesTab' });
  const { values, selectedSchedule } = props;

  const { currentSchedule, scheduleIndex, isNewSchedule } = useScheduleInfo(selectedSchedule, values.schedules);

  const onHandleReset = () => {
    if (_.isNil(currentSchedule)) {
      props.handleReset?.('fromDate' as keyof FilterFormValues);
      props.handleReset?.('toDate' as keyof FilterFormValues);
      return;
    }
    if (currentSchedule.frequency === ScheduleFrequency.OneTime) {
      props.handleReset?.(`schedules[${scheduleIndex}].fromDate` as keyof FilterFormValues);
      props.handleReset?.(`schedules[${scheduleIndex}].toDate` as keyof FilterFormValues);
    } else {
      props.handleReset?.(`schedules[${scheduleIndex}].scope` as keyof FilterFormValues);
    }
  };

  return (
    <AudienceTabLayout
      cardProps={{
        sx: {
          borderTopLeftRadius: 0,
        },
      }}
      headerProps={{
        title: (
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
            <Typography variant="h6">{t('dates')}</Typography>
            {!isNewSchedule && <ResetButton onClick={onHandleReset} />}
          </Stack>
        ),
        titleTruncate: true,
      }}
      contentProps={{
        children: <DatesContentComponent {...props} />,
        sx: {
          alignItems: 'flex-start',
        },
      }}
    />
  );
};
