import { useForm } from 'common-components';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import * as uuid from 'uuid';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { WriteAccessConnection } from '../../selectors';
import { AudienceScheduleForm, ScheduleFrequency } from '../../types';
import { dateString } from '../useform';
import { DailyScopeEnum, MonthlyScopeEnum, OneTimeScopeEnum, Weekday, WeeklyScopeEnum } from './enums';

export const audienceDetailSchema = (): any =>
  yup.lazy(({ frequency }) => {
    const baseValidationSchema = {
      connectionId: yup.string().nullable().required('error.required'),
      frequency: yup.mixed().oneOf(Object.values(ScheduleFrequency)).required('error.required'),
      day: yup.number().nullable().required('error.required'),
      scope: yup.string().nullable().required('error.required'),
      time: yup.string().trim().required('error.required'),
      list: yup.string().nullable().required('error.required'),
      fromDate: dateString().nullable(),
      toDate: dateString().nullable(),
    };
    let validationSchema: ObjectShape = { ...baseValidationSchema };
    if (frequency === ScheduleFrequency.OneTime) {
      validationSchema = {
        ...validationSchema,
        day: yup.number().nullable(),
        scope: yup.string().nullable(),
        fromDate: dateString().nullable().required('error.required'),
        toDate: dateString().nullable().required('error.required'),
      };
    }
    return yup.object().shape(validationSchema);
  });

function useValidationSchema() {
  return useMemo(() => audienceDetailSchema(), []);
}

function useDefaultValue(defaultSchedule: AudienceScheduleForm | null, connections: WriteAccessConnection[]) {
  const getDefaultValues = useCallback(
    (data: Partial<AudienceScheduleForm> | null = null) => {
      const getDefaultFrequencyValues = (frequency?: ScheduleFrequency) => {
        const now = new Date();
        if (frequency === ScheduleFrequency.Weekly) {
          return {
            frequency: ScheduleFrequency.Weekly,
            scope: WeeklyScopeEnum.PriorWeek,
            day: now.getDay() + 1,
          };
        }
        if (frequency === ScheduleFrequency.Daily) {
          return {
            frequency: ScheduleFrequency.Daily,
            scope: DailyScopeEnum.Yesterday,
            day: Weekday.Weekdays,
          };
        }
        if (frequency === ScheduleFrequency.OneTime) {
          return {
            frequency: ScheduleFrequency.OneTime,
            scope: OneTimeScopeEnum.CustomRange,
            day: null,
            fromDate: null,
            toDate: null,
          };
        }

        return {
          frequency: ScheduleFrequency.Monthly,
          scope: MonthlyScopeEnum.PriorMonth,
          day: now.getDate(),
        };
      };
      const frequencyValues = getDefaultFrequencyValues(data?.frequency);
      const defaultConnection = _.get(connections, '0');
      const values: AudienceScheduleForm = {
        __internalId: data?.__internalId ?? uuid.v4(),
        frequency: frequencyValues.frequency,
        connectionId: data?.connectionId ?? defaultConnection.id,
        connectionName: data?.connectionName ?? defaultConnection.connectionName,
        connectionSource: data?.connectionSource ?? defaultConnection.connectionSource,
        time: data?.time ?? '03:00',
        list: data?.list ?? null,
        scope: data?.scope ?? frequencyValues.scope,
        day: data?.day ?? frequencyValues.day,
        fromDate: data?.fromDate ?? frequencyValues.fromDate,
        toDate: data?.toDate ?? frequencyValues.toDate,
      };

      return values;
    },
    [connections]
  );
  return {
    defaultValues: useMemo<AudienceScheduleForm>(() => {
      return getDefaultValues(defaultSchedule);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSchedule]),
    getDefaultValues,
  };
}

export function useScheduleDetailForm(
  defaultSchedule: AudienceScheduleForm | null,
  connections: WriteAccessConnection[]
) {
  const { defaultValues, getDefaultValues } = useDefaultValue(defaultSchedule, connections);
  const validationSchema = useValidationSchema();

  const formStates = useForm<AudienceScheduleForm>({
    initialValues: defaultValues,
    validationSchema,
  });
  const { castedValues, handleReset } = formStates;
  const previousFrequency = useRef(castedValues.frequency);

  useEffect(() => {
    if (previousFrequency.current !== castedValues.frequency) {
      const autoFills = _.pick(castedValues, ['__internalId', 'frequency', 'connectionId', 'time', 'list']);
      const newValues = getDefaultValues(autoFills);
      handleReset(undefined, newValues);
      previousFrequency.current = castedValues.frequency;
    }
  }, [castedValues, getDefaultValues, handleReset, previousFrequency]);

  return formStates;
}
