import { GA_EVENTS, UseAPI, useAPI, useExportExcelAPI } from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExportTypeQuickSelect, NEW_EXPORT_DEFINITION } from './constants';
import { AudienceExportType, Operators, QuickSelectGroup } from './enums';
import { AudienceFieldsResponse, CellFormData, Definition, FieldConfig, FilterFormValues } from './types';

const GET_AUDIENCE_EXPORT_FIELDS_API = '/api/export/audience/fields';
const POST_AUDIENCE_EXPORT_CSV_API = '/api/export/audience/export';
const POST_AUDIENCE_EXPORT_COUNT_PROSPECT_API = '/api/export/audience/count';

const CREATE_DEFINITION_API = '/api/export/definition';
const GET_DEFINITION_API = '/api/export/definition';
const DELETE_DEFINITION_API = '/api/export/definition/{id}';
const UPDATE_DEFINITION_API = '/api/export/definition/{id}';
const DATE_TIME_FORMAT = 'YYYYMMDD HHmmss';

export const useAudienceFieldsAPI = (): UseAPI<AudienceFieldsResponse> => {
  return useAPI<AudienceFieldsResponse>(GET_AUDIENCE_EXPORT_FIELDS_API);
};

export const useAudienceExportAPI = (fileName = NEW_EXPORT_DEFINITION.label): UseAPI => {
  const gaEvents = {
    category: GA_EVENTS.audienceExport.__name,
    action: GA_EVENTS.audienceExport.exportAudienceToExcel,
  };
  const now = moment().format(DATE_TIME_FORMAT);
  return useExportExcelAPI(POST_AUDIENCE_EXPORT_CSV_API, {}, `${fileName} ${now}.csv`, gaEvents, 'POST');
};

export const useProspectCountAPI = () => {
  return useAPI<{ count: number }>(POST_AUDIENCE_EXPORT_COUNT_PROSPECT_API, undefined, 'POST');
};

export const useGetAllDefinitionAPI = (): UseAPI<Definition[]> => {
  return useAPI<Definition[]>(GET_DEFINITION_API);
};

export const useCreateDefinitionAPI = (): UseAPI<Definition> => {
  return useAPI<Definition>(CREATE_DEFINITION_API, undefined, 'POST');
};

export const useDeleteDefinitionAPI = (id?: number) => {
  return useAPI(DELETE_DEFINITION_API, { id }, 'DELETE');
};

export const useUpdateDefinitionAPI = (id?: number) => {
  return useAPI(UPDATE_DEFINITION_API, { id }, 'PUT');
};

export const useExportFields = (fieldsFilter: FieldConfig[], exportType?: AudienceExportType) => {
  const getFieldsByQuickSelect = useCallback(
    (quickSelect: QuickSelectGroup) =>
      fieldsFilter.filter((item) => item.quickSelectGroup?.includes(quickSelect)).map((field) => field.id),
    [fieldsFilter]
  );

  const defaultNewExportFields = useMemo(
    () => getFieldsByQuickSelect(QuickSelectGroup.Emails),
    [getFieldsByQuickSelect]
  );

  const defaultExportFields: string[] = useMemo(() => {
    if (!exportType) return defaultNewExportFields;
    if (ExportTypeQuickSelect[exportType] === QuickSelectGroup.AllFields) return _.map(fieldsFilter, 'id');
    return getFieldsByQuickSelect(ExportTypeQuickSelect[exportType]);
  }, [defaultNewExportFields, exportType, fieldsFilter, getFieldsByQuickSelect]);

  const [exportFields, setExportFields] = useState(defaultExportFields);
  const resetForNewExport = useCallback(() => setExportFields(defaultNewExportFields), [defaultNewExportFields]);

  return {
    exportFields,
    setExportFields,
    resetForNewExport,
  };
};

export const useAutoEnableExportFilterValues = (
  visitFields: CellFormData[],
  handleReset: (field: keyof FilterFormValues) => void,
  setFieldValue: (value: any, field: keyof FilterFormValues) => void,
  definition?: Definition
) => {
  const autoEnableRef = useRef(true);

  const onReset = useCallback(() => {
    handleReset('exportFilterValues');
    autoEnableRef.current = false;
  }, [handleReset]);

  useEffect(() => {
    autoEnableRef.current = !definition;
  }, [definition]);

  const hasFilter = useMemo(
    () => Object.values(visitFields).some((filter) => filter.operator !== Operators.DoNotFilter),
    [visitFields]
  );

  useEffect(() => {
    if (autoEnableRef.current && hasFilter) {
      setFieldValue(true, 'exportFilterValues');
    }
  }, [hasFilter, setFieldValue]);

  useEffect(() => {
    autoEnableRef.current = true;
  }, [visitFields]);

  return { onReset };
};
