var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAudit, useStateParam } from '../hooks';
import { FSAuditAction, FS_SESSION_ACTIVE_KEY, FS_TARGET } from './constants';
var FullscreenContext = React.createContext({});
export var useActiveFullscreenSession = function () {
    var components = useContext(FullscreenContext).components;
    return useStateParam(useMemo(function () { return ({
        initialState: '',
        paramName: FS_SESSION_ACTIVE_KEY,
        validator: function (state) { return !!(components === null || components === void 0 ? void 0 : components[state]); },
    }); }, [components]));
};
export var useActiveFullscreenSessionComponent = function () {
    var activeSession = useActiveFullscreenSession()[0];
    var components = useContext(FullscreenContext).components;
    return activeSession && (components === null || components === void 0 ? void 0 : components[activeSession]);
};
export var useFullscreen = function () {
    var searchParam = useSearchParams()[0];
    var handleFullscreen = useCallback(function (sessionName) {
        searchParam.set(FS_SESSION_ACTIVE_KEY, sessionName);
        var fsUrl = "".concat(window.location.pathname, "?").concat(searchParam.toString());
        window.open(fsUrl, FS_TARGET);
    }, [searchParam]);
    return [handleFullscreen];
};
export var FullscreenProvider = function (_a) {
    var components = _a.components, props = __rest(_a, ["components"]);
    var memoComponents = useMemo(function () { return ({ components: components }); }, [components]);
    return _jsx(FullscreenContext.Provider, __assign({ value: memoComponents }, { children: props.children }));
};
export var FullscreenContainer = function (_a) {
    var children = _a.children;
    var activeSession = useActiveFullscreenSession()[0];
    var ActiveSessionComponent = useActiveFullscreenSessionComponent();
    var sendAuditEvent = useAudit()[0];
    useEffect(function () {
        var auditAction = FSAuditAction[(activeSession !== null && activeSession !== void 0 ? activeSession : '')];
        if (auditAction) {
            sendAuditEvent({ action: auditAction });
        }
    }, [activeSession, sendAuditEvent]);
    if (activeSession === undefined)
        return _jsx(_Fragment, {});
    return _jsx(_Fragment, { children: ActiveSessionComponent ? _jsx(ActiveSessionComponent, {}) : children });
};
