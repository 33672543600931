import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, styled } from '@mui/material';
import {
  ConfirmDialog,
  Dialog,
  DialogContent,
  DialogTitle,
  ErrorMessageQueueBox,
  FallbackComponent,
  GA_EVENTS,
  themeLevel3,
  useGA4,
} from 'common-components';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { usePageTitleAPI } from '../../pagetitleselect/selectors';
import { AudienceExport } from '../enums';
import { useAudiencesAPI, useGetAllList, useGetWriteAccessConnections } from '../selectors';
import { getDefinitionPayload, getSchedulePayload } from './constants';
import { AudienceProvider, useAudience } from './context';
import { NoScheduleOption } from './datestab/constants';
import { ExportConfigTabs, ExportConfigTabsApi } from './exportconfigtabs';
import { useSelectSchedule } from './hooks';
import { ProspectCount } from './prospectcount';
import {
  useAudienceAPI,
  useAudienceExportAPI,
  useFieldAPI,
  useProspectCountAPI,
  useSaveAudienceAPI,
} from './selectors';
import { Settings } from './setting';
import { useAudienceForm } from './useform';

const StyledDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  minHeight: 'calc(100vh - 11rem)',
}));

interface AudiencesDialogWrapperProps {
  onClose: () => void;
}
interface AudiencesDialogProps extends AudiencesDialogWrapperProps {
  open: boolean;
  audienceId?: number;
}

const AudiencesDialogWrapper = ({ onClose }: AudiencesDialogWrapperProps) => {
  const { t } = useTranslation(['audience', 'translation']);
  const [, , putEvent] = useGA4();

  const [, fetchAllList, clearAllList] = useGetAllList();
  const [connectionsData] = useGetWriteAccessConnections();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [audienceData] = useAudienceAPI();
  const [audienceFields] = useFieldAPI();
  const [pageTitleData] = usePageTitleAPI();
  const [exportData, doFetchExport] = useAudienceExportAPI(audienceData.data?.name);
  const [prospectCount] = useProspectCountAPI();
  const [savedAudienceData, fetchSaveAudienceData, clearSavedAudienceData] = useSaveAudienceAPI();
  const { castedValues, errors, isDirty, setFieldValue, handleSubmit, registerField, handleReset } = useAudienceForm();
  const [, fetchAudiencesData] = useAudiencesAPI();
  const configTabRef = useRef<ExportConfigTabsApi>(null);
  const audienceContext = useAudience();
  const [selectedSchedule, setSelectedScheduleId] = useSelectSchedule(
    castedValues.schedules,
    castedValues.fromDate,
    castedValues.toDate
  );
  const errorTabs = useMemo(() => {
    const focusError = _.pick(errors, [AudienceExport.Prospect, AudienceExport.Visit]) as Record<AudienceExport, any>;
    return Object.keys(focusError).reduce((acc: AudienceExport[], tab) => {
      if (!_.isEmpty(focusError[tab as AudienceExport])) {
        acc.push(tab as AudienceExport);
      }
      return acc;
    }, []);
  }, [errors]);

  const isNotSelectAnyFields = useMemo(
    () => !_.some(castedValues.exportFields, { checked: true }),
    [castedValues.exportFields]
  );

  useEffect(() => {
    if (connectionsData.data) {
      fetchAllList();
    }
    return () => {
      clearAllList();
    };
  }, [fetchAllList, clearAllList, connectionsData.data]);

  useEffect(() => {
    if (savedAudienceData.data) {
      fetchAudiencesData();
      onClose();
    }
    return clearSavedAudienceData;
  }, [clearSavedAudienceData, fetchAudiencesData, onClose, savedAudienceData.data]);

  const onSubmit = (event?: React.FormEvent) => {
    event?.preventDefault();

    const submit = handleSubmit((formValues) => {
      fetchSaveAudienceData({
        ..._.omit(formValues, 'id'),
        ...getDefinitionPayload(formValues, audienceContext.dateRange),
        ...getSchedulePayload(formValues),
      });
      putEvent({
        category: GA_EVENTS.audience.__name,
        action: audienceData.data?.id ? GA_EVENTS.audience.editAudience : GA_EVENTS.audience.addAudience,
      });
    });

    if (errorTabs.length) {
      configTabRef.current?.showError(errorTabs, () => submit(event));
    } else {
      submit(event);
    }
  };

  const onDownload = handleSubmit((formValues) => {
    doFetchExport(getDefinitionPayload(formValues, audienceContext.dateRange, selectedSchedule?.connectionSource));
    putEvent({ category: GA_EVENTS.audience.__name, action: GA_EVENTS.audience.downloadAudience });
  }, 'name');

  const handleClose = () => {
    if (isDirty) {
      return setShowConfirmDialog(true);
    }
    onClose();
  };
  const handleContinue = () => {
    setShowConfirmDialog(false);
    onClose();
  };

  return (
    <Dialog open onClose={handleClose} maxWidth={false} baseTheme={themeLevel3}>
      <DialogTitle variant="h2" color="success.main" my={4}>
        {t('detail.audienceDetails')}
      </DialogTitle>
      <StyledDialogContent>
        <Stack component="form" onSubmit={onSubmit} flex={1}>
          <FallbackComponent
            requesting={!!audienceFields.requesting || !!pageTitleData.requesting || !!audienceData.requesting}
          >
            <ErrorMessageQueueBox
              errors={[exportData.error, prospectCount.error, savedAudienceData.error]}
              sx={{ mb: 2 }}
            />
            <Grid container columnSpacing={6} flex={1} rowSpacing={6}>
              <Grid item xs={12} md={5} lg={3.5} display="flex" flexDirection="column" gap={4} flex={1}>
                <Settings
                  values={castedValues}
                  registerField={registerField}
                  setFieldValue={setFieldValue}
                  setSelectedScheduleId={setSelectedScheduleId}
                />
              </Grid>
              <Grid item xs={12} md={7} lg={8.5} display="flex" flexDirection="column" gap={4} flex={1}>
                <ExportConfigTabs
                  ref={configTabRef}
                  audience={audienceData?.data}
                  values={castedValues}
                  registerField={registerField}
                  setFieldValue={setFieldValue}
                  handleReset={handleReset}
                  fields={audienceFields.data}
                  selectedSchedule={selectedSchedule?.__internalId ?? NoScheduleOption.id}
                  setSelectedSchedule={setSelectedScheduleId}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={4} display="flex" gap={4} flex={1} />
              <Grid item xs={8} display="flex" flexDirection="column" gap={4} flex={1}>
                <Stack ml={'6rem'} direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" spacing={4} alignItems="center">
                    <LoadingButton
                      onClick={onDownload}
                      variant="outlined"
                      color="inherit"
                      disabled={isNotSelectAnyFields && !selectedSchedule}
                      loading={exportData.requesting}
                    >
                      {t('translation:button.download')}
                    </LoadingButton>
                    <ProspectCount
                      selectedSchedule={selectedSchedule}
                      errors={errors}
                      values={castedValues}
                      onChangeScheduleId={(id) => setSelectedScheduleId(id)}
                    />
                  </Stack>
                  <Stack direction="row" spacing={4} alignItems="center">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disabled={!isDirty || isNotSelectAnyFields}
                      loading={savedAudienceData.requesting}
                    >
                      {t('translation:button.save')}
                    </LoadingButton>
                    <Button variant="outlined" color="inherit" onClick={handleClose}>
                      {t('translation:button.close')}
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </FallbackComponent>
        </Stack>
        <ConfirmDialog
          message={t('translation:text.unsavedChangesContent')}
          confirmButtonText={t('translation:button.continue')}
          abortButtonText={t('translation:button.goBack')}
          open={showConfirmDialog}
          onConfirm={handleContinue}
          onAbort={() => setShowConfirmDialog(false)}
        />
      </StyledDialogContent>
    </Dialog>
  );
};

export const AudiencesDialog = withSuspense()(({ audienceId, open, ...props }: AudiencesDialogProps) => {
  if (!open) return null;
  return (
    <AudienceProvider audienceId={audienceId}>
      <AudiencesDialogWrapper {...props} />
    </AudienceProvider>
  );
});
