import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { ProfileType } from '../types';

export const MetricIcons: Record<keyof ProfileType, IconProp> = {
  region: ['fal', 'location-dot'],
  gender: ['fal', 'user'],
  age: ['fal', 'cake-candles'],
  income: ['fal', 'money-bill-1-wave'],
  married: ['fal', 'rings-wedding'],
  children: ['fal', 'children'],
  homeowner: ['fal', 'house-heart'],
  networth: ['fal', 'sack-dollar'],
};

export const getDescription = (t: TFunction, descriptionKey: string | boolean | null) =>
  descriptionKey === null ? '' : t(`responseProfileExplorer.descriptiveContent1.${descriptionKey}`);

export const getBoolDescription = (
  t: TFunction,
  value: string | null | boolean,
  trueDesc: string,
  falseDesc: string
) => {
  const valueDesc = value ? trueDesc : falseDesc;
  return _.isNil(value) ? '' : t(`responseProfileExplorer.descriptiveContent1.${valueDesc}`);
};

export const getPart = (t: TFunction, value: string | null) => {
  if (!_.isEmpty(value)) {
    return t(`${value}`);
  }
  return '';
};

export const generateSentence = (profile: ProfileType, t: TFunction) => {
  const parts: string[] = [];

  parts.push(getPart(t, profile.gender));
  if (!_.isEmpty(profile.age)) {
    parts.push(getDescription(t, 'age') + ' ' + getPart(t, profile.age));
  }
  parts.push(getBoolDescription(t, profile.married, 'married', 'nomarried'));
  parts.push(getBoolDescription(t, profile.children, 'children', 'nochildren'));
  parts.push(getBoolDescription(t, profile.homeowner, 'homeowner', 'nohomeowner'));
  if (!_.isEmpty(profile.networth)) {
    parts.push(getDescription(t, 'netWorth') + ' ' + getPart(t, profile.networth));
  }
  if (!_.isEmpty(profile.income)) {
    parts.push(getDescription(t, 'earning') + ' ' + getPart(t, profile.income));
  }
  if (!_.isEmpty(profile.region)) {
    parts.push(
      `${t('responseProfileExplorer.descriptiveContent1.and')} ${t(
        'responseProfileExplorer.descriptiveContent1.living'
      )} ${profile.region}`
    );
  }

  const sentence = parts
    .filter((part) => part !== '')
    // begin a sentence with a capital letter
    .map((part, index) => (index === 0 ? _.capitalize(part) : part))
    .join(', ');
  return sentence ? `${sentence}.` : '';
};

export const getMetricNames = (value: any, key: string, t: TFunction): string => {
  if (key === 'married') {
    return getBoolDescription(t, value, 'married', 'nomarried');
  }
  if (key === 'children') {
    return getBoolDescription(t, value, 'children', 'nochildren');
  }
  if (key === 'homeowner') {
    return getBoolDescription(t, value, 'homeowner', 'nohomeowner');
  }
  return t(`${value}`).toString();
};

export const RPEExcellFileName = 'Response Profile Explorer';
