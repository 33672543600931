import moment from 'moment';
export var DEFAULT_CUSTOM_QUICK_DAY = 7;
export var PATTERN_LAST_DATE_FIELD = /^[1-9]\d*$/;
export var QUICK_SELECT = function (onChange, minDate, maxDate) { return [
    {
        label: 'allData',
        onClick: function () {
            if (moment(minDate).isValid() && moment(maxDate).isValid())
                return onChange([moment(minDate), moment(maxDate)]);
        },
        disabled: !moment(minDate).isValid() || !moment(maxDate).isValid(),
    },
    {
        label: 'yesterday',
        onClick: function () { return onChange([moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')]); },
    },
    {
        label: 'lastWeek',
        onClick: function () { return onChange([moment().startOf('week').subtract(1, 'week'), moment().startOf('week').subtract(1)]); },
    },
    {
        label: 'lastMonth',
        onClick: function () { return onChange([moment().startOf('month').subtract(1, 'month'), moment().startOf('month').subtract(1)]); },
    },
    {
        label: 'lastQuarter',
        onClick: function () {
            return onChange([moment().startOf('quarter').subtract(1, 'quarter'), moment().startOf('quarter').subtract(1)]);
        },
    },
    {
        label: 'lastYear',
        onClick: function () { return onChange([moment().startOf('year').subtract(1, 'year'), moment().startOf('year').subtract(1)]); },
    },
]; };
