export enum VisitFilterTab {
  Score = 'score',
  Details = 'details',
}

export enum FilterFieldId {
  MatchType = 'fd5f8834-2d98-4b7e-ae18-5b07df6e081e',
  UniquePagesVisited = 'db27686a-f42d-40e2-99d5-5b679131e669',
  Visits = '2312aba4-301b-41a8-8f21-01faa50080da',
}

export enum GroupField {
  VisitFields = 'visitFields',
  CommonFields = 'commonFields',
}

export enum Category {
  Demographic = 'demographic',
  VisitScore = 'visitScore',
  VisitDetails = 'visitDetails',
}
export type VisitCategory = Category.VisitScore | Category.VisitDetails;
export const VisitCategories: VisitCategory[] = [Category.VisitDetails, Category.VisitScore];

export enum QuickSelectGroup {
  Custom = 'Custom',
  AllFields = 'All Fields',
  Emails = 'Emails',
  EnrichedB2C = 'Emails Plus - B2C',
  EnrichedB2B = 'Emails Plus - B2B',
}

export enum AudienceExportType {
  VisitorEngagement = 'visitorEngagement',
  SiteEngagement = 'siteEngagement',
  EmailsProspects = 'emailsProspects',
  EmailsPlusB2CProspects = 'emailsPlusB2CProspects',
  EmailsPlusB2BProspects = 'emailsPlusB2BProspects',
}
export const ExportTypeVisitCategory: Record<AudienceExportType, VisitCategory> = {
  [AudienceExportType.VisitorEngagement]: Category.VisitDetails,
  [AudienceExportType.SiteEngagement]: Category.VisitDetails,
  [AudienceExportType.EmailsProspects]: Category.VisitScore,
  [AudienceExportType.EmailsPlusB2CProspects]: Category.VisitScore,
  [AudienceExportType.EmailsPlusB2BProspects]: Category.VisitScore,
};

export enum FieldType {
  String = 'string',
  Enum = 'enum',
  Number = 'number',
  NumberSet = 'numberSet',
  NumberMetric = 'numberMetric',
}

export enum Operators {
  DoNotFilter = 'Do Not Filter',
  OneOf = 'One of',
  Contain = 'Contains',
  NotContain = 'Does not contain',
  Equal = 'Equals',
  StartWith = 'Starts With',
  Between = 'Between',
  NotEqual = 'Does not equal',
  LessThan = 'Less Than',
  MoreThan = 'More Than',
  AtLeast = 'At least',
  AtMost = 'At most',
  TopN = 'Top N',
  TopPercent = 'Top %',
}

export enum MatchType {
  Email = 'email',
  Personal = 'personal',
  Business = 'business',
}
