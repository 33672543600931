import { HttpMethod, IEventGA4, UseAPI, checkEmptyString, useCachedData, useExportExcelAPI } from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { useCallback } from 'react';
import { GroupBy } from '../components/campaign360/types';
import { use360FilterIgnoreLowVolumeState, useDateRangeState, usePageTitleState } from '../state/app';
import { DateRangeStateEnum } from '../state/app/appSlice';

export enum DateSelectorDependency {
  WebResolution = 'WebResolution',
  TrafficInsightsDataAvailabilityB2C = 'TrafficInsightsDataAvailabilityB2C',
  TrafficInsightsDataFrequencyB2C = 'TrafficInsightsDataFrequencyB2C',
  TrafficInsightsGeoMappingsB2C = 'TrafficInsightsGeoMappingsB2C',
  TrafficInsightsGeoMappingsByUSGroupingsB2C = 'TrafficInsightsGeoMappingsByUSGroupingsB2C',
  TrafficInsightsDataAvailabilityB2B = 'TrafficInsightsDataAvailabilityB2B',
  TrafficInsightsDataFrequencyB2B = 'TrafficInsightsDataFrequencyB2B',
  TrafficInsightsGeoMappingsB2B = 'TrafficInsightsGeoMappingsB2B',
  TrafficInsightsGeoMappingsByUSGroupingsB2B = 'TrafficInsightsGeoMappingsByUSGroupingsB2B',
  LookupCampaigns = 'LookupCampaigns',
  P360StatSummary = '360StatSummary',
  P360GeoLocationDetail = 'P360GeoLocationDetail',
  P360RegionsLocationDetail = 'P360RegionsLocationDetail',
  P360GroupAnalysis = 'P360GroupAnalysis',
  ResponseCount = 'ResponseCount',
  P360StatSummaryExportExcel = 'P360StatSummaryExportExcel',
  P360GroupAnalysisExportExcel = 'P360GroupAnalysisExportExcel',
  P360GeoLocationDetailExportExcel = 'P360GeoLocationDetailExportExcel',
  AnalyticsRPE_B2C = 'AnalyticsRPE_B2C',
  AnalyticsInsight_B2C = 'AnalyticsInsight_B2C',
  P360ReferralChannel = 'P360ReferralChannel',
  VisitorEngagement = 'VisitorEngagement',
  SiteEngagement = 'SiteEngagement',
}

export const DEPENDENCY_BINDING: Record<DateSelectorDependency, string> = {
  [DateSelectorDependency.WebResolution]:
    '/api/webResolution/resolutions-summary?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsDataAvailabilityB2C]:
    '/api/webResolution/traffic-insights-data-availability-b2c?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsDataFrequencyB2C]:
    '/api/webResolution/traffic-insights-data-frequency-b2c?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsGeoMappingsB2C]:
    '/api/webResolution/traffic-insights-geo-mappings-b2c?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsGeoMappingsByUSGroupingsB2C]:
    '/api/webResolution/traffic-insights-region-b2c?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsDataAvailabilityB2B]:
    '/api/webResolution/traffic-insights-data-availability-b2b?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsDataFrequencyB2B]:
    '/api/webResolution/traffic-insights-data-frequency-b2b?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsGeoMappingsB2B]:
    '/api/webResolution/traffic-insights-geo-mappings-b2b?fromDate={fromDate}&toDate={toDate}&dataField={dataField}&pageTitle={pageTitle}',
  [DateSelectorDependency.TrafficInsightsGeoMappingsByUSGroupingsB2B]:
    '/api/webResolution/traffic-insights-region-b2b?fromDate={fromDate}&toDate={toDate}&dataField={dataField}&pageTitle={pageTitle}',
  [DateSelectorDependency.VisitorEngagement]:
    '/api/webResolution/visitor-engagement?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.SiteEngagement]:
    '/api/webResolution/site-engagement?fromDate={fromDate}&toDate={toDate}&pageTitle={pageTitle}',
  [DateSelectorDependency.LookupCampaigns]:
    '/api/campaign360/campaigns?fromDate={fromDate}&toDate={toDate}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.ResponseCount]:
    '/api/campaign360/response-count?campaign={campaign}&fromDate={fromDate}&toDate={toDate}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.P360StatSummary]:
    '/api/campaign360/stat-summary?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.P360GeoLocationDetail]:
    '/api/campaign360/response-geo-mappings-by-zip-centroid?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&metrics={metrics}&minCount={minCount}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.P360GroupAnalysis]:
    '/api/campaign360/response-by-groupings?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&metrics={metrics}&field1={field1}&field2={field2}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.P360RegionsLocationDetail]:
    '/api/campaign360/response-geo-mappings-by-us-region?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&metrics={metrics}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.P360StatSummaryExportExcel]:
    '/api/excel/campaign360/stat-summary?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.P360GroupAnalysisExportExcel]:
    '/api/excel/campaign360/response-by-groupings?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&metrics={metrics}&field1={field1}&field2={field2}&fileName={fileName}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.P360GeoLocationDetailExportExcel]:
    '/api/excel/campaign360/response-geo-mappings?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&metrics={metrics}&fileName={fileName}&ignoreLowVolume={ignoreLowVolume}',
  [DateSelectorDependency.AnalyticsRPE_B2C]:
    '/api/analytics/rpe-b2c/?organizationId={organizationId}&ignoreLowVolume={ignoreLowVolume}&fromDate={fromDate}&toDate={toDate}&',
  [DateSelectorDependency.AnalyticsInsight_B2C]:
    '/api/analytics/insights-b2c/?organizationId={organizationId}&ignoreLowVolume={ignoreLowVolume}&fromDate={fromDate}&toDate={toDate}&',
  [DateSelectorDependency.P360ReferralChannel]:
    '/api/campaign360/referral-channel/?fromDate={fromDate}&toDate={toDate}&campaign={campaign}&ignoreLowVolume={ignoreLowVolume}',
};

export enum DataFieldType {
  CompanyZip = 'company_zip',
  ContactZip = 'contact_zip',
  CompanyState = 'company_state',
  ContactState = 'contact_state',
}
export interface TrafficInsightPayload {
  dataFields?: string;
  dataField?: DataFieldType;
  b2cDataFields?: string;
  b2bDataFields?: string;
}
export type P360Payload = {
  campaign?: string;
  minCount?: number;
  field1?: GroupBy;
  field2?: GroupBy;
  metrics?: string;
  organizationId?: string;
  fileName?: string;
};
export type WebResolutionPayload = {
  resolutionType?: string;
  multiVisit?: number;
};
export type MultiParams = {
  events: Array<string> | null;
  campaigns: Array<string> | null;
};

export interface UseDateSelectorBindingAPIParams<T, R> {
  depend: DateSelectorDependency;
  params?: TrafficInsightPayload | P360Payload;
  _parseData?: (data?: T) => R;
  ignoreDateRange?: boolean;
  convertDateToUTC?: boolean;
  multiParams?: MultiParams;
  method?: HttpMethod;
  dateRangeState?: DateRangeStateEnum;
}

export const useDateSelectorBindingAPI = <T, R = T>({
  depend,
  params,
  _parseData,
  ignoreDateRange = false,
  convertDateToUTC = false,
  multiParams,
  method,
  dateRangeState = DateRangeStateEnum.Global,
}: UseDateSelectorBindingAPIParams<T, R>): UseAPI<R> => {
  const [pageTitle] = usePageTitleState();
  const [dateRange] = useDateRangeState(dateRangeState);
  const [ignoreLowVolume] = use360FilterIgnoreLowVolumeState();

  const [fromDate, toDate] = dateRange || [];

  const parseData = (data?: T) => (_parseData ? _parseData(data) : (data as R));
  const fromDateFormat = moment(fromDate).format('YYYY-MM-DD');
  const toDateFormat = moment(toDate).format('YYYY-MM-DD');
  const [data, fetchData, clearData] = useCachedData<R>(
    DEPENDENCY_BINDING[depend].concat(qs.stringify(multiParams, { arrayFormat: 'repeat' })),
    {
      fromDate: convertDateToUTC ? moment.utc(fromDateFormat).startOf('day').format() : fromDateFormat,
      toDate: convertDateToUTC ? moment.utc(toDateFormat).endOf('day').format() : toDateFormat,
      pageTitle,
      ignoreLowVolume,
      ...params,
    },
    method,
    undefined,
    parseData
  );

  const doFetchData: UseAPI[1] = useCallback(
    (...props) => {
      if (
        fromDate &&
        toDate &&
        (!params || !_.some(params, checkEmptyString)) &&
        (!multiParams || !_.some(multiParams, checkEmptyString))
      ) {
        fetchData(...props);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchData, fromDate, toDate]
  );

  return [data, ignoreDateRange ? fetchData : doFetchData, clearData];
};

export const useDateSelectorBindingExcelAPI = <T, R = T>(
  depend: DateSelectorDependency,
  params?: TrafficInsightPayload | P360Payload | WebResolutionPayload,
  gaEvents?: IEventGA4,
  fileName?: string,
  dateRangeState = DateRangeStateEnum.Global
): UseAPI<R> => {
  const [dateRange] = useDateRangeState(dateRangeState);
  const [pageTitle] = usePageTitleState();
  const [ignoreLowVolume] = use360FilterIgnoreLowVolumeState();
  const [fromDate, toDate] = dateRange || [];

  return useExportExcelAPI(
    DEPENDENCY_BINDING[depend],
    {
      fromDate: fromDate?.format('YYYY-MM-DD'),
      toDate: toDate?.format('YYYY-MM-DD'),
      pageTitle,
      ignoreLowVolume,
      ...params,
    },
    `[MS] ${fileName ?? ''}.xlsx`,
    gaEvents
  );
};

export { useDateRangeState };
