import { useEffect, useRef } from 'react';
import { useGA4 } from '../hooks/usega4';
import { useAPI } from '../state/api';
export var useExportExcelAPI = function (url, params, fileName, GAEvents, method) {
    if (method === void 0) { method = 'GET'; }
    var exportingRef = useRef();
    var _a = useAPI(url, params, method), exportData = _a[0], fetchExportData = _a[1], clearData = _a[2];
    var _b = useGA4(), putEvent = _b[2];
    useEffect(function () {
        if (exportData.data && exportingRef.current) {
            if (GAEvents) {
                //put GA event when exported excel file
                putEvent(GAEvents);
            }
            exportingRef.current = false;
            var blob = new Blob([exportData.data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            clearData();
        }
    }, [GAEvents, clearData, exportData.data, fileName, putEvent]);
    var doFetchData = function (body) {
        if (!exportData.requesting) {
            fetchExportData(body, undefined, undefined, 'blob');
        }
        exportingRef.current = true;
    };
    return [exportData, doFetchData, clearData];
};
