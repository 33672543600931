import { ConnectionSource } from 'common-components';
import _ from 'lodash';
import { Operators, QuickSelectGroup } from '../enums';
import { CellFormData, DateRangeFilter, FieldConfig, FilterFormValues, ProspectCountPayload } from '../types';

export const getFieldsIDByQuickSelect = (quickSelect: QuickSelectGroup, fields: FieldConfig[]) =>
  fields.filter((item) => item.quickSelectGroup?.includes(quickSelect)).map((field) => field.id);

export const getUniqueQuickSelectGroups = (data: FieldConfig[]): string[] =>
  _.uniq(_.flatMap(data, (i) => i.quickSelectGroup ?? []));

export const getGroupFieldsMap = (data: FieldConfig[]) =>
  data.reduce((map, field) => {
    field.quickSelectGroup?.forEach((group) => {
      if (!map.get(group)) map.set(group, []);
      map.get(group)?.push(field.id);
    });
    return map;
  }, new Map<QuickSelectGroup, string[]>());

export const getProspectCountPayload = (
  formValues: FilterFormValues,
  dateRangeFilter: DateRangeFilter
): ProspectCountPayload => {
  const extractValues = (field: CellFormData): (string | number)[] => {
    const values = _.compact([...field.enumValues, field.value0]);
    if (field.operator === Operators.Between) {
      return _.compact([...values, field.value1]);
    }
    return values;
  };
  const getFilters = (items: CellFormData[] = []) => {
    return items
      .filter(
        ({ group, operator }) =>
          operator !== Operators.DoNotFilter &&
          (!group || !formValues.visitCategory || group.includes(formValues?.visitCategory))
      )
      .map((field) => ({
        fieldId: field.fieldId,
        operator: field.operator,
        values: extractValues(field),
      }));
  };

  return {
    pageTitle: formValues?.pageTitle ?? '',
    bounceRateReduction: formValues?.bounceRateReduction ?? false,
    prospectFilters: getFilters(formValues.prospectFilters),
    visitFilters: getFilters(formValues.visitFilters),
    dateRangeFilter: _.omitBy(dateRangeFilter, _.isNil) as DateRangeFilter,
  };
};

export const getDefinitionPayload = (
  formValues: FilterFormValues,
  dateRangeFilter: DateRangeFilter,
  connectionSource?: ConnectionSource
) => {
  return {
    ..._.pick(formValues, 'version', 'exportVisitMetrics'),
    ...getProspectCountPayload(formValues, dateRangeFilter),
    exportFields: formValues.exportFields
      ?.filter((v) => (connectionSource ? v.connections?.includes(connectionSource) : v.checked))
      .map((v) => v.id),
  };
};

export const getSchedulePayload = (formValues: FilterFormValues) => {
  return {
    schedules: formValues.schedules.map((s) =>
      _.pick(s, ['id', 'audienceId', 'connectionId', 'day', 'frequency', 'list', 'scope', 'time', 'fromDate', 'toDate'])
    ),
    removedScheduleIds: formValues.removedScheduleIds,
  };
};

export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';
