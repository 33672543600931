import { PageDto, PageMetaDto, useAPI } from 'common-components';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AudienceHistory } from './types';

const AUDIENCE_HISTORY_API = `/api/audience/history/{id}?page={page}&take={take}`;
export const useGetAudienceHistoryAPI = (params?: { id?: number; page: number; take: number }) => {
  return useAPI<PageDto<AudienceHistory>>(AUDIENCE_HISTORY_API, params);
};
const ITEMS_PER_PAGE = 20;
export const usePaginatedAudienceHistory = (
  audienceId?: number
): [
  Omit<ReturnType<typeof useGetAudienceHistoryAPI>[0], 'data'> & {
    data: AudienceHistory[];
    firstFetching: boolean;
    meta: PageMetaDto | null;
  },
  () => void,
  () => void
] => {
  const [page, setPage] = useState(1);
  const [{ data: pageData, requesting, error }, fetchData, clearData] = useGetAudienceHistoryAPI({
    id: audienceId,
    page,
    take: ITEMS_PER_PAGE,
  });
  const pageMeta = useRef<PageMetaDto | null>(null);
  const [data, setData] = useState<AudienceHistory[]>([]);
  const firstFetching = !!requesting && page === 1;

  useEffect(() => {
    if (!_.isEmpty(pageData) && pageData.meta.page !== pageMeta.current?.page) {
      pageMeta.current = pageData.meta;
      setData((prev) => [...prev, ...pageData.data]);
    }
  }, [pageData]);

  useEffect(() => {
    if (audienceId) {
      fetchData();
    }

    return () => clearData();
  }, [audienceId, fetchData, clearData]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return [{ data, requesting, error, firstFetching, meta: pageMeta.current }, loadMore, clearData];
};
