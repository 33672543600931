import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ErrorMessageBox,
  FallbackComponent,
  PhoneInput,
  Select,
  ZipInput,
  useForm,
  useStateCodes,
  useTimeOut,
  useUpdateUserInformationAPI,
  useUserSessionAPI,
} from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { validationSchema } from './constants';

interface UserInformationDialogProps {
  open: boolean;
  onHide: () => void;
}

export const UserInformationDialog = withSuspense()(({ open, onHide }: UserInformationDialogProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['userinformationdialog', 'translation']);
  const [userSessionData] = useUserSessionAPI();
  const [updateUserInformation, fetchUpdateUserInformation, clearUpdateUserInformation] = useUpdateUserInformationAPI();
  const [stateCodesData, fetchStateCodesData, clearStateCodesData] = useStateCodes();
  const [timeReload, startCountReload] = useTimeOut();
  const { values, getError, handleSubmit, handleChange, handleBlur } = useForm(
    useMemo(
      () => ({
        initialValues: {
          id: userSessionData.data?.id ?? '',
          firstName: userSessionData.data?.firstName ?? '',
          lastName: userSessionData.data?.lastName ?? '',
          title: userSessionData.data?.title ?? '',
          email: userSessionData.data?.email ?? '',
          mobilePhone: userSessionData.data?.mobilePhone ?? '',
          name: userSessionData.data?.organization?.name ?? '',
          address1: userSessionData.data?.organization?.address1 ?? '',
          address2: userSessionData.data?.organization?.address2 ?? '',
          city: userSessionData.data?.organization?.city ?? '',
          stateCode: userSessionData.data?.organization?.stateCode ?? '01',
          zip: userSessionData.data?.organization?.zip ?? '',
        },
        validationSchema: validationSchema(t, { ns: 'translation' }),
        restrictPattern: {
          zip: /^\d*$/,
        },
      }),
      [t, userSessionData.data]
    )
  );

  useEffect(() => {
    if (open) {
      fetchStateCodesData();
      return () => {
        clearStateCodesData();
        clearUpdateUserInformation();
      };
    }
  }, [fetchStateCodesData, clearStateCodesData, clearUpdateUserInformation, open]);

  useEffect(() => {
    if (updateUserInformation.data) {
      onHide();
    }
  }, [onHide, updateUserInformation.data]);

  useEffect(() => {
    if (
      !updateUserInformation.requesting &&
      updateUserInformation.error?.type === 'account.firstOrganizationAlreadyExists'
    )
      startCountReload(() => {
        window.location.reload();
      }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserInformation.error, updateUserInformation.requesting]);

  const onSubmit = handleSubmit((castedValues) => {
    fetchUpdateUserInformation({
      id: castedValues.id,
      firstName: castedValues.firstName,
      lastName: castedValues.lastName,
      email: castedValues.email,
      mobilePhone: castedValues.mobilePhone,
      title: castedValues.title,
      organization: {
        name: castedValues.name,
        address1: castedValues.address1,
        address2: castedValues.address2,
        city: castedValues.city,
        stateCode: castedValues.stateCode,
        zip: castedValues.zip,
      },
    });
  });

  return (
    <Dialog open={open} maxWidth="lg">
      <Box component="form" onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h6">{t('completeYourInitialSetup')}</Typography>
        </DialogTitle>
        <DialogContent>
          <FallbackComponent requesting={!!updateUserInformation.requesting} overlay>
            <ErrorMessageBox error={updateUserInformation.error} tOptions={{ time: timeReload / 1000 }} />
            <Stack direction="row" spacing={5} divider={<Divider orientation="vertical" flexItem />}>
              <Box flex={1} marginBottom={2.5}>
                <Grid container rowSpacing={2.5} columnSpacing={4}>
                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('firstName')}</InputLabel>
                    <TextField
                      InputProps={{ name: 'firstName' }}
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('firstName')}
                      helperText={t(getError('firstName'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('lastName')}</InputLabel>
                    <TextField
                      InputProps={{ name: 'lastName' }}
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('lastName')}
                      helperText={t(getError('lastName'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>{t('title')}</InputLabel>
                    <TextField
                      InputProps={{ name: 'title' }}
                      value={values.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('title')}
                      helperText={t(getError('title'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>{t('email')}</InputLabel>
                    <TextField value={values.email} fullWidth disabled={true} />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>{t('mobilePhone')}</InputLabel>
                    <PhoneInput
                      InputProps={{ name: 'mobilePhone' }}
                      value={values.mobilePhone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('mobilePhone')}
                      helperText={t(getError('mobilePhone'), { ns: 'translation' })}
                      placeholder="###-###-####"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box flex={1} marginBottom={2.5}>
                <Grid container rowSpacing={2.5} columnSpacing={4}>
                  <Grid item xs={12}>
                    <InputLabel>{t('organizationName')}</InputLabel>
                    <TextField
                      InputProps={{ name: 'name' }}
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('name')}
                      helperText={t(getError('name'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>{t('address1')}</InputLabel>
                    <TextField
                      InputProps={{ name: 'address1' }}
                      value={values.address1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('address1')}
                      helperText={t(getError('address1'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>{t('address2')}</InputLabel>
                    <TextField
                      InputProps={{ name: 'address2' }}
                      value={values.address2}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('address2')}
                      helperText={t(getError('address2'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('city')}</InputLabel>
                    <TextField
                      InputProps={{ name: 'city' }}
                      value={values.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!getError('city')}
                      helperText={t(getError('city'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <InputLabel>{t('stateCode')}</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name="stateCode"
                        value={values.stateCode}
                        MenuProps={{ sx: { maxHeight: theme.typography.pxToRem(320) } }}
                        onChange={(e: any) => handleChange(e)}
                      >
                        {stateCodesData.data?.map((state) => (
                          <MenuItem value={state.stateCode} key={state.stateCode}>
                            {state.stateAbbreviation}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <InputLabel>{t('zip')}</InputLabel>
                    <ZipInput
                      InputProps={{ name: 'zip' }}
                      inputProps={{ maxLength: 10 }}
                      value={values.zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!getError('zip')}
                      helperText={t(getError('zip'), { ns: 'translation' })}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </FallbackComponent>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" disabled={updateUserInformation.requesting || !!timeReload}>
            {t('button.save', { ns: 'translation' })}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
});
