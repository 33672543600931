var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Collapse, IconButton, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from './confirmdialog';
var StyledAlert = styled(Alert)({
    backgroundColor: 'rgb(22, 11, 11, 0.5)',
});
export var MessageBox = function (_a) {
    var messages = _a.messages, onClose = _a.onClose, autoDismiss = _a.autoDismiss, props = __rest(_a, ["messages", "onClose", "autoDismiss"]);
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var clearMessage = function () {
        setShow(false);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    useEffect(function () {
        if (messages === null || messages === void 0 ? void 0 : messages.length) {
            setShow(true);
            if (autoDismiss) {
                var handler_1 = setTimeout(clearMessage, 5000);
                return function () {
                    clearTimeout(handler_1);
                    onClose === null || onClose === void 0 ? void 0 : onClose();
                };
            }
        }
        else {
            setShow(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);
    return (_jsx(Collapse, __assign({ in: show, unmountOnExit: true }, { children: _jsx(StyledAlert, __assign({ action: _jsx(IconButton, __assign({ "aria-label": "close", color: "inherit", size: "small", onClick: clearMessage }, { children: _jsx(FontAwesomeIcon, { icon: ['fal', 'xmark-large'] }) })), sx: { mb: 4 } }, props, { children: messages })) })));
};
export var ErrorMessageBox = function (props) {
    var _a;
    var t = useTranslation((_a = props.ns) !== null && _a !== void 0 ? _a : 'externalerror').t;
    var message = props.error && t(props.error.type, __assign(__assign({}, props.tOptions), { text: props.error.text && t(props.error.text) }));
    return (_jsx(MessageBox, { sx: props.sx, severity: "error", messages: message, autoDismiss: props.autoDismiss, onClose: props.onClose }));
};
export var ErrorMessageQueueBox = function (_a) {
    var errors = _a.errors, onClose = _a.onClose, props = __rest(_a, ["errors", "onClose"]);
    var oldError = useRef();
    var oldErrors = useRef(errors);
    var _b = useState(), error = _b[0], setError = _b[1];
    var handleChangeError = function (e) {
        oldError.current = e;
        setError(e);
    };
    var handleClose = function () {
        handleChangeError(undefined);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    useEffect(function () {
        var e = errors.find(function (value, index) { return value !== oldErrors.current[index]; });
        oldErrors.current = errors;
        if (!!oldError.current && e) {
            handleChangeError(undefined);
            var handler_2 = setTimeout(function () { return handleChangeError(e); }, 500);
            return function () {
                clearTimeout(handler_2);
            };
        }
        else {
            handleChangeError(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArray([], errors, true));
    return _jsx(ErrorMessageBox, __assign({ error: error }, props, { onClose: handleClose }));
};
export var MessageDialog = function (props) {
    var oldMessage = useRef(undefined);
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    useEffect(function () {
        if (props.message && oldMessage.current !== props.message) {
            setOpen(true);
        }
        oldMessage.current = props.message;
    }, [props.message]);
    var handleConfirm = function () {
        var _a;
        setOpen(false);
        (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return _jsx(ConfirmDialog, __assign({}, props, { open: open, onConfirm: handleConfirm }));
};
