var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
export var ConnectionBusinessTypeInfo = function (_a) {
    var connectionBusinessType = _a.connectionBusinessType;
    return (_jsx(Box, __assign({ display: 'inline-flex', justifyContent: 'center', bgcolor: connectionBusinessType.bgColor, borderRadius: '10px', padding: '2px 10px', color: connectionBusinessType.color, fontSize: connectionBusinessType.fontSize, width: '112px' }, { children: connectionBusinessType.displayName })));
};
