import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { useFeaturePermissionAPI } from '../selectors';
var usePermission = function () {
    var permissionData = useFeaturePermissionAPI()[0];
    var hasAccessible = function (featureAccess) {
        var permission = permissionData.data;
        return !featureAccess || (!!permission && featureAccess.every(function (f) { return permission.includes(f); }));
    };
    return { permission: permissionData.data, hasAccessible: hasAccessible };
};
export var useAuthorize = function (_a) {
    var _b = _a.hasPermission, hasPermission = _b === void 0 ? true : _b, featureAccess = _a.featureAccess;
    var hasAccessible = usePermission().hasAccessible;
    return hasPermission && hasAccessible(featureAccess);
};
export var PrivateRoute = function (props) {
    var hasPermission = useAuthorize(props);
    if (!hasPermission)
        return _jsx(Navigate, { to: "/", replace: true });
    return _jsx(_Fragment, { children: props.children });
};
export var Authenticated = function (props) {
    var hasPermission = useAuthorize(props);
    if (!hasPermission)
        return null;
    return _jsx(_Fragment, { children: props.children });
};
