var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, List, ListItemButton, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, Link as RouterLink } from 'react-router-dom';
import { withSuspense } from 'react-suspenser';
import { Authenticated, FontAwesomeIcon } from '../common';
import { themeLevel2, themeLevel3 } from '../config/theme';
import { useActivePageItem } from '../hooks/useactivepageitem';
import { UserMenu } from './usermenu';
var Logo = styled(Link)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'block',
        margin: theme.spacing(3, 4.75, 7.75, 8.5),
    });
});
var NavItem = styled(ListItemButton)(function (_a) {
    var theme = _a.theme;
    return ({
        marginLeft: theme.spacing(3.25),
        borderRadius: "".concat(theme.typography.pxToRem(theme.shape.borderRadius * 2), " 0 0 ").concat(theme.typography.pxToRem(theme.shape.borderRadius * 2)),
        '&.Mui-selected, &.Mui-selected:hover': {
            boxShadow: theme.shadows[3],
            backgroundColor: themeLevel2.palette.background.default,
            color: themeLevel3.palette.success.main,
        },
        '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.text.disabled,
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    });
});
export var Navbar = withSuspense()(function (_a) {
    var _b = _a.defaultUrl, defaultUrl = _b === void 0 ? '/' : _b, noAction = _a.noAction, pageItems = _a.pageItems, userMenuProps = _a.userMenuProps, hiddenItems = _a.hiddenItems, showAppVersion = _a.showAppVersion;
    var t = useTranslation(['navbar', 'translation']).t;
    var activePageItem = useActivePageItem(pageItems);
    return (_jsxs(Stack, __assign({ alignItems: "center", justifyContent: "space-between", flexShrink: 0 }, { children: [_jsxs(Box, __assign({ sx: { py: 5 } }, { children: [_jsx(Logo, __assign({ to: defaultUrl }, { children: _jsx("img", { src: "/assets/images/krateoSquare.png", alt: "MS", width: "64", height: "64" }) })), !hiddenItems && (_jsx(List, __assign({ component: "nav", sx: function (theme) { return ({ pt: 0, minWidth: theme.typography.pxToRem(122) }); } }, { children: pageItems.map(function (_a) {
                            var _b = _a.scale, scale = _b === void 0 ? 1 : _b, pi = __rest(_a, ["scale"]);
                            return pi.icon && (_jsx(Authenticated, __assign({ featureAccess: pi.accessFeature }, { children: _jsx(NavItem, __assign({ disabled: !!noAction, component: RouterLink, to: pi.url, selected: !noAction && (activePageItem === null || activePageItem === void 0 ? void 0 : activePageItem.name) === pi.name }, { children: _jsxs(Stack, __assign({ alignItems: "center", spacing: 1.5, width: "100%" }, { children: [_jsx(FontAwesomeIcon, { fontSize: themeLevel2.typography.pxToRem(42 * scale), icon: pi.icon }), _jsx(Typography, __assign({ textAlign: "center", variant: "body4" }, { children: t(pi.name) }))] })) }), pi.url) }), pi.name));
                        }) })))] })), !hiddenItems && (_jsxs(Stack, __assign({ alignItems: "center", mb: 12 }, { children: [userMenuProps && _jsx(UserMenu, __assign({}, userMenuProps)), showAppVersion && _jsxs(Typography, __assign({ variant: "body4" }, { children: ["v", process.env.REACT_APP_VERSION] }))] })))] })));
});
export * from './types';
