var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button, styled } from '@mui/material';
import { useState } from 'react';
import { useTimeOut } from '../hooks';
var StyledOverlayButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        color: '#E5E5E5!important',
        backgroundColor: "".concat(theme.palette.background.paper, "!important"),
    });
});
export var OverlayButton = function (_a) {
    var onClick = _a.onClick, _b = _a.duration, duration = _b === void 0 ? 2000 : _b, overlayProps = _a.overlayProps, buttonProps = __rest(_a, ["onClick", "duration", "overlayProps"]);
    var _c = useTimeOut(), setTimeOut = _c[1];
    var _d = useState(false), showOverlay = _d[0], setShowOverlay = _d[1];
    var handleClick = function (event) {
        setShowOverlay(true);
        setTimeOut(function () { return setShowOverlay(false); }, duration, duration);
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
    };
    return showOverlay ? (_jsx(StyledOverlayButton, __assign({}, buttonProps, overlayProps, { disabled: true }))) : (_jsx(Button, __assign({ onClick: handleClick }, buttonProps)));
};
