import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Card, CardContent, CardHeader, Dialog, DialogTitle, Select, Tooltip, useForm } from 'common-components';
import _ from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitleSelect } from '../pagetitleselect';
import { NEW_EXPORT_DEFINITION } from './constants';
import { useGetAllDefinitionAPI } from './selectors';
import { FilterFormValues } from './types';

const DeleteIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  '&.MuiChip-deleteIcon': {
    color: `${theme.palette.text.primary} !important`,
    fontSize: theme.typography.pxToRem(16),
  },
}));

interface SettingsProps {
  values: FilterFormValues;
  isSaveDisabled: boolean;
  selectedDef: number;
  setDeleteDefId: Dispatch<SetStateAction<number | undefined>>;
  onChangeDefinition: (event: SelectChangeEvent<any>) => void;
  onDeleteDefinition: () => void;
  handleShowSaveDialog: () => void;
  registerField: ReturnType<typeof useForm<FilterFormValues>>['registerField'];
  setFieldValue: (value: any, field: keyof FilterFormValues) => void;
}

export const Settings = ({
  values,
  isSaveDisabled,
  selectedDef,
  setDeleteDefId,
  setFieldValue,
  registerField,
  onChangeDefinition,
  onDeleteDefinition,
  handleShowSaveDialog,
}: SettingsProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['audienceexport', 'translation']);
  const [definitions] = useGetAllDefinitionAPI();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDelete = () => {
    onDeleteDefinition();
    setShowDeleteDialog(false);
  };

  const handleShowDeleteDialog = (e: any, id: number) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
    setDeleteDefId(id);
  };

  return (
    <>
      <Card>
        <CardHeader title={t('settings.title')} titleTruncate />
        <CardContent sx={{ flex: 1 }}>
          <Stack direction={{ lg: 'row' }} gap={4} flex={1}>
            <Box flex={1} maxWidth="25rem">
              <Typography marginBottom={1.5}>{t('name')}</Typography>
              <Select fullWidth value={selectedDef} onChange={onChangeDefinition}>
                <MenuItem value={NEW_EXPORT_DEFINITION.value}>{NEW_EXPORT_DEFINITION.label}</MenuItem>
                {_.map(definitions.data, (de) => (
                  <MenuItem value={de.id} key={de.id} sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    {de.title}
                    {de.id !== selectedDef && (
                      <Box
                        sx={{ minWidth: '1rem', textAlign: 'right' }}
                        onClick={(e) => handleShowDeleteDialog(e, de.id)}
                      >
                        <DeleteIcon icon={['fal', 'xmark']} style={{ fontSize: theme.typography.pxToRem(18) }} />
                      </Box>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <PageTitleSelect sx={{ maxWidth: '25rem' }} {...registerField('pageTitle')} />
            <Stack alignSelf="end" direction="row" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="bounceRateReduction"
                    checked={values.bounceRateReduction}
                    onChange={(__, checked) => setFieldValue(checked, 'bounceRateReduction')}
                  />
                }
                label={
                  <Typography component="span" lineHeight={1}>
                    {t('settings.bounceRateReduction')}
                  </Typography>
                }
              />
              <Tooltip title={t('settings.bounceRateReductionHelperText')}>
                <FontAwesomeIcon size="lg" icon={['fal', 'circle-info']} />
              </Tooltip>
            </Stack>
            <Stack justifyContent="flex-end" ml="auto">
              <Button variant="outlined" color="inherit" onClick={handleShowSaveDialog} disabled={isSaveDisabled}>
                {t('settings.save')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Dialog open={showDeleteDialog} fullScreenBreakpoint="xs" maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6">{t('settings.deleteExportDefinition')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body3">{t('settings.deleteText')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDeleteDialog(false)}
            variant="outlined"
            color="inherit"
            sx={{ minWidth: theme.typography.pxToRem(120) }}
          >
            {t('button.cancel', { ns: 'translation' })}
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="primary"
            disabled={false}
            sx={{ minWidth: theme.typography.pxToRem(120) }}
          >
            <Typography variant="button" color={theme.palette.common.black}>
              {t('button.delete', { ns: 'translation' })}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
