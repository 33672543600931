var _a;
import { AUDIT_EVENTS } from '../constants';
export var FS_SESSION_ACTIVE_KEY = 'fsSession';
export var FS_TARGET = '_blank';
export var SessionActive;
(function (SessionActive) {
    SessionActive["EmailOnly"] = "emailOnly";
    SessionActive["EnrichedB2C"] = "enrichedB2C";
    SessionActive["EnrichedB2B"] = "enrichedB2B";
    SessionActive["StatSummary"] = "statSummary";
    SessionActive["LocationDetail"] = "locationDetail";
    SessionActive["GroupAnalysis"] = "groupAnalysis";
    SessionActive["Insights"] = "insights";
    SessionActive["Age"] = "age";
    SessionActive["Income"] = "income";
    SessionActive["Gender"] = "gender";
    SessionActive["SeniorityLevel"] = "seniorityLevel";
    SessionActive["Department"] = "department";
    SessionActive["CompanyRevenue"] = "company_revenue";
    SessionActive["CompanyEmployeeCount"] = "company_employee_count";
    SessionActive["Geography"] = "geography";
    SessionActive["CompanyGeography"] = "companyGeography";
    SessionActive["ContactGeography"] = "contactGeography";
    SessionActive["AccountManagement"] = "accountManagement";
    SessionActive["ResponseProfileExplorer"] = "responseProfileExplorer";
    SessionActive["Married"] = "married";
    SessionActive["Children"] = "children";
    SessionActive["Homeowner"] = "homeowner";
    SessionActive["NetWorth"] = "net_worth";
})(SessionActive || (SessionActive = {}));
export var FSAuditAction = (_a = {},
    _a[SessionActive.StatSummary] = AUDIT_EVENTS.campaign360.statSummary.fullScreen,
    _a[SessionActive.GroupAnalysis] = AUDIT_EVENTS.campaign360.demographicSummary.fullScreen,
    _a[SessionActive.LocationDetail] = AUDIT_EVENTS.campaign360.locationDetail.fullScreen,
    _a[SessionActive.ResponseProfileExplorer] = AUDIT_EVENTS.campaign360.responseProfileExplorer.fullScreen,
    _a[SessionActive.Insights] = AUDIT_EVENTS.campaign360.insights.fullScreen,
    _a);
