import _ from 'lodash';
import { AllListByConnection } from '../../selectors';
import { AudienceScheduleForm, ScheduleFrequency } from '../../types';

export enum ScheduleType {
  None,
  NotRecurring,
  Recurring,
}

export type FilterOption = {
  id: string;
  name: string;
  type: ScheduleType;
};

export const NoScheduleOption: FilterOption = {
  id: '-1',
  name: 'No Schedule - Download only',
  type: ScheduleType.None,
};

export const getOptions = (schedules: AudienceScheduleForm[], allList?: AllListByConnection[]): FilterOption[] => {
  return schedules.map((s) => {
    const listName = _.chain(allList)
      .find(['connectionId', s.connectionId])
      .get('lists', [])
      .find(['id', s.list])
      .get('name', '...')
      .value();

    return {
      id: s.__internalId,
      name: `${s.connectionName} - ${listName}`,
      type: s.frequency === ScheduleFrequency.OneTime ? ScheduleType.NotRecurring : ScheduleType.Recurring,
    };
  });
};
