import { Paper, Typography, styled } from '@mui/material';
import { FallbackComponent } from 'common-components';
import { useTranslation } from 'react-i18next';
import { useVisitorAPI } from './selectors';

const StyledProspectCount = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  minHeight: theme.typography.pxToRem(128),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const ProspectCount = () => {
  const { t } = useTranslation('webresolution');
  const [visitorData] = useVisitorAPI();

  return (
    <StyledProspectCount>
      <FallbackComponent requesting={visitorData.requesting}>
        <Typography variant="h4" textAlign="center">
          <Typography variant="h4" component="span" color="success.main" fontWeight="bold">
            {visitorData.data?.totalCount.toLocaleString() ?? 0}
          </Typography>{' '}
          {t('prospects')}
        </Typography>
      </FallbackComponent>
    </StyledProspectCount>
  );
};
