import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import {
  Autocomplete,
  GA_EVENTS,
  Select,
  SessionActive,
  Tooltip,
  formatValue,
  themeLevel4,
  useActiveFullscreenSession,
  useGA4,
} from 'common-components';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { use360FilterIgnoreLowVolumeState } from '../../state/app';
import { getAllCampaignOption, modifyDuplicateNames } from './constants';
import { Campaign, MetricCount, useCampaignState, useMetricTypeState, useMetricsAPI } from './selectors';
import { MetricType } from './types';

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  marginTop: 'auto',
  minHeight: theme.typography.pxToRem(37.13),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBlock: theme.spacing(0.5),
  paddingInline: theme.spacing(1),
}));

interface HeaderProps {
  campaignsData: Campaign[];
  metricCountData: Record<string, MetricCount>;
  requestingCampaign?: boolean;
}

export const Header: React.FC<HeaderProps> = withSuspense()(
  ({ campaignsData, metricCountData, requestingCampaign }) => {
    const { t } = useTranslation('360analysis');

    const [, , putEvent] = useGA4();
    const [metricData, fetchMetricData, clearMetricData] = useMetricsAPI();
    const [campaignIds, setCampaignIds] = useCampaignState();
    const [metricType, setMetricType] = useMetricTypeState();
    const [ignoreLowVolume, setIgnoreLowVolume] = use360FilterIgnoreLowVolumeState();

    const [activeFullScreenComponent] = useActiveFullscreenSession();
    const showResponseTypeAndSummaryBox = activeFullScreenComponent !== SessionActive.StatSummary;
    const allCampaign = getAllCampaignOption(t);

    const handleChangeCampaign = (event: SelectChangeEvent<string[]>) => {
      setCampaignIds(event.target.value as string[]);
      putEvent({
        category: GA_EVENTS._360InsightsDashboard.__name,
        action: GA_EVENTS._360InsightsDashboard.changeCampaignFilter,
      });
    };

    const handleChangeResponseType = (e: SelectChangeEvent<any>) => {
      const selectedResponseType = e.target.value;
      setMetricType(selectedResponseType);
    };

    const summaryData = useMemo(() => {
      if (!metricCountData || !metricType) return;
      const responseCount = metricCountData[metricType]?.count || 0;
      const emailsCount = metricCountData[MetricType.Emails]?.count || 0;
      const percent = responseCount / emailsCount;

      return { responseCount, emailsCount, percent };
    }, [metricCountData, metricType]);

    useEffect(() => {
      fetchMetricData();
      return clearMetricData;
    }, [clearMetricData, fetchMetricData]);

    return (
      <Box width="100%">
        <Grid flex={1} container spacing={5.5}>
          <Grid container item xs={4}>
            <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" marginBottom={1.5}>
              <Typography variant="h6">{t('campaigns')}</Typography>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={ignoreLowVolume}
                      onChange={(__, checked) => setIgnoreLowVolume(checked)}
                    />
                  }
                  label={
                    <Typography component="span" fontSize={themeLevel4.typography.body3.fontSize}>
                      {t('ignoreVolumeIcon')}
                    </Typography>
                  }
                  sx={{ marginRight: '0.5rem', verticalAlign: 'middle' }}
                />
                <Tooltip title={t('ignoreVolumeTooptip')} sx={{ verticalAlign: 'middle' }}>
                  <FontAwesomeIcon size="sm" icon={['fal', 'circle-info']} />
                </Tooltip>
              </Box>
            </Stack>
            <Autocomplete
              limitTags={2}
              value={campaignIds}
              options={modifyDuplicateNames(campaignsData)}
              allOption={allCampaign}
              popupIcon={<FontAwesomeIcon fontSize="1.125rem" icon={['fal', 'magnifying-glass']} />}
              onChange={handleChangeCampaign}
              requesting={requestingCampaign ?? false}
              transformPopupIcon={false}
            />
          </Grid>
          {showResponseTypeAndSummaryBox && (
            <>
              <Grid container item xs={4}>
                <Typography variant="h6" marginBottom={1.5}>
                  {t('responseType')}
                </Typography>
                <Select fullWidth value={metricType ?? ''} onChange={handleChangeResponseType}>
                  {Object.keys(metricData.data || {}).map((key) => (
                    <MenuItem value={key} key={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid container item xs={4}>
                {summaryData && !!summaryData.emailsCount && (
                  <StyledPaper>
                    <Typography data-testid="summary-component" textAlign="center" variant="subtitle2">
                      <Typography color="success.main" fontWeight="bold" component="span">
                        {formatValue(summaryData.emailsCount, { ignoreNotation: 4, maximumFractionDigits: 0 })}&nbsp;
                      </Typography>
                      {t('emailsSent')} /&nbsp;
                      <Typography color="success.main" fontWeight="bold" component="span">
                        {formatValue(summaryData.responseCount, { ignoreNotation: 4, maximumFractionDigits: 0 })}&nbsp;
                      </Typography>
                      {metricType}&nbsp;
                      <Typography variant="body3">
                        ({formatValue(summaryData.percent, { style: 'percent' })})
                      </Typography>
                    </Typography>
                  </StyledPaper>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  }
);
