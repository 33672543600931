import { Box, Stack, Typography } from '@mui/material';
import { ParagraphTruncateTooltip } from 'common-components';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileStatsType } from '../types';

interface RateMetricsBoxesProps {
  profileStats: ProfileStatsType;
}

interface RateBoxProps {
  label: string;
  value?: number;
  percentOfTotal?: number;
}

const RateBox: React.FC<RateBoxProps> = ({ label, percentOfTotal, value = 0 }) => {
  return (
    <Box textAlign={'center'} margin={'auto 0'}>
      <ParagraphTruncateTooltip fontSize="0.75rem" content={label} maxLine={1} />
      <Typography sx={{ fontWeight: '700', fontSize: '1.125rem' }}>
        {_.round(value, 2)}
        {!_.isNil(percentOfTotal) ? (
          <Typography component={'span'} fontSize="0.75rem">
            {` (${percentOfTotal}%)`}
          </Typography>
        ) : (
          '%'
        )}
      </Typography>
    </Box>
  );
};

export const RateMetricsBoxes: React.FC<RateMetricsBoxesProps> = ({ profileStats }) => {
  const { t } = useTranslation('360analysis');

  return (
    <Stack
      display={'flex'}
      width={'100%'}
      direction={'row'}
      justifyContent="space-evenly"
      alignItems="stretch"
      padding={3.2}
      gap={1}
    >
      <RateBox
        label={t(`responseProfileExplorer.segmentSize`)}
        value={profileStats.segmentSize}
        percentOfTotal={profileStats.percentOfTotal}
      />
      <RateBox label={t(`responseProfileExplorer.responseRate`)} value={profileStats.responseRate} />
      <RateBox label={t(`responseProfileExplorer.liftAverage`)} value={profileStats.liftOverAverage} />
    </Stack>
  );
};
