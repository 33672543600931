import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, ListItemIcon, MenuItem, Stack } from '@mui/material';
import {
  DateRangeSelector,
  FallbackComponent,
  GA_EVENTS,
  Header as HeaderComponent,
  Menu,
  PortalPageName,
  useGA4,
  useLastRefreshedAPI,
  usePrintAPI,
  usePrintParam,
} from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { useDateRangeState } from '../../selectors';
import { useIsCampaignDateRangeSetState } from '../../state/app';
import { DateRangeStateEnum } from '../../state/app/appSlice';
import { PageURL } from '../navbar/constants';
import { PageTitleSelect } from '../pagetitleselect';

interface HeaderProps {
  dateRangeState?: DateRangeStateEnum;
  title: string;
  noAction?: boolean;
  showMenu?: boolean;
  showDateRange?: boolean;
}

const DashboardMenu = withSuspense()(() => {
  const [, , putEvent] = useGA4();
  const { t } = useTranslation('dashboardmenu');
  const [printPDFData, fetchPrintPDF] = usePrintAPI();
  const [printPDFParam] = usePrintParam();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (printPDFParam) return null;
  if (printPDFData.requesting) return <FallbackComponent overlay />;
  const handlePrintPDF = () => {
    const pathname = window.location.pathname;
    if (PageURL.Dashboard === pathname) {
      putEvent({
        category: GA_EVENTS.websiteResolutionDashboard.__name,
        action: GA_EVENTS.websiteResolutionDashboard.exportPageToPDF,
      });
    } else if (PageURL._AudienceExport === pathname) {
      putEvent({
        category: GA_EVENTS.audienceExport.__name,
        action: GA_EVENTS.audienceExport.exportPageToPDF,
      });
    } else {
      putEvent({
        category: GA_EVENTS._360InsightsDashboard.__name,
        action: GA_EVENTS._360InsightsDashboard.exportPagetoPDF,
      });
    }
    fetchPrintPDF();
  };
  return (
    <>
      <IconButton data-testid="dashboard-menu" onClick={handleClick}>
        <FontAwesomeIcon icon={['fal', 'ellipsis']} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} onClick={handleClose}>
        <MenuItem onClick={handlePrintPDF}>
          <ListItemIcon>
            <FontAwesomeIcon fontSize="1.375rem" icon={['fal', 'file-export']} />
          </ListItemIcon>
          {t('exportToPDFFormat')}
        </MenuItem>
      </Menu>
    </>
  );
});

export const Header = withSuspense()((props: HeaderProps) => {
  const [lastRefreshedData, fetchLastRefreshedData, clearLastRefreshedData] = useLastRefreshedAPI(props.title);
  const [[fromDate, toDate], setDateRange] = useDateRangeState(props.dateRangeState);
  const [isCampaignDateRangeSet, setIsCampaignDateSet] = useIsCampaignDateRangeSetState();

  useEffect(() => {
    fetchLastRefreshedData();
    return clearLastRefreshedData;
  }, [clearLastRefreshedData, fetchLastRefreshedData]);

  // for first time to launched Campaign page, set default date_range is min & max of campaign_date
  useEffect(() => {
    if (props.title === PortalPageName.campaign360 && !_.isNil(lastRefreshedData.data) && !isCampaignDateRangeSet) {
      setDateRange([moment(lastRefreshedData.data.minDate), moment(lastRefreshedData.data.maxDate)]);
      setIsCampaignDateSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRefreshedData.data]);

  const onChangeDateRangeSelector = ([startDate, endDate]: [moment.Moment, moment.Moment]) => {
    setDateRange([startDate, endDate]);
  };

  return (
    <HeaderComponent {...props} lastRefreshedDate={lastRefreshedData.data?.maxDate}>
      <Stack direction="row" alignItems="center" gap={4}>
        {props.title === PortalPageName.visitor360 ? <PageTitleSelect direction="row" /> : undefined}
        {props.showDateRange && (
          <DateRangeSelector
            onChange={onChangeDateRangeSelector}
            fromDate={fromDate}
            toDate={toDate}
            maxDate={lastRefreshedData.data ? moment(lastRefreshedData.data.maxDate) : null}
            minDate={lastRefreshedData.data ? moment(lastRefreshedData.data.minDate) : null}
          />
        )}
      </Stack>
      {props.showMenu && <DashboardMenu />}
    </HeaderComponent>
  );
});
