var _a;
import { PortalPageName } from '../constants';
import { useAPI } from '../state';
var VISIT_LAST_REFRESHED_URL = '/api/webResolution/last-refreshed-date';
var CAMPAIGN_LAST_REFRESHED_URL = '/api/campaign360/last-refreshed-date';
export var LAST_REFRESHED_URL = (_a = {},
    _a[PortalPageName.visitor360] = VISIT_LAST_REFRESHED_URL,
    _a[PortalPageName.audienceExport] = VISIT_LAST_REFRESHED_URL,
    _a[PortalPageName.campaign360] = CAMPAIGN_LAST_REFRESHED_URL,
    _a[PortalPageName.audiencesPage] = VISIT_LAST_REFRESHED_URL,
    _a);
export var useLastRefreshedAPI = function (pageName) {
    return useAPI(LAST_REFRESHED_URL[pageName]);
};
