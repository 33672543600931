var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as yup from 'yup';
import { NO_SPECIAL_CHARACTER_REGEX, ZIP_CODE_FORMAT_REGEX } from '../constants';
export var OrgFieldLength = {
    name: 125,
    address: 255,
};
export var ORG_OBJECT_SHAPE = function (t, options) {
    if (options === void 0) { options = {}; }
    return ({
        name: yup
            .string()
            .trim()
            .nullable()
            .required('error.required')
            .max(OrgFieldLength.name, "".concat(t('error.maxLength', __assign(__assign({}, options), { length: OrgFieldLength.name }))))
            .matches(NO_SPECIAL_CHARACTER_REGEX, 'error.orgNameFormatError')
            .label('Organization Name'),
        address1: yup
            .string()
            .trim()
            .nullable()
            .required('error.required')
            .max(OrgFieldLength.address, "".concat(t('error.maxLength', __assign(__assign({}, options), { length: OrgFieldLength.address }))))
            .label('Address 1'),
        address2: yup
            .string()
            .trim()
            .nullable()
            .max(OrgFieldLength.address, "".concat(t('error.maxLength', __assign(__assign({}, options), { length: OrgFieldLength.address }))))
            .label('Address 2'),
        city: yup
            .string()
            .trim()
            .nullable()
            .required('error.required')
            .max(OrgFieldLength.name, "".concat(t('error.maxLength', __assign(__assign({}, options), { length: OrgFieldLength.name }))))
            .label('City'),
        stateCode: yup.string().trim().nullable().required('error.required').label('State code'),
        zip: yup
            .string()
            .trim()
            .nullable()
            .required('error.required')
            .matches(ZIP_CODE_FORMAT_REGEX, 'error.zipCodeFormatError')
            .label('Zip code'),
    });
};
export var validationSchema = function (t, options) {
    if (options === void 0) { options = {}; }
    return yup.object().shape(__assign(__assign({}, ORG_OBJECT_SHAPE(t, options)), { contactEmailAddress: yup
            .string()
            .trim()
            .nullable()
            .email('error.emailFormatError')
            .label('Contact Email Address')
            .max(OrgFieldLength.address, "".concat(t('error.maxLength', __assign(__assign({}, options), { length: OrgFieldLength.address }))))
            .when('id', function (id, schema) { return (id ? schema : schema.required('error.required')); }), planId: yup.string().trim().nullable().required('error.required').label('Plan') }));
};
