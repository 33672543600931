import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Autocomplete } from 'common-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateOptions } from '../scheduledetails/const';
import { DatesTabProps } from './index';
import { useScheduleInfo } from './selectors';

export type RecurringScheduleScopeProps = Pick<DatesTabProps, 'values' | 'selectedSchedule' | 'registerField'>;

export const RecurringScheduleScope: React.FC<RecurringScheduleScopeProps> = ({
  values,
  selectedSchedule,
  registerField,
}) => {
  const { t } = useTranslation('audience');
  const { currentSchedule, scheduleIndex } = useScheduleInfo(selectedSchedule, values.schedules);

  const scopeOptions = generateOptions('scope', {
    format: (val) => ({ ...val, name: t(`schedule.${val.name}`) }),
    frequency: currentSchedule?.frequency,
  });

  return (
    <Grid container flex={1} spacing={6} mt={2}>
      <Grid item xs={12}>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('datesTab.recurringText', { frequency: currentSchedule?.frequency }) }}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack
          flex={1}
          direction={'column'}
          alignItems={'stretch'}
          gap={4}
          height="100%"
          width={(theme) => theme.typography.pxToRem(350)}
        >
          <Typography color={(theme) => theme.palette.text.primary} whiteSpace="nowrap">
            {t('schedule.scope')}
          </Typography>
          <Box flex={1}>
            <Autocomplete
              multiple={false}
              options={scopeOptions}
              popupIcon={<FontAwesomeIcon fontSize="1.125rem" icon={['fal', 'chevron-down']} />}
              {...registerField(`schedules.${scheduleIndex}.scope`)}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
