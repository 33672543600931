import { SessionActive } from 'common-components';
import { AnalyzerType, ContentType, UnitType } from './types';

export const DEMOGRAPHIC_MARGIN_VALUE = 8;

export const DEMOGRAPHIC_MARGIN_LABEL = 14;

export const DEMOGRAPHIC_FONT_SIZE = 10;

export const AnalyzerSessionName: Record<string, SessionActive> = {
  [AnalyzerType.Age]: SessionActive.Age,
  [AnalyzerType.Income]: SessionActive.Income,
  [AnalyzerType.Gender]: SessionActive.Gender,
  [AnalyzerType.SeniorityLevel]: SessionActive.SeniorityLevel,
  [AnalyzerType.Department]: SessionActive.Department,
  [AnalyzerType.CompanyRevenue]: SessionActive.CompanyRevenue,
  [AnalyzerType.CompanyEmployeeCount]: SessionActive.CompanyEmployeeCount,
  [AnalyzerType.Geography]: SessionActive.Geography,
  [AnalyzerType.CompanyGeography]: SessionActive.CompanyGeography,
  [AnalyzerType.ContactGeography]: SessionActive.ContactGeography,
  [AnalyzerType.Married]: SessionActive.Married,
  [AnalyzerType.Homeowner]: SessionActive.Homeowner,
  [AnalyzerType.Children]: SessionActive.Children,
  [AnalyzerType.NetWorth]: SessionActive.NetWorth,
};

export const AnalyzerUnitType: Partial<Record<AnalyzerType, UnitType>> = {
  [AnalyzerType.CompanyRevenue]: UnitType.Revenue,
  [AnalyzerType.CompanyEmployeeCount]: UnitType.Employees,
};

export const DemographyMaxDisplay: Record<ContentType, number> = {
  [ContentType.B2C]: Infinity,
  [ContentType.B2B]: 10,
};

export const calculateChartMargin = (label: Array<string>): Partial<Plotly.Margin> => {
  const maxLength = Math.max(...label.map((i) => i.length));
  return {
    t: 20,
    r: 10,
    b: DEMOGRAPHIC_FONT_SIZE + DEMOGRAPHIC_MARGIN_LABEL,
    l: maxLength * (DEMOGRAPHIC_FONT_SIZE / 2) + DEMOGRAPHIC_MARGIN_LABEL,
    pad: 0,
  };
};
