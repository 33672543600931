import { UseAPI } from 'common-components';
import _ from 'lodash';
import { DateSelectorDependency, useDateSelectorBindingAPI } from '../../../selectors';
import { DateRangeStateEnum } from '../../../state/app/appSlice';
import { useCampaignState, useLookupCampaignsAPI } from '../selectors';

export interface ReferralChannelType {
  channel: string;
  visitors: number;
  percentage: number;
}

export const useReferralChannelAPI = (): UseAPI<ReferralChannelType[]> => {
  const [campaignIds] = useCampaignState();
  const [campaigns] = useLookupCampaignsAPI();

  return useDateSelectorBindingAPI<ReferralChannelType[]>({
    depend: DateSelectorDependency.P360ReferralChannel,
    params: {
      campaign: !_.isEmpty(campaigns.data) ? campaignIds?.join(',') : '',
    },
    dateRangeState: DateRangeStateEnum.Campaign360,
  });
};
