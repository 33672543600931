import { useTheme } from '@mui/material';
import { PlotComponent } from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { AnalyzerSessionName } from './constants';
import { GeographyProps } from './geography';
import { useTrafficInsightsGeoMappingsByUSGroupingsAPI } from './selectors';

export const CompanyGeographyRegion = withSuspense()(({ contentType, analyzerType }: GeographyProps) => {
  const { t } = useTranslation(['translation', 'webresolution']);
  const [trafficGeoMappingsData, fetchTrafficGeoMappingsData, clearTrafficGeoMappingsData] =
    useTrafficInsightsGeoMappingsByUSGroupingsAPI(contentType, analyzerType);

  useEffect(() => {
    fetchTrafficGeoMappingsData(undefined, true);
    return clearTrafficGeoMappingsData;
  }, [clearTrafficGeoMappingsData, fetchTrafficGeoMappingsData]);

  const theme = useTheme();
  const { x, y } = useMemo(() => {
    const _x: Array<number> = [];
    const _y: Array<string> = [];
    trafficGeoMappingsData.data?.forEach((d) => {
      _x.push(d.count);
      _y.push(d.value);
    });
    return { x: _x, y: _y };
  }, [trafficGeoMappingsData.data]);
  return (
    <PlotComponent
      fullscreenName={AnalyzerSessionName[analyzerType]}
      data={[
        {
          x,
          y,
          type: 'bar',
          orientation: 'h',
          hoverinfo: 'none',
          marker: {
            color: theme.palette.primary.main,
          },
          customdata: [y, x],
        },
      ]}
      layout={{
        margin: { b: 34 },
        xaxis: { showgrid: true },
      }}
      requesting={trafficGeoMappingsData.requesting}
      isNoData={!(x.length && y.length)}
      templatetooltip={(__: any, value: any) =>
        t('template.chartTooltipNoLabel', {
          value,
          unitLabel: { name: 'visitors', ns: 'webresolution' },
          ns: 'translation',
        })
      }
    />
  );
});
