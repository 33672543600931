var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select as SelectMUI, ThemeProvider, createTheme, styled, } from '@mui/material';
import { Children, useMemo } from 'react';
import { themeLevel4 } from '../config/theme';
var StyledSelect = styled(SelectMUI, { shouldForwardProp: function (prop) { return prop !== 'helperText' && prop !== 'inputRef'; } })(function (_a) {
    var theme = _a.theme;
    return ({
        '.MuiSelect-icon': {
            right: theme.spacing(3),
        },
    });
});
var themeSelect = createTheme(themeLevel4, {
    components: {
        MuiSelect: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                select: {
                    lineHeight: themeLevel4.typography.pxToRem(20),
                },
                icon: {
                    fontSize: themeLevel4.typography.body3.fontSize,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: { fontSize: themeLevel4.typography.body3.fontSize },
            },
        },
    },
});
var IconComponent = function (iconProps) { return _jsx(FontAwesomeIcon, __assign({}, iconProps, { icon: ['fal', 'chevron-down'] })); };
export var Select = function (_a) {
    var value = _a.value, disabled = _a.disabled, defaultValue = _a.defaultValue, onChange = _a.onChange, props = __rest(_a, ["value", "disabled", "defaultValue", "onChange"]);
    var noOption = useMemo(function () { return !Children.count(props.children); }, [props.children]);
    return (_jsx(ThemeProvider, __assign({ theme: themeSelect }, { children: _jsx(StyledSelect, __assign({}, props, { onChange: function (e) { return onChange === null || onChange === void 0 ? void 0 : onChange(e); }, disabled: disabled || noOption, value: !noOption ? value : '', defaultValue: defaultValue, IconComponent: IconComponent })) })));
};
