var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
export var NoDataAvailable = withSuspense()(function (_a) {
    var description = _a.description, ns = _a.ns, sx = _a.sx;
    var t = useTranslation(ns).t;
    var theme = useTheme();
    return (_jsx(Box, __assign({ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }, { children: _jsx(Typography, { sx: sx, variant: "h6", textAlign: "center", lineHeight: theme.typography.pxToRem(28), color: theme.palette.text.disabled, dangerouslySetInnerHTML: { __html: t(description !== null && description !== void 0 ? description : 'text.noDataAvailable') } }) })));
});
