var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
export var PasswordInput = function (_a) {
    var value = _a.value, defaultShowText = _a.defaultShowText, _b = _a.symbol, symbol = _b === void 0 ? '*' : _b, props = __rest(_a, ["value", "defaultShowText", "symbol"]);
    var _c = useState(defaultShowText), showText = _c[0], setShowText = _c[1];
    var _value = useMemo(function () { return (showText ? value : symbol.repeat(String(value).length)); }, [showText, symbol, value]);
    return (_jsxs(Stack, __assign({ direction: 'row', spacing: 2 }, { children: [_jsx(TextField, __assign({ value: _value, disabled: !showText, size: "medium" }, props)), _jsx(IconButton, __assign({ onClick: function () { return setShowText(function (prev) { return !prev; }); } }, { children: _jsx(FontAwesomeIcon, { width: '1.25rem', fontSize: "1.25rem", icon: ['fal', showText ? 'eye-slash' : 'eye'] }) }))] })));
};
