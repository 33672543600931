import { Card, CardContent, CardHeader, RestrictedBackdrop, setSxProps } from 'common-components';
import React, { PropsWithChildren } from 'react';

type CardProps = React.ComponentProps<typeof Card>;
type CardHeaderProps = React.ComponentProps<typeof CardHeader>;
type CardContentProps = React.ComponentProps<typeof CardContent>;
type TabLayoutProps = {
  cardProps?: CardProps;
  headerProps?: CardHeaderProps;
  contentProps?: CardContentProps;
  backdrop?: false;
};
type BackDropProps = React.ComponentProps<typeof RestrictedBackdrop>;
type TabLayoutWithBackdrop = { backdrop: true; backdropProps: BackDropProps } & Omit<TabLayoutProps, 'backdrop'>;
type AudienceTabLayoutProps = TabLayoutProps | TabLayoutWithBackdrop;
function CardWrapper(props: PropsWithChildren<CardProps>) {
  return (
    <Card
      {...props}
      sx={[
        ({ spacing }) => ({
          flex: 1,
          height: 'calc(100vh - 15rem)',
          minHeight: '30rem',
          padding: `${spacing(3.25)} ${spacing(7.5)} ${spacing(2.75)} ${spacing(5)}`,
        }),
        ...setSxProps(props?.sx),
      ]}
    >
      {props.children}
    </Card>
  );
}
type CardInnerProps = { headerProps?: CardHeaderProps; contentProps?: CardContentProps };
function CardInner({ headerProps, contentProps }: Readonly<CardInnerProps>) {
  return (
    <>
      <CardHeader
        {...headerProps}
        sx={[({ spacing }) => ({ p: 0, mb: spacing(4.5) }), ...setSxProps(headerProps?.sx)]}
      />
      <CardContent {...contentProps} sx={[{ overflow: 'auto', p: 0 }, ...setSxProps(contentProps?.sx)]}>
        {contentProps?.children}
      </CardContent>
    </>
  );
}
export function AudienceTabLayout({ cardProps, ...props }: AudienceTabLayoutProps) {
  if (props.backdrop) {
    return (
      <CardWrapper {...cardProps} sx={[...setSxProps(cardProps?.sx), { position: 'relative' }]}>
        <RestrictedBackdrop {...props.backdropProps}>
          <CardInner {...props} />
        </RestrictedBackdrop>
      </CardWrapper>
    );
  }
  return (
    <CardWrapper {...cardProps}>
      <CardInner {...props} />
    </CardWrapper>
  );
}
