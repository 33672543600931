import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { withSuspense } from 'react-suspenser';
import { FeatureFlagStatus } from '../enum';
import { useFeatureFlagAPI, useOrgFeatureFlagPermissionAPI } from '../selectors';
var hasPermissionOnLimitedFeature = function (flagAccess, orgFeatureFlags) {
    if (!flagAccess || !orgFeatureFlags) {
        return true;
    }
    return orgFeatureFlags.some(function (feature) { return feature.flagKey === flagAccess; });
};
var checkReleasedFeature = function (featureFlags, flagAccess) {
    return !flagAccess || !featureFlags || featureFlags.length === 0
        ? true
        : featureFlags.some(function (feature) { return feature.status === FeatureFlagStatus.Released && feature.flagKey === flagAccess; });
};
export var LimitedFeature = withSuspense()(function (_a) {
    var flagAccess = _a.flagAccess, children = _a.children;
    var featureFlags = useFeatureFlagAPI()[0];
    var _b = useOrgFeatureFlagPermissionAPI(), orgPermission = _b[0], fetchOrgFeatureFlagPermission = _b[1], clearOrgFeatureFlagPermission = _b[2];
    var isReleasedFeature = useMemo(function () { return checkReleasedFeature(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.data, flagAccess); }, [featureFlags, flagAccess]);
    useEffect(function () {
        if (!isReleasedFeature) {
            fetchOrgFeatureFlagPermission();
        }
        return clearOrgFeatureFlagPermission;
    }, [fetchOrgFeatureFlagPermission, isReleasedFeature, clearOrgFeatureFlagPermission]);
    if (orgPermission.requesting) {
        return null;
    }
    if (isReleasedFeature) {
        return _jsx(_Fragment, { children: children });
    }
    if (!isReleasedFeature && hasPermissionOnLimitedFeature(flagAccess, orgPermission.data)) {
        return _jsx(_Fragment, { children: children });
    }
    return null;
});
