import { useForm } from 'common-components';
import { TFunction } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';
import * as yup from 'yup';
import { usePageTitleState } from '../../../state/app';
import { AudienceExport, FieldType, Operators, QuickSelectGroup, VisitCategory } from '../enums';
import {
  Audience,
  AudienceSchedule,
  AudienceScheduleForm,
  CellFormData,
  FieldConfig,
  FilterFormValues,
  FilterItem,
} from '../types';
import { DATE_FILTER_FORMAT } from './constants';
import { audienceDetailSchema } from './scheduledetails/useform';
import { useAudienceAPI, useFieldAPI } from './selectors';

export const dateString = () =>
  yup
    .string()
    .test({
      test: (value, ctx) => {
        if (_.isNil(value) || moment(value).isValid()) return true;
        return ctx.createError({ message: 'invalid date' });
      },
    })
    .transform((value) => {
      if (!value) return value;
      return moment(value).format(DATE_FILTER_FORMAT);
    });

export const filterSchema = (t: TFunction, min = 0): any =>
  yup.lazy(({ decimalPlaces = 0, operator, type, group }, data: any) => {
    const currVisitFilterCategory = _.get(data.from?.[0].value, 'visitCategory' as keyof FilterFormValues);
    if (group && !group.includes(currVisitFilterCategory)) {
      return yup.object();
    }
    if (operator === Operators.DoNotFilter) {
      return yup.object();
    }
    let shape: any = {
      value0: yup
        .string()
        .nullable()
        .trim()
        .required(`${t('error.required')}`),
    };
    switch (type) {
      case FieldType.Enum: {
        shape = {
          enumValues: yup.array().min(1, `${t('error.required')}`),
        };
        break;
      }
      case FieldType.Number:
      case FieldType.NumberMetric:
      case FieldType.NumberSet: {
        const stepSize = 1 / Math.pow(10, decimalPlaces);
        shape = {
          value0: yup
            .number()
            .nullable()
            .required(`${t('error.required')}`)
            .when('operator', {
              is: Operators.LessThan,
              then: (schema) => schema.min(min + stepSize, `${t('error.invalid')}`),
              otherwise: (schema) => schema.min(min, `${t('error.invalid')}`),
            })
            .when('operator', {
              is: Operators.TopPercent,
              then: (schema) => schema.min(1, `${t('error.invalid')}`).max(99, `${t('error.invalid')}`),
            }),
          value1: yup
            .number()
            .nullable()
            .when('operator', {
              is: Operators.Between,
              then: (schema) =>
                schema.required(`${t('error.required')}`).test({
                  message: `${t('error.invalid')}`,
                  test: (value, ctx) => (value ?? min + stepSize) > (ctx.parent.value0 ?? min),
                }),
            }),
        };
      }
    }
    return yup.object().shape(shape);
  });

export const populateFormData = (
  fields?: FieldConfig[],
  allFilters?: FilterItem[],
  defaultFilters?: FilterItem[]
): CellFormData[] => {
  return _.map(fields, (field) => {
    const defaultFilterValue = _.find(defaultFilters, ['fieldId', field.id]);
    const filterValue = _.find(allFilters, ['fieldId', field.id]) ?? defaultFilterValue;
    const getDefaultValue = [FieldType.Number, FieldType.NumberSet, FieldType.NumberMetric].includes(field.type)
      ? null
      : '';
    const getEnumValues = field.type === FieldType.Enum ? filterValue?.values || [] : [];
    return {
      fieldId: field.id,
      type: field.type,
      operator: filterValue ? filterValue.operator : Operators.DoNotFilter,
      value0: field.type !== FieldType.Enum && _.get(filterValue, 'values[0]', getDefaultValue),
      value1: field.type !== FieldType.Enum && _.get(filterValue, 'values[1]', getDefaultValue),
      enumValues: getEnumValues,
      decimalPlaces: field.decimalPlaces,
      category: field.category,
      group: field.group,
    } as CellFormData;
  });
};

export const getDefaultExportFields = (fields: FieldConfig[] = [], audience?: Audience) => {
  const exportFieldMap = _.keyBy(audience?.exportFields);
  return fields.map(({ id, quickSelectGroup, fieldName, connections }) => ({
    id,
    fieldName,
    connections,
    checked: audience ? !!exportFieldMap[id] : !!quickSelectGroup?.includes(QuickSelectGroup.Emails),
  }));
};

export const mapScheduleFormValues = (schedules: AudienceSchedule[]): AudienceScheduleForm[] =>
  schedules.map((schedule) => ({ ...schedule, __internalId: uuid.v4() }));

export const useDefaultValue = (id?: number) => {
  const [audienceData] = useAudienceAPI(id);
  const [fieldData] = useFieldAPI();
  const [pageTitle] = usePageTitleState();
  const defaultScheduleFormValues = useRef<AudienceScheduleForm[] | null>(null);
  if (!defaultScheduleFormValues.current && audienceData.data) {
    defaultScheduleFormValues.current = mapScheduleFormValues(audienceData.data.schedules);
  }

  return useMemo<FilterFormValues>(
    () => ({
      id: audienceData.data?.id,
      version: fieldData.data?.version ?? '',
      name: audienceData.data?.name ?? '',
      pageTitle: audienceData.data?.pageTitle ?? pageTitle,
      bounceRateReduction: audienceData.data?.bounceRateReduction ?? true,
      exportVisitMetrics: !!audienceData.data?.exportVisitMetrics,
      visitCategory: audienceData.data?.visitCategory ?? VisitCategory.Score,
      [AudienceExport.Fields]: getDefaultExportFields(fieldData.data?.fields.prospect, audienceData.data),
      [AudienceExport.Prospect]: populateFormData(fieldData.data?.fields.prospect, audienceData.data?.prospectFilters),
      [AudienceExport.Visit]: populateFormData(fieldData.data?.fields.visit, audienceData.data?.visitFilters),
      schedules: defaultScheduleFormValues.current || [],
      removedScheduleIds: [],
      fromDate: audienceData.data?.fromDate ?? moment().subtract(7, 'days').format(DATE_FILTER_FORMAT),
      toDate: audienceData.data?.toDate ?? moment().format(DATE_FILTER_FORMAT),
    }),
    [audienceData.data, fieldData.data, pageTitle, defaultScheduleFormValues]
  );
};

export const useValidationSchema = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required('error.required')
          .matches(/^[\w\s"'()-]+$/, 'error.specialCharacters'),
        [AudienceExport.Prospect]: yup.array().of(filterSchema(t)),
        [AudienceExport.Visit]: yup.array().of(filterSchema(t, 1)),
        schedules: yup.array().of(audienceDetailSchema()),
        fromDate: dateString().nullable().required('error.required'),
        toDate: dateString().nullable().required('error.required'),
      }),
    [t]
  );
};

export const useAudienceForm = () => {
  const initialValues = useDefaultValue();
  const validationSchema = useValidationSchema();
  return useForm<FilterFormValues>({
    initialValues,
    validationSchema,
  });
};
