import { MenuItem, Stack, Typography } from '@mui/material';
import { AlignTable, HeadCell, NoDataAvailable, Select, Table, checkNoData, useForm } from 'common-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterForm } from '../../audienceexport/filterform';
import { AudienceExport } from '../enums';
import { FieldConfig } from '../types';
import { FieldWithHelperText, ResetButton } from './component';
import { ExportConfigProps } from './exportconfigtabs';
import { AudienceTabLayout } from './tablayout';

interface BounceRateReductionFilterProps {
  registerField: ReturnType<typeof useForm>['registerField'];
}
export const BounceRateReductionFilter = (props: BounceRateReductionFilterProps) => {
  const { t } = useTranslation('audience', { keyPrefix: 'detail.bounceRateReduction' });
  const { checked, onChange, ...selectProps } = props.registerField('bounceRateReduction', 'checked');
  const [on, off] = t<'options', string[]>('options', { returnObjects: true });

  return (
    <Select
      data-testid="bounce-rate-reduction-filter"
      sx={{ width: '9.5rem' }}
      value={checked ? on : off}
      onChange={(e) => onChange(e, e.target.value === on)}
      {...selectProps}
    >
      {[on, off].map((val) => (
        <MenuItem value={val} key={val}>
          {val}
        </MenuItem>
      ))}
    </Select>
  );
};

type FieldsTableProps = Readonly<Pick<ExportConfigProps, 'fields' | 'values' | 'registerField'>>;
function FieldsTable({ values, registerField, fields }: FieldsTableProps) {
  const { t } = useTranslation('audience');
  const filterFields = useMemo(
    // Add an empty config as a placeholder for bounce rate reduction filter (index=0)
    () => [{} as FieldConfig, ...(fields?.fields.prospect || [])],
    [fields?.fields.prospect]
  );
  const noFieldsData = useMemo(() => checkNoData(fields), [fields]);

  const columns = useMemo<Array<HeadCell<FieldConfig>>>(
    () => [
      {
        title: t('detail.field'),
        dataIndex: 'fieldName',
        width: '45%',
        render: (field, row: FieldConfig, index: number) =>
          index === 0 ? (
            <FieldWithHelperText
              label={t('detail.bounceRateReduction.title')}
              helperText={t('detail.bounceRateReduction.helperText')}
            />
          ) : (
            field
          ),
      },
      {
        title: t('detail.filter'),
        dataIndex: 'type',
        width: '55%',
        align: 'left' as AlignTable,
        render: (type, row: FieldConfig, index: number) => {
          if (index === 0) {
            return <BounceRateReductionFilter registerField={registerField} />;
          }
          const formIdx = index - 1;
          return (
            <FilterForm
              key={`${AudienceExport.Prospect}_${formIdx}`}
              operator={fields?.operators}
              groupName={AudienceExport.Prospect}
              index={formIdx}
              rowValue={values[AudienceExport.Prospect][formIdx]}
              rowConfig={row}
              registerField={registerField}
            />
          );
        },
      },
    ],
    [registerField, t, values, fields?.operators]
  );

  if (noFieldsData) {
    return <NoDataAvailable />;
  }

  return (
    <Table
      columns={columns}
      dataSources={filterFields}
      tableContainerProps={{ sx: { flex: 1, flexBasis: 0 } }}
      tableProps={{ stickyHeader: true, sx: { tableLayout: 'fixed' } }}
      rowCount={filterFields?.length}
    />
  );
}

export function ProspectFiltersTab({ handleReset, ...props }: Readonly<ExportConfigProps>) {
  const { t } = useTranslation(['audience', 'translation']);

  const onHandleReset = () => {
    handleReset('bounceRateReduction');
    handleReset(AudienceExport.Prospect);
  };

  return (
    <AudienceTabLayout
      headerProps={{
        title: (
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
            <Typography variant="h6">{t('prospectFilters.title')}</Typography>
            <ResetButton onClick={onHandleReset} />
          </Stack>
        ),
      }}
      contentProps={{
        children: <FieldsTable {...props} />,
      }}
    />
  );
}
