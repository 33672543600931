import _ from 'lodash';
import { Campaign } from './selectors';
import { DefaultFilter, MetricType } from './types';

export const StatSummaryExportColumns = [
  'campaign',
  'leads',
  'opens',
  'clicks',
  'web_interactions',
  'cart_creations',
  'conversions',
] as const;

export const getAllCampaignOption = (t: (arg0: DefaultFilter) => any) => ({
  id: DefaultFilter.All,
  name: t(DefaultFilter.All),
});
export const DATE_FORMAT_EXCEL = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_METRIC_VALUE = ['Checkouts'];

export const GeoLocationDetailExportColumns = ['campaign', 'latitude', 'longitude', 'field', 'value'] as const;

export const RegionLocationDetailExportColumns = ['campaign', 'region', 'field', 'value'] as const;

export const GroupAnalysisExportColumns = ['campaign', 'demographic', 'field', 'value'] as const;

export const RemovedMetrics: MetricType[] = [MetricType.Emails, MetricType.Bounces, MetricType.Checkouts];

export const CombinedMetrics: Record<string, MetricType[]> = {
  [MetricType.OptOuts]: [MetricType.Unsubscribes, MetricType.MarkedSpam],
};

export const getMetricsParam = (metric?: string): string | undefined => {
  if (metric && CombinedMetrics[metric]) {
    return CombinedMetrics[metric].join(',');
  }

  return metric;
};

export const getMetricsArray = (metric?: string): string[] | null => {
  if (!metric) {
    return null;
  }
  if (metric && CombinedMetrics[metric]) {
    return CombinedMetrics[metric];
  }
  return [metric];
};

export const modifyDuplicateNames = (campaignData: Campaign[]): Campaign[] => {
  const nameCount: Record<string, number> = {};
  return _.chain(campaignData)
    .map((item) => {
      let modifyName = item.name.trim();
      nameCount[modifyName] = (nameCount[modifyName] ?? 0) + 1;
      if (nameCount[modifyName] > 1) {
        modifyName = `${modifyName} (${nameCount[modifyName]})`;
      }
      return {
        ...item,
        name: modifyName,
      };
    })
    .sortBy(['name'])
    .value();
};
