import { Box, Button, Link, Stack, ThemeProvider, styled } from '@mui/material';
import { FontAwesomeIcon, themeLevel5 } from 'common-components';
import { useRef } from 'react';
import { withSuspense } from 'react-suspenser';
import { ChatWindow, ChatbotIcon } from './chatwindow';
import { useChatbot } from './context';

const ChatbotContainer = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(3),
  bottom: theme.spacing(3),
  zIndex: theme.zIndex.modal,
}));
const CloseButton = styled(Button)(({ theme }) => ({
  minWidth: theme.typography.pxToRem(60),
  height: theme.typography.pxToRem(60),
  borderRadius: '50%',
  marginRight: theme.spacing(5),
}));

export const ChatbotLauncher = () => {
  const { open, onOpen, onClose } = useChatbot();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleOpen = () => {
    onOpen(buttonRef.current);
  };

  return (
    <Box data-testid="chatbot-launcher">
      <CloseButton
        sx={{
          visibility: open ? 'visible' : 'hidden',
        }}
        variant="contained"
        onClick={onClose}
        ref={buttonRef}
      >
        <FontAwesomeIcon size="xl" icon={['fal', 'xmark-large']} />
      </CloseButton>
      {!open && (
        <Link component="button" onClick={handleOpen}>
          <ChatbotIcon />
        </Link>
      )}
    </Box>
  );
};

export const Chatbot = withSuspense()(() => {
  const { enabled } = useChatbot();

  if (!enabled) return null;
  return (
    <ThemeProvider theme={themeLevel5}>
      <ChatbotContainer>
        <ChatWindow />
        <ChatbotLauncher />
      </ChatbotContainer>
    </ThemeProvider>
  );
});
