import { CircularProgress, Stack, Typography } from '@mui/material';
import { NestedRecord, useDebounce } from 'common-components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { AudienceScheduleForm, FilterFormValues } from '../types';
import { useAudience } from './context';
import { useProspectCountAPI } from './selectors';

export const UPDATING_PROSPECT_COUNT_DEBOUNCE_TIME_IN_MS = 1000;

interface ProspectCountProps {
  selectedSchedule: AudienceScheduleForm | null;
  errors: NestedRecord<FilterFormValues, string>;
  values: FilterFormValues;
  onChangeScheduleId: (id: string) => void;
}

export const ProspectCount = ({ values, errors, selectedSchedule, onChangeScheduleId }: ProspectCountProps) => {
  const { t } = useTranslation(['audience']);
  const { schedules } = values;
  const { refreshProspectCount } = useAudience();
  const [prospectCount] = useProspectCountAPI();
  const audCtx = useAudience();

  const nextScheduleEnabled = selectedSchedule && schedules.length > 1;

  useDebounce(
    () => {
      const hasError = !_.isEmpty(_.omit(errors, 'name'));
      refreshProspectCount(values, audCtx.dateRange, hasError);
    },
    [values, errors, audCtx.dateRange],
    UPDATING_PROSPECT_COUNT_DEBOUNCE_TIME_IN_MS
  );

  const handleCycleSelectSchedule = () => {
    const currIdx = schedules.findIndex((schedule) => schedule.__internalId === selectedSchedule?.__internalId);
    const next = currIdx > -1 ? _.get(schedules, currIdx + 1, schedules[0]) : schedules[0];
    onChangeScheduleId(next.__internalId);
  };

  return (
    <Stack
      data-testid="prospectcount"
      sx={{ cursor: nextScheduleEnabled ? 'pointer' : 'default' }}
      onClick={() => nextScheduleEnabled && handleCycleSelectSchedule()}
    >
      {selectedSchedule && (
        <Typography variant="body4">
          {selectedSchedule.connectionName} {nextScheduleEnabled ? `(${t('schedule.click_to_cycle')})` : ''}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" justifyContent="start" columnGap={4}>
        <Typography variant="h5">
          {prospectCount.requesting ? (
            <CircularProgress color="success" size={20} />
          ) : (
            <Typography variant="h5" component="span" color="success.main" fontWeight="bold">
              {prospectCount.data?.count.toLocaleString() ?? '--'}
            </Typography>
          )}{' '}
          {t('detail.prospects')}
        </Typography>
      </Stack>
    </Stack>
  );
};
