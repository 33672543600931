import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { ConfirmDialog } from '../common';
import { themeLevel3 } from '../config/theme';
import { useGA4 } from '../hooks';
import { useUserSessionAPI } from '../selectors';
import { logoutSession } from '../utils';
var SESSION_EXPIRATION_KEY = 'session_expiration';
export var SessionExpiredDialog = withSuspense()(function () {
    var _a;
    var _b = useGA4(), putEvent = _b[2];
    var t = useTranslation().t;
    var timeoutHandlerRef = useRef();
    var _c = useState(false), show = _c[0], setShow = _c[1];
    var userSessionData = useUserSessionAPI()[0];
    var setSessionTimeout = useCallback(function (sessionExp) {
        clearTimeout(timeoutHandlerRef.current);
        var duration = (sessionExp - moment().unix()) * 1000;
        if (duration > 0) {
            setShow(false);
            timeoutHandlerRef.current = setTimeout(function () {
                setShow(true);
            }, duration);
        }
        else {
            setShow(true);
        }
    }, []);
    useEffect(function () {
        var _a;
        var sessionExp = (_a = userSessionData.data) === null || _a === void 0 ? void 0 : _a.sessionExp;
        if (sessionExp) {
            setSessionTimeout(sessionExp);
            localStorage.setItem(SESSION_EXPIRATION_KEY, sessionExp.toString());
        }
        var syncStore = function (ev) {
            if (ev.key === SESSION_EXPIRATION_KEY) {
                var newSessionExp = Number(ev.newValue) || 0;
                setSessionTimeout(newSessionExp);
            }
        };
        window.addEventListener('storage', syncStore);
        return function () {
            clearTimeout(timeoutHandlerRef.current);
            window.removeEventListener('storage', syncStore);
        };
    }, [setSessionTimeout, (_a = userSessionData.data) === null || _a === void 0 ? void 0 : _a.sessionExp]);
    return (_jsx(ConfirmDialog, { open: show, title: t('title.sessionExpired'), message: t('text.sessionExpiredContent'), confirmButtonText: t('button.signIn'), onConfirm: function () { return logoutSession(putEvent); }, confirmButtonProps: { color: 'primary' }, dialogProps: {
            baseTheme: themeLevel3,
            componentsProps: {
                backdrop: {
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.8)', backdropFilter: 'blur(0.25rem)' },
                },
            },
        } }));
});
