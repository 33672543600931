import { Card, CardContent, CardHeader, checkNoData } from 'common-components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AudienceExportType, FilterFieldId, Operators } from '../../audienceexport/enums';
import { FilterItem } from '../../audienceexport/types';
import { convertLabelsPieChart } from '../constants';
import { useEngagementAction, useVisitorEngagementAPI } from '../selectors';
import { EngagementPieChart } from './piechart';

interface FilterByLabel {
  match: (label: string) => boolean;
  getFilters: (label: string) => FilterItem[];
}

const filterByLabels: FilterByLabel[] = [
  {
    match: (label) => label === '1',
    getFilters: () => [
      {
        fieldId: FilterFieldId.Visits,
        operator: Operators.Equal,
        values: [1],
      },
    ],
  },
  {
    match: (label) => label.indexOf('-') > -1,
    getFilters: (label: string) => {
      const range = label.split('-');
      if (range.length !== 2) return [];
      const [min, max] = range;
      return [
        {
          fieldId: FilterFieldId.Visits,
          operator: Operators.Between,
          values: [Number(min.trim()), Number(max.trim())],
        },
      ];
    },
  },
  {
    match: (label) => label.indexOf('+') > -1,
    getFilters: (label: string) => {
      const value = label.slice(0, label.indexOf('+')).trim();
      return [
        {
          fieldId: FilterFieldId.Visits,
          operator: Operators.MoreThan,
          values: [Number(value) - 1],
        },
      ];
    },
  },
];

export const VisitorEngagement = () => {
  const { t } = useTranslation(['webresolution', 'translation']);
  const [visitorData, fetchChartData, clearChartData] = useVisitorEngagementAPI();

  const chartData = useMemo(
    () =>
      visitorData.data
        ?.filter((item) => item.value)
        ?.map((item: { label: string; value: number }) => ({
          ...item,
          label: convertLabelsPieChart(item.label, t),
          filters: filterByLabels.find((v) => v.match(item.label))?.getFilters(item.label) ?? [],
        })) || [],
    [t, visitorData.data]
  );

  const isNoData = useMemo(() => checkNoData(chartData), [chartData]);
  const action = useEngagementAction(AudienceExportType.VisitorEngagement, isNoData);

  useEffect(() => {
    fetchChartData(undefined, true);
    return clearChartData;
  }, [clearChartData, fetchChartData]);

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader title={t('visitorEngagement.title')} titleTruncate action={action} />
      <CardContent>
        <EngagementPieChart
          chartData={chartData}
          isNoData={isNoData}
          requesting={!!visitorData.requesting}
          exportType={AudienceExportType.VisitorEngagement}
        />
      </CardContent>
    </Card>
  );
};
