var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, ListItemIcon, MenuItem } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { Card, CardContent, ConfirmDialog, ErrorMessageQueueBox, FallbackComponent, MessageDialog, RowMenu, Table, } from '../common';
import { AUDIT_EVENTS, GA_EVENTS } from '../constants';
import { Site, SiteContextValue } from '../context';
import { IdRepresentation } from '../enum';
import { useAudit, useGA4, useTimeOut } from '../hooks';
import { useDeleteUserAPI, useResetUserPasswordAPI, useUpsertUserAPI, useUserDetailAPI, useUserSessionAPI, } from '../selectors';
import { UserModifier } from '../usermodifier';
import { UsersManagementHeader } from './header';
import { useUsersAPI } from './selectors';
var UserActionMenu = withSuspense()(function (_a) {
    var id = _a.id, email = _a.email, setUserId = _a.setUserId, requestDelete = _a.requestDelete;
    var t = useTranslation('translation').t;
    var _b = useContext(SiteContextValue).site, site = _b === void 0 ? Site.Portal : _b;
    var isAdminSide = site === Site.Admin;
    var user = useUserSessionAPI()[0].data;
    var _c = useResetUserPasswordAPI(), fetchResetUserPasswordData = _c[1];
    var _d = useState(false), showConfirmDialog = _d[0], setShowConfirmDialog = _d[1];
    var handleResetPassword = function () {
        fetchResetUserPasswordData({ id: id });
        setShowConfirmDialog(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ConfirmDialog, { title: t('title.confirmResetPassword', { ns: 'translation' }), message: t('text.adminResetPasswordConfirmMessage', { email: email, ns: 'translation' }), confirmButtonText: t('button.reset', { ns: 'translation' }), abortButtonText: t('button.cancel', { ns: 'translation' }), open: showConfirmDialog, onConfirm: handleResetPassword, onAbort: function () { return setShowConfirmDialog(false); } }), isAdminSide ? (_jsxs(RowMenu, { children: [_jsxs(MenuItem, __assign({ onClick: function () { return setUserId(id); } }, { children: [_jsx(ListItemIcon, { children: _jsx(FontAwesomeIcon, { fontSize: "1.375rem", icon: ['fal', 'pen'] }) }), t('button.manage')] })), _jsxs(MenuItem, __assign({ onClick: function () { return setShowConfirmDialog(true); } }, { children: [_jsx(ListItemIcon, { children: _jsx(FontAwesomeIcon, { fontSize: "1.375rem", icon: ['fal', 'key'] }) }), t('button.resetPassword')] })), _jsxs(MenuItem, __assign({ onClick: function () { return requestDelete(id); } }, { children: [_jsx(ListItemIcon, { children: _jsx(FontAwesomeIcon, { fontSize: "1.375rem", icon: ['fal', 'circle-minus'] }) }), t('button.delete')] }))] })) : (_jsxs(RowMenu, { children: [_jsx(MenuItem, __assign({ onClick: function () { return setUserId(id); } }, { children: t('button.manage') })), _jsx(MenuItem, __assign({ disabled: (user === null || user === void 0 ? void 0 : user.id) === id, onClick: function () { return requestDelete(id); } }, { children: t('button.delete') }))] }))] }));
});
var UsersTable = withSuspense()(function (props) {
    var usersData = useUsersAPI()[0];
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var columns = useMemo(function () { return [
        {
            title: t('fieldName.name'),
            dataIndex: 'firstName',
            sortKey: 'firstName',
            sortFunc: function (row) {
                return row.firstName ? [row.lastName, row.firstName].join(', ') : t('text.notProvided', { ns: 'translation' });
            },
            render: function (firstName, u) { return (_jsx(Link, __assign({ component: "button", underline: "none", color: "inherit", variant: "body2", onClick: function () { return props.setUserId(u.id); } }, { children: firstName ? [u.lastName, firstName].join(', ') : t('text.notProvided', { ns: 'translation' }) }))); },
        },
        {
            title: t('fieldName.email'),
            dataIndex: 'email',
            sortKey: 'email',
            sortFunc: function (row) { return row.email; },
        },
        {
            title: t('fieldName.role'),
            dataIndex: 'role',
            sortKey: 'roleName',
            sortFunc: function (row) { var _a; return (_a = row.role.name) !== null && _a !== void 0 ? _a : ''; },
            render: function (role) { return role.name; },
        },
        {
            title: t('fieldName.lastLogin'),
            dataIndex: 'lastLogin',
            sortKey: 'lastLogin',
            sortFunc: function (row) { var _a; return (_a = row.lastLogin) !== null && _a !== void 0 ? _a : ''; },
            render: function (lastLogin) {
                return lastLogin
                    ? t('dateTimeFormat.longDateTime', { val: moment(lastLogin).toDate(), ns: 'translation' })
                    : t('text.never', { ns: 'translation' });
            },
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'right',
            render: function (data, u) { return _jsx(UserActionMenu, __assign({ id: data, email: u.email }, props)); },
            hideSortIcon: true,
        },
    ]; }, [props, t]);
    return (_jsx(Table, { columns: columns, dataSources: usersData.data, tableContainerProps: { sx: { flex: 1, flexBasis: 0 } }, tableProps: { stickyHeader: true }, sortable: true, order: 'asc', orderBy: 'firstName' }));
});
export var UsersManagement = withSuspense()(function () {
    var _a, _b;
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _c = useState(IdRepresentation.EMPTY), userId = _c[0], setUserId = _c[1];
    var _d = useState(IdRepresentation.EMPTY), deletedUserId = _d[0], setDeletedUserId = _d[1];
    var _e = useState(false), showConfirmDelete = _e[0], setShowConfirmDelete = _e[1];
    var _f = useUserDetailAPI(userId), userDetailData = _f[0], fetchUserDetailData = _f[1], clearUserDetailData = _f[2];
    var _g = useUpsertUserAPI(), userUpsertData = _g[0], fetchUserUpsertData = _g[1], clearUserUpsertData = _g[2];
    var _h = useDeleteUserAPI(deletedUserId), userDeleteData = _h[0], fetchUserDeleteData = _h[1], clearUserDeleteData = _h[2];
    var _j = useResetUserPasswordAPI(), resetUserPasswordData = _j[0], clearResetUserPasswordData = _j[2];
    var _k = useUsersAPI(), usersData = _k[0], fetchUsers = _k[1], clearFetchUsers = _k[2];
    var userSessionData = useUserSessionAPI()[0];
    var _l = useTimeOut(), setTimeOut = _l[1];
    var _m = useGA4(), putEvent = _m[2];
    var sendAuditEvent = useAudit()[0];
    useEffect(function () {
        fetchUsers();
        sendAuditEvent({ action: AUDIT_EVENTS.accountManagement.users.view });
        return function () {
            clearFetchUsers();
            clearResetUserPasswordData();
        };
    }, [fetchUsers, clearFetchUsers, clearResetUserPasswordData, sendAuditEvent]);
    useEffect(function () {
        if (userId > 0) {
            fetchUserDetailData();
        }
    }, [fetchUserDetailData, userId]);
    useEffect(function () {
        if (!userId) {
            clearUserDetailData();
            clearUserUpsertData();
            clearUserDeleteData();
        }
    }, [clearUserDeleteData, clearUserDetailData, clearUserUpsertData, userId]);
    useEffect(function () {
        if (userUpsertData.data || userDeleteData.data) {
            fetchUsers({});
            setUserId(IdRepresentation.EMPTY);
            setDeletedUserId(IdRepresentation.EMPTY);
        }
    }, [userUpsertData.data, userDeleteData.data, fetchUsers]);
    useEffect(function () {
        var _a, _b;
        if ((!userDetailData.requesting && ((_a = userDetailData.error) === null || _a === void 0 ? void 0 : _a.type) === 'common.notExist') ||
            (!userDeleteData.requesting && ((_b = userDeleteData.error) === null || _b === void 0 ? void 0 : _b.type) === 'common.notExist'))
            setTimeOut(function () {
                fetchUsers({});
                setUserId(IdRepresentation.EMPTY);
            }, 5000);
    }, [
        userDetailData.error,
        userDetailData.requesting,
        userDeleteData.error,
        userDeleteData.requesting,
        setTimeOut,
        fetchUsers,
    ]);
    var messageResetPassword = useMemo(function () {
        var _a;
        if (resetUserPasswordData.data)
            return t('text.adminResetPasswordSuccess', { email: resetUserPasswordData.data.email, ns: 'translation' });
        if (resetUserPasswordData.error)
            return t('text.adminResetPasswordFail', { email: (_a = resetUserPasswordData.error.data) === null || _a === void 0 ? void 0 : _a.email, ns: 'translation' });
        return undefined;
    }, [resetUserPasswordData.data, resetUserPasswordData.error, t]);
    var handleSubmit = function (castedValues) {
        fetchUserUpsertData(castedValues);
        if (userId === IdRepresentation.ADD_MODE) {
            putEvent({ category: GA_EVENTS.accountManagement.__name, action: GA_EVENTS.accountManagement.addUser });
        }
        else {
            putEvent({ category: GA_EVENTS.accountManagement.__name, action: GA_EVENTS.accountManagement.editUser });
        }
    };
    var handleCancel = function () {
        setUserId(IdRepresentation.EMPTY);
    };
    var requestDelete = function (id) {
        setDeletedUserId(id);
        setShowConfirmDelete(true);
    };
    var handleDelete = function () {
        fetchUserDeleteData();
        setShowConfirmDelete(false);
        putEvent({ category: GA_EVENTS.accountManagement.__name, action: GA_EVENTS.accountManagement.deleteUser });
    };
    var handleAbort = function () {
        setShowConfirmDelete(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Card, __assign({ style: { height: '100%', flex: 1, position: 'relative' } }, { children: _jsxs(FallbackComponent, __assign({ requesting: !userId && (!!userDeleteData.requesting || !!resetUserPasswordData.requesting || !!usersData.requesting), overlay: true }, { children: [_jsx(UsersManagementHeader, { onAddUserClick: function () { return setUserId(IdRepresentation.ADD_MODE); } }), _jsxs(CardContent, __assign({ sx: { flex: 1, position: 'relative', flexDirection: 'column' } }, { children: [_jsx(ErrorMessageQueueBox, { errors: [!userId ? userDeleteData.error : undefined], autoDismiss: true }), _jsx(UsersTable, { setUserId: setUserId, requestDelete: requestDelete })] }))] })) })), _jsx(UserModifier, { open: userId !== IdRepresentation.EMPTY, addMode: userId === IdRepresentation.ADD_MODE, userDetail: userDetailData.data, externalErrors: [userDetailData.error, userUpsertData.error, userDeleteData.error], requesting: !!(userDetailData.requesting || userUpsertData.requesting || userDeleteData.requesting), onSubmit: handleSubmit, onCancel: handleCancel, onDelete: function () { return requestDelete(userId); }, isOwner: ((_a = userDetailData.data) === null || _a === void 0 ? void 0 : _a.id) === ((_b = userSessionData.data) === null || _b === void 0 ? void 0 : _b.id) }), _jsx(ConfirmDialog, { title: t('title.confirmDelete', { ns: 'translation' }), message: t('text.deleteConfirmMessage', { ns: 'translation' }), confirmButtonText: t('button.delete', { ns: 'translation' }), abortButtonText: t('button.cancel', { ns: 'translation' }), open: showConfirmDelete, onConfirm: handleDelete, onAbort: handleAbort }), _jsx(MessageDialog, { message: messageResetPassword, confirmButtonText: t('button.ok', { ns: 'translation' }), dialogProps: { maxWidth: 'xs' }, onConfirm: clearResetUserPasswordData })] }));
});
