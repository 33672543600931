var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, styled } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
var HeaderSection = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(3),
        paddingTop: theme.spacing(8.25),
        paddingBottom: theme.spacing(6),
        paddingInline: theme.spacing(7.75),
    });
});
export var Header = withSuspense()(function (props) {
    var _a;
    var t = useTranslation(['header', 'translation']).t;
    return (_jsxs(HeaderSection, __assign({ className: "header-section" }, { children: [_jsxs(Box, __assign({ mr: "auto" }, { children: [_jsx(Typography, __assign({ "data-testid": "title", variant: "h1", color: (_a = props.color) !== null && _a !== void 0 ? _a : 'success.main' }, { children: t("pageName.".concat(props.title)) })), !props.noAction && props.lastRefreshedDate && (_jsx(Typography, __assign({ "data-testid": "last-refreshed-date", variant: "body3" }, { children: "".concat(t('latestData'), " ").concat(moment(props.lastRefreshedDate).format("".concat(t('dateTimeFormat.longDate', { ns: 'translation' })))) })))] })), !props.noAction && props.children] })));
});
