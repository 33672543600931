import { Box, FormControl, MenuItem, SelectChangeEvent, Stack, styled, useTheme } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
  FullscreenHandler,
  GA_EVENTS,
  PlotComponent,
  Select,
  SessionActive,
  useActiveFullscreenSession,
  useGA4,
} from 'common-components';
import _ from 'lodash';
import { Data, Layout } from 'plotly.js';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { CompanyGeographyRegion } from './companygeoregions';
import { AnalyzerSessionName } from './constants';
import { useGeoViewModeState, useTrafficInsightsGeoMappingsAPI } from './selectors';
import { AnalyzerType, ContentType, GeographyViewMode } from './types';

const StyledMapboxPlotly = styled(PlotComponent)({
  '.mapboxgl-control-container': {
    display: 'none',
  },
});

const StyledCard = styled(Card)({
  flex: 1,
});

export interface GeographyProps {
  analyzerType: AnalyzerType;
  contentType: ContentType;
  zoom?: number;
}

const MapboxChart = withSuspense()(({ contentType, analyzerType, zoom = 2 }: GeographyProps) => {
  const { t } = useTranslation(['trafficinsightdialog', 'webresolution', 'translation']);
  const theme = useTheme();
  const [trafficGeoData, fetchTrafficGeoData, clearTrafficGeoData] = useTrafficInsightsGeoMappingsAPI(
    contentType,
    analyzerType
  );

  useEffect(() => {
    fetchTrafficGeoData(undefined, true);
    return clearTrafficGeoData;
  }, [clearTrafficGeoData, fetchTrafficGeoData]);

  const { lat, lon, count } = useMemo(() => {
    const _lat: Array<string> = [];
    const _lon: Array<string> = [];
    const _count: Array<number> = [];
    trafficGeoData.data?.forEach((g) => {
      _lat.push(g.latitude);
      _lon.push(g.longitude);
      _count.push(g.count);
    });
    return { lat: _lat, lon: _lon, count: _count };
  }, [trafficGeoData.data]);

  const layout = useMemo<Partial<Layout>>(
    () => ({
      margin: { l: 0, r: 0, b: 0, t: 0, pad: 0 },
      mapbox: {
        style: 'dark',
        accesstoken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        center: {
          lon: -98,
          lat: 38.5,
        },
        zoom,
      },
    }),
    [zoom]
  );

  const data = useMemo<Data[]>(
    () => [
      {
        type: 'scattermapbox',
        lat,
        lon,
        customdata: [[], [...count]],
        hoverinfo: 'none',
        marker: {
          size: count,
          color: theme.palette.primary.main,
          opacity: 0.5,
        },
      },
    ],
    [count, lat, lon, theme.palette.primary.main]
  );
  return (
    <StyledMapboxPlotly
      data={data}
      layout={layout}
      templatetooltip={(__: any, value: any) =>
        t('template.chartTooltipNoLabel', {
          value,
          unitLabel: { name: 'visitors', ns: 'webresolution' },
          ns: 'translation',
        })
      }
      requesting={trafficGeoData.requesting}
      isNoData={!(lat.length && lon.length)}
    />
  );
});
type GATrafficChartTracking = {
  [key in ContentType]: { action: string };
} & {
  element: React.FC<GeographyProps>;
  category: string;
};
const GeographyChart: Record<GeographyViewMode, GATrafficChartTracking> = {
  [GeographyViewMode.Map]: {
    element: MapboxChart,
    category: GA_EVENTS.websiteResolutionDashboard.__name,
    [ContentType.B2C]: {
      action: GA_EVENTS.websiteResolutionDashboard.b2cTrafficInsightChangeGeoToMap,
    },
    [ContentType.B2B]: {
      action: GA_EVENTS.websiteResolutionDashboard.b2bTrafficInsightChangeGeoToMap,
    },
  },
  [GeographyViewMode.Regions]: {
    element: CompanyGeographyRegion,
    category: GA_EVENTS.websiteResolutionDashboard.__name,
    [ContentType.B2C]: {
      action: GA_EVENTS.websiteResolutionDashboard.b2cTrafficInsightChangeGeoToRegions,
    },
    [ContentType.B2B]: {
      action: GA_EVENTS.websiteResolutionDashboard.b2bTrafficInsightChangeGeoToRegions,
    },
  },
};

export const Geography: React.FC<GeographyProps> = withSuspense()((props) => {
  const theme = useTheme();
  const { t } = useTranslation('trafficinsightdialog');
  const [viewMode, setViewMode] = useGeoViewModeState(props.analyzerType);
  const [, , putEvent] = useGA4();
  const ChartComponent = useMemo(() => viewMode && GeographyChart[viewMode].element, [viewMode]);
  const [activeFullscreenSession] = useActiveFullscreenSession();
  const isFullscreen =
    !!activeFullscreenSession &&
    ![SessionActive.EnrichedB2B, SessionActive.EnrichedB2C].includes(activeFullscreenSession as SessionActive);

  const handleChangeViewMode = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    putEvent({
      category: GeographyChart[value as GeographyViewMode].category,
      action: GeographyChart[value as GeographyViewMode][props.contentType].action,
    });
    setViewMode(value);
  };

  return (
    <StyledCard data-testid="geography-card" disabledShadow={!isFullscreen}>
      <CardHeader
        sx={{ ...(!isFullscreen && { paddingTop: 0 }) }}
        title={t('demographic.' + props.analyzerType)}
        action={
          <Box marginLeft="0.3rem" margin="none">
            <Stack direction="row" alignItems="center">
              <FormControl sx={{ minWidth: theme.typography.pxToRem(110) }}>
                <Select value={viewMode ?? ''} onChange={handleChangeViewMode}>
                  {_.map(GeographyViewMode, (value) => (
                    <MenuItem value={value} key={value}>
                      {t(value)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FullscreenHandler sessionName={AnalyzerSessionName[props.analyzerType]} />
            </Stack>
          </Box>
        }
      />
      <CardContent
        sx={{
          ...(!isFullscreen && viewMode === GeographyViewMode.Regions && { padding: 3, paddingBottom: '0 !important' }),
        }}
      >
        {ChartComponent && (
          <ChartComponent analyzerType={props.analyzerType} contentType={props.contentType} zoom={props.zoom} />
        )}
      </CardContent>
    </StyledCard>
  );
});
