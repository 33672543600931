import { GA_EVENTS } from 'common-components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DateSelectorDependency, useDateSelectorBindingAPI, useDateSelectorBindingExcelAPI } from '../../../selectors';
import { DateRangeStateEnum } from '../../../state/app/appSlice';
import { useCampaignState, useLookupCampaignsAPI } from '../selectors';
import { MetricType } from '../types';

export interface SlatSummaryCount {
  cart_creations: number | undefined;
  clicks: number | undefined;
  conversions: number | undefined;
  leads: number | undefined;
  opens: number | undefined;
  web_interactions: number | undefined;
}

interface StatSummaryMetric {
  metric: string;
  count: number;
}

export const useStatSummaryAPI = () => {
  const [campaignIds] = useCampaignState();
  const [campaigns] = useLookupCampaignsAPI();

  return useDateSelectorBindingAPI<StatSummaryMetric[], Record<string, StatSummaryMetric & { percent: number }>>({
    depend: DateSelectorDependency.P360StatSummary,
    params: {
      campaign: !_.isEmpty(campaigns.data) ? campaignIds?.join(',') : '',
    },
    _parseData: (data) => {
      const dataKeyBy = _.keyBy(data, 'metric');
      return _.chain(dataKeyBy)
        .omit(MetricType.Emails)
        .mapValues((value) => ({ ...value, percent: value.count / dataKeyBy[MetricType.Emails]?.count }))
        .value();
    },
    dateRangeState: DateRangeStateEnum.Campaign360,
  });
};

export const useStatSummaryExcelData = () => {
  const { t } = useTranslation('360analysis');
  const fileName = t('statSummary');
  const [campaignIds] = useCampaignState();
  const gaEvents = {
    category: GA_EVENTS._360InsightsDashboard.__name,
    action: GA_EVENTS._360InsightsDashboard.exportStatSummaryToExcel,
  };
  return useDateSelectorBindingExcelAPI(
    DateSelectorDependency.P360StatSummaryExportExcel,
    {
      campaign: campaignIds?.join(','),
    },
    gaEvents,
    fileName,
    DateRangeStateEnum.Campaign360
  );
};
