export var IdRepresentation;
(function (IdRepresentation) {
    IdRepresentation[IdRepresentation["ADD_MODE"] = -1] = "ADD_MODE";
    IdRepresentation[IdRepresentation["EMPTY"] = 0] = "EMPTY";
})(IdRepresentation || (IdRepresentation = {}));
export var ConnectionSource;
(function (ConnectionSource) {
    ConnectionSource["Trovo"] = "trovo";
    ConnectionSource["Klaviyo"] = "klaviyo";
    ConnectionSource["MailChimp"] = "mailchimp";
    ConnectionSource["LegacyTrovo"] = "legacyTrovo";
    ConnectionSource["SendGrid"] = "sendgrid";
    ConnectionSource["Meta"] = "meta";
})(ConnectionSource || (ConnectionSource = {}));
export var FeatureFlagStatus;
(function (FeatureFlagStatus) {
    FeatureFlagStatus[FeatureFlagStatus["Limited"] = 0] = "Limited";
    FeatureFlagStatus[FeatureFlagStatus["Released"] = 1] = "Released";
})(FeatureFlagStatus || (FeatureFlagStatus = {}));
export var AccessFeatureFlag;
(function (AccessFeatureFlag) {
    AccessFeatureFlag["MailChimpConnector"] = "MAILCHIMP_ACCESS";
})(AccessFeatureFlag || (AccessFeatureFlag = {}));
export var KeyAccess;
(function (KeyAccess) {
    KeyAccess["ReadOnly"] = "Read Only";
    KeyAccess["Write"] = "Write Audience Data";
})(KeyAccess || (KeyAccess = {}));
