import {
  IUserMenu,
  Navbar as NavbarComponent,
  OrganizationStatus,
  logoutSession,
  useActiveFullscreenSession,
  useGA4,
  usePrintParam,
  useUserSessionAPI,
} from 'common-components';
import { useMemo } from 'react';
import { withSuspense } from 'react-suspenser';
import { useDefaultPageUrl } from '../../selectors/page';
import { PAGE_ITEMS, USER_MENU, UserNameMenu } from './constants';

export const Navbar = withSuspense()(() => {
  const [, , putEvent] = useGA4();
  const defaultPageUrl = useDefaultPageUrl();
  const [printPDF] = usePrintParam();
  const [userSessionData] = useUserSessionAPI();
  const [activeFullscreenSession] = useActiveFullscreenSession();
  const isOnboarding =
    !userSessionData.data?.organization || userSessionData.data?.organization.status === OrganizationStatus.Onboarding;

  const userMenu = useMemo(() => {
    const menu: Record<string, IUserMenu> = { ...USER_MENU };
    if (userSessionData.data?.superUser) {
      delete menu.signOut;
    }
    return menu;
  }, [userSessionData.data]);

  const handleSignOut = () => {
    // clear session storage when logout
    sessionStorage.removeItem('persist:ms-portal-state');
    logoutSession(putEvent);
  };

  if (printPDF) return null;
  return (
    <NavbarComponent
      defaultUrl={defaultPageUrl}
      pageItems={PAGE_ITEMS}
      noAction={isOnboarding}
      hiddenItems={!!activeFullscreenSession}
      userMenuProps={{
        doActionMenu: {
          [UserNameMenu.SignOut]: handleSignOut,
        },
        options: userMenu,
      }}
    />
  );
});
