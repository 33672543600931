import { Box, Grid, Paper, PaperProps, Stack, SxProps, Theme, Typography, styled } from '@mui/material';
import { themeLevel5 } from 'common-components';

const StyledPaper = styled(Paper)<PaperProps>(({ theme, color }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  borderLeft: `solid ${theme.typography.pxToRem(10)} ${color}`,
  padding: theme.spacing(3, 4),
  position: 'relative',
  minHeight: '10rem',
  backgroundColor: themeLevel5.palette.background.paper,
}));

export const Layout = ({
  header,
  body,
  sx,
}: {
  header: string | JSX.Element | JSX.Element[] | null;
  body: JSX.Element | JSX.Element[];
  sx?: SxProps<Theme>;
}) => {
  return (
    <Grid className="header" sx={sx} container spacing={1.5} flex={1} display="flex" flexDirection="column">
      <Grid item>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {typeof header === 'string' ? <Typography variant="subtitle2">{header}</Typography> : header}
        </Box>
      </Grid>
      <Grid className="body" item display={'flex'} flex={1}>
        <StyledPaper elevation={2}>
          <Stack className="body__container" height="100%" flex={1}>
            {body}
          </Stack>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};
