import { styled, useTheme } from '@mui/material';
import {
  CardContent,
  CardHeader,
  ChartMenu,
  FallbackComponent,
  PlotComponent,
  PlotProps,
  SessionActive,
  checkNoData,
  formatValue,
  generateCountPercentAxisTicks,
  getMaxLength,
} from 'common-components';
import _ from 'lodash';
import { PlotData } from 'plotly.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { StyledItemCard } from '../styled';
import { useStatSummaryAPI, useStatSummaryExcelData } from './selectors';

const StyledPlot = styled(PlotComponent)({
  '.overplot': {
    display: 'none',
  },
});

export const StatSummary = withSuspense()(() => {
  const { t } = useTranslation(['360analysis', 'translation']);
  const [statSummaryData, fetchStatSummaryData, clearStatSummaryData] = useStatSummaryAPI();
  const [exportedData, fetchExcelExport] = useStatSummaryExcelData();
  const [chartHeight, setChartHeight] = useState(0);
  const theme = useTheme();

  const onResize = useCallback<Required<PlotProps>['onResize']>((clientRect) => {
    setChartHeight(clientRect?.height ?? 0);
  }, []);

  const isNoData = useMemo(() => checkNoData(statSummaryData.data), [statSummaryData.data]);

  const [chartData, maxPercent, maxCount] = useMemo<[Partial<PlotData>, number, number]>(() => {
    const values = Object.values(statSummaryData.data || {});
    const maxValue = _.maxBy(values, 'count');
    const x = Object.keys(statSummaryData.data || {});
    const y = values.map((v) => v.count || 0);
    const text = y.map((v) => formatValue(v, { ignoreNotation: 4 }));

    return [{ x, y, text, customdata: [x, y] }, maxValue?.percent ?? 0, maxValue?.count ?? 0];
  }, [statSummaryData.data]);

  const [percentAxis, countAxis] = useMemo(() => {
    return generateCountPercentAxisTicks(maxPercent, maxCount, {
      availableSpace: chartHeight,
      minStepSpace: 40,
      maxStepSpace: 100,
      numberOfStep: 5,
      maxNumberOfStep: 6,
    });
  }, [chartHeight, maxCount, maxPercent]);

  useEffect(() => {
    fetchStatSummaryData(undefined, true);
    return () => {
      clearStatSummaryData();
    };
  }, [clearStatSummaryData, fetchStatSummaryData]);
  return (
    <FallbackComponent overlay requesting={exportedData.requesting === true}>
      <StyledItemCard>
        <CardHeader
          title={t('statSummary')}
          titleTruncate
          action={
            <ChartMenu fsSession={SessionActive.StatSummary} onExcelExport={fetchExcelExport} isNoData={isNoData} />
          }
        />
        <CardContent>
          <StyledPlot
            data={[
              {
                x: chartData.x,
                y: [maxPercent],
                showlegend: false,
                type: 'bar',
                hoverinfo: 'none',
                yaxis: 'y2',
              },
              {
                ...chartData,
                type: 'bar',
                textposition: 'auto',
                showlegend: false,
                marker: {
                  color: theme.palette.primary.main,
                },
                hoverinfo: 'none',
                yaxis: 'y',
                ...{ insidetextanchor: 'middle' },
              },
            ]}
            layout={{
              margin: { r: 10 + 7 * getMaxLength(percentAxis.ticktext) },
              yaxis: { showgrid: true, ...countAxis },
              yaxis2: {
                showgrid: false,
                tickcolor: 'transparent',
                side: 'right',
                position: 1,
                overlaying: 'y',
                ticks: 'outside',
                ...percentAxis,
              },
              dragmode: false,
            }}
            templatetooltip={(label, value) =>
              t('template.chartTooltip', {
                label,
                value,
                unitLabel: { name: `unitLabel.${label}`, ns: '360analysis' },
                ns: 'translation',
              })
            }
            onResize={onResize}
            config={{ showAxisDragHandles: false }}
            requesting={statSummaryData.requesting ?? false}
            isNoData={isNoData}
          />
        </CardContent>
      </StyledItemCard>
    </FallbackComponent>
  );
});
