var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, ListItemIcon, MenuItem, Typography, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '../common';
import { useActiveFullscreenSession, useFullscreen } from '../fullscreen';
import { usePrintParam } from '../hooks';
import { excelExport } from '../utils';
var Dropdown = styled(IconButton)(function (_a) {
    var theme = _a.theme;
    return ({
        border: "1px solid ".concat(theme.palette.text.primary),
        borderRadius: theme.typography.pxToRem(theme.shape.borderRadius / 2),
    });
});
export var ChartMenu = function (_a) {
    var excelData = _a.excelData, fsSession = _a.fsSession, trafficItem = _a.trafficItem, prefixItems = _a.prefixItems, onExcelExported = _a.onExcelExported, onExcelExport = _a.onExcelExport, isNoData = _a.isNoData, exportExcelItemName = _a.exportExcelItemName, _b = _a.showExport, showExport = _b === void 0 ? true : _b;
    var _c = useState(), anchorMenu = _c[0], setAnchorMenu = _c[1];
    var handleFullScreen = useFullscreen()[0];
    var activeFSSession = useActiveFullscreenSession()[0];
    var printPDF = usePrintParam()[0];
    var t = useTranslation('chartmenu').t;
    var handleClose = function () {
        setAnchorMenu(null);
    };
    var handleExportExcel = useCallback(function () {
        if (excelData) {
            excelExport(excelData);
            onExcelExported === null || onExcelExported === void 0 ? void 0 : onExcelExported();
        }
        else {
            onExcelExport === null || onExcelExport === void 0 ? void 0 : onExcelExport();
        }
    }, [excelData, onExcelExport, onExcelExported]);
    var choiceItems = useMemo(function () {
        var items = [
            {
                name: t('fullScreen'),
                icon: ['fal', 'expand'],
                action: function () { return fsSession && handleFullScreen(fsSession); },
                hidden: !!activeFSSession || !fsSession,
            },
            {
                name: exportExcelItemName !== null && exportExcelItemName !== void 0 ? exportExcelItemName : t('exportExcel'),
                icon: ['fal', 'file-export'],
                action: handleExportExcel,
                hidden: !showExport,
                disabled: isNoData,
            },
        ];
        if (trafficItem) {
            items.splice(1, 0, trafficItem);
        }
        if (prefixItems) {
            items.push.apply(items, prefixItems);
        }
        return items;
    }, [
        t,
        activeFSSession,
        fsSession,
        exportExcelItemName,
        handleExportExcel,
        showExport,
        isNoData,
        trafficItem,
        prefixItems,
        handleFullScreen,
    ]);
    var areAllItemsHidden = choiceItems.every(function (item) { return item.hidden; });
    if (areAllItemsHidden || printPDF)
        return null;
    return (_jsxs(Box, { children: [_jsx(Dropdown, __assign({ onClick: function (e) { return setAnchorMenu(e.currentTarget); } }, { children: _jsx(FontAwesomeIcon, { fontSize: "1.125rem", icon: ['fal', 'ellipsis'] }) })), _jsx(Menu, __assign({ anchorEl: anchorMenu, open: !!anchorMenu, onClose: handleClose, onClick: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                } }, { children: choiceItems.map(function (ci) {
                    return !ci.hidden && (_jsxs(MenuItem, __assign({ onClick: ci.action, disabled: ci.disabled }, { children: [_jsx(ListItemIcon, { children: _jsx(FontAwesomeIcon, { fontSize: "1.375rem", icon: ci.icon }) }), _jsx(Typography, { children: ci.name })] }), ci.name));
                }) }))] }));
};
