import _ from 'lodash';
import { useAPI } from '../state';
var GET_TRIAL_PLANS = '/api/plan/trial';
export var PlanType;
(function (PlanType) {
    PlanType[PlanType["InsightsBooster"] = 1] = "InsightsBooster";
    PlanType[PlanType["EnrichmentBooster"] = 2] = "EnrichmentBooster";
    PlanType[PlanType["EnrichmentBoosterTrial"] = 3] = "EnrichmentBoosterTrial";
    PlanType[PlanType["ProspectBooster"] = 4] = "ProspectBooster";
    PlanType[PlanType["InsightsBoosterTrial"] = 5] = "InsightsBoosterTrial";
})(PlanType || (PlanType = {}));
export var useTrialPlansAPI = function () {
    return useAPI(GET_TRIAL_PLANS, undefined, undefined, undefined, function (data) { return _.sortBy(data, 'name'); });
};
