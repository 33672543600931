import {
  AppRoute,
  FeatureList,
  IUserMenu,
  OrganizationStatus,
  PAccountManagement,
  PageItem,
  PortalPageName,
} from 'common-components';
import { DateRangeStateEnum } from '../../state/app/appSlice';
import { AudienceManagement } from '../audience';
import { AudienceExport } from '../audienceexport';
import { P360Analysis } from '../campaign360';
import { FAQ } from '../faq';
import { WebResolution } from '../webresolution';
import { Welcome } from '../welcome';

export enum UserNameMenu {
  Account = 'account',
  Faq = 'faq',
  SignOut = 'signOut',
}
export enum PageURL {
  Dashboard = '/websiteResolution',
  Campaign360 = '/campaign360',
  Faq = '/faq',
  AccountManagement = '/accountManagement',
  None = '#',
  _Welcome = '/welcome',
  _AudienceExport = '/audienceExport',
  AudiencesPage = '/audiences',
}

interface PortalPageItem extends PageItem {
  dateRangeState?: DateRangeStateEnum;
  showMenu?: boolean;
  enableChatbot?: boolean;
  showDateRange?: boolean;
}

const FAQ_PAGE: PortalPageItem = {
  url: PageURL.Faq,
  name: PortalPageName.faqPage,
  element: FAQ,
  accessFeature: [FeatureList.Faq],
};
const ACCOUNT_PAGE: PortalPageItem = {
  url: PageURL.AccountManagement,
  name: PortalPageName.accountManagementPage,
  element: PAccountManagement,
  accessFeature: [FeatureList.AccountManagement],
};

export const PAGE_ITEMS: Array<PortalPageItem> = [
  {
    icon: ['fal', 'globe-search'],
    url: PageURL.Dashboard,
    name: PortalPageName.visitor360,
    element: WebResolution,
    accessFeature: [FeatureList.WebsiteResolution],
    scale: 0.9,
    showMenu: true,
    enableChatbot: true,
  },
  {
    icon: ['fal', 'magnifying-glass-chart'],
    url: PageURL.Campaign360,
    name: PortalPageName.campaign360,
    element: P360Analysis,
    accessFeature: [FeatureList._360Analysis],
    showMenu: true,
    enableChatbot: true,
    dateRangeState: DateRangeStateEnum.Campaign360,
  },
  {
    icon: ['fal', 'share-nodes'],
    url: PageURL._AudienceExport,
    name: PortalPageName.audienceExport,
    element: AudienceExport,
    accessFeature: [FeatureList.AudienceExport],
    dateRangeState: DateRangeStateEnum.Export,
  },
  {
    icon: ['fal', 'screen-users'],
    url: PageURL.AudiencesPage,
    name: PortalPageName.audiencesPage,
    element: AudienceManagement,
    accessFeature: [FeatureList.AudiencesPage],
    enableChatbot: true,
    showMenu: false,
    showDateRange: false,
  },
  ACCOUNT_PAGE,
  FAQ_PAGE,
];

export const ONBOARDING_PAGE_ITEMS: Array<PortalPageItem> = [
  {
    url: PageURL._Welcome,
    name: 'welcome',
    element: Welcome,
    enableChatbot: true,
  },
  ACCOUNT_PAGE,
  FAQ_PAGE,
];

export const APP_ROUTES: Array<AppRoute<PortalPageItem> & { id: string }> = [
  {
    id: 'pages',
    pages: PAGE_ITEMS,
    hasPermission: (status: OrganizationStatus) => status !== OrganizationStatus.Onboarding,
  },
  {
    id: 'onboarding-pages',
    pages: ONBOARDING_PAGE_ITEMS,
    hasPermission: (status: OrganizationStatus) => status === OrganizationStatus.Onboarding,
    noNavAction: true,
  },
];

export const USER_MENU: Record<UserNameMenu, IUserMenu> = {
  [UserNameMenu.Account]: {
    url: PageURL.AccountManagement,
    icon: ['fal', 'square-list'],
    accessFeature: [FeatureList.AccountManagement],
  },
  [UserNameMenu.Faq]: {
    url: PageURL.Faq,
    icon: ['fal', 'message-question'],
    accessFeature: [FeatureList.Faq],
  },
  [UserNameMenu.SignOut]: {
    url: PageURL.None,
    icon: ['fal', 'right-from-bracket'],
  },
};
