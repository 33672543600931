export enum Category {
  Prospect = 'prospect',
  Visit = 'visit',
}
export enum VisitCategory {
  Score = 'score',
  Detail = 'detail',
}

export enum QuickSelectGroup {
  Custom = 'Custom',
  AllFields = 'All Fields',
  Emails = 'Emails',
  EnrichedB2C = 'Emails Plus - B2C',
  EnrichedB2B = 'Emails Plus - B2B',
}

export enum FieldType {
  String = 'string',
  Enum = 'enum',
  Number = 'number',
  NumberSet = 'numberSet',
  NumberMetric = 'numberMetric',
}

export enum Operators {
  DoNotFilter = 'Do Not Filter',
  OneOf = 'One of',
  Contain = 'Contains',
  NotContain = 'Does not contain',
  Equal = 'Equals',
  StartWith = 'Starts With',
  Between = 'Between',
  NotEqual = 'Does not equal',
  LessThan = 'Less Than',
  MoreThan = 'More Than',
  AtLeast = 'At least',
  AtMost = 'At most',
  TopN = 'Top N',
  TopPercent = 'Top %',
}

export enum AudienceExport {
  Fields = 'exportFields',
  Prospect = 'prospectFilters',
  Visit = 'visitFilters',
  Schedules = 'schedules',
  Dates = 'datesFilters',
}

