import { UseAPI, useAPI } from 'common-components';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelectorDependency, useDateSelectorBindingAPI } from '../../selectors';
import { use360FilterCampaignIdState, use360FilterResponseMetricState } from '../../state/app';
import { DateRangeStateEnum } from '../../state/app/appSlice';
import { CombinedMetrics, RemovedMetrics, getAllCampaignOption } from './constants';

export const GET_CONNECTION_METRICS_MAPPING = 'api/connection-metric-mapping';
export interface Campaign {
  id: string;
  name: string;
}

export interface Metric {
  connectionName: string;
  connectionTypeId: number;
  id: string;
  name: string;
  sequence: number;
  type: string;
}

export type MetricData = Record<string, Metric | Record<string, Metric>>;

export interface MetricCount {
  name: string;
  count: number;
}

export const useLookupCampaignsAPI = (): UseAPI<Campaign[]> => {
  return useDateSelectorBindingAPI<Campaign[], Campaign[]>({
    depend: DateSelectorDependency.LookupCampaigns,
    dateRangeState: DateRangeStateEnum.Campaign360,
  });
};

export const useMetricsAPI = () => {
  return useAPI<MetricData>(GET_CONNECTION_METRICS_MAPPING, undefined, undefined, undefined, (data) => {
    const dataKeyBy = _.keyBy(data, 'name');
    return _.chain(dataKeyBy)
      .assign(_.mapValues(CombinedMetrics, (metrics) => _.pick(dataKeyBy, metrics)))
      .omit([..._.flatten(Object.values(CombinedMetrics)), ...RemovedMetrics])
      .value();
  });
};

export const useMetricTypeState = (): [string | undefined, (metrics: string) => void] => {
  const [metricData] = useMetricsAPI();
  const [responseMetric, setResponseMetric] = use360FilterResponseMetricState();
  // init state
  useMemo(() => {
    if (_.isEmpty(responseMetric) && metricData.data) {
      setResponseMetric(_.first(Object.keys(metricData.data ?? {})) ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData.data, setResponseMetric]);

  return [responseMetric, setResponseMetric];
};

export const useCampaignState = (): [string[], (campaignIds: string[]) => void] => {
  const { t } = useTranslation('360analysis');
  const [campaigns] = useLookupCampaignsAPI();
  const [campaignIds, setCampaignIds] = use360FilterCampaignIdState();
  const allCampaignId = getAllCampaignOption(t).id;

  useEffect(() => {
    if (_.isEmpty(campaignIds) && campaigns.data) {
      setCampaignIds([allCampaignId]);
    }
    // filter out list campaignIds outdated
    const validCampaignIds = campaignIds.filter(
      (id) => campaigns.data?.some((campaign) => campaign.id === id) || id === allCampaignId
    );
    if (!_.isNil(campaigns.data) && validCampaignIds.length !== campaignIds.length) {
      setCampaignIds(validCampaignIds);
    }
  }, [allCampaignId, campaignIds, campaigns.data, setCampaignIds]);
  return [campaignIds, setCampaignIds];
};

export const useMetricCountAPI = () => {
  const [campaignIds] = useCampaignState();

  return useDateSelectorBindingAPI<MetricCount[], Record<string, MetricCount> | undefined>({
    depend: DateSelectorDependency.ResponseCount,
    params: { campaign: campaignIds?.join(',') },
    _parseData: (data) => {
      const dataKeyBy = _.keyBy(data, 'name');
      return _.chain(dataKeyBy)
        .assign(
          _.mapValues(CombinedMetrics, (metrics, key) => ({
            name: key,
            count: _.chain(dataKeyBy).pick(metrics).values().sumBy('count').value(),
          }))
        )
        .value();
    },
    dateRangeState: DateRangeStateEnum.Campaign360,
  });
};
