import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  CardContent,
  CardHeader,
  ChartMenu,
  Dialog,
  DialogTitle,
  FallbackComponent,
  GA_EVENTS,
  PlotComponent,
  PlotProps,
  Select,
  SessionActive,
  checkNoData,
  generateCountPercentAxisTicks,
  getMaxLength,
  useForm,
  useGA4,
} from 'common-components';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { useMetricTypeState } from '../selectors';
import { StyledItemCard } from '../styled';
import { GroupBy } from '../types';
import { getGroupAnalysisName } from './constants';
import { useGroupAnalysisAPI, useGroupAnalysisData, useGroupAnalysisExcelData, useGroupByState } from './selectors';

const StyledSettingIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.typography.pxToRem(theme.shape.borderRadius / 2),
  border: `1px solid ${theme.palette.text.primary}`,
}));

const StyledPlot = styled(PlotComponent)({
  '.overplot': {
    display: 'none',
  },
});

export const GroupSetting = withSuspense()(
  ({
    groupShow,
    groupBy,
    onChange,
  }: {
    groupShow?: string;
    groupBy?: GroupBy;
    onChange?: (state: GroupBy) => void;
  }) => {
    const [show, setShow] = useState(false);
    const theme = useTheme();
    const [, , putEvent] = useGA4();
    const { t } = useTranslation(['360analysis', 'translation']);
    const { values, setFieldValue, handleSubmit, handleReset } = useForm<{
      groupBy: GroupBy;
    }>(
      useMemo(
        () => ({
          initialValues: {
            groupBy,
          },
        }),
        [groupBy]
      )
    );

    const handleClose = () => {
      setShow(false);
      handleReset();
    };

    const onApply = handleSubmit((valuesForm) => {
      onChange && onChange(valuesForm.groupBy);
      if (groupBy !== valuesForm.groupBy) {
        putEvent({
          category: GA_EVENTS._360InsightsDashboard.__name,
          action: GA_EVENTS._360InsightsDashboard.changeChartDisplayForDemographicsComponent,
        });
      }
      handleClose();
    });

    const onChangeGroupSelect = (event: SelectChangeEvent<any>) => setFieldValue(event.target.value, event.target.name);

    return (
      <Box marginRight={5}>
        <StyledButton onClick={() => setShow(true)}>
          <StyledSettingIcon fontSize="1.125rem" icon={['fal', 'sliders']} />
        </StyledButton>
        <Dialog open={show} onClose={handleClose} fullScreenBreakpoint="xs" maxWidth="sm">
          <DialogTitle>
            <Box alignItems="center" display="flex" flexBasis="100%" justifyContent="space-between">
              {t('groupAnalysis.text.show')}
              <FormControl sx={{ minWidth: theme.typography.pxToRem(160) }}>
                <Select value={groupShow ?? ''} disabled>
                  {_.map([groupShow], (gs) => (
                    <MenuItem value={gs} key={gs ?? ''}>
                      {gs}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {t('groupAnalysis.text.by')}
              <FormControl sx={{ minWidth: theme.typography.pxToRem(288) }}>
                <Select value={values.groupBy} name="groupBy" onChange={onChangeGroupSelect}>
                  {_.map(GroupBy, (gb) => (
                    <MenuItem value={gb} key={gb}>
                      {t(`groupAnalysis.groupBy.${gb}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button onClick={onApply} variant="contained">
              <Typography variant="button">{t('button.apply', { ns: 'translation' })}</Typography>
            </Button>
            <Button onClick={handleClose} variant="outlined" color="inherit">
              {t('button.cancel', { ns: 'translation' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
);

export const GroupAnalysis = withSuspense()(() => {
  const { t } = useTranslation(['360analysis', 'translation']);
  const [groupShow] = useMetricTypeState();
  const [groupBy, setGroupBy] = useGroupByState();
  const [groupAnalysisData, fetchGroupAnalysisData, clearGroupAnalysisData] = useGroupAnalysisAPI();
  const [[chartData], isPending] = useGroupAnalysisData();
  const [exportingData, handleExcelExport] = useGroupAnalysisExcelData();
  const isNoData = useMemo(() => checkNoData(groupAnalysisData.data), [groupAnalysisData.data]);

  const [chartHeight, setChartHeight] = useState(0);

  const onResize = useCallback<Required<PlotProps>['onResize']>((clientRect) => {
    setChartHeight(clientRect?.height ?? 0);
  }, []);

  const [percentAxis, countAxis] = useMemo(() => {
    return generateCountPercentAxisTicks(chartData.maxPercent, chartData.maxCount, {
      availableSpace: chartHeight,
      minStepSpace: 40,
      maxStepSpace: 100,
      numberOfStep: 5,
      maxNumberOfStep: 6,
    });
  }, [chartData, chartHeight]);

  useEffect(() => {
    fetchGroupAnalysisData(undefined, true);
    return clearGroupAnalysisData;
  }, [clearGroupAnalysisData, fetchGroupAnalysisData]);

  const nameGrouping = useMemo(() => getGroupAnalysisName(t, groupShow, groupBy), [t, groupShow, groupBy]);

  return (
    <FallbackComponent overlay requesting={exportingData.requesting === true}>
      <StyledItemCard>
        <CardHeader
          title={nameGrouping}
          titleTruncate
          action={
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <GroupSetting groupShow={groupShow} groupBy={groupBy} onChange={setGroupBy} />
              <ChartMenu
                fsSession={SessionActive.GroupAnalysis}
                onExcelExport={handleExcelExport}
                isNoData={isNoData}
              />
            </Box>
          }
        />
        <CardContent>
          <FallbackComponent requesting={isPending ?? false} isNoData={isNoData}>
            <Box justifyContent="space-between" gap={10} display="flex" flexBasis="100%">
              <Box flex={1} display="flex" flexDirection="column">
                <StyledPlot
                  data={[
                    {
                      x: _.first(chartData.data)?.x,
                      y: [chartData.maxPercent ?? 0],
                      textposition: 'auto',
                      showlegend: false,
                      type: 'bar',
                      hoverinfo: 'none',
                      yaxis: 'y2',
                    },
                    ...(chartData.data || []),
                  ]}
                  layout={{
                    yaxis: { showgrid: true, ...countAxis },
                    yaxis2: {
                      automargin: true,
                      showgrid: false,
                      tickcolor: 'transparent',
                      side: 'right',
                      position: 1,
                      overlaying: 'y',
                      ticks: 'outside',
                      ...percentAxis,
                    },
                    barmode: 'group',
                    bargroupgap: 0.2,
                    dragmode: false,
                    legend: {
                      orientation: 'v',
                      yanchor: 'middle',
                      valign: 'top',
                      y: 0.5,
                      x: 1 + 0.02 * getMaxLength(percentAxis.ticktext),
                      itemclick: false,
                      itemdoubleclick: false,
                    },
                  }}
                  templatetooltip={(label, value) =>
                    t('template.chartTooltip', {
                      label,
                      value,
                      unitLabel: { name: `unitLabel.${groupShow}`, ns: '360analysis' },
                      ns: 'translation',
                    })
                  }
                  onResize={onResize}
                  config={{ showAxisDragHandles: false }}
                  requesting={isPending ?? false}
                />
              </Box>
            </Box>
          </FallbackComponent>
        </CardContent>
      </StyledItemCard>
    </FallbackComponent>
  );
});
