import { Box, MenuItem, TextField, styled } from '@mui/material';
import { Autocomplete, NumberInput, Select, useForm } from 'common-components';
import React from 'react';
import { FieldType, Operators } from './enums';
import { AudienceFieldOperator } from './types';

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-input': { textOverflow: 'ellipsis' },
})) as typeof TextField;

const StyledAutocomplete = styled(Autocomplete)(() => ({
  overflow: 'hidden',
  paddingBottom: 0.125,
  '.MuiAutocomplete-inputRoot': {
    backgroundColor: 'inherit',
  },
}));
interface ConfigType {
  type: FieldType;
  options?: string[];
  decimalPlaces?: number;
}
interface FilterFormProps<
  Group extends string,
  FieldValue extends Record<string, any>,
  FieldConfig extends ConfigType
> {
  index: number;
  groupName: Group;
  rowConfig: FieldConfig;
  rowValue: FieldValue;
  registerField: ReturnType<typeof useForm>['registerField'];
  operator?: AudienceFieldOperator;
}

interface FilterProps<Group extends string, FieldValue extends Record<string, any>, FieldConfig extends ConfigType>
  extends FilterFormProps<Group, FieldValue, FieldConfig> {
  fieldName: string;
}

const EnumFilter = <Group extends string, FieldValue extends Record<string, any>, FieldConfig extends ConfigType>(
  props: FilterProps<Group, FieldValue, FieldConfig>
) => (
  <StyledAutocomplete
    {...props.registerField(`${props.groupName}.${props.index}.enumValues`)}
    options={props.rowConfig.options}
    forcePopupIcon={false}
    limitTags={1}
    disableClearable={false}
  />
);
const NumberFilter = <Group extends string, FieldValue extends Record<string, any>, FieldConfig extends ConfigType>(
  props: FilterProps<Group, FieldValue, FieldConfig>
) => <NumberInput {...props.registerField(props.fieldName)} decimalPlaces={props.rowConfig.decimalPlaces} />;
const StringFilter = <Group extends string, FieldValue extends Record<string, any>, FieldConfig extends ConfigType>(
  props: FilterProps<Group, FieldValue, FieldConfig>
) => <StyledTextField {...props.registerField(props.fieldName)} />;

const COMPONENT_BY_TYPE: Record<FieldType, React.ElementType> = {
  [FieldType.String]: StringFilter,
  [FieldType.Number]: NumberFilter,
  [FieldType.NumberSet]: NumberFilter,
  [FieldType.NumberMetric]: NumberFilter,
  [FieldType.Enum]: EnumFilter,
};

export const FilterForm = <
  Group extends string,
  FieldValue extends Record<string, any>,
  FieldConfig extends ConfigType
>(
  props: FilterFormProps<Group, FieldValue, FieldConfig>
) => {
  const { rowConfig, registerField, groupName, index, rowValue } = props;
  const operators = props.operator?.[rowConfig.type] ?? [];
  const FieldControl = COMPONENT_BY_TYPE[rowConfig.type];

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Select
        {...registerField(`${groupName}.${index}.operator`)}
        fullWidth={false}
        sx={{ minWidth: '9.5rem', marginRight: '0.5rem' }}
      >
        {[Operators.DoNotFilter, ...operators].map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {rowValue.operator !== Operators.DoNotFilter && (
        <FieldControl {...props} fieldName={`${groupName}.${index}.value0`} />
      )}
      {rowValue.operator === Operators.Between && (
        <>
          <Box component="span" marginTop="0.5rem" marginX="0.5rem">
            to
          </Box>
          <FieldControl {...props} fieldName={`${groupName}.${index}.value1`} />
        </>
      )}
    </Box>
  );
};
