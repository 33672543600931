var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Menu as MenuMui, Popover as PopoverMui, ThemeProvider, createTheme, } from '@mui/material';
import { themeLevel4 } from '../config/theme';
export var themeMenuPopover = createTheme(themeLevel4, {
    typography: {
        body1: {
            fontSize: themeLevel4.typography.body2.fontSize,
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    fontSize: themeLevel4.typography.body2.fontSize,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    minWidth: themeLevel4.typography.pxToRem(260),
                    padding: "".concat(themeLevel4.spacing(2), " 0"),
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: "".concat(themeLevel4.spacing(2.25), " ").concat(themeLevel4.spacing(6.25), " !important"),
                },
            },
        },
    },
});
export var Menu = function (props) {
    return (_jsx(ThemeProvider, __assign({ theme: themeMenuPopover }, { children: _jsx(MenuMui, __assign({}, props)) })));
};
export var Popover = function (props) {
    return (_jsx(ThemeProvider, __assign({ theme: themeMenuPopover }, { children: _jsx(PopoverMui, __assign({}, props)) })));
};
