import { Button, Stack, Typography, styled, tableCellClasses } from '@mui/material';
import { FeatureList, useAuthorize } from 'common-components';
import { useTranslation } from 'react-i18next';
import { AudienceExportType } from '../audienceexport/enums';
import { FilterItem } from '../audienceexport/types';
import { useHandleExport } from './selectors';

export const ChartTooltipContainer = styled(Stack)(({ theme }) => ({
  gap: 1,
  [`.${tableCellClasses.root}.${tableCellClasses.head}`]: {
    fontSize: theme.typography.body4.fontSize,
  },
  [`.${tableCellClasses.root}.${tableCellClasses.body}`]: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface ChartTooltipHeaderProps {
  exportType: AudienceExportType;
  label?: string;
  value?: number | string;
  dateRange?: [string, string];
  exportFilters?: FilterItem[];
}
export const ChartTooltipHeader = (props: ChartTooltipHeaderProps) => {
  const { t } = useTranslation('webresolution');
  const hasAudienceExportPermission = useAuthorize({ featureAccess: [FeatureList.AudienceExport] });
  const handleExport = useHandleExport(props.exportType, { dateRange: props.dateRange, filters: props.exportFilters });

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Typography fontWeight="bold" variant="body4">
          {props.label}
        </Typography>
        <Typography variant="body4">
          {t('template.chartTooltipNoLabel', {
            value: props.value,
            unitLabel: { name: 'visitors', ns: 'webresolution' },
            ns: 'translation',
          })}
        </Typography>
      </Stack>
      {hasAudienceExportPermission && (
        <Button variant="contained" color="success" size="small" onClick={handleExport}>
          <Typography variant="body4">{t('export')}</Typography>
        </Button>
      )}
    </Stack>
  );
};
