import { useEffect } from 'react';
export var useEscape = function (onEscape) {
    useEffect(function () {
        var handleEsc = function (event) {
            if (event.key === 'Escape')
                onEscape();
        };
        window.addEventListener('keydown', handleEsc);
        return function () {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onEscape]);
};
export default useEscape;
