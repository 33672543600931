var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import _ from 'lodash';
import { forwardRef, useMemo } from 'react';
import { conditionalNumberFormatter } from '../utils';
import { Tooltip } from './tooltip';
export var DEFAULT_NUMBER_FORMAT_CONDITIONS = [
    { from: 1e3, to: 1e5, format: { maximumFractionDigits: 1 } },
    { from: 1e6, to: 1e8, format: { maximumFractionDigits: 1 } },
    { from: 1e9, to: 1e11, format: { maximumFractionDigits: 1 } },
    { from: 1e12, to: 1e14, format: { maximumFractionDigits: 1 } },
];
export var NumberFormatTypography = forwardRef(function (_a, ref) {
    var value = _a.value, _b = _a.conditions, conditions = _b === void 0 ? DEFAULT_NUMBER_FORMAT_CONDITIONS : _b, defaultFormat = _a.defaultFormat, tooltipProps = _a.tooltipProps, typographyProps = __rest(_a, ["value", "conditions", "defaultFormat", "tooltipProps"]);
    var _c = useMemo(function () {
        var formatted = conditionalNumberFormatter(value, conditions, defaultFormat);
        return [formatted, !_.isNil(value) && formatted !== value.toLocaleString()];
    }, [conditions, defaultFormat, value]), formattedValue = _c[0], isFormatted = _c[1];
    return (_jsx(Tooltip, __assign({ title: isFormatted && (value === null || value === void 0 ? void 0 : value.toLocaleString()) }, tooltipProps, { children: _jsx(Typography, __assign({ ref: ref }, typographyProps, { children: formattedValue })) })));
});
