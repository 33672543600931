var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Paper, Typography, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { withSuspense } from 'react-suspenser';
import { AccountManagementPageParam } from '.';
import { Backdrop, FallbackComponent, TabComponent, TabPanel } from '../common';
import { ConnectionCard, EmptyCard } from '../connection';
import { ConnectionStatus, useConnectionTypeAPI, useCreateOrUpdateConnectionAPI, } from '../connection/selectors';
import { ACCOUNT_MANAGEMENT_TAB_INDEX, AUDIT_EVENTS } from '../constants';
import { useAudit } from '../hooks';
import { useOrganizationDetailAPI, useUpdateOrganizationAPI } from './selectors';
import { UsersManagement } from './usersmanagement';
var StyledPaper = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return ({
        height: '100%',
        margin: 0,
        flex: 1,
        padding: "".concat(theme.spacing(7), " ").concat(theme.spacing(5.5)),
        rowGap: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
    });
});
var UnavailableCard = function (props) { return (_jsxs(Box, __assign({ position: "relative", width: "100%" }, { children: [_jsx(Backdrop, { description: "Upgrade to add this connection!" }), _jsx(ConnectionCard, { connectionTypeData: props, disable: true, onSaveConnection: _.noop, requesting: false, color: "#e3e3e3" })] }))); };
var ConnectionTab = withSuspense()(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var orgDetail = useOrganizationDetailAPI()[0];
    var sendAuditEvent = useAudit()[0];
    var _k = useCreateOrUpdateConnectionAPI(), createOrUpdateData = _k[0], fetchCreateConData = _k[1], clearCreateConData = _k[2];
    var _l = useConnectionTypeAPI((_a = orgDetail.data) === null || _a === void 0 ? void 0 : _a.id, (_b = orgDetail.data) === null || _b === void 0 ? void 0 : _b.planId), connectionType = _l[0], fetchConnectionType = _l[1], clearConnectionType = _l[2];
    var updateOrgData = useUpdateOrganizationAPI()[0];
    var disableConnectionTypes = useMemo(function () { var _a; return (_a = connectionType.data) === null || _a === void 0 ? void 0 : _a.activeType.filter(function (u) { return _.find(u.connections, ['status', ConnectionStatus.Disabled]); }); }, [(_c = connectionType.data) === null || _c === void 0 ? void 0 : _c.activeType]);
    var enableConnectionTypes = useMemo(function () { var _a; return (_a = connectionType.data) === null || _a === void 0 ? void 0 : _a.activeType.filter(function (u) { return _.find(u.connections, ['status', ConnectionStatus.Enabled]); }); }, [(_d = connectionType.data) === null || _d === void 0 ? void 0 : _d.activeType]);
    var pendingConnectionTypes = useMemo(function () { var _a; return (_a = connectionType.data) === null || _a === void 0 ? void 0 : _a.activeType.filter(function (u) { return _.find(u.connections, ['status', ConnectionStatus.Pending]); }); }, [(_e = connectionType.data) === null || _e === void 0 ? void 0 : _e.activeType]);
    useEffect(function () {
        fetchConnectionType();
        sendAuditEvent({ action: AUDIT_EVENTS.accountManagement.connections.view });
        return function () {
            clearCreateConData();
            clearConnectionType();
        };
    }, [fetchConnectionType, clearConnectionType, clearCreateConData, sendAuditEvent]);
    useEffect(function () {
        if (createOrUpdateData.data) {
            fetchConnectionType();
        }
    }, [createOrUpdateData.data, fetchConnectionType]);
    useEffect(function () {
        if (updateOrgData.data) {
            fetchConnectionType();
        }
    }, [updateOrgData.data, fetchConnectionType]);
    var handleSaveConnection = function (connection) {
        fetchCreateConData(connection);
    };
    return (_jsx(StyledPaper, __assign({ elevation: 4 }, { children: _jsxs(FallbackComponent, __assign({ requesting: connectionType.requesting }, { children: [enableConnectionTypes && enableConnectionTypes.length >= 1 && (_jsxs(Grid, __assign({ container: true, spacing: 5.5 }, { children: [_jsx(Grid, __assign({ container: true, item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", sx: { wordBreak: 'break-word' } }, { children: t('connection.activeSection') })) })), enableConnectionTypes.map(function (activeType) {
                            var _a;
                            return (_jsx(Grid, __assign({ container: true, item: true, xs: 6 }, { children: _jsx(ConnectionCard, { connectionTypeData: activeType, orgId: (_a = orgDetail.data) === null || _a === void 0 ? void 0 : _a.id, onSaveConnection: handleSaveConnection, externalError: createOrUpdateData.error, requesting: !!createOrUpdateData.requesting, color: "#e4e4e4" }, activeType.id) }), activeType.id));
                        })] }))), pendingConnectionTypes && pendingConnectionTypes.length >= 1 && (_jsxs(Grid, __assign({ container: true, spacing: 5.5 }, { children: [_jsx(Grid, __assign({ container: true, item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", sx: { wordBreak: 'break-word' } }, { children: "Pending" })) })), pendingConnectionTypes.map(function (at) {
                            var _a;
                            return (_jsx(Grid, __assign({ container: true, item: true, xs: 6 }, { children: _jsx(ConnectionCard, { connectionTypeData: at, orgId: (_a = orgDetail.data) === null || _a === void 0 ? void 0 : _a.id, onSaveConnection: handleSaveConnection, externalError: createOrUpdateData.error, requesting: !!createOrUpdateData.requesting, color: "#e4e4e4" }, at.id) }), at.id));
                        })] }))), disableConnectionTypes && disableConnectionTypes.length >= 1 && (_jsxs(Grid, __assign({ container: true, spacing: 5.5 }, { children: [_jsx(Grid, __assign({ container: true, item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", sx: { wordBreak: 'break-word' } }, { children: t('connection.disabled') })) })), disableConnectionTypes.map(function (at) {
                            var _a;
                            return (_jsx(Grid, __assign({ container: true, item: true, xs: 6 }, { children: _jsx(ConnectionCard, { connectionTypeData: at, orgId: (_a = orgDetail.data) === null || _a === void 0 ? void 0 : _a.id, onSaveConnection: handleSaveConnection, externalError: createOrUpdateData.error, requesting: !!createOrUpdateData.requesting, color: "#e4e4e4" }, at.id) }), at.id));
                        })] }))), _jsxs(Grid, __assign({ container: true, spacing: 5.5 }, { children: [_jsx(Grid, __assign({ container: true, item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h6", sx: { wordBreak: 'break-word' } }, { children: t('connection.availableSection') })) })), (_f = connectionType.data) === null || _f === void 0 ? void 0 : _f.availableType.map(function (availableType) {
                            var _a;
                            return (_jsx(Grid, __assign({ container: true, item: true, xs: 6 }, { children: _jsx(ConnectionCard, { connectionTypeData: availableType, orgId: (_a = orgDetail.data) === null || _a === void 0 ? void 0 : _a.id, onSaveConnection: handleSaveConnection, externalError: createOrUpdateData.error, requesting: !!createOrUpdateData.requesting, color: "#e3e3e3" }, availableType.id) }), availableType.id));
                        }), (_g = connectionType.data) === null || _g === void 0 ? void 0 : _g.unavailableType.map(function (unavailableType) { return (_jsx(Grid, __assign({ container: true, item: true, xs: 6 }, { children: _jsx(UnavailableCard, __assign({}, unavailableType)) }), unavailableType.id)); }), _.isEmpty((_h = connectionType.data) === null || _h === void 0 ? void 0 : _h.unavailableType) && _.isEmpty((_j = connectionType.data) === null || _j === void 0 ? void 0 : _j.availableType) && (_jsx(Grid, __assign({ container: true, item: true, xs: 6 }, { children: _jsx(EmptyCard, { text: t('connection.unavailableText') }) })))] }))] })) })));
});
export var TabsUsersAndConnections = function () {
    var theme = useTheme();
    var state = useLocation().state;
    var defaultTab = _.get(state, [AccountManagementPageParam.DEFAULT_TAB], ACCOUNT_MANAGEMENT_TAB_INDEX.USERS);
    return (_jsx(Box, __assign({ flex: 1, display: "flex", flexDirection: "column", position: "relative", width: "100%" }, { children: _jsxs(TabComponent, __assign({ defaultTab: defaultTab, tabs: [
                {
                    label: 'Users',
                    value: ACCOUNT_MANAGEMENT_TAB_INDEX.USERS,
                },
                {
                    label: 'Connections',
                    value: ACCOUNT_MANAGEMENT_TAB_INDEX.CONNECTIONS,
                },
            ], customTabListStyles: {
                marginBottom: theme.spacing(2.5),
            } }, { children: [_jsx(TabPanel, __assign({ value: ACCOUNT_MANAGEMENT_TAB_INDEX.USERS }, { children: _jsx(UsersManagement, {}) })), _jsx(TabPanel, __assign({ value: ACCOUNT_MANAGEMENT_TAB_INDEX.CONNECTIONS }, { children: _jsx(ConnectionTab, {}) }))] })) })));
};
