var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, CardMedia, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import { themeLevel2 } from '../config/theme';
import { useGA4 } from '../hooks';
import { logoutSession } from '../utils';
import { Card, CardContent } from './card';
var StyledCard = styled(Card)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: themeLevel2.palette.background.default,
        width: theme.typography.pxToRem(420),
        margin: "".concat(theme.typography.pxToRem(30), " auto"),
        borderRadius: 0,
        padding: theme.typography.pxToRem(15),
    });
});
export var NoAccess = withSuspense()(function (_a) {
    var message = _a.message, disableReLogin = _a.disableReLogin;
    var _b = useGA4(), putEvent = _b[2];
    var t = useTranslation('translation').t;
    return (_jsx(Box, __assign({ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center" }, { children: _jsxs(StyledCard, { children: [_jsx(CardContent, __assign({ sx: { justifyContent: 'center', mb: 10 } }, { children: _jsx(CardMedia, { sx: { width: 'auto', height: 101 }, image: "/assets/images/krateoSquare.png", component: "img" }) })), _jsx(Typography, { variant: "button", lineHeight: 2, textAlign: "center", dangerouslySetInnerHTML: { __html: t("noAccess.".concat(message)) } }), _jsx(Button, __assign({ sx: { mt: 10 }, variant: "contained", onClick: function () { return logoutSession(putEvent); }, disabled: disableReLogin }, { children: t('button.returnToLogin') }))] }) })));
});
