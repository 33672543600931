import { Theme } from '@mui/material';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { PlotData } from 'plotly.js';
import { Gender, GroupAnalysisFieldValue, GroupBy } from '../types';
import { GroupAnalysis } from './selectors';

const GenderChartColor: Record<Gender, string> = {
  [Gender.Female]: 'palette.success.main',
  [Gender.Male]: 'palette.primary.main',
  [Gender.Unknown]: 'palette.secondary.main',
};

export type BuildDataFunctionType = (
  t: TFunction<string, string>,
  groupShow: string | undefined,
  groupBy: string | undefined,
  data: GroupAnalysis[] | undefined,
  theme: Theme
) => [{ data?: Partial<PlotData>[]; maxCount?: number; maxPercent?: number }];

export const getGroupAnalysisName = (t: TFunction<string, string>, groupShow?: string, groupBy?: string) => {
  return t('groupAnalysis.title', {
    groupShow: groupShow,
    groupBy: t(`groupAnalysis.groupBy.${groupBy}`),
  });
};

const buildGenderAgeGroupAnalysisData: BuildDataFunctionType = (t, groupShow, groupBy, data, theme) => {
  const dataGroupBy = _.chain(data)
    .groupBy(GroupAnalysisFieldValue.Value1)
    .mapValues((values) => _.keyBy(values, GroupAnalysisFieldValue.Value2))
    .value();

  const maxValue = _.maxBy(data, 'count');
  const x = Object.keys(dataGroupBy);
  const chartData: Partial<PlotData>[] = _.flatMap(Gender, (gender) => {
    const name = `${t(gender)}`;
    const y = Object.values(dataGroupBy).map((d) => d[gender]?.count || 0);

    return [
      {
        name,
        x,
        y,
        yaxis: 'y',
        type: 'bar',
        marker: {
          color: _.get(theme, GenderChartColor[gender]),
        },
        hoverinfo: 'none',
        customdata: [x.map((label) => `${label} (${name})`), y],
      },
    ];
  });
  return [{ data: chartData, maxCount: maxValue?.count, maxPercent: maxValue?.percent }];
};

const buildSingleAnalysisData: BuildDataFunctionType = (t, groupShow, groupBy, data, theme) => {
  const dataGroupBy = _.chain(data)
    .groupBy(GroupAnalysisFieldValue.Value1)
    .mapValues((values) => ({ count: _.sumBy(values, 'count'), percent: _.sumBy(values, 'percent') }))
    .value();

  const x = Object.keys(dataGroupBy).map((value) => t(value));
  const y = Object.values(dataGroupBy).map((v) => v.count);
  const maxValue = _.maxBy(Object.values(dataGroupBy), 'count');

  const chartData: Partial<PlotData>[] | undefined = data && [
    {
      x,
      y,
      type: 'bar',
      showlegend: false,
      marker: {
        color: theme.palette.primary.main,
      },
      yaxis: 'y',
      hoverinfo: 'none',
      customdata: [x, y],
    },
  ];

  return [{ data: chartData, maxCount: maxValue?.count, maxPercent: maxValue?.percent }];
};

export const buildGroupAnalysisData: Record<GroupBy, BuildDataFunctionType> = {
  [GroupBy.GenderAge]: buildGenderAgeGroupAnalysisData,
  [GroupBy.Gender]: buildSingleAnalysisData,
  [GroupBy.Age]: buildSingleAnalysisData,
  [GroupBy.Income]: buildSingleAnalysisData,
};
