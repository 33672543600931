import { Grid } from '@mui/material';
import { DateTimeInput, DateType, useForm } from 'common-components';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FILTER_FORMAT } from '../constants';

interface DateTimeInputsProps {
  registerField: ReturnType<typeof useForm>['registerField'];
}
export function DateTimeInputs({ registerField }: Readonly<DateTimeInputsProps>) {
  const fromDateInputProps = registerField(`fromDate`);
  const toDateInputProps = registerField(`toDate`);
  const { t } = useTranslation('translation');
  const maxToDate = useMemo(() => moment().startOf('date'), []);
  const maxFromDate = useMemo(() => moment().startOf('date').subtract(1, 'day'), []);
  const prevFromDateRef = useRef<DateType>();
  const fromDateInputRef = useRef<HTMLInputElement>(null);
  const toDateInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fromDateInputProps.value) {
      prevFromDateRef.current = moment(fromDateInputProps.value);
    }
  }, [fromDateInputProps.value]);

  const handleChange = (inputName: string, value: DateType) => {
    const date = value ? moment(value).format(DATE_FILTER_FORMAT) : null;
    const event = { target: { name: inputName, value: date } } as ChangeEvent<any>;
    inputName === fromDateInputProps.name ? fromDateInputProps.onChange(event) : toDateInputProps.onChange(event);
  };

  const handleBlur = (inputName: string) => {
    const event = { target: { name: inputName } } as React.FocusEvent<any>;
    inputName === fromDateInputProps.name ? fromDateInputProps.onBlur(event) : toDateInputProps.onBlur(event);
  };

  const handleFromDateChange = (date: string | moment.Moment | null) => {
    if (maxFromDate && date && moment(date).isAfter(maxFromDate, 'date')) {
      date = prevFromDateRef.current?.toISOString() ?? '';
    }
    handleChange(fromDateInputProps.name, date ? moment(date) : null);
    if (date && toDateInputProps.value && moment(date).isAfter(toDateInputProps.value, 'date')) {
      handleChange(toDateInputProps.name, null);
      toDateInputRef.current?.focus();
    }
    if (date) {
      toDateInputRef.current?.focus();
    }
  };
  const handleToDateChange = (date: string | moment.Moment | null) => {
    if (maxToDate && date && moment(date).isAfter(maxToDate, 'date')) {
      date = maxToDate?.toISOString() ?? '';
    }
    if (date && moment(date).isBefore(prevFromDateRef.current, 'date')) {
      handleChange(fromDateInputProps.name, date ? moment(date) : null);
      handleChange(toDateInputProps.name, null);
      toDateInputRef.current?.focus();
    } else {
      handleChange(toDateInputProps.name, date ? moment(date) : null);
      if (date) {
        handleChange(fromDateInputProps.name, prevFromDateRef.current);
        fromDateInputRef.current?.focus();
      }
    }
  };

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs>
        <DateTimeInput
          label={`${t('from')}`}
          value={fromDateInputProps.value ? moment(fromDateInputProps.value) : null}
          error={fromDateInputProps.helperText}
          onChange={handleFromDateChange}
          onBlur={() => handleBlur(fromDateInputProps.name)}
        />
      </Grid>
      <Grid item xs>
        <DateTimeInput
          label={`${t('to')}`}
          value={toDateInputProps.value ? moment(toDateInputProps.value) : null}
          error={toDateInputProps.helperText}
          onChange={handleToDateChange}
          onBlur={() => handleBlur(toDateInputProps.name)}
        />
      </Grid>
    </Grid>
  );
}
