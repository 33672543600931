import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MarAction, MarAppState } from 'common-components';
import moment, { Moment } from 'moment';

export enum DateRangeStateEnum {
  Global = 'global',
  Export = 'export',
  Campaign360 = 'campaign360',
}

export interface Page360Filter {
  campaignId?: string[];
  responseMetric?: string;
  ignoreLowVolume: boolean;
}

export interface AppState {
  pageTitle?: string;
  dateRange: Record<DateRangeStateEnum, [Moment, Moment]>;
  page360Filter: Page360Filter;
  isCampaignDateRangeSet: boolean;
}
const initialState: AppState = {
  pageTitle: undefined,
  page360Filter: {
    ignoreLowVolume: true,
  },
  dateRange: {
    global: [moment().subtract(7, 'days'), moment()],
    export: [moment().subtract(7, 'days'), moment()],
    campaign360: [moment().subtract(7, 'days'), moment()],
  },
  isCampaignDateRangeSet: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setPageTitle: (state: AppState, action: MarAction) => {
      state.pageTitle = action.payload.value;
    },
    setDateRange: (state: AppState, action: PayloadAction<{ key: DateRangeStateEnum; value: [Moment, Moment] }>) => {
      state.dateRange = {
        ...state.dateRange,
        [action.payload.key]: action.payload.value,
      };
    },
    set360FilterCampaignId: (state: AppState, action: MarAction) => {
      state.page360Filter.campaignId = action.payload.value;
    },
    set360FilterMetrics: (state: AppState, action: MarAction) => {
      state.page360Filter.responseMetric = action.payload.value;
    },
    set360FilterIgnoreLowVolume: (state: AppState, action: MarAction) => {
      state.page360Filter.ignoreLowVolume = action.payload.value;
    },
    setIsCampaignDateRangeSet: (state: AppState, action: MarAction) => {
      state.isCampaignDateRangeSet = action.payload.value;
    },
  },
});

export const {
  setPageTitle,
  setDateRange,
  set360FilterCampaignId,
  set360FilterMetrics,
  setIsCampaignDateRangeSet,
  set360FilterIgnoreLowVolume,
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAppState = (state: MarAppState) => state.app;

export default appSlice.reducer;
