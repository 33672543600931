import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack } from '@mui/material';
import { ErrorMessageBox } from 'common-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WriteAccessConnection, useGetListByConnectionId } from '../../selectors';
import { AudienceScheduleForm } from '../../types';
import { NEW_LIST_VALUE } from './const';
import { Layout } from './layout';
import { NewListDialog } from './newlistdialog';
import { useScheduleDetailForm } from './useform';
import { useFormInputs } from './useforminputs';

export type ScheduleDetailProps = {
  data: AudienceScheduleForm | null;
  connections: WriteAccessConnection[];
  onClose: () => void;
  onSave: (data: AudienceScheduleForm) => void;
};

export const ScheduleDetail = ({ data, connections, onClose, onSave }: ScheduleDetailProps) => {
  const { t } = useTranslation(['audience', 'translation']);
  const [newListDialogOpen, setNewListDialogOpen] = useState(false);

  const { castedValues, handleSubmit, registerField, setFieldTouched, setFieldValue, isDirty } = useScheduleDetailForm(
    data,
    connections
  );

  const [{ data: lists, requesting: listRequesting, error: listError }, fetchLists, clearLists] =
    useGetListByConnectionId(castedValues.connectionId, { orderField: 'name', order: 'asc' });

  useEffect(() => {
    if (castedValues.connectionId) {
      fetchLists();
      return () => {
        clearLists();
      };
    }
  }, [fetchLists, clearLists, castedValues.connectionId]);

  const { inputs, renderInput } = useFormInputs(
    { frequency: castedValues.frequency, connections, lists },
    {
      registerField,
      setFieldTouched,
      setFieldValue,
    }
  );

  const onSubmit = handleSubmit((formValues: AudienceScheduleForm) => {
    const isNewList = formValues.list === NEW_LIST_VALUE;
    if (isNewList) {
      setNewListDialogOpen(true);
      return;
    }

    onSave(formValues);
  });

  const handleNewListDialogSave = useCallback(
    (newList: { id: string; name: string }) => {
      setNewListDialogOpen(false);
      setFieldValue(newList.id, 'list');
      onSubmit();
    },
    [setFieldValue, onSubmit]
  );

  return (
    <Layout
      header={t('schedule.schedule_details')}
      body={
        <>
          <ErrorMessageBox sx={{ mb: 2 }} error={listError} autoDismiss />
          <Grid container columnSpacing={5} rowSpacing={2.5}>
            {inputs.map((input) => {
              return (
                <Grid {...input.gridProps} key={input.name} item>
                  {renderInput(input)}
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={4}
                alignItems="center"
                justifyContent="flex-end"
                sx={{ pt: '34px', pb: '22px', maxHeight: '75px' }}
              >
                <Button variant="outlined" color="inherit" onClick={onClose}>
                  {t('translation:button.close')}
                </Button>
                <LoadingButton variant="contained" disabled={!isDirty || !!listRequesting} onClick={onSubmit}>
                  {t('translation:button.save')}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
          <NewListDialog
            open={newListDialogOpen}
            connectionId={castedValues.connectionId}
            onClose={() => setNewListDialogOpen(false)}
            onSave={handleNewListDialogSave}
          />
        </>
      }
    />
  );
};
