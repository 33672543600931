var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withSuspense } from 'react-suspenser';
import { Card, CardContent, CardHeader, FallbackComponent } from '../common';
import { AUDIT_EVENTS, GA_EVENTS } from '../constants';
import { useAudit, useGA4 } from '../hooks';
import { OrganizationModifier } from '../organizationmodifier';
import { useLimitedFeatureFlagAPI } from '../selectors';
import { formatZipCode } from '../utils';
import { useOrganizationDetailAPI, useUpdateOrganizationAPI } from './selectors';
export var Organization = withSuspense()(function () {
    var _a;
    var navigate = useNavigate();
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _b = useState(false), showModifier = _b[0], setShowModifier = _b[1];
    var _c = useUpdateOrganizationAPI(), updateOrgData = _c[0], fetchUpdateOrgData = _c[1], clearUpdateOrgData = _c[2];
    var _d = useOrganizationDetailAPI(), orgDetail = _d[0], fetchOrgDetail = _d[1], clearOrgDetail = _d[2];
    var _e = useLimitedFeatureFlagAPI(), limitedFlags = _e[0], fetchLimitedFeatureFlag = _e[1], clearLimitedFeatureFlag = _e[2];
    var _f = useGA4(), putEvent = _f[2];
    var sendAuditEvent = useAudit()[0];
    var orgInfo = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return ({
            name: (_a = orgDetail.data) === null || _a === void 0 ? void 0 : _a.name,
            address: [
                (_b = orgDetail.data) === null || _b === void 0 ? void 0 : _b.address1,
                (_c = orgDetail.data) === null || _c === void 0 ? void 0 : _c.address2,
                [
                    [(_d = orgDetail.data) === null || _d === void 0 ? void 0 : _d.city, (_f = (_e = orgDetail.data) === null || _e === void 0 ? void 0 : _e.lookupStateCode) === null || _f === void 0 ? void 0 : _f.stateAbbreviation].filter(function (text) { return text; }).join(', '),
                    formatZipCode((_g = orgDetail.data) === null || _g === void 0 ? void 0 : _g.zip),
                ].join(' '),
            ],
        });
    }, [orgDetail.data]);
    useEffect(function () {
        sendAuditEvent({ action: AUDIT_EVENTS.accountManagement.organization.view });
        fetchOrgDetail();
        fetchLimitedFeatureFlag();
        return function () {
            clearOrgDetail();
            clearLimitedFeatureFlag();
        };
    }, [clearOrgDetail, clearLimitedFeatureFlag, fetchOrgDetail, fetchLimitedFeatureFlag, sendAuditEvent]);
    useEffect(function () {
        if (!showModifier)
            clearUpdateOrgData();
    }, [clearUpdateOrgData, showModifier]);
    useEffect(function () {
        if (updateOrgData.data) {
            setShowModifier(false);
            fetchOrgDetail();
        }
    }, [fetchOrgDetail, updateOrgData.data]);
    useEffect(function () {
        var _a;
        if (((_a = orgDetail.error) === null || _a === void 0 ? void 0 : _a.type) === 'common.notExist') {
            navigate('/', { replace: true });
        }
    }, [navigate, orgDetail.error]);
    var handleSubmit = function (castedValues) {
        fetchUpdateOrgData(castedValues);
        putEvent({ category: GA_EVENTS.accountManagement.__name, action: GA_EVENTS.accountManagement.editOrganization });
    };
    return (_jsxs(Card, { children: [_jsx(CardHeader, { title: t('organization'), action: _jsx(IconButton, __assign({ "data-testid": "edit-button", onClick: function () { return setShowModifier(true); } }, { children: _jsx(FontAwesomeIcon, { icon: ['fal', 'pen'] }) })) }), _jsx(CardContent, { children: _jsx(FallbackComponent, __assign({ requesting: orgDetail.requesting }, { children: _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "subtitle1" }, { children: (_a = orgInfo.name) !== null && _a !== void 0 ? _a : t('text.notProvided', { ns: 'translation' }) })), orgInfo.address.map(function (i, index) {
                                return i && (_jsx(Typography, __assign({ variant: "body2", sx: { wordBreak: 'break-word' } }, { children: i }), "".concat(i, "-").concat(index)));
                            })] }) })) }), _jsx(OrganizationModifier, { open: showModifier, requesting: !!orgDetail.requesting || !!updateOrgData.requesting, orgDetail: orgDetail.data, onSubmit: handleSubmit, onCancel: function () { return setShowModifier(false); }, externalError: updateOrgData.error, limitedFlags: limitedFlags.data || [] })] }));
});
