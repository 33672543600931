import { Theme } from '@mui/material';
import { FeatureList, GA_EVENTS } from 'common-components';
import { TFunction } from 'i18next';
import _ from 'lodash';
import moment, { Moment, unitOfTime } from 'moment';
import { AudienceExportType, FilterFieldId, MatchType, Operators } from '../audienceexport/enums';
import { ContentType } from '../trafficinsightdialog/types';
import { ClassificationChartData, VisitorClassification } from './selectors';

const CLASSIFICATION_DIFF_DAYS = 30;
export const SOURCE_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'MM/DD/YY';

export enum FrequenceVisitorType {
  Single = 'singleVisitVisitors',
  Multi = 'multiVisitVisitors',
}
export enum VisitorLabelName {
  Propspects = 'Prospect',
  B2CProspects = 'B2C Prospects',
  B2BProspects = 'B2B Prospects',
}

export enum VisitorBreakdownEnum {
  EmailOnly = 'emailOnly',
  EnrichedB2C = 'enrichedB2C',
  EnrichedB2B = 'enrichedB2B',
}

export const VisitorBreakdownExportType: Record<VisitorBreakdownEnum, AudienceExportType> = {
  [VisitorBreakdownEnum.EmailOnly]: AudienceExportType.EmailsProspects,
  [VisitorBreakdownEnum.EnrichedB2C]: AudienceExportType.EmailsPlusB2CProspects,
  [VisitorBreakdownEnum.EnrichedB2B]: AudienceExportType.EmailsPlusB2BProspects,
};

export const VisitorBreakdownMatchType: Record<VisitorBreakdownEnum, MatchType> = {
  [VisitorBreakdownEnum.EmailOnly]: MatchType.Email,
  [VisitorBreakdownEnum.EnrichedB2C]: MatchType.Personal,
  [VisitorBreakdownEnum.EnrichedB2B]: MatchType.Business,
};

export const SVIType: Record<string, string> = {
  [VisitorLabelName.Propspects]: VisitorBreakdownEnum.EmailOnly,
  [VisitorLabelName.B2CProspects]: VisitorBreakdownEnum.EnrichedB2C,
  [VisitorLabelName.B2BProspects]: VisitorBreakdownEnum.EnrichedB2B,
};

export const SVITypeGAEvent: Record<string, { category?: string; action?: string }> = {
  [VisitorBreakdownEnum.EmailOnly]: {},
  [VisitorBreakdownEnum.EnrichedB2C]: {
    category: GA_EVENTS.websiteResolutionDashboard.__name,
    action: GA_EVENTS.websiteResolutionDashboard.exportEnrichedConsumerDataToExcel,
  },
  [VisitorBreakdownEnum.EnrichedB2B]: {
    category: GA_EVENTS.websiteResolutionDashboard.__name,
    action: GA_EVENTS.websiteResolutionDashboard.exportEnrichedBusinessContactToExcel,
  },
};

export enum SiteEngagementCategoryLabel {
  singleVisitMultiPage = 'singleVisitMultiPage',
  singleVisitSinglePage = 'singleVisitSinglePage',
  multiVisitSinglePage = 'multiVisitSinglePage',
  multiVisitMultiPage = 'multiVisitMultiPage',
}

export interface Permission {
  requiredPermission: FeatureList;
  description: string;
}

export const VisitorBreakdownPermission: Record<VisitorBreakdownEnum, Permission | null> = {
  [VisitorBreakdownEnum.EmailOnly]: null,
  [VisitorBreakdownEnum.EnrichedB2C]: {
    requiredPermission: FeatureList.TrafficInsight,
    description: 'Upgrade to see enriched consumer data and traffic insight',
  },
  [VisitorBreakdownEnum.EnrichedB2B]: {
    requiredPermission: FeatureList.TrafficInsight,
    description: 'Upgrade to see enriched business contact data and traffic insight',
  },
};

export interface GATracking {
  category: string;
  action: string;
}
interface VisitorBreakdownOption {
  label: string;
  color: string;
  gaTracking?: GATracking;
}

export const VISITOR_BREAKDOWN_OPTIONS = (theme: Theme): Record<VisitorBreakdownEnum, VisitorBreakdownOption> => ({
  [VisitorBreakdownEnum.EmailOnly]: {
    label: 'prospects',
    color: theme.palette.primary.main,
    gaTracking: {
      category: GA_EVENTS.websiteResolutionDashboard.__name,
      action: GA_EVENTS.websiteResolutionDashboard.exportProspectsToExcel,
    },
  },
  [VisitorBreakdownEnum.EnrichedB2C]: {
    label: 'enrichedB2C',
    color: theme.palette.success.main,
    gaTracking: {
      category: GA_EVENTS.websiteResolutionDashboard.__name,
      action: GA_EVENTS.websiteResolutionDashboard.exportEngagementToExcel,
    },
  },
  [VisitorBreakdownEnum.EnrichedB2B]: {
    label: 'enrichedB2B',
    color: theme.palette.secondary.main,
    gaTracking: {
      category: GA_EVENTS.websiteResolutionDashboard.__name,
      action: GA_EVENTS.websiteResolutionDashboard.exportEngagementToExcel,
    },
  },
});

export const TRAFFIC_DIALOG_MAP = new Map<VisitorBreakdownEnum, ContentType>([
  [VisitorBreakdownEnum.EnrichedB2B, ContentType.B2B],
  [VisitorBreakdownEnum.EnrichedB2C, ContentType.B2C],
]);

export const generateData = (fromDate?: Moment, toDate?: Moment, classificationData?: VisitorClassification) => {
  const data: Array<ClassificationChartData> = [];
  if (!fromDate || !toDate) return data;

  const classificationCountsData = _.clone(classificationData?.countsByDate || []);

  const unit: unitOfTime.Diff =
    toDate.endOf('day').diff(fromDate.startOf('day'), 'days') + 1 >= CLASSIFICATION_DIFF_DAYS ? 'week' : 'day';

  for (let index: Moment = toDate.clone(); index.isSameOrAfter(fromDate, 'day'); index.subtract(1, unit)) {
    const clonedIndex = moment(index);
    const from = unit === 'week' ? moment.max(clonedIndex.subtract(1, unit).add(1, 'day'), fromDate) : undefined;
    const to = index;
    const diff = toDate.diff(to, unit);
    const [countData] = _.remove(classificationCountsData, ({ date }) => date === diff);

    data.unshift({
      dateRange: [from?.toISOString() ?? to.toISOString(), to.toISOString()],
      label: `${from ? from.format(DATE_FORMAT) + ' - ' : ''}${to.format(DATE_FORMAT)}`,
      count: countData?.count ?? 0,
      detail: countData?.detail,
    });
  }

  return data;
};

export const getGATracking = (type: VisitorBreakdownEnum, theme: Theme) => {
  const { gaTracking } = VISITOR_BREAKDOWN_OPTIONS(theme)[type];
  return gaTracking;
};

export const getRestrictedFeatures = (
  type: VisitorBreakdownEnum | VisitorBreakdownEnum[],
  allowFeature: FeatureList[] = []
) => {
  return _.chain(VisitorBreakdownPermission)
    .pickBy(
      (v, k): v is Permission =>
        !!v &&
        (typeof type === 'string' ? type === k : type.includes(k as VisitorBreakdownEnum)) &&
        !allowFeature.includes(v.requiredPermission)
    )
    .mapValues<Permission & { type: VisitorBreakdownEnum }>((v, k) => ({ ...v, type: k as VisitorBreakdownEnum }))
    .values()
    .compact()
    .value();
};

export const convertLabelsPieChart = (label: string, t: TFunction): string => {
  if (label === '1') {
    return t('visitorEngagement.labelForSingle');
  }
  return `${label} ${t('visitorEngagement.labelForMulti')}`;
};

export const getMatchTypeFilter = (type: VisitorBreakdownEnum) => ({
  fieldId: FilterFieldId.MatchType,
  operator: Operators.OneOf,
  values: [VisitorBreakdownMatchType[type]],
});
