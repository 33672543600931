import { useAPI } from 'common-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { ulid } from 'ulid';
import { ChatMessage, ChatPayload, ChatResponse, MessageType } from './types';

export const SOCKET_URL = `${process.env.REACT_APP_CHATBOT_URL ?? ''}/api/chatbot/ws`;
const FEEDBACK_API_URL = '/api/chatbot/conversation/feedback';
const TIMEOUT_MS = 5 * 60 * 1000;
const MAX_MESSAGE = 100;

export const useFeedbackAPI = () => {
  return useAPI(FEEDBACK_API_URL, undefined, 'PUT');
};

export const useChat = () => {
  const [, fetchFeedback] = useFeedbackAPI();
  const conversationIdRef = useRef(ulid());
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(SOCKET_URL, {
    shouldReconnect: () => true,
  });
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  useEffect(() => {
    if (isWaitingResponse) {
      const timeoutId = setTimeout(() => setIsWaitingResponse(false), TIMEOUT_MS);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isWaitingResponse]);

  const pushMessage = useCallback((message: ChatMessage) => {
    setMessages((curr) => [...curr.slice(-MAX_MESSAGE), message]);
  }, []);

  const onSendMessage = useCallback(
    (body: string) => {
      const messageId = ulid();
      const parentMessageId = messages[messages.length - 1]?.id ?? 'system';
      sendJsonMessage({
        conversationId: conversationIdRef.current,
        message: {
          content: [
            {
              body,
              contentType: 'text',
            },
          ],
          role: 'user',
          messageId: messageId,
          parentMessageId: parentMessageId,
        },
      } as ChatPayload);
      pushMessage({ id: messageId, type: MessageType.User, body });
      setIsWaitingResponse(true);
    },
    [messages, pushMessage, sendJsonMessage]
  );

  const onFeedback = useCallback(
    (messageId: string) => {
      fetchFeedback({
        conversationId: conversationIdRef.current,
        messageId: messageId,
      });
    },
    [fetchFeedback]
  );

  useEffect(() => {
    if (!lastJsonMessage) return;
    const response = (lastJsonMessage as ChatResponse) ?? {};
    pushMessage({
      type: MessageType.Assistant,
      body: response.body,
      id: response.messageId,
      isError: response.statusCode !== 200,
    });
    setIsWaitingResponse(false);
  }, [lastJsonMessage, pushMessage]);

  return { readyState, messages, isWaitingResponse, onSendMessage, onFeedback };
};
