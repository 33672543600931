import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, ErrorMessageQueueBox, useForm } from 'common-components';
import { TFunction } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CreateListOutput, useCreateListByConnectionId } from '../../selectors';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    listName: yup
      .string()
      .trim()
      .required('error.required')
      .max(100, `${t('error.maxLength', { ns: 'translation', length: 100 })}`)
      .matches(/^[\w\s.-]+$/, 'error.specialCharacters'),
  });
export const NewListDialog = ({
  open,
  connectionId,
  onClose,
  onSave,
}: {
  open: boolean;
  connectionId: string;
  onClose: () => void;
  onSave: (newList: CreateListOutput) => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [createListData, createList] = useCreateListByConnectionId(connectionId, (newList) => onSave(newList));
  const { registerField, handleSubmit, handleReset } = useForm<{ listName: string }>(
    useMemo(
      () => ({
        initialValues: {
          listName: '',
        },
        validationSchema: validationSchema(t),
      }),
      [t]
    )
  );

  useEffect(() => {
    if (open) {
      handleReset();
    }
  }, [handleReset, open]);

  const onSubmit = handleSubmit((values) => createList(values));

  return (
    <Dialog open={open} fullScreenBreakpoint="xs" maxWidth="sm" onClose={onClose}>
      <Box component="form" onSubmit={onSubmit} name="newlistform">
        <DialogTitle>
          <Typography variant="h6">Save new list</Typography>
        </DialogTitle>
        <DialogContent>
          <ErrorMessageQueueBox sx={{ mb: 2 }} errors={[createListData.error]} />
          <InputLabel>List name</InputLabel>
          <TextField {...registerField('listName')} inputProps={{ maxLength: 100 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="inherit" sx={{ minWidth: theme.typography.pxToRem(120) }}>
            {t('button.cancel')}
          </Button>
          <LoadingButton
            data-testid="newlistdialog-savebutton"
            variant="contained"
            color="primary"
            loading={!!createListData.requesting}
            sx={{ minWidth: theme.typography.pxToRem(120) }}
            onClick={onSubmit}
          >
            <Typography variant="button" color={theme.palette.common.black}>
              {t('button.save')}
            </Typography>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
