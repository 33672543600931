export enum MessageType {
  User = 'user',
  Assistant = 'assistant',
  AssistantIntro = 'assistantIntro',
  AssistantResponding = 'assistantResponding',
}

export interface ChatMessage {
  id: string;
  body: string;
  type: MessageType;
  isError?: boolean;
}

export interface ChatPayload {
  conversationId: string;
  message: {
    content: [{ body: string; contentType: 'text' }];
    role: 'user';
    messageId: string;
    parentMessageId: string;
  };
}

export interface ChatResponse {
  statusCode: number;
  body: string;
  messageId: string;
  conversationId: string;
}
