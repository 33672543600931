var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, DialogActions, FormControl, Grid, IconButton, InputLabel, MenuItem, TextField, Typography, styled, } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageBox, FallbackComponent, Select } from '../common';
import { Dialog, DialogContent, DialogTitle } from '../common/dialog';
import { PasswordInput } from '../common/passwordinput';
import { KeyAccess } from '../enum';
import { useForm } from '../hooks';
import { ConnectionBusinessTypeInfo } from './connectionBusinessTypeInfo';
import { validateMetaConnectionSchema } from './constants';
import { ConnectionStatus } from './selectors';
var ConstTextFieldStyle = styled(TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        fieldset: { border: 'none' },
        input: { WebkitTextFillColor: "".concat(theme.palette.text.primary, "!important") },
    });
});
var InputLabelStyle = styled(InputLabel)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.primary,
    });
});
export var MetaConnectorDialog = function (_a) {
    var open = _a.open, connectionData = _a.connectionData, connectionBusinessType = _a.connectionBusinessType, props = __rest(_a, ["open", "connectionData", "connectionBusinessType"]);
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _b = useForm(useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return ({
            initialValues: {
                accessToken: ((_b = (_a = connectionData[0]) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.accessToken) || '',
                adsAccountId: ((_d = (_c = connectionData[0]) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.adsAccountId) || '',
                access: ((_e = connectionData[0]) === null || _e === void 0 ? void 0 : _e.access) || 0,
                status: (_g = (_f = connectionData[0]) === null || _f === void 0 ? void 0 : _f.status) !== null && _g !== void 0 ? _g : ConnectionStatus.Enabled,
            },
            validationSchema: validateMetaConnectionSchema(t),
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t])), values = _b.values, isDirty = _b.isDirty, handleChange = _b.handleChange, handleSubmit = _b.handleSubmit, handleReset = _b.handleReset, getError = _b.getError;
    var onCancel = function () {
        var _a;
        handleReset();
        (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    var onSubmit = handleSubmit(function (castedValues) {
        var _a;
        props.onSubmit(__assign(__assign({}, connectionData[0]), { connectionTypeId: props.connectionTypeId, orgId: (_a = props.orgId) !== null && _a !== void 0 ? _a : '', details: {
                accessToken: castedValues.accessToken,
                adsAccountId: castedValues.adsAccountId,
            }, access: castedValues.access, status: castedValues.status }));
    });
    return (_jsx(Dialog, __assign({ maxWidth: "sm", open: open, onClose: onCancel }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: onSubmit }, { children: [_jsxs(DialogTitle, { children: [_jsxs(Typography, __assign({ marginRight: "auto", variant: "h6" }, { children: [' ', t('tagDialog.title'), ' '] })), _jsx(IconButton, __assign({ "data-testid": "close-button", onClick: onCancel }, { children: _jsx(FontAwesomeIcon, { icon: ['fal', 'xmark-large'], fontSize: "1.5rem" }) }))] }), _jsx(DialogContent, __assign({ sx: { padding: '0 2.25rem 1.5rem 2.25rem' } }, { children: _jsxs(FallbackComponent, __assign({ requesting: props.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { error: props.externalError }), _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 9 }, { children: [_jsxs(InputLabelStyle, { children: [t('tagDialog.provider'), " "] }), _jsx(ConstTextFieldStyle, { inputProps: { style: { fontSize: '1rem' } }, value: props.provider, fullWidth: true, disabled: true })] })), _jsxs(Grid, __assign({ item: true, xs: 3 }, { children: [_jsx(InputLabelStyle, { children: "\u00A0 " }), _jsx(ConnectionBusinessTypeInfo, { connectionBusinessType: connectionBusinessType })] })), _jsxs(Grid, __assign({ item: true, xs: 8 }, { children: [_jsxs(InputLabelStyle, { children: [t('metaConnectorDialog.access'), " "] }), _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(Select, __assign({ name: "access", SelectDisplayProps: { style: { fontSize: '1rem', lineHeight: '1.4375rem' } }, value: values.access, onChange: function (e) { return handleChange(e); } }, { children: Object.keys(KeyAccess).map(function (key, index) {
                                                        var keyName = KeyAccess[key];
                                                        return (_jsxs(MenuItem, __assign({ sx: { fontSize: '1rem' }, value: index }, { children: [' ', keyName, ' '] }), keyName));
                                                    }) })) }))] })), _jsxs(Grid, __assign({ item: true, xs: 4 }, { children: [_jsxs(InputLabelStyle, { children: [t('metaConnectorDialog.status'), " "] }), _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(Select, __assign({ name: "status", SelectDisplayProps: { style: { fontSize: '1rem', lineHeight: '1.4375rem' } }, value: values.status, onChange: function (e) { return handleChange(e); } }, { children: Object.keys(ConnectionStatus)
                                                        .filter(function (key) { return isNaN(Number(key)); })
                                                        .map(function (connectionKey) {
                                                        var value = ConnectionStatus[connectionKey];
                                                        if (value !== ConnectionStatus.Pending) {
                                                            return (_jsxs(MenuItem, __assign({ sx: { fontSize: '1rem' }, value: value }, { children: [' ', connectionKey, ' '] }), value));
                                                        }
                                                        return null;
                                                    }) })) }))] })), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(InputLabelStyle, { children: t('metaConnectorDialog.adsAccountId') }), _jsx(PasswordInput, { "data-testid": "text-field-ads-account-id", variant: "outlined", fullWidth: true, InputProps: { name: 'adsAccountId' }, inputProps: { inputMode: 'text', maxLength: 125, style: { fontSize: '1rem' } }, value: values.adsAccountId, defaultShowText: !values.adsAccountId, onChange: handleChange, error: !!getError('adsAccountId'), helperText: t(getError('adsAccountId')) })] })), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(InputLabelStyle, { children: t('metaConnectorDialog.accessToken') }), _jsx(PasswordInput, { "data-testid": "text-field-access-token", variant: "outlined", fullWidth: true, InputProps: { name: 'accessToken' }, inputProps: { inputMode: 'text', maxLength: 500, style: { fontSize: '1rem' } }, value: values.accessToken, defaultShowText: !values.accessToken, onChange: handleChange, error: !!getError('accessToken'), helperText: t(getError('accessToken')) })] }))] }))] })) })), _jsxs(DialogActions, __assign({ sx: { gap: 3.5, padding: '0 2.25rem 2.25rem 2.25rem' }, disableSpacing: true }, { children: [_jsx(Button, __assign({ type: "submit", variant: "contained", disabled: props.requesting || !isDirty }, { children: t('button.apply', { ns: 'translation' }) })), _jsx(Button, __assign({ variant: "outlined", color: "inherit", disabled: props.requesting, onClick: onCancel }, { children: t('button.cancel', { ns: 'translation' }) }))] }))] })) })));
};
