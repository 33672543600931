import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, styled } from '@mui/material';
import { useActiveFullscreenSession } from 'common-components';
import { useMemo } from 'react';
import { RpeB2cResultsType } from '../types';
import { DescriptiveContent } from './descriptivecontent';
import { MetricsContent } from './metricscontent';
import { RateMetricsBoxes } from './ratemetricboxes';
import { useViewModeState } from './selectors';
import { RPEViewMode } from './types';

const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: '#656565',
  borderRadius: theme.typography.pxToRem(10),
  marginInline: theme.spacing(4),
  // don't allow break page inside box content when print pdf
  pageBreakInside: 'avoid',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  boxShadow: '0px 0px 2px rgba(20, 24, 47, 0.1), 0px 2px 4px rgba(13, 16, 33, 0.08)',
  borderRadius: theme.typography.pxToRem(10),
  padding: theme.spacing(2),
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  userSelect: 'none',
  gap: theme.spacing(2),
}));

const StyledBoxIcon = styled(Box)(({ theme }) => ({
  position: 'relative',
  float: 'right',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.text.primary,
  borderRadius: '50%',
  display: 'inline-flex',
  padding: theme.spacing(0.5),
}));

export interface RPETileProps {
  data: RpeB2cResultsType;
  activeFullScreen?: string;
}

const RPETileContent: Record<RPEViewMode, React.FC<RPETileProps>> = {
  [RPEViewMode.Descriptive]: DescriptiveContent,
  [RPEViewMode.Metrics]: MetricsContent,
};

export const RPETile: React.FC<RPETileProps> = ({ data }) => {
  const [activeFullScreen] = useActiveFullscreenSession();
  const [viewMode, setViewMode] = useViewModeState();

  const ContentComponent = useMemo(() => viewMode && RPETileContent[viewMode as RPEViewMode], [viewMode]);

  const handleChangeViewMode = () => {
    if (viewMode === RPEViewMode.Metrics) {
      setViewMode(RPEViewMode.Descriptive);
    } else {
      setViewMode(RPEViewMode.Metrics);
    }
  };

  if (activeFullScreen)
    return (
      <StyledBoxWrapper data-testid="rpe-tile" sx={{ borderWidth: '1px', padding: 4 }} flexDirection="row">
        <Box display={'flex'} flex={7}>
          <StyledPaper
            data-testid="rpe-tile-btn"
            onClick={handleChangeViewMode}
            sx={{ minHeight: '11rem', padding: 3 }}
          >
            {ContentComponent && <ContentComponent data={data} activeFullScreen={activeFullScreen} />}
          </StyledPaper>
        </Box>
        <Box display="flex" flex={5} justifyContent="center">
          <RateMetricsBoxes profileStats={data.profileStats} />
        </Box>
      </StyledBoxWrapper>
    );

  return (
    <StyledBoxWrapper data-testid="rpe-tile" flexDirection={'column'} flex={1}>
      <Box display={'flex'} flex={1}>
        <StyledPaper data-testid="rpe-tile-btn" onClick={handleChangeViewMode}>
          {ContentComponent && <ContentComponent data={data} />}
          <Box position={'relative'}>
            <StyledBoxIcon>
              <FontAwesomeIcon
                style={{ marginLeft: 0, width: '0.7rem', height: '0.7rem' }}
                icon={viewMode === RPEViewMode.Metrics ? ['fal', 'person'] : ['fal', 'info']}
              />
            </StyledBoxIcon>
          </Box>
        </StyledPaper>
      </Box>
      {viewMode === RPEViewMode.Descriptive ? (
        <Box display="flex" justifyContent="center">
          <RateMetricsBoxes profileStats={data.profileStats} />
        </Box>
      ) : (
        <Box height="1rem" />
      )}
    </StyledBoxWrapper>
  );
};
