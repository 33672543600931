var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ClickAwayListener, IconButton, ListItemIcon, MenuItem, Typography } from '@mui/material';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withSuspense } from 'react-suspenser';
import { Authenticated, Menu, Tooltip } from '../common';
import { Site, SiteContextValue } from '../context';
import { useUserSessionAPI } from '../selectors';
export var UserMenu = withSuspense()(function (_a) {
    var doActionMenu = _a.doActionMenu, options = _a.options;
    var t = useTranslation(['usermenu', 'navbar']).t;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var site = useContext(SiteContextValue).site;
    var userSessionData = useUserSessionAPI()[0];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var handleTooltipClose = function () {
        setOpen(false);
    };
    var handleTooltipOpen = function () {
        setOpen(true);
    };
    var handleClick = function (event) {
        setOpen(false);
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var tooltipTitle = useMemo(function () {
        var _a, _b, _c, _d;
        if (site !== Site.Portal || !userSessionData.data) {
            return t('userMenu', { ns: 'navbar' });
        }
        return (_jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", fontWeight: "bold" }, { children: [(_a = userSessionData.data) === null || _a === void 0 ? void 0 : _a.firstName, (_b = userSessionData.data) === null || _b === void 0 ? void 0 : _b.lastName].join(' ') })), _jsx(Typography, __assign({ variant: "body2", fontWeight: "light" }, { children: (_d = (_c = userSessionData.data) === null || _c === void 0 ? void 0 : _c.organization) === null || _d === void 0 ? void 0 : _d.name }))] }));
    }, [site, t, userSessionData.data]);
    return (_jsxs(_Fragment, { children: [_jsx(ClickAwayListener, __assign({ onClickAway: handleTooltipClose }, { children: _jsx(Box, __assign({ component: "div" }, { children: _jsx(Tooltip, __assign({ open: open && !anchorEl, onClose: handleTooltipClose, onOpen: handleTooltipOpen, title: tooltipTitle, placement: "right", disableInteractive: true, componentsProps: { tooltip: { sx: { fontWeight: 'bold', maxWidth: 'unset' } } }, disableFocusListener: true, disableHoverListener: !!anchorEl }, { children: _jsx(IconButton, __assign({ className: "rounded-circle", onClick: handleClick }, { children: _jsx(FontAwesomeIcon, { fontSize: "2.625rem", icon: ['fal', 'circle-user'] }) })) })) })) })), _jsx(Menu, __assign({ anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, onClick: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                } }, { children: _.map(options, function (menu, key) { return (_jsx(Authenticated, __assign({ featureAccess: menu.accessFeature }, { children: _jsxs(MenuItem, __assign({ onClick: doActionMenu[key], component: Link, to: menu.url }, { children: [_jsx(ListItemIcon, { children: _jsx(FontAwesomeIcon, { fontSize: "1.375rem", icon: menu.icon }) }), t(key)] })) }), key)); }) }))] }));
});
