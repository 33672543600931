var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { Map } from 'immutable';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_URL } from '../../constants';
import { useAxios } from './axios';
import { FETCH_URL_FAILED, FETCH_URL_RESET, FETCH_URL_START, FETCH_URL_SUCCESS } from './reducer';
var DEFAULT_TIMEOUT = 60000; // One Minute Timeout
var substituteParams = function (str, params) {
    return params !== undefined
        ? Object.keys(params).reduce(function (current, key) { var _a; return current.replace("{".concat(key, "}"), "".concat((_a = params[key]) !== null && _a !== void 0 ? _a : '')); }, str)
        : str;
};
var startFetch = function (key, method, timeout, abortController) { return ({
    type: FETCH_URL_START,
    payload: { key: key, method: method, timeout: timeout, abortController: abortController },
}); };
var successFetch = function (key, method, data, statusCode) { return ({
    type: FETCH_URL_SUCCESS,
    payload: { key: key, method: method, data: data, statusCode: statusCode },
}); };
var failFetch = function (key, method, error, statusCode) { return ({
    type: FETCH_URL_FAILED,
    payload: {
        key: key,
        method: method,
        error: statusCode === 502 || statusCode === 504
            ? 'The system is currently busy, please try again in a couple of minutes'
            : error,
        statusCode: statusCode,
    },
}); };
var resetState = function (key, method) { return ({
    type: FETCH_URL_RESET,
    payload: { key: key, method: method },
}); };
var APIClient = /** @class */ (function () {
    function APIClient(url, key, method) {
        this.url = url;
        this.key = key;
        this.method = method;
    }
    APIClient.prototype.getKey = function () {
        return this.key;
    };
    APIClient.prototype.getMethod = function () {
        return this.method;
    };
    APIClient.prototype.getUrl = function () {
        return this.url;
    };
    APIClient.prototype.abort = function (state) {
        var _a, _b;
        (_b = (_a = state.api.get("".concat(this.key, ":").concat(this.method))) === null || _a === void 0 ? void 0 : _a.get('abortController')) === null || _b === void 0 ? void 0 : _b.abort();
    };
    APIClient.prototype.resetState = function () {
        var _this = this;
        return function (dispatch, getState) {
            _this.abort(getState());
            dispatch(resetState(_this.getKey(), _this.getMethod()));
        };
    };
    APIClient.prototype.request = function (timeout, axiosInstance, body, parseData, responseType) {
        var _this = this;
        if (timeout === void 0) { timeout = DEFAULT_TIMEOUT; }
        return function (dispatch, getState) { return __awaiter(_this, void 0, void 0, function () {
            var abortController, headers, request, response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.url || !axiosInstance)
                            return [2 /*return*/];
                        this.abort(getState());
                        abortController = new AbortController();
                        dispatch(startFetch(this.key, this.method, Date.now() + timeout, abortController));
                        headers = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 5]);
                        return [4 /*yield*/, axiosInstance({
                                url: this.url,
                                method: this.method,
                                signal: abortController.signal,
                                data: body,
                                headers: headers,
                                responseType: responseType,
                            })];
                    case 2:
                        request = _a.sent();
                        response = parseData ? parseData(request.data) : request.data;
                        if (response === null || response === void 0 ? void 0 : response.errorMessage) {
                            dispatch(failFetch(this.key, this.method, { type: response === null || response === void 0 ? void 0 : response.errorMessage }, request.status));
                            return [2 /*return*/];
                        }
                        dispatch(successFetch(this.key, this.method, response, request.status));
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        return [4 /*yield*/, this._handleRequestException(e_1, dispatch)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
    };
    APIClient.prototype._handleRequestException = function (e, dispatch) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var data, _d, _e, message;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!axios.isCancel(e)) return [3 /*break*/, 1];
                        return [3 /*break*/, 5];
                    case 1:
                        if (!axios.isAxiosError(e)) return [3 /*break*/, 4];
                        data = ((_a = e.response) !== null && _a !== void 0 ? _a : {}).data;
                        if (!(data instanceof Blob && data.type === 'application/json')) return [3 /*break*/, 3];
                        _e = (_d = JSON).parse;
                        return [4 /*yield*/, data.text()];
                    case 2:
                        data = _e.apply(_d, [_f.sent()]);
                        _f.label = 3;
                    case 3:
                        message = (data === null || data === void 0 ? void 0 : data.message) || data || e.message;
                        dispatch(failFetch(this.key, this.method, (message === null || message === void 0 ? void 0 : message.type) ? message : { type: message }, (_b = e.response) === null || _b === void 0 ? void 0 : _b.status));
                        if (((_c = e.response) === null || _c === void 0 ? void 0 : _c.status) === 440) {
                            window.open(LOGOUT_URL, '_self');
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        console.error(e);
                        dispatch(failFetch(this.key, this.method, { type: 'Internal Server Error' }, 500));
                        _f.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return APIClient;
}());
export { APIClient };
export var useAPI = function (url, substitutions, method, key, parseData) {
    if (substitutions === void 0) { substitutions = {}; }
    if (method === void 0) { method = 'GET'; }
    if (key === void 0) { key = url; }
    var parseDataRef = useRef();
    parseDataRef.current = parseData;
    var dispatch = useDispatch();
    var substitutedUrl = substituteParams(url, substitutions);
    var substitutedKey = substituteParams(key, substitutions);
    var axiosInstance = useAxios();
    var client = useMemo(function () { return new APIClient(substitutedUrl, substitutedKey, method); }, [substitutedUrl, substitutedKey, method]);
    var doRequest = useCallback(function (body, force, timeout, responseType) {
        if (timeout === void 0) { timeout = DEFAULT_TIMEOUT; }
        return dispatch(client.request(timeout, axiosInstance.current, body, parseDataRef.current, responseType));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, dispatch]);
    var data = useSelector(function (state) {
        return state.api.get("".concat(client.getKey(), ":").concat(client.getMethod()), Map({})).toObject();
    });
    var resetStateAPI = useCallback(function () { return dispatch(client.resetState()); }, [client, dispatch]);
    return [data, doRequest, resetStateAPI];
};
export var useCachedData = function (url, substitutions, method, key, parseData) {
    if (method === void 0) { method = 'GET'; }
    if (key === void 0) { key = url; }
    var _a = useAPI(url, substitutions, method, key, parseData), data = _a[0], doRequest = _a[1], resetStateAPI = _a[2];
    var ignoreRequest = useRef(false);
    ignoreRequest.current = !!data.data || !!data.requesting;
    var fetchData = useCallback(function (body, force, timeout) {
        if (force || !ignoreRequest.current) {
            doRequest(body, force, timeout);
        }
    }, [doRequest]);
    return [data, fetchData, resetStateAPI];
};
export * from './types';
export * from './utils';
