var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, DialogActions, FormControl, Grid, IconButton, InputLabel, MenuItem, TextField, Typography, styled, } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageBox, FallbackComponent, Select } from '../common';
import { Dialog, DialogContent, DialogTitle } from '../common/dialog';
import { PasswordInput } from '../common/passwordinput';
import { KeyAccess } from '../enum';
import { useForm } from '../hooks';
import { ConnectionBusinessTypeInfo } from './connectionBusinessTypeInfo';
import { validationSchema } from './constants';
import { ConnectionStatus } from './selectors';
var StyledInputLabel = styled(InputLabel)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.primary,
    });
});
var StyledConstTextField = styled(TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        input: { WebkitTextFillColor: "".concat(theme.palette.text.primary, "!important") },
        fieldset: { border: 'none' },
    });
});
export var APIKeyDialog = function (_a) {
    var open = _a.open, connectionData = _a.connectionData, enableAccessConfig = _a.enableAccessConfig, connectionBusinessType = _a.connectionBusinessType, props = __rest(_a, ["open", "connectionData", "enableAccessConfig", "connectionBusinessType"]);
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _b = useForm(useMemo(function () {
        var _a, _b, _c, _d, _e;
        return ({
            initialValues: {
                apiKey: ((_b = (_a = connectionData[0]) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.apiKey) || '',
                status: (_d = (_c = connectionData[0]) === null || _c === void 0 ? void 0 : _c.status) !== null && _d !== void 0 ? _d : ConnectionStatus.Enabled,
                access: ((_e = connectionData[0]) === null || _e === void 0 ? void 0 : _e.access) || 0,
                enableAccessConfig: enableAccessConfig,
            },
            validationSchema: validationSchema(t),
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t])), values = _b.values, isDirty = _b.isDirty, getError = _b.getError, handleChange = _b.handleChange, handleSubmit = _b.handleSubmit, handleReset = _b.handleReset;
    var onSubmit = handleSubmit(function (castedValues) {
        var _a;
        props.onSubmit(__assign(__assign({}, connectionData[0]), { orgId: (_a = props.orgId) !== null && _a !== void 0 ? _a : '', details: {
                apiKey: castedValues.apiKey,
            }, status: castedValues.status, access: castedValues.access, connectionTypeId: props.connectionTypeId }));
    });
    var onCancel = function () {
        var _a;
        handleReset();
        (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return (_jsx(Dialog, __assign({ open: open, maxWidth: "sm", onClose: onCancel }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: onSubmit }, { children: [_jsxs(DialogTitle, { children: [_jsx(Typography, __assign({ variant: "h6", marginRight: "auto" }, { children: t('tagDialog.title') })), _jsx(IconButton, __assign({ onClick: onCancel, "data-testid": "close-button" }, { children: _jsx(FontAwesomeIcon, { fontSize: "1.5rem", icon: ['fal', 'xmark-large'] }) }))] }), _jsx(DialogContent, __assign({ sx: { padding: '0 2.25rem 1.5rem 2.25rem' } }, { children: _jsxs(FallbackComponent, __assign({ requesting: props.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { error: props.externalError }), _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 9 }, { children: [_jsx(StyledInputLabel, { children: t('tagDialog.provider') }), _jsx(StyledConstTextField, { fullWidth: true, inputProps: { style: { fontSize: '1rem' } }, value: props.provider, disabled: true })] })), _jsxs(Grid, __assign({ item: true, xs: 3 }, { children: [_jsx(StyledInputLabel, { children: "\u00A0" }), _jsx(ConnectionBusinessTypeInfo, { connectionBusinessType: connectionBusinessType })] })), values.enableAccessConfig ? (_jsxs(Grid, __assign({ item: true, xs: 8 }, { children: [_jsx(StyledInputLabel, { children: t('apiKeyDialog.access') }), _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(Select, __assign({ name: "access", value: values.access, onChange: function (e) { return handleChange(e); }, SelectDisplayProps: { style: { fontSize: '1rem', lineHeight: '1.4375rem' } } }, { children: Object.keys(KeyAccess).map(function (key, index) {
                                                        var name = KeyAccess[key];
                                                        return (_jsx(MenuItem, __assign({ sx: { fontSize: '1rem' }, value: index }, { children: name }), name));
                                                    }) })) }))] }))) : null, _jsxs(Grid, __assign({ item: true, xs: values.enableAccessConfig ? 4 : 12 }, { children: [_jsx(StyledInputLabel, { children: t('apiKeyDialog.status') }), _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(Select, __assign({ name: "status", value: values.status, onChange: function (e) { return handleChange(e); }, SelectDisplayProps: { style: { fontSize: '1rem', lineHeight: '1.4375rem' } } }, { children: Object.keys(ConnectionStatus)
                                                        .filter(function (key) { return isNaN(Number(key)); })
                                                        .map(function (key) {
                                                        var value = ConnectionStatus[key];
                                                        if (value === ConnectionStatus.Pending) {
                                                            return null;
                                                        }
                                                        return (_jsx(MenuItem, __assign({ sx: { fontSize: '1rem' }, value: value }, { children: key }), value));
                                                    }) })) }))] })), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(StyledInputLabel, { children: t('apiKeyDialog.apiKey') }), _jsx(PasswordInput, { "data-testid": "text-field-api-key", variant: "outlined", InputProps: { name: 'apiKey' }, inputProps: { inputMode: 'text', maxLength: 125, style: { fontSize: '1rem' } }, fullWidth: true, onChange: handleChange, value: values.apiKey, defaultShowText: !values.apiKey, error: !!getError('apiKey'), helperText: t(getError('apiKey')) })] }))] }))] })) })), _jsxs(DialogActions, __assign({ sx: { gap: 3.5, padding: '0 2.25rem 2.25rem 2.25rem' }, disableSpacing: true }, { children: [_jsx(Button, __assign({ type: "submit", variant: "contained", disabled: props.requesting || !isDirty }, { children: t('button.apply', { ns: 'translation' }) })), _jsx(Button, __assign({ onClick: onCancel, variant: "outlined", color: "inherit", disabled: props.requesting }, { children: t('button.cancel', { ns: 'translation' }) }))] }))] })) })));
};
