import { ThemeProvider } from '@mui/material';
import { FallbackComponent, themeLevel5 } from 'common-components';
import { useCallback, useEffect, useState } from 'react';
import { WriteAccessConnection, useGetWriteAccessConnections } from '../../selectors';
import { AudienceScheduleForm } from '../../types';
import { ScheduleDetail } from './detail';
import { ScheduleList } from './list';

interface ScheduleCardProps {
  schedules?: AudienceScheduleForm[];
  onSave: (schedule: AudienceScheduleForm) => void;
  onDelete: (schedule: AudienceScheduleForm) => void;
}

enum ScheduleView {
  List,
  Edit,
}

export const ScheduleCard: React.FC<ScheduleCardProps> = ({ schedules, onDelete, onSave }) => {
  const [view, setView] = useState<ScheduleView>(ScheduleView.List);
  const [selectedSchedule, setSelectedSchedule] = useState<AudienceScheduleForm | null>(null);
  const [{ data: connections = [], requesting: connectionsRequesting }, fetchConnections, clearConnections] =
    useGetWriteAccessConnections();

  useEffect(() => {
    fetchConnections();
    return () => clearConnections();
  }, [fetchConnections, clearConnections]);

  const getAvailableConnections = useCallback(
    (includedId?: string) => {
      const availableConnections = [];
      let includeConnection!: WriteAccessConnection;
      for (const conn of connections) {
        if (!schedules?.some((schedule) => schedule.connectionId === conn.id)) {
          availableConnections.push(conn);
        }
        if (includedId && includedId === conn.id) {
          includeConnection = conn;
        }
      }
      return includeConnection ? [includeConnection, ...availableConnections] : availableConnections;
    },
    [schedules, connections]
  );

  const resetStates = () => {
    setSelectedSchedule(null);
    setView(ScheduleView.List);
  };

  const onEditSchedule = useCallback(
    (schedule: AudienceScheduleForm) => {
      setSelectedSchedule(schedule);
      setView(ScheduleView.Edit);
    },
    [setSelectedSchedule]
  );

  const onSaveSchedule = useCallback(
    (values: AudienceScheduleForm) => {
      const connection = connections.find((conn) => conn.id === values.connectionId) as WriteAccessConnection;
      const schedule: AudienceScheduleForm = {
        __internalId: values.__internalId,
        id: selectedSchedule?.id,
        audienceId: selectedSchedule?.audienceId,
        connectionId: values.connectionId,
        connectionName: connection.connectionName,
        connectionSource: connection.connectionSource,
        frequency: values.frequency,
        time: values.time,
        day: values.day,
        list: values.list as string,
        scope: values.scope,
        fromDate: values.fromDate,
        toDate: values.toDate,
        nextFireTime: new Date(),
      };
      onSave(schedule);
      resetStates();
    },
    [onSave, selectedSchedule, connections]
  );

  const renderView = useCallback(() => {
    if (view === ScheduleView.Edit) {
      return (
        <ScheduleDetail
          data={selectedSchedule}
          connections={getAvailableConnections(selectedSchedule?.connectionId)}
          onClose={resetStates}
          onSave={onSaveSchedule}
        />
      );
    }

    return (
      <ScheduleList
        schedules={schedules}
        connections={connections}
        addPermission={getAvailableConnections().length > 0}
        onDelete={onDelete}
        onAdd={() => setView(ScheduleView.Edit)}
        onEdit={onEditSchedule}
      />
    );
  }, [
    view,
    selectedSchedule,
    schedules,
    onDelete,
    onEditSchedule,
    onSaveSchedule,
    getAvailableConnections,
    connections,
  ]);

  return (
    <ThemeProvider theme={themeLevel5}>
      <FallbackComponent requesting={!!connectionsRequesting}>{renderView()}</FallbackComponent>
    </ThemeProvider>
  );
};
