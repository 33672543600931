import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, styled } from '@mui/material';
import { useRectSize } from 'common-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFitText from 'use-fit-text';
import { generateSentence } from './constant';
import { RPETileProps } from './rpetile';

const MIN_FONT_SIZE = 60;

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  flexBasis: 0,
  overflow: 'hidden',
  fontSize: '1rem',
}));

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export const DescriptiveContent: React.FC<RPETileProps> = ({ data: { profile }, activeFullScreen }) => {
  const { t } = useTranslation('360analysis');
  const { fontSize, ref } = useFitText({ logLevel: 'debug', minFontSize: MIN_FONT_SIZE });
  const [containerRef, rect] = useRectSize();

  const content = useMemo(() => generateSentence(profile, t), [profile, t]);

  return (
    <StyledBox data-testid="descriptive-content">
      <Box
        display={'flex'}
        height="100%"
        alignItems={'center'}
        sx={{ padding: 2, paddingInline: activeFullScreen && 4, paddingBlock: activeFullScreen && 8 }}
      >
        <Box ref={containerRef} height="100%">
          <StyledIcon fontSize={rect?.height ?? 0} icon={['fal', 'person']} />
        </Box>
      </Box>
      <Typography ref={ref} component={'div'} flex={1} overflow={'hidden'} fontSize={fontSize} maxHeight={'100%'}>
        {content}
      </Typography>
    </StyledBox>
  );
};
