var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Typography } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from '.';
export var HelpDialog = function (_a) {
    var open = _a.open, title = _a.title, messages = _a.messages, onCancel = _a.onCancel;
    return (_jsxs(Dialog, __assign({ "data-testid": "help-dialog", open: open, maxWidth: "sm", onClose: onCancel }, { children: [_jsxs(DialogTitle, { children: [_jsx(Typography, __assign({ variant: "h6", marginRight: "auto", color: "success.main" }, { children: title })), _jsx(IconButton, __assign({ onClick: onCancel }, { children: _jsx(FontAwesomeIcon, { fontSize: "1.5rem", icon: ['fal', 'xmark-large'] }) }))] }), _jsx(DialogContent, { children: messages === null || messages === void 0 ? void 0 : messages.map(function (value) { return (_jsx(Typography, __assign({ gutterBottom: true }, { children: value }), value)); }) })] })));
};
