import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
export var useStateParam = function (props) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var _b = props, initialParam = _b.initialParam, initialState = _b.initialState, paramName = _b.paramName, validator = _b.validator, _c = _b.serialize, serialize = _c === void 0 ? function (state) { return state; } : _c, _d = _b.deserialize, deserialize = _d === void 0 ? function (state) { return state; } : _d;
    var _e = useState(), state = _e[0], setState = _e[1];
    var existingValue = useMemo(function () { var _a; return (_a = searchParams.get(paramName)) !== null && _a !== void 0 ? _a : ''; }, [paramName, searchParams]);
    var handleDeserialize = useCallback(function (value) {
        var newState = value && deserialize(value);
        if (!newState || (validator && !validator(newState)))
            return initialState;
        return newState;
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialState, validator]);
    var updateSearchParam = useCallback(function (s) {
        setSearchParams(function (prev) {
            prev.set(paramName, serialize(s));
            return prev;
        }, { replace: true });
    }, [paramName, serialize, setSearchParams]);
    var onChange = function (s) {
        updateSearchParam(s);
    };
    //initial state on url search param
    useEffect(function () {
        if (!existingValue && initialState && initialParam) {
            updateSearchParam(initialState);
        }
    }, [existingValue, initialState, initialParam, updateSearchParam]);
    //map state from url search param
    useEffect(function () {
        setState(handleDeserialize(existingValue));
    }, [existingValue, handleDeserialize]);
    return [state, onChange];
};
