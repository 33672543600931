var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, DialogActions, FormControl, Grid, IconButton, InputLabel, MenuItem, TextField, Typography, styled, } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageBox, FallbackComponent, Select } from '../common';
import { Dialog, DialogContent, DialogTitle } from '../common/dialog';
import { ConnectionBusinessTypeInfo } from './connectionBusinessTypeInfo';
import { ConnectionStatus, useOAuth2Mailchimp } from './selectors';
var StyledInputLabel = styled(InputLabel)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.primary,
    });
});
var StyledConstTextField = styled(TextField)(function (_a) {
    var theme = _a.theme;
    return ({
        input: { WebkitTextFillColor: "".concat(theme.palette.text.primary, "!important") },
        fieldset: { border: 'none' },
    });
});
export var MailChimpDialog = function (_a) {
    var _b, _c;
    var open = _a.open, connectionData = _a.connectionData, connectionBusinessType = _a.connectionBusinessType, props = __rest(_a, ["open", "connectionData", "connectionBusinessType"]);
    var t = useTranslation(['accountmanagement', 'translation']).t;
    var _d = useOAuth2Mailchimp(), oAuth2Data = _d[0], fetchOAuth2Mailchimp = _d[1], clearOAuth2Mailchimp = _d[2];
    useEffect(function () {
        return function () {
            clearOAuth2Mailchimp();
        };
    }, [clearOAuth2Mailchimp]);
    useEffect(function () {
        var _a, _b;
        if ((_a = oAuth2Data.data) === null || _a === void 0 ? void 0 : _a.url) {
            window.open("".concat((_b = oAuth2Data.data) === null || _b === void 0 ? void 0 : _b.url), '_blank');
        }
    }, [oAuth2Data]);
    var onCancel = function () {
        var _a;
        clearOAuth2Mailchimp();
        (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    var getContentMessage = function () {
        var _a, _b, _c;
        switch ((_b = (_a = oAuth2Data.data) === null || _a === void 0 ? void 0 : _a.connection.status) !== null && _b !== void 0 ? _b : (_c = connectionData[0]) === null || _c === void 0 ? void 0 : _c.status) {
            case ConnectionStatus.Enabled:
                return t('mailchimp.enableMessage');
            case ConnectionStatus.Disabled:
                return t('mailchimp.defaultMessage');
            case ConnectionStatus.Pending:
                return t('mailchimp.pendingMessage');
            default:
                return t('mailchimp.defaultMessage');
        }
    };
    return (_jsxs(Dialog, __assign({ open: open, maxWidth: "sm", onClose: onCancel }, { children: [_jsxs(DialogTitle, { children: [_jsx(Typography, __assign({ variant: "h6", marginRight: "auto" }, { children: t('mailchimp.title') })), _jsx(IconButton, __assign({ onClick: onCancel, "data-testid": "close-button" }, { children: _jsx(FontAwesomeIcon, { fontSize: "1.5rem", icon: ['fal', 'xmark-large'] }) }))] }), _jsx(DialogContent, __assign({ sx: { padding: '0 2.25rem 1.5rem 2.25rem' } }, { children: _jsxs(FallbackComponent, __assign({ requesting: oAuth2Data.requesting || props.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { error: oAuth2Data.error }), _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 9 }, { children: [_jsx(StyledInputLabel, { children: t('mailchimp.provider') }), _jsx(StyledConstTextField, { fullWidth: true, inputProps: { style: { fontSize: '1rem' } }, value: t('mailchimp.name'), disabled: true })] })), _jsxs(Grid, __assign({ item: true, xs: 3 }, { children: [_jsx(StyledInputLabel, { children: "\u00A0" }), _jsx(ConnectionBusinessTypeInfo, { connectionBusinessType: connectionBusinessType })] })), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(StyledInputLabel, { children: t('mailchimp.status') }), _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(Select, __assign({ name: "status", value: (_c = (_b = connectionData[0]) === null || _b === void 0 ? void 0 : _b.status) !== null && _c !== void 0 ? _c : ConnectionStatus.Disabled, SelectDisplayProps: { style: { fontSize: '1rem', lineHeight: '1.4375rem' } }, inputProps: { readOnly: true } }, { children: Object.keys(ConnectionStatus)
                                                    .filter(function (key) { return isNaN(Number(key)); })
                                                    .map(function (key) {
                                                    var value = ConnectionStatus[key];
                                                    return (_jsx(MenuItem, __assign({ sx: { fontSize: '1rem' }, value: value }, { children: key }), value));
                                                }) })) }))] })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(Box, __assign({ sx: {
                                            width: '100%',
                                            height: 132,
                                            borderRadius: '0.5rem',
                                            backgroundColor: '#424242',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        } }, { children: [_jsx(StyledInputLabel, { children: getContentMessage() }), _jsx(Button, __assign({ "data-testid": "goto-mailchimp-btn", sx: { marginTop: '0.5rem', width: '13.75rem' }, onClick: function () { return fetchOAuth2Mailchimp(); }, variant: "outlined", color: "inherit", disabled: props.disableOauth2 || oAuth2Data.requesting || props.requesting }, { children: t('mailchimp.btnGotoMailChimp') }))] })) }))] }))] })) })), _jsx(DialogActions, __assign({ sx: { gap: 3.5, padding: '0 2.25rem 2.25rem 2.25rem' }, disableSpacing: true }, { children: _jsx(Button, __assign({ onClick: onCancel, variant: "contained", disabled: props.requesting }, { children: t('button.close') })) }))] })));
};
