import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, MenuProps, styled } from '@mui/material';
import {
  Authenticated,
  ChartUtilProps,
  ChoiceItem,
  FeatureList,
  Menu,
  useActiveFullscreenSession,
  useAuthorize,
  useFullscreen,
  usePrintParam,
} from 'common-components';
import {
  IconMenuItem as MuiIconMenuItem,
  NestedMenuItem as MuiNestedMenuItem,
  NestedMenuItemProps,
} from 'mui-nested-menu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AudienceExportType } from '../audienceexport/enums';
import { TrafficInsightDialog } from '../trafficinsightdialog';
import { ContentType } from '../trafficinsightdialog/types';
import { VisitorBreakdownEnum, getMatchTypeFilter } from '../webresolution/constants';
import { useHandleExport } from '../webresolution/selectors';

const subMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
};

interface EngagementNestedProps<D extends Record<string, any>> extends ChartUtilProps<D> {
  isNoB2CData: boolean;
  isNoB2BData: boolean;
}
interface NestedChoiceItem extends ChoiceItem {
  subItems?: NestedChoiceItem[];
}

const Dropdown = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.primary}`,
  borderRadius: theme.typography.pxToRem(theme.shape.borderRadius / 2),
}));
const IconMenuItem = styled(MuiIconMenuItem)(() => ({
  '.MuiTypography-root': {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
}));
const NestedMenuItem = styled(MuiNestedMenuItem)<NestedMenuItemProps>(() => ({
  '.MuiTypography-root': {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
}));

export const EngagementNestedMenu = <D extends Record<string, any>>({
  isNoB2CData,
  isNoB2BData,
}: EngagementNestedProps<D>) => {
  const { t } = useTranslation(['chartmenu', 'webresolution']);
  const hasAudienceExportPermission = useAuthorize({ featureAccess: [FeatureList.AudienceExport] });
  const [printPDF] = usePrintParam();
  const [activeFSSession] = useActiveFullscreenSession();
  const [handleFullScreen] = useFullscreen();

  const [trafficInsightShow, setTrafficInsightShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [trafficType, setTrafficType] = useState<ContentType>();

  const handleExportB2C = useHandleExport(AudienceExportType.EmailsPlusB2CProspects, {
    filters: [getMatchTypeFilter(VisitorBreakdownEnum.EnrichedB2C)],
  });
  const handleExportB2B = useHandleExport(AudienceExportType.EmailsPlusB2BProspects, {
    filters: [getMatchTypeFilter(VisitorBreakdownEnum.EnrichedB2B)],
  });

  const handleClose = () => setAnchorEl(null);

  const handleShowTraffic = (type: ContentType) => {
    setTrafficType(type);
    setTrafficInsightShow(true);
  };

  const renderTrafficInsightDialog = () => {
    if (!trafficType) return null;
    return (
      <Authenticated featureAccess={[FeatureList.TrafficInsight]} hasPermission={!!trafficType}>
        <TrafficInsightDialog
          contentType={trafficType}
          show={trafficInsightShow}
          onHide={() => setTrafficInsightShow(false)}
        />
      </Authenticated>
    );
  };

  const items: NestedChoiceItem[] = [
    {
      name: t('fullScreen'),
      icon: ['fal', 'expand'] as IconProp,
      action: () => null,
      hidden: !!activeFSSession,
      subItems: [
        {
          name: t('fullScreenB2c'),
          icon: ['fal', 'expand'] as IconProp,
          action: () => handleFullScreen(VisitorBreakdownEnum.EnrichedB2C),
          hidden: !!activeFSSession,
        },
        {
          name: t('fullScreenB2b'),
          icon: ['fal', 'expand'] as IconProp,
          action: () => handleFullScreen(VisitorBreakdownEnum.EnrichedB2B),
          hidden: !!activeFSSession,
        },
      ],
    },
    {
      name: t('showTraffic'),
      icon: ['fal', 'route-interstate'],
      action: () => null,
      subItems: [
        {
          name: t('trafficB2c'),
          icon: ['fal', 'route-interstate'],
          action: () => handleShowTraffic(ContentType.B2C),
          disabled: isNoB2CData,
        },
        {
          name: t('trafficB2b'),
          icon: ['fal', 'route-interstate'],
          action: () => handleShowTraffic(ContentType.B2B),
          disabled: isNoB2BData,
        },
      ],
    },
    {
      name: t('webresolution:exportEmailsPlusB2CProspects'),
      icon: ['fal', 'file-export'] as IconProp,
      action: handleExportB2C,
      disabled: isNoB2CData,
      hidden: !hasAudienceExportPermission,
      subItems: [],
    },
    {
      name: t('webresolution:exportEmailsPlusB2BProspects'),
      icon: ['fal', 'file-export'] as IconProp,
      action: handleExportB2B,
      disabled: isNoB2BData,
      hidden: !hasAudienceExportPermission,
      subItems: [],
    },
  ];

  // recursion function to get nested menu items
  const getMenuItems = (item: NestedChoiceItem) => {
    if (!item.subItems || (item.subItems && item.subItems.length === 0)) {
      return (
        <IconMenuItem
          key={item.name}
          onClick={item.action}
          leftIcon={<FontAwesomeIcon fontSize="1.375rem" icon={item.icon} />}
          label={item.name}
          disabled={item.disabled}
        />
      );
    }
    return (
      <NestedMenuItem
        key={item.name}
        rightIcon={<FontAwesomeIcon fontSize="1.375rem" icon={['fal', 'chevron-right']} />}
        leftIcon={<FontAwesomeIcon fontSize="1.375rem" icon={item.icon} />}
        label={item.name}
        parentMenuOpen={!!anchorEl}
        MenuProps={subMenuProps}
      >
        {item.subItems?.map((ci) => getMenuItems(ci))}
      </NestedMenuItem>
    );
  };

  // hidden it when printpdf and full screen page
  if (printPDF || activeFSSession) return null;

  return (
    <Box>
      {renderTrafficInsightDialog()}
      <Dropdown onClick={(e: any) => setAnchorEl(e.currentTarget)}>
        <FontAwesomeIcon fontSize="1.125rem" icon={['fal', 'ellipsis']} />
      </Dropdown>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((ci) => !ci.hidden && getMenuItems(ci))}
      </Menu>
    </Box>
  );
};
