import { OrganizationStatus, useUserSessionAPI } from 'common-components';
import { useMemo } from 'react';
import { PageURL } from '../components/navbar/constants';

export const useDefaultPageUrl = () => {
  const [userSessionData] = useUserSessionAPI();
  const defaultPageUrl = useMemo(
    () =>
      userSessionData.data?.organization?.status === OrganizationStatus.Onboarding
        ? PageURL._Welcome
        : PageURL.Dashboard,
    [userSessionData.data?.organization?.status]
  );

  return defaultPageUrl;
};
