import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';

export const LearningInProgress: React.FC = withSuspense()(() => {
  const { t } = useTranslation('360analysis');

  return (
    <Stack height={'100%'} flex={1} alignItems="center" justifyContent="center" spacing={8.5} color="text.disabled">
      <FontAwesomeIcon fontSize="5rem" icon={['fal', 'face-thinking']} />
      <Typography fontWeight="medium" textAlign="center">
        {t('learningInProgress')}
      </Typography>
      <Typography textAlign="center">{t('yourResultWillAppearHereWhenReady')}</Typography>
    </Stack>
  );
});
