var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CardActions as CardActionsMUI, CardContent as CardContentMUI, CardHeader as CardHeaderMUI, Card as CardMUI, Typography, styled, } from '@mui/material';
import { setSxProps } from '../utils';
import { ParagraphTruncateTooltip } from './texttruncatetooltip';
var StyledCardActions = styled(CardActionsMUI)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(4),
        paddingTop: 0,
    });
});
var StyledCardHeader = styled(CardHeaderMUI)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: 0,
        '.MuiCardHeader-action': {
            margin: 0,
        },
    });
});
var StyledCardContent = styled(CardContentMUI)(function (_a) {
    var theme = _a.theme;
    return ({
        flex: 1,
        display: 'flex',
        padding: theme.spacing(4),
        '&:last-child': {
            paddingBottom: theme.spacing(4),
        },
    });
});
var StyledCard = styled(CardMUI)({
    display: 'flex',
    flexDirection: 'column',
});
export var CardHeader = function (_a) {
    var title = _a.title, titleTypographyProps = _a.titleTypographyProps, titleTruncate = _a.titleTruncate, titleMaxLine = _a.titleMaxLine, props = __rest(_a, ["title", "titleTypographyProps", "titleTruncate", "titleMaxLine"]);
    var getTitle = function () {
        if (typeof title === 'string') {
            return titleTruncate ? (_jsx(ParagraphTruncateTooltip, __assign({}, titleTypographyProps, { maxLine: titleMaxLine, variant: "h6", content: title }))) : (_jsx(Typography, __assign({}, titleTypographyProps, { variant: "h6" }, { children: title })));
        }
        return title;
    };
    return _jsx(StyledCardHeader, __assign({ title: getTitle() }, props));
};
export var CardContent = function (props) {
    return _jsx(StyledCardContent, __assign({}, props));
};
export var CardActions = function (props) {
    return _jsx(StyledCardActions, __assign({}, props));
};
export var Card = function (_a) {
    var disabledShadow = _a.disabledShadow, sx = _a.sx, props = __rest(_a, ["disabledShadow", "sx"]);
    return _jsx(StyledCard, __assign({}, props, { sx: __spreadArray([disabledShadow && { boxShadow: 'none' }], setSxProps(sx), true) }));
};
