import { useLayoutEffect, useRef, useState } from 'react';
export var useRectSize = function (onResize) {
    var containerRef = useRef(null);
    var _a = useState(), containerRect = _a[0], setContainerRect = _a[1];
    useLayoutEffect(function () {
        var containerEl = containerRef.current;
        if (!containerEl)
            return;
        var myObserver = new ResizeObserver(function () {
            var clientRect = containerEl.getBoundingClientRect();
            setContainerRect(clientRect);
            onResize === null || onResize === void 0 ? void 0 : onResize(clientRect);
        });
        myObserver.observe(containerEl);
        return function () {
            myObserver.unobserve(containerEl);
        };
    }, [onResize]);
    return [containerRef, containerRect];
};
