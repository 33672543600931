var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Link, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as LinkRoute } from 'react-router-dom';
import { withSuspense } from 'react-suspenser';
import { ChartMenu } from '../chartmenu';
import { CardContent, CardHeader } from '../common';
import { AUDIT_EVENTS } from '../constants';
import { Site, SiteContextValue } from '../context';
import { SessionActive } from '../fullscreen';
import { useAudit } from '../hooks';
import { useAccountManagementExcel } from './selectors';
var SLinkRoute = styled(LinkRoute)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.primary,
        textDecoration: 'none',
    });
});
var AddUserButton = withSuspense()(function (props) {
    var t = useTranslation('accountmanagement').t;
    return (_jsx(Button, __assign({ variant: "contained", type: "button", onClick: props.onAddUserClick }, { children: t('button.addUser') })));
});
var OrganizationsLink = withSuspense()(function () {
    var t = useTranslation('accountmanagement').t;
    return (_jsx(Link, __assign({ href: "#", color: "text.primary", underline: "none", component: "span" }, { children: _jsx(SLinkRoute, __assign({ to: "/admin" }, { children: _jsxs(Box, __assign({ display: "flex", columnGap: 2, alignItems: "center", fontSize: "1.5rem" }, { children: [_jsx(FontAwesomeIcon, { icon: ['fal', 'arrow-left-long'] }), t('organizations')] })) })) })));
});
var UsersManagementChartMenu = function () {
    var excelData = useAccountManagementExcel()[0];
    var sendAuditEvent = useAudit()[0];
    return (_jsx(ChartMenu, { fsSession: SessionActive.AccountManagement, excelData: excelData, onExcelExported: function () { return sendAuditEvent({ action: AUDIT_EVENTS.accountManagement.users.export }); } }));
};
var UsersManagementPortalHeader = withSuspense()(function (props) {
    var t = useTranslation('accountmanagement').t;
    return (_jsxs(_Fragment, { children: [_jsx(CardHeader, { title: t('users'), action: _jsx(UsersManagementChartMenu, {}) }), _jsx(CardContent, __assign({ sx: { pb: 0, flex: 'unset' } }, { children: _jsx(AddUserButton, __assign({}, props)) }))] }));
});
var UsersManagementAdminLeftHeader = function (props) {
    return (_jsxs(Box, __assign({ display: "flex", columnGap: 5, alignItems: "center" }, { children: [_jsx(AddUserButton, __assign({}, props)), _jsx(OrganizationsLink, {})] })));
};
var UsersManagementAdminHeader = function (props) {
    return _jsx(CardHeader, { title: _jsx(UsersManagementAdminLeftHeader, __assign({}, props)), action: _jsx(UsersManagementChartMenu, {}) });
};
var UsersManagementHeaderSelector = (_a = {},
    _a[Site.Portal] = UsersManagementPortalHeader,
    _a[Site.Admin] = UsersManagementAdminHeader,
    _a);
export var UsersManagementHeader = function (props) {
    var _a = useContext(SiteContextValue).site, site = _a === void 0 ? Site.Portal : _a;
    var Component = UsersManagementHeaderSelector[site];
    return _jsx(Component, __assign({}, props));
};
