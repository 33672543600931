var _a, _b;
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    backend: {
        loadPath: "/assets/locales/{{lng}}/{{ns}}.json?v=".concat(process.env.REACT_APP_VERSION),
    },
    fallbackLng: 'en',
    supportedLngs: ['en'],
    interpolation: {
        escapeValue: false,
    },
});
(_a = i18n.services.formatter) === null || _a === void 0 ? void 0 : _a.add('dateTime', function (value, lng, option) {
    var format = option.format;
    if (format) {
        return moment(value).format(format);
    }
    return value;
});
(_b = i18n.services.formatter) === null || _b === void 0 ? void 0 : _b.add('skipUndefined', function (value) {
    if (value === undefined) {
        return '';
    }
    if (value === null) {
        return '';
    }
    return value;
});
