export function descendingComparator(a, b, orderBy, sortFunc) {
    if (typeof orderBy !== 'string') {
        throw new Error('orderBy must be a string!');
    }
    if (!sortFunc) {
        throw new Error();
    }
    var valueA = sortFunc(a);
    var valueB = sortFunc(b);
    if (valueB < valueA) {
        return -1;
    }
    if (valueB > valueA) {
        return 1;
    }
    return 0;
}
export function getComparator(order, orderBy, sortFunc) {
    return function (a, b) {
        if (order === 'desc') {
            return descendingComparator(a, b, orderBy, sortFunc);
        }
        else {
            return -descendingComparator(a, b, orderBy, sortFunc);
        }
    };
}
