var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContentText } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from './dialog';
import { ErrorMessageQueueBox } from './messagebox';
export var ConfirmDialog = function (props) {
    var _a;
    return (_jsxs(Dialog, __assign({ open: !!props.open, onClose: props.onAbort }, props.dialogProps, { children: [props.title && _jsx(DialogTitle, { children: props.title }), _jsxs(DialogContent, { children: [_jsx(ErrorMessageQueueBox, { errors: (_a = props.errors) !== null && _a !== void 0 ? _a : [], sx: { mb: 2 } }), _jsx(DialogContentText, { children: props.message })] }), _jsxs(DialogActions, { children: [props.confirmButtonText && (_jsx(LoadingButton, __assign({ loading: props.confirmButtonRequesting, onClick: props.onConfirm, variant: "contained", autoFocus: true }, props.confirmButtonProps, { children: props.confirmButtonText }))), props.abortButtonText && (_jsx(Button, __assign({ disabled: !!props.confirmButtonRequesting, onClick: props.onAbort, variant: "outlined", color: "inherit" }, props.abortButtonProps, { children: props.abortButtonText })))] })] })));
};
