export enum Weekday {
  Weekdays = 1,
  Workdays = 2,
  Weekends = 3,
}
export enum MonthlyScopeEnum {
  PriorMonth = 'prior_month',
  Last30Days = 'last_30_days',
}
export enum WeeklyScopeEnum {
  PriorWeek = 'prior_week',
  Last30Days = 'last_30_days',
  Last7Days = 'last_7_days',
}
export enum DailyScopeEnum {
  Yesterday = 'yesterday',
  Last30Days = 'last_30_days',
  Last7Days = 'last_7_days',
}
export enum OneTimeScopeEnum {
  CustomRange = 'custom_range',
}
