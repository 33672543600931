import { Checkbox } from '@mui/material';
import { AlignTable, HeadCell, NoDataAvailable, Table, checkNoData } from 'common-components';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getUpdatedSelectedRows, parseFieldsFilterData } from './constants';
import { GroupField } from './enums';
import { FilterForm } from './filterform';
import { useAudienceFieldsAPI } from './selectors';
import { FieldConfig, TableProps } from './types';

interface FieldsTableProps extends TableProps {
  selectedRow: string[];
  setSelectedRow: Dispatch<SetStateAction<string[]>>;
}

export const FieldsTable: React.FC<FieldsTableProps> = ({ values, selectedRow, setSelectedRow, registerField }) => {
  const { t } = useTranslation('audienceexport');
  const [audienceFields] = useAudienceFieldsAPI();
  const { fieldsFilter } = useMemo(() => parseFieldsFilterData(audienceFields.data), [audienceFields.data]);
  const noFieldsData = useMemo(() => checkNoData(fieldsFilter), [fieldsFilter]);

  const handleSelectRow = useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      setSelectedRow((prevSelect: any) => getUpdatedSelectedRows(prevSelect, id));
    },
    [setSelectedRow]
  );

  const handleSelectAllRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = fieldsFilter.map((row) => row.id);
      setSelectedRow(newSelected);
    } else {
      setSelectedRow([]);
    }
  };

  const columns = useMemo<Array<HeadCell<FieldConfig>>>(
    () => [
      {
        title: '',
        dataIndex: 'id',
        width: '3rem',
        maxWidth: '3rem',
        align: 'left' as AlignTable,
        render: (id, row: FieldConfig) => (
          <Checkbox
            key={id}
            color="primary"
            checked={selectedRow?.indexOf(row.id) !== -1}
            inputProps={{
              'aria-labelledby': 'labelId',
            }}
            onClick={(event) => handleSelectRow(event, row.id)}
          />
        ),
        isCheckBoxAll: true,
        cellProps: { padding: 'checkbox' },
      },
      {
        title: t('fieldsFilter.fieldConlumn'),
        dataIndex: 'fieldName',
        width: '35%',
        maxWidth: '35%',
        cellProps: { padding: 'checkbox' },
      },
      {
        title: '',
        dataIndex: 'category',
        width: '2rem',
        maxWidth: '2rem',
        breakColumn: true,
      },
      {
        title: t('fieldsFilter.filterConlumn'),
        dataIndex: 'type',
        width: '60%',
        maxWidth: '60%',
        align: 'left' as AlignTable,
        render: (type, row: FieldConfig, index: number) => (
          <FilterForm
            key={`commonFields_${index}`}
            operator={audienceFields.data?.operators}
            groupName={GroupField.CommonFields}
            index={index}
            rowValue={values.commonFields[index]}
            rowConfig={row}
            registerField={registerField}
          />
        ),
      },
    ],
    [registerField, handleSelectRow, selectedRow, t, values.commonFields, audienceFields.data?.operators]
  );

  if (noFieldsData) {
    return <NoDataAvailable />;
  }

  return (
    <Table
      columns={columns}
      dataSources={fieldsFilter}
      tableContainerProps={{ sx: { flex: 1, flexBasis: 0 } }}
      tableProps={{ stickyHeader: true, sx: { tableLayout: 'fixed' } }}
      onSelectAllClick={handleSelectAllRow}
      numSelected={selectedRow?.length}
      rowCount={fieldsFilter.length}
    />
  );
};
