import { FallbackComponent } from 'common-components';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDateRangeState } from '../../selectors';
import { usePageTitleState } from '../../state/app';
import { DateRangeStateEnum } from '../../state/app/appSlice';
import { DEFAULT_VISIT_FILTER_CATEGORY, getDefinitionPayload } from './constants';
import { AudienceExportType, ExportTypeVisitCategory, VisitCategory } from './enums';
import { useProspectCountAPI } from './selectors';
import { AudienceLocationState, FilterFormValues, FilterItem, ProspectCountPayload } from './types';

interface AudienceExportState {
  defaultVisitFilterCategory: VisitCategory;
  prospectCountPayloadRef: MutableRefObject<ProspectCountPayload | undefined>;
  exportType?: AudienceExportType;
  defaultFilters?: FilterItem[];
  dateRange: [Moment, Moment];
  setDateRange: (v: [Moment, Moment]) => void;
  resetDefaultFilters: () => void;
  refreshProspectCount: (values: FilterFormValues, hasError: boolean) => void;
}

const AudienceExportContext = React.createContext<AudienceExportState>({} as AudienceExportState);

export const useAudienceExport = () => useContext(AudienceExportContext);

export const AudienceExportProvider = ({
  children,
  requesting,
}: React.PropsWithChildren<{
  requesting: boolean;
}>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [{ exportType, dateRange: exportDateRangeState, filters: defaultFilters }, setLocationState] =
    useState<AudienceLocationState>(location.state ?? {});
  const [prospectCountRequesting, setProspectCountRequesting] = useState(false);
  const [pageTitle] = usePageTitleState();
  const [appDateRange] = useDateRangeState();
  const [exportDateRange, setExportDateRange] = useDateRangeState(DateRangeStateEnum.Export);
  const [countData, fetchProspectCount, clearProspectCount] = useProspectCountAPI();

  const initialFetchRef = useRef(true);
  const prospectCountPayloadRef = useRef<ProspectCountPayload>();
  const dateRangeRef = useRef<[Moment, Moment]>(
    exportDateRangeState ? [moment(exportDateRangeState[0]), moment(exportDateRangeState[1])] : appDateRange
  );
  const defaultFiltersRef = useRef<FilterItem[]>(defaultFilters ?? []);
  const defaultVisitFilterCategory = exportType ? ExportTypeVisitCategory[exportType] : DEFAULT_VISIT_FILTER_CATEGORY;

  useEffect(() => {
    navigate('.', { replace: true });
    dateRangeRef.current && setExportDateRange(dateRangeRef.current);
  }, [navigate, setExportDateRange]);

  useEffect(() => {
    if (!initialFetchRef.current) return;
    const payload = getDefinitionPayload(
      {
        pageTitle,
        bounceRateReduction: !exportType,
        visitFilterCategory: defaultVisitFilterCategory,
      } as FilterFormValues,
      dateRangeRef.current,
      undefined,
      defaultFiltersRef.current
    );
    fetchProspectCount(payload);
    prospectCountPayloadRef.current = payload;
    setProspectCountRequesting(true);
    initialFetchRef.current = false;
  }, [exportType, pageTitle, exportDateRangeState, fetchProspectCount, setExportDateRange, defaultVisitFilterCategory]);

  useEffect(() => {
    if (countData.requesting !== undefined) setProspectCountRequesting((curr) => curr && !!countData.requesting);
  }, [countData.requesting]);

  const resetDefaultFilters = useCallback(() => {
    setLocationState({});
    setExportDateRange(appDateRange);
  }, [appDateRange, setExportDateRange]);

  const refreshProspectCount = useCallback(
    (values: FilterFormValues, hasError: boolean) => {
      const countPayload = getDefinitionPayload(values, exportDateRange);
      if (!_.isEqual(countPayload, prospectCountPayloadRef.current) && !hasError) {
        fetchProspectCount(countPayload);
        prospectCountPayloadRef.current = countPayload;
      }
    },
    [exportDateRange, fetchProspectCount]
  );

  useEffect(() => {
    return clearProspectCount;
  }, [clearProspectCount]);

  const value: AudienceExportState = useMemo(
    () => ({
      dateRange: exportDateRange,
      exportType,
      defaultFilters,
      defaultVisitFilterCategory,
      prospectCountPayloadRef,
      setDateRange: setExportDateRange,
      resetDefaultFilters,
      refreshProspectCount,
    }),
    [
      exportDateRange,
      exportType,
      defaultFilters,
      defaultVisitFilterCategory,
      setExportDateRange,
      resetDefaultFilters,
      refreshProspectCount,
    ]
  );

  return (
    <AudienceExportContext.Provider value={value}>
      <FallbackComponent requesting={requesting || !!prospectCountRequesting}>{children}</FallbackComponent>
    </AudienceExportContext.Provider>
  );
};
