var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DialogTitle as DialogTitleMUI, Dialog as MuiDialog, DialogContent as MuiDialogContent, ThemeProvider, createTheme, useMediaQuery, } from '@mui/material';
import { useMemo } from 'react';
import { themeLevel3, themeLevel4 } from '../config/theme';
var themeDialog = function (baseTheme) {
    if (baseTheme === void 0) { baseTheme = themeLevel4; }
    return createTheme(baseTheme, {
        components: {
            MuiDialog: {
                defaultProps: {
                    scroll: 'body',
                    fullWidth: true,
                },
                styleOverrides: {
                    paperWidthLg: {
                        maxWidth: themeLevel3.typography.pxToRem(1250),
                        margin: themeLevel3.spacing(4),
                        position: 'unset',
                    },
                    container: {
                        height: '100%!important',
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: "0 ".concat(baseTheme.spacing(6), " ").concat(baseTheme.spacing(4)),
                    },
                },
            },
        },
    });
};
export var DialogTitle = function (props) {
    return _jsx(DialogTitleMUI, __assign({ display: "flex", flexDirection: "row", gap: 5, component: 'div', alignItems: "center" }, props));
};
export var DialogContent = function (props) {
    return (_jsx(ThemeProvider, __assign({ theme: themeLevel4 }, { children: _jsx(MuiDialogContent, __assign({}, props)) })));
};
export var Dialog = function (_a) {
    var fullScreenBreakpoint = _a.fullScreenBreakpoint, baseTheme = _a.baseTheme, props = __rest(_a, ["fullScreenBreakpoint", "baseTheme"]);
    var theme = useMemo(function () { return themeDialog(baseTheme); }, [baseTheme]);
    var fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenBreakpoint !== null && fullScreenBreakpoint !== void 0 ? fullScreenBreakpoint : 0));
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(MuiDialog, __assign({ fullScreen: fullScreen }, props, { maxWidth: !fullScreen && !props.fullScreen && props.maxWidth })) })));
};
export { DialogActions } from '@mui/material';
