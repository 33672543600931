import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Tooltip, baseTheme } from 'common-components';
import { useTranslation } from 'react-i18next';

export const ResetButton = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('translation');
  return (
    <Button
      sx={(theme) => ({
        color: 'white',
        border: 'none !important',
        fontSize: theme.typography.body3.fontSize,
      })}
      variant="text"
      startIcon={<FontAwesomeIcon icon={['fal', 'arrow-rotate-left']} size="xs" />}
      onClick={onClick}
    >
      <Typography component="span" lineHeight={1} variant="body3">
        {t('button.reset')}
      </Typography>
    </Button>
  );
};

interface FieldWithHelperTextProps {
  label: string;
  helperText: string;
}
export const FieldWithHelperText = ({ label, helperText }: FieldWithHelperTextProps) => (
  <Stack direction="row" alignItems="center" spacing={2}>
    <Typography variant="body2">{label}</Typography>
    <Tooltip title={helperText}>
      <Box>
        <FontAwesomeIcon style={{ fontSize: baseTheme.typography.pxToRem(20) }} icon={['fal', 'circle-info']} />
      </Box>
    </Tooltip>
  </Stack>
);
