import { Button, Typography, styled } from '@mui/material';
import {
  AUDIT_EVENTS,
  GA_EVENTS,
  RestrictedBackdrop,
  RoleId,
  important,
  useAudit,
  useGA4,
  usePaymentConfigAPI,
  useUserSessionAPI,
} from 'common-components';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';

const StyledButton = styled(Button)(({ theme }) => ({
  background: important('#000000'),
  borderColor: important('#ffffffde'),
  marginRight: theme.spacing(1),
  color: theme.palette.success.light,
}));

export const RequiredUpgradeInsightBackdrop: React.FC<PropsWithChildren<{ show?: boolean }>> = withSuspense()(
  (props) => {
    const { t } = useTranslation(['webresolution']);
    const [configData] = usePaymentConfigAPI();
    const [userSessionData] = useUserSessionAPI();
    const [, , putEvent] = useGA4();
    const [sendAuditEvent] = useAudit();

    const openCustomerPortalLink = () => {
      if (configData.data && userSessionData.data) {
        putEvent({ category: GA_EVENTS.accountManagement.__name, action: GA_EVENTS.accountManagement.managePlan });
        sendAuditEvent({ action: AUDIT_EVENTS.accountManagement.plan.manage });
        window.open(`${configData.data.paymentCustomerPortalLink}?prefilled_email=${userSessionData.data.email}`);
      }
    };

    return (
      <RestrictedBackdrop
        open={props.show}
        description={
          <Typography variant="body2" component="span">
            {userSessionData.data?.roleId === RoleId.Manager ? (
              <StyledButton variant="outlined" onClick={openCustomerPortalLink}>
                {t('upgrade')}
              </StyledButton>
            ) : (
              t('upgrade')
            )}
            &nbsp;{t('requiredUpgradeInsight')}
          </Typography>
        }
      >
        {props.children}
      </RestrictedBackdrop>
    );
  }
);
