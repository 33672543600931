var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Backdrop as MUIBackdrop, Typography, styled, useMediaQuery, } from '@mui/material';
import { NoDataAvailable } from './nodataavailable';
var StyledBackdrop = styled(MUIBackdrop)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.success.light,
        position: 'absolute',
        top: '0',
        bottom: '0',
        right: '0',
        left: 'unset',
        width: '100%',
        height: '100%',
        borderRadius: theme.typography.pxToRem(8),
        opacity: 0.25,
        backdropFilter: "blur(".concat(theme.typography.pxToRem(8), ")"),
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    });
});
export var Backdrop = function (_a) {
    var description = _a.description, sx = _a.sx, _b = _a.open, open = _b === void 0 ? true : _b, backdropProps = __rest(_a, ["description", "sx", "open"]);
    return (_jsx(StyledBackdrop, __assign({ open: open, sx: __assign({ zIndex: function (themes) { return themes.zIndex.drawer; } }, sx) }, backdropProps, { children: _jsx(Typography, __assign({ variant: "body2", component: "span", textAlign: "center" }, { children: description })) })));
};
export var RestrictedBackdrop = function (_a) {
    var description = _a.description, open = _a.open, backdropProps = _a.backdropProps, children = _a.children;
    var isPrinting = useMediaQuery('print');
    return isPrinting && open ? (_jsx(NoDataAvailable, { description: "text.noRestrictedDataAvailable" })) : (_jsxs(_Fragment, { children: [_jsx(Backdrop, __assign({ description: description, open: open }, backdropProps)), children] }));
};
