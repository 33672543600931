var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
import { formatValue, niceNum } from './number';
export var generateRatioAxisesTicks = function (_a, options) {
    var maxAnchorValue = _a[0], values = _a.slice(1);
    var _b = options || {}, _c = _b.numberOfStep, numberOfStep = _c === void 0 ? 4 : _c, _d = _b.minNumberOfStep, minNumberOfStep = _d === void 0 ? 2 : _d, _e = _b.maxNumberOfStep, maxNumberOfStep = _e === void 0 ? Infinity : _e, _f = _b.availableSpace, availableSpace = _f === void 0 ? 0 : _f, _g = _b.minStepSpace, minStepSpace = _g === void 0 ? 30 : _g, _h = _b.maxStepSpace, maxStepSpace = _h === void 0 ? 150 : _h, _j = _b.includeEndValue, includeEndValue = _j === void 0 ? true : _j;
    var step = niceNum(maxAnchorValue / numberOfStep);
    var stepSpace = (step / maxAnchorValue) * availableSpace;
    var calculateNiceStep = function () {
        if (stepSpace > maxStepSpace) {
            return Math.max(niceNum((maxAnchorValue * maxStepSpace) / availableSpace), niceNum(maxAnchorValue / maxNumberOfStep));
        }
        if (stepSpace < minStepSpace) {
            return Math.min(niceNum((maxAnchorValue * minStepSpace) / availableSpace), niceNum(maxAnchorValue / minNumberOfStep));
        }
        return step;
    };
    var niceStep = calculateNiceStep();
    var maxRange = maxAnchorValue + (includeEndValue ? niceStep : 0);
    var tickAnchorValues = _.range(0, maxRange, niceStep);
    return __spreadArray([
        tickAnchorValues
    ], values.map(function (num) {
        var ref = Number(num / maxAnchorValue) || 0;
        return tickAnchorValues.map(function (v) { return v * ref; });
    }), true);
};
export var generateCountPercentAxisTicks = function (maxPercentValue, maxCountValues, options) {
    if (maxPercentValue === void 0) { maxPercentValue = 0; }
    if (maxCountValues === void 0) { maxCountValues = 0; }
    var _a = generateRatioAxisesTicks([maxPercentValue, maxCountValues], options), percentTickValues = _a[0], countTickValues = _a[1];
    return [
        { tickvals: percentTickValues, ticktext: percentTickValues.map(function (v) { return formatValue(v, { style: 'percent' }); }) },
        {
            tickvals: countTickValues,
            ticktext: countTickValues.map(function (v) { return formatValue(v, { ignoreNotation: 4, autoRound: 2 }); }),
        },
    ];
};
