import { Box, Grid, Paper, PaperProps, Stack, ThemeProvider, Typography, styled, useTheme } from '@mui/material';
import {
  FeatureList,
  HeadCell,
  MouseEventType,
  NumberFormatTypography,
  PlotComponent,
  ProgressBar,
  Table,
  themeLevel4,
  useActiveFullscreenSession,
  useAuthorize,
  useFeaturePermissionAPI,
} from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { withSuspense } from 'react-suspenser';
import useFitText from 'use-fit-text';
import { AudienceExportType } from '../../audienceexport/enums';
import { ChartMenu } from '../../chartmenu';
import { ChartTooltipContainer, ChartTooltipHeader } from '../charttooltip';
import {
  DATE_FORMAT,
  SOURCE_DATE_FORMAT,
  TRAFFIC_DIALOG_MAP,
  VISITOR_BREAKDOWN_OPTIONS,
  VisitorBreakdownEnum,
  VisitorBreakdownExportType,
  getMatchTypeFilter,
  getRestrictedFeatures,
} from '../constants';
import { useClassificationChartData, useHandleExport, useVisitorAPI } from '../selectors';
import { RequiredUpgradeInsightBackdrop } from './upgraderequiredbackdrop';

const StyledPaper = styled(Paper)<PaperProps & { color: string }>(({ theme, color }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  borderLeft: `solid ${theme.typography.pxToRem(10)} ${color}`,
  padding: theme.spacing(3, 6.75),
  position: 'relative',
  minHeight: '10rem',
}));

const StyledProgressBar = styled(ProgressBar)(({ theme }) => ({
  height: theme.typography.pxToRem(20),
  '&::after': {
    fontSize: theme.typography.body3.fontSize,
  },
}));

export interface ClassificationProps {
  type: VisitorBreakdownEnum;
}

interface EmailTooltipProps {
  label?: string;
  value?: { total: number; detail: Array<{ email: string; date: string }> };
  dateRange?: [string, string];
}

interface AvailabilityTooltipProps {
  type: VisitorBreakdownEnum;
  label?: string;
  value?: { total: number; detail: Record<string, number> };
  dateRange?: [string, string];
}

export const EmailToolTip: React.FC<EmailTooltipProps> = (props) => {
  const { t } = useTranslation(['webresolution', 'translation']);
  const columns = useMemo<Array<HeadCell<{ email: string; date: string }>>>(
    () => [
      {
        title: t('tooltip.email'),
        dataIndex: 'email',
      },
      {
        title: t('tooltip.activityDate'),
        dataIndex: 'date',
        render: (date) => moment(date, SOURCE_DATE_FORMAT).format(DATE_FORMAT),
      },
    ],
    [t]
  );

  return (
    <ChartTooltipContainer maxHeight="15.5rem">
      <ChartTooltipHeader
        exportType={AudienceExportType.EmailsProspects}
        dateRange={props.dateRange}
        label={props.label}
        value={props.value?.total}
        exportFilters={[getMatchTypeFilter(VisitorBreakdownEnum.EmailOnly)]}
      />
      <Table
        columns={columns}
        dataSources={props.value?.detail}
        getRowId={(row) => row.email}
        tableContainerProps={{ sx: { flex: 1 } }}
        tableProps={{ stickyHeader: true, sx: { fontSize: '10px' } }}
      />
    </ChartTooltipContainer>
  );
};

export const AvailabilityTooltip: React.FC<AvailabilityTooltipProps> = (props) => {
  const { t } = useTranslation(['trafficinsightdialog', 'webresolution', 'translation']);

  return (
    <ChartTooltipContainer width={'18.125rem'}>
      <ChartTooltipHeader
        exportType={VisitorBreakdownExportType[props.type]}
        dateRange={props.dateRange}
        label={props.label}
        value={props.value?.total}
        exportFilters={[getMatchTypeFilter(props.type)]}
      />
      <Stack spacing={1}>
        {_.map(props.value?.detail, (value, key) => (
          <Box key={key}>
            <Typography variant="body4" marginRight={-14}>
              {t(`dataAvailability.${key}`)}
            </Typography>
            <StyledProgressBar value={value} />
          </Box>
        ))}
      </Stack>
    </ChartTooltipContainer>
  );
};

const TooltipComponent: Record<VisitorBreakdownEnum, React.FC<{ label?: any; value?: any }>> = {
  [VisitorBreakdownEnum.EmailOnly]: EmailToolTip,
  [VisitorBreakdownEnum.EnrichedB2C]: (props) => (
    <AvailabilityTooltip {...props} type={VisitorBreakdownEnum.EnrichedB2C} />
  ),
  [VisitorBreakdownEnum.EnrichedB2B]: (props) => (
    <AvailabilityTooltip {...props} type={VisitorBreakdownEnum.EnrichedB2B} />
  ),
};

const ExportItemName: Record<VisitorBreakdownEnum, string> = {
  [VisitorBreakdownEnum.EmailOnly]: 'exportEmailsProspects',
  [VisitorBreakdownEnum.EnrichedB2C]: 'exportEmailsPlusB2CProspects',
  [VisitorBreakdownEnum.EnrichedB2B]: 'exportEmailsPlusB2BProspects',
};

export const Classification = withSuspense()(({ type }: ClassificationProps) => {
  const [visitorData, fetchData, clearData] = useVisitorAPI();
  const { t } = useTranslation(['webresolution', 'translation', 'trafficinsightdialog']);
  const theme = useTheme();
  const { label, color } = useMemo(() => VISITOR_BREAKDOWN_OPTIONS(theme)[type], [theme, type]);
  const [fullscreenActive] = useActiveFullscreenSession();
  const [total, chartData, isNoData] = useClassificationChartData(type);
  const [featurePermissionData] = useFeaturePermissionAPI();
  const hasAudienceExportPermission = useAuthorize({ featureAccess: [FeatureList.AudienceExport] });
  const { fontSize, ref } = useFitText({ logLevel: 'debug', minFontSize: 40 });
  const handleExport = useHandleExport(VisitorBreakdownExportType[type], { filters: [getMatchTypeFilter(type)] });

  useEffect(() => {
    if (fullscreenActive) {
      fetchData(undefined, true);
      return clearData;
    }
  }, [clearData, fetchData, fullscreenActive]);

  const isRestricted = useMemo(
    () => !!getRestrictedFeatures(type, featurePermissionData.data).length,
    [featurePermissionData.data, type]
  );

  const percent = visitorData.data?.totalCount && `${Math.round((total / visitorData.data.totalCount) * 100)}%`;

  return (
    <ThemeProvider theme={themeLevel4}>
      <StyledPaper color={color} elevation={2}>
        <Grid flex={1} display="flex" flexDirection="column">
          <Box justifyContent="space-between" display="flex">
            <Typography variant="h6">{t(label)}</Typography>
            {!isRestricted && fullscreenActive && (
              <ChartMenu
                fsSession={type}
                trafficType={TRAFFIC_DIALOG_MAP.get(type)}
                onExcelExport={handleExport}
                exportExcelItemName={t(ExportItemName[type])}
                isNoData={isNoData && !isRestricted}
                showExport={hasAudienceExportPermission}
              />
            )}
          </Box>
          <RequiredUpgradeInsightBackdrop show={!!(fullscreenActive && isRestricted)}>
            <Box flex={5} display="flex" gap={1} fontSize={theme.typography.h3.fontSize}>
              <Box>
                <NumberFormatTypography
                  ref={ref}
                  variant="h3"
                  component="div"
                  overflow="hidden"
                  height="fit-content"
                  width={{ xs: '4rem', md: '5.5rem', lg: '7rem' }}
                  hidden={isNoData}
                  fontSize={fontSize}
                  whiteSpace="nowrap"
                  value={total}
                  tooltipProps={{ placement: 'bottom-start' }}
                />
                <Typography hidden={isNoData} variant="h5">
                  {percent}
                </Typography>
              </Box>
              <Box flex={6} paddingTop="0.675rem">
                <PlotComponent
                  data={[
                    {
                      type: 'bar',
                      marker: {
                        color: theme.palette.primary.main,
                      },
                      hoverinfo: 'none',
                      ...chartData,
                    } as any,
                  ]}
                  layout={{
                    yaxis: { showgrid: true },
                  }}
                  xAxisAutoTick={chartData.x as string[]}
                  requesting={!!visitorData.requesting}
                  isNoData={isNoData}
                  TooltipRender={TooltipComponent[type]}
                  showTooltipEvent={MouseEventType.Click}
                  allowTooltipEvents
                />
              </Box>
            </Box>
          </RequiredUpgradeInsightBackdrop>
        </Grid>
      </StyledPaper>
    </ThemeProvider>
  );
});
