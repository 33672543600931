import { CircularProgress, Stack, Typography } from '@mui/material';
import { useDebounce } from 'common-components';
import { useTranslation } from 'react-i18next';
import { useAudienceExport } from './context';
import { useProspectCountAPI } from './selectors';
import { FilterFormValues } from './types';

export const UPDATING_PROSPECT_COUNT_DEBOUNCE_TIME_IN_MS = 1000;

interface ProspectCountProps {
  hasError: boolean;
  values: FilterFormValues;
}

export const ProspectCount = ({ values, hasError }: ProspectCountProps) => {
  const { t } = useTranslation(['audienceexport']);

  const { refreshProspectCount } = useAudienceExport();
  const [prospectCount] = useProspectCountAPI();

  useDebounce(
    () => refreshProspectCount(values, hasError),
    [values, hasError],
    UPDATING_PROSPECT_COUNT_DEBOUNCE_TIME_IN_MS
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="end" spacing={4}>
      <Typography variant="h4">
        {prospectCount.requesting ? (
          <CircularProgress color="success" size={28} />
        ) : (
          <Typography variant="h4" component="span" color="success.main" fontWeight="bold">
            {prospectCount.data?.count.toLocaleString() ?? '--'}
          </Typography>
        )}{' '}
        {t('prospects')}
      </Typography>
    </Stack>
  );
};
