var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
var MAX_BOX_DIAMETER_RATIO = 0.5;
var MIN_BOX_DIAMETER_RATIO = 0.02;
var MIN_BOX_DIAMETER_PIXEL = 5;
export var MouseEventType;
(function (MouseEventType) {
    MouseEventType[MouseEventType["Click"] = 0] = "Click";
    MouseEventType[MouseEventType["Hover"] = 1] = "Hover";
})(MouseEventType || (MouseEventType = {}));
var PlotlyChartType;
(function (PlotlyChartType) {
    PlotlyChartType["Pie"] = "pie";
    PlotlyChartType["Bar"] = "bar";
    PlotlyChartType["ScatterMapbox"] = "scattermapbox";
})(PlotlyChartType || (PlotlyChartType = {}));
var getSizeRef = function (sizes, containerHeight) {
    if (!containerHeight)
        return {};
    var minSize = Math.min.apply(Math, sizes);
    var maxSize = Math.max.apply(Math, sizes);
    var maxDiameter = containerHeight * MAX_BOX_DIAMETER_RATIO;
    var minDiameter = Math.max(containerHeight * MIN_BOX_DIAMETER_RATIO, MIN_BOX_DIAMETER_PIXEL);
    var minRef = minSize / minDiameter;
    var maxRef = maxSize / maxDiameter;
    var sizeRef = maxSize / minRef > maxDiameter ? maxRef : minRef;
    var defaultSize = sizeRef !== minRef ? minSize * (sizeRef / minRef) : undefined;
    return { sizeRef: sizeRef, defaultSize: defaultSize };
};
export var transformPlotData = function (data, containerRect) {
    var _a, _b, _c, _d, _e, _f;
    if (data.type === PlotlyChartType.ScatterMapbox &&
        typeof ((_a = data.marker) === null || _a === void 0 ? void 0 : _a.size) !== 'number' &&
        ((_c = (_b = data.marker) === null || _b === void 0 ? void 0 : _b.size) === null || _c === void 0 ? void 0 : _c.length)) {
        var _g = getSizeRef((_d = data.marker) === null || _d === void 0 ? void 0 : _d.size, containerRect === null || containerRect === void 0 ? void 0 : containerRect.height), sizeRef = _g.sizeRef, defaultSize_1 = _g.defaultSize;
        return __assign(__assign({}, data), { marker: __assign(__assign(__assign({}, data.marker), (defaultSize_1 && { size: (_f = (_e = data.marker) === null || _e === void 0 ? void 0 : _e.size) === null || _f === void 0 ? void 0 : _f.map(function (num) { return Math.max(num, defaultSize_1); }) })), { sizeref: sizeRef }) });
    }
    return data;
};
export var getEventData = function (ev) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = ev.points[0], pointNumber = _j.pointNumber, data = _j.data;
    var _k = ev.event.originalEvent || ev.event, clientX = _k.clientX, clientY = _k.clientY;
    var label, value;
    if (data.type === PlotlyChartType.Pie) {
        label = (_b = (_a = data.customdata) === null || _a === void 0 ? void 0 : _a[pointNumber]) === null || _b === void 0 ? void 0 : _b.label;
        value = (_d = (_c = data.customdata) === null || _c === void 0 ? void 0 : _c[pointNumber]) === null || _d === void 0 ? void 0 : _d.value;
    }
    else {
        label = (_f = (_e = data.customdata) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f[pointNumber];
        value = (_h = (_g = data.customdata) === null || _g === void 0 ? void 0 : _g[1]) === null || _h === void 0 ? void 0 : _h[pointNumber];
    }
    return { label: label, value: value, clientX: clientX, clientY: clientY };
};
export var createVirtualElement = function (clientX, clientY) {
    if (clientX === void 0) { clientX = 0; }
    if (clientY === void 0) { clientY = 0; }
    return ({
        getBoundingClientRect: function () { return ({
            width: 0,
            height: 0,
            x: clientX,
            y: clientY,
            top: clientY,
            left: clientX,
            right: clientX,
            bottom: clientY,
            toJSON: function () { return ''; },
        }); },
    });
};
export var getMaxLength = function (arr) {
    var _a, _b;
    if (arr === void 0) { arr = []; }
    return (_b = (_a = _.maxBy(arr, 'length')) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
};
