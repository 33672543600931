import _ from 'lodash';
import { useMemo } from 'react';
import { AudienceScheduleForm, ScheduleFrequency } from '../../types';
import { ScheduleType } from './constants';

export const useScheduleInfo = (selectedSchedule: string | undefined, schedules: AudienceScheduleForm[]) => {
  const { currentSchedule, scheduleIndex, isNewSchedule, currentType } = useMemo(() => {
    const schedule = _.find(schedules, ['__internalId', selectedSchedule]);
    const index = _.findIndex(schedules, ['__internalId', selectedSchedule]);
    const isNew: boolean = schedule ? !schedule.id : false;
    let scheduleType: ScheduleType = ScheduleType.None;
    if (schedule) {
      scheduleType =
        schedule.frequency === ScheduleFrequency.OneTime ? ScheduleType.NotRecurring : ScheduleType.Recurring;
    }
    return { currentSchedule: schedule, scheduleIndex: index, isNewSchedule: isNew, currentType: scheduleType };
  }, [selectedSchedule, schedules]);

  return { currentSchedule, scheduleIndex, isNewSchedule, currentType };
};
