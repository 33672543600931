var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { usePrintParam } from '../hooks';
import { useActiveFullscreenSession, useFullscreen } from './context';
export var FullscreenHandler = function (_a) {
    var sessionName = _a.sessionName, buttonProps = _a.buttonProps, iconProps = _a.iconProps;
    var handleFullScreen = useFullscreen()[0];
    var activeSession = useActiveFullscreenSession()[0];
    var printPDFParam = usePrintParam()[0];
    if (printPDFParam || activeSession)
        return null;
    return (_jsx(IconButton, __assign({ onClick: function () { return handleFullScreen(sessionName); } }, buttonProps, { children: _jsx(FontAwesomeIcon, __assign({}, __assign({ icon: ['fal', 'expand'] }, iconProps))) })));
};
