import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
  ErrorMessageBox,
  FallbackComponent,
  Tooltip,
  checkNoData,
  themeLevel2,
} from 'common-components';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AudienceAction, AudienceActivity } from './enums';
import { usePaginatedAudienceHistory } from './selectors';
import {
  ACTION_ICON_SIZE,
  DATE_ICON_SIZE,
  StyledTimeline,
  StyledTimelineConnector,
  StyledTimelineContent,
  StyledTimelineDot,
} from './styles';
import { AudienceHistory } from './types';

const DateIcon = ({ date, month, tooltip }: { date: number; month: string; tooltip: string }) => {
  return (
    <Tooltip title={tooltip}>
      <Stack
        sx={({ palette, typography }) => ({
          borderRadius: typography.pxToRem(5),
          boxSizing: 'border-box',
          border: `1px solid ${palette.text.primary}`,
          width: typography.pxToRem(DATE_ICON_SIZE),
          height: typography.pxToRem(DATE_ICON_SIZE),
          overflow: 'hidden',
          textAlign: 'center',
        })}
      >
        <Typography
          sx={({ palette, typography }) => ({
            backgroundColor: palette.text.primary,
            color: themeLevel2.palette.background.default,
            lineHeight: typography.pxToRem(18),
          })}
          variant="body4"
        >
          {month}
        </Typography>
        <Typography
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
          variant="h6"
        >
          {date}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

function AudienceHistoryTimeline({ data }: Readonly<{ data?: AudienceHistory[] }>) {
  const { t } = useTranslation(['audience', 'translation', 'webresolution']);
  const theme = useTheme();

  if (!data) return null;
  return (
    <StyledTimeline>
      {data.map((record, i) => {
        const { id, eventDate, prospectInformation, connectionType: connectionName, action, activity } = record;
        const mDate = moment(eventDate);
        const isLastIdx = i === data.length - 1;
        const actionIcon = (() => {
          if (action === AudienceAction.Automated) {
            return <img src="/assets/images/krateoSquare.png" alt="MS" width="100%" height="100%" />;
          }
          return <FontAwesomeIcon style={{ fontSize: theme.typography.pxToRem(34) }} icon={['fal', 'pen-swirl']} />;
        })();
        const activityContent = (() => {
          const message = (() => {
            // Message for user update
            if (action === AudienceAction.UserPerformed) {
              if (activity === AudienceActivity.Created) {
                return t('audienceHistory.addedProspects', {
                  totalCount: prospectInformation?.addedCount?.toLocaleString(),
                });
              }

              if (activity === AudienceActivity.Updated) {
                return t('audienceHistory.existingProspects', {
                  totalCount: prospectInformation?.existingCount?.toLocaleString(),
                });
              }
            }

            // Message for automated schedule update
            if (!_.isNil(prospectInformation?.sharedCount)) {
              return t('audienceHistory.sharedProspects', {
                totalCount: prospectInformation?.sharedCount?.toLocaleString(),
              });
            }
            if (checkNoData(prospectInformation?.addedCount) && checkNoData(prospectInformation?.removedCount)) {
              return t('audienceHistory.noChanges');
            }
            if (Number(prospectInformation?.addedCount) > 0 && Number(prospectInformation?.removedCount) > 0) {
              return t('audienceHistory.addedAndRemovedProspects', {
                addedCount: prospectInformation?.addedCount?.toLocaleString(),
                removedCount: prospectInformation?.removedCount?.toLocaleString(),
              });
            }
            if (Number(prospectInformation?.addedCount) > 0) {
              return t('audienceHistory.addedProspects', {
                totalCount: prospectInformation?.addedCount?.toLocaleString(),
              });
            }
            return t('audienceHistory.removedProspects', {
              totalCount: prospectInformation?.removedCount?.toLocaleString(),
            });
          })();
          if (activity === AudienceActivity.Created) {
            return {
              title: t('audienceHistory.audienceCreatedActivity'),
              desc: message,
            };
          } else if (activity === AudienceActivity.Updated) {
            return {
              title: t('audienceHistory.audienceUpdatedActivity'),
              desc: message,
            };
          } else if (activity === AudienceActivity.Shared) {
            return {
              title: t('audienceHistory.connectionListShareActivity', { connectionName }),
              desc: message,
            };
          } else {
            return { title: '', desc: '' };
          }
        })();

        return (
          <TimelineItem key={id}>
            <TimelineSeparator>
              <Stack flexDirection={'row'} alignItems={'center'}>
                <StyledTimelineDot />
              </Stack>
              {!isLastIdx && <StyledTimelineConnector />}
            </TimelineSeparator>

            <StyledTimelineContent>
              <Stack flexDirection={'row'} columnGap={theme.typography.pxToRem(15)} alignItems={'center'}>
                <DateIcon
                  date={mDate.date()}
                  month={mDate.format(t('translation:dateTimeFormat.shortMonth') ?? '')}
                  tooltip={mDate.format(t('translation:dateTimeFormat.longDate') ?? '')}
                />

                <Box
                  sx={({ typography }) => ({
                    width: typography.pxToRem(ACTION_ICON_SIZE),
                    height: typography.pxToRem(ACTION_ICON_SIZE),
                  })}
                >
                  {actionIcon}
                </Box>

                <Stack flex={1}>
                  <Typography sx={({ spacing }) => ({ fontWeight: 700, mb: spacing(1) })} variant="body3">
                    {activityContent?.title}
                  </Typography>
                  <Typography variant="body4">{activityContent?.desc}</Typography>
                </Stack>
              </Stack>
            </StyledTimelineContent>
          </TimelineItem>
        );
      })}
    </StyledTimeline>
  );
}

type AudienceHistoryViewProps = {
  audience?: Partial<{
    id: number;
    name: string;
  }>;
};
export const AudienceHistoryTab = ({ audience }: AudienceHistoryViewProps) => {
  const { t } = useTranslation(['audience', 'translation']);
  const [{ data, firstFetching, error, requesting, meta }, fetchMore] = usePaginatedAudienceHistory(audience?.id);

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader
        title={t('audienceHistory.title')}
        subheader={
          <Typography variant="body2" sx={({ spacing }) => ({ mt: spacing(2.7) })}>
            {audience?.name}
          </Typography>
        }
      ></CardHeader>

      <ErrorMessageBox
        sx={({ spacing }) => ({
          mx: spacing(2),
          mt: spacing(1.5),
          mb: spacing(0.625),
        })}
        error={error}
      />

      <CardContent sx={{ flexDirection: 'column', overflow: 'hidden' }}>
        <FallbackComponent
          requesting={!!firstFetching}
          isNoData={meta?.itemCount === 0}
          noDataProps={{
            description: 'audienceHistory.noRecoredHistory',
            ns: 'audience',
          }}
        >
          <Box sx={{ height: '100%', overflow: 'auto' }}>
            <AudienceHistoryTimeline data={data} />
            {meta?.hasNextPage && (
              <LoadingButton sx={{ display: 'block', margin: 'auto' }} loading={!!requesting} onClick={fetchMore}>
                {t('translation:button.more')}...
              </LoadingButton>
            )}
          </Box>
        </FallbackComponent>
      </CardContent>
    </Card>
  );
};
