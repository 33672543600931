import { useCallback, useEffect, useRef, useState } from 'react';
export function useStateWithCallbackLazy(initialValue) {
    var callbackRef = useRef(null);
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    useEffect(function () {
        if (callbackRef.current) {
            callbackRef.current(value);
            callbackRef.current = null;
        }
    }, [value]);
    var setValueWithCallback = useCallback(function (newValue, callback) {
        callbackRef.current = callback || null;
        return setValue(newValue);
    }, []);
    return [value, setValueWithCallback];
}
