import { useAPI } from '../state';
export var Role;
(function (Role) {
    Role["Viewer"] = "Viewer";
    Role["Manager"] = "Manager";
    Role["Administrator"] = "Administrator";
})(Role || (Role = {}));
export var RoleId;
(function (RoleId) {
    RoleId[RoleId["Viewer"] = 1] = "Viewer";
    RoleId[RoleId["Manager"] = 2] = "Manager";
    RoleId[RoleId["Administrator"] = 3] = "Administrator";
})(RoleId || (RoleId = {}));
var GET_ALL_ROLES_API = '/api/role';
export var useRolesAPI = function () {
    return useAPI(GET_ALL_ROLES_API);
};
